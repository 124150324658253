import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { changeLabel } from "../api/labels";

export const colors = [
  '#003BD2',
  '#6C3299',
  '#DE4AF5',
  '#00A5A5',
  '#CC027B',
  '#00C466',
  '#3B6FF5',
  '#5600C4',
  '#0055A6',
  '#614EBD',
  '#0162BF'
];

export type DataType = {
  id:             number;
  status:         'Active' | 'In progress' | 'Error';
  name:           string;
  type:           string;
  color:          string;
  condition:      ConditionType[];
  logicOperator:  '&' | '||';
  editing?:       boolean;
  /* Нужен для отката данных в случае отмены редактирования */
  unsavedData?:   DataType;
};

export type ConditionType = {
  id:       number;
  field:    string;
  operator: '==' | '<' | '>';
  value:    string;
};

type LabelsStateType = {
  data: DataType[];
};

const initialState: LabelsStateType = {
  data: []
};

export const labelsSlice = createSlice({
  name: "labels",
  initialState,
  reducers: {
    setLabelsData(state, action: PayloadAction<DataType[]>) {
      const data = action.payload;
      state.data = data;
    },
    addLabelsRow(state, action: PayloadAction<{ id: number }>) {
      const { id } = action.payload;
      const data = state.data.find(row => row.id === id);
      if (data) {
        data.editing = false;
        
        const { name, type, logicOperator, color, condition } = data;
        changeLabel({
          label_name: name,
          label_type: type,
          logic_operator: logicOperator,
          colour: color,
          label_logic: condition
            .map(c => ({
              labellogic_id: c.id,
              field:         c.field,
              operator:      c.operator,
              value:         c.value
            }))
            .map(c => JSON.stringify(c))
            .join('|')
            .replace(/"/g, '"')
        })
          .then(console.log)
          .catch(console.error)
      }
    },
    changeLabelsRow(state, action: PayloadAction<{ id: number; changes: {}, shouldSave?: boolean }>) {
      const { id, changes, shouldSave } = action.payload;
      const data = state.data.find(row => row.id === id);
      if (data) {
        for (let key in changes) {
          data[key] = changes[key];
        }

        if (shouldSave) {
          const { id, name, type, logicOperator, color, condition } = data;
          changeLabel({
            id,
            label_name: name,
            label_type: type,
            logic_operator: logicOperator,
            colour: color,
            label_logic: condition
              .map(c => ({
                labellogic_id: c.id,
                field:         c.field,
                operator:      c.operator,
                value:         c.value
              }))
              .map(c => JSON.stringify(c))
              .join('|')
              .replace(/"/g, '"')
          })
            .then(console.log)
            .catch(console.error)
        }
      }
    },
    removeLabelsRow(state, action: PayloadAction<{ id: number; shouldSave?: boolean }>) {
      const { id, shouldSave = true } = action.payload;
      const index = state.data.findIndex(row => row.id === id);
      if (index !== -1) {
        const data = state.data[index];
        state.data.splice(index, 1);

        if (shouldSave) {
          const { id, name, type, logicOperator, color, condition } = data;
          changeLabel({
            id,
            label_name: name,
            label_type: type,
            logic_operator: logicOperator,
            colour: color,
            active: 'False',
            label_logic: condition
              .map(c => ({
                labellogic_id: c.id,
                field:         c.field,
                operator:      c.operator,
                value:         c.value
              }))
              .map(c => JSON.stringify(c))
              .join('|')
              .replace(/"/g, '"')
          })
            .catch(console.error)
        }
      }
    },
    addEmptyLabel(state) {
      const id = Math.max(...state.data.map(r => r.id)) + 1;
      state.data.unshift({
        id,
        status:         'In progress',
        name:           '',
        type:           '',
        color:          colors[0],
        condition:      [{
          id:       0,
          field:    '',
          operator: '==',
          value:    ''
        }],
        logicOperator:  '&',
        // Автоматически включаем режим редактирования
        editing:        true,
        unsavedData:    null
      });
    }
  }
});

// Actions

export const { setLabelsData, addLabelsRow, changeLabelsRow, removeLabelsRow, addEmptyLabel } = labelsSlice.actions;

// Selectors

export const labelsSelector = state => state.labels;

export const selectLabelsData = createSelector(
  labelsSelector,
  (state: LabelsStateType) => state.data
);

export default labelsSlice.reducer;