import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useSelector } from 'react-redux';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../store/userSlice';
import { selectDataSourceID } from '../../store/common-slice';

import styles from './DateFilter.module.scss';

const options = [
  { label: 'Custom' },
  { label: 'Today' },
  { label: 'Yesterday' },
  { label: '7d' },
  { label: '30d' },
  { label: '3m' },
  { label: '12m' }
];

type DateFilterProps = HTMLAttributes<HTMLDivElement> & {
  period: number[];
  onChange: (label: string, range?: number[]) => void;
};

export default function DateFilter(props: DateFilterProps) {
  const { period } = props;
  const [active, setActive] = useState<string>('30d');

  const [customRange, setCustomRange] = useState<number[]>([period[0], period[1]]);
  const [ isDatePickerVisible, setIsDatePickerVisible ] = useState(false);

  const dataSourceID = useSelector(selectDataSourceID);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);

  const popup = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне
      if (!popup.current?.contains(e.target)) {
        setIsDatePickerVisible(false)
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const url = window.location.href;

  return (
    <>
      <div {...props} className={classNames(styles.wrapper, props.className)}>
        {options.map((option, index) => {
          if (option.label.toLowerCase() === 'custom') {
            return (
              <div
                key={index}
                ref={popup}
                className={classNames(styles.customDate, active === option.label && 'active')}
              >
                <button
                  className={classNames(active === option.label && 'active', styles.filterButton)}
                  onClick={e => {
                    setActive(option.label);
                    setIsDatePickerVisible(!isDatePickerVisible);
                  }}
                >
                  {option.label}
                </button>

                {isDatePickerVisible &&
                  <div className={styles.dateRangeWrapper}>
                    <DateRange
                      className={styles.dateRange}
                      ranges={[{
                        startDate: new Date(customRange[0] ?? Date.now()),
                        endDate:   new Date(customRange[1] ?? Date.now()),
                        key:       'selection'
                      }]}
                      editableDateInputs='false'
                      moveRangeOnFirstSelection='false'
                      onChange={data => {
                        console.log(data);
                        const { selection } = data;
                        const { startDate, endDate } = selection;
                        setCustomRange([+startDate, +endDate]);
                      }}
                    />

                    <div className={styles.buttons}>
                      <button
                        className={styles.cancel}
                        onClick={() => {
                          setCustomRange([Date.now(), Date.now()]);
                          setIsDatePickerVisible(false);
                        }}
                      >
                        Cancel
                      </button>
            
                      <button
                        className={styles.save}
                        onClick={() => {
                          props?.onChange(option.label, customRange);
                          setIsDatePickerVisible(false);

                          if (isAnalyticsInitialized) {
                            if (url.includes('web-events')) {
                              // @ts-ignore
                              merlin.send({
                                event_name: 'web_events_calendar_filter_applied',
                                event_type: 'click',
                                event_properties: {
                                  data_source_id: dataSourceID, 
                                  list_of_filters: {
                                    startDate: +new Date(customRange[0] ?? Date.now()),
                                    endDate:   +new Date(customRange[1] ?? Date.now())
                                  }
                                },
                                user_properties: {
                                  tenantName
                                }
                              });
                            }

                            if (url.includes('user-journey')) {
                              // @ts-ignore
                              merlin.send({
                                event_name: 'user_journey_calendar_filter_applied',
                                event_type: 'click',
                                event_properties: {
                                  data_source_id: dataSourceID, 
                                  list_of_filters: {
                                    startDate: +new Date(customRange[0] ?? Date.now()),
                                    endDate:   +new Date(customRange[1] ?? Date.now())
                                  }
                                },
                                user_properties: {
                                  tenantName
                                }
                              });
                            }
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                }
              </div>
            )
          }
          else return (
            <button
              key={index}
              className={
                classNames(
                  active === option.label && 'active',
                  styles.filterButton,
                  (index === options.length - 1) && styles.lastFilter
                )
              }
              onClick={e => {
                setActive(option.label);
                props?.onChange(option.label);

                if (isAnalyticsInitialized) {
                  if (url.includes('web-events')) {
                    // @ts-ignore
                    merlin.send({
                      event_name: 'web_events_filter_applied',
                      event_type: 'click',
                      event_properties: {
                        data_source_id: dataSourceID, 
                        list_of_filters: {
                          startDate: +new Date(customRange[0] ?? Date.now()),
                          endDate:   +new Date(customRange[1] ?? Date.now())
                        }
                      },
                      user_properties: {
                        tenantName
                      }
                    });
                  }
                  if (url.includes('wallet-analysis')) {
                    // @ts-ignore
                    merlin.send({
                      event_name: 'wallet_explorer_filter_applied',
                      event_type: 'click',
                      event_properties: {
                        data_source_id: dataSourceID, 
                        list_of_filters: {
                          startDate: +new Date(customRange[0] ?? Date.now()),
                          endDate:   +new Date(customRange[1] ?? Date.now())
                        }
                      },
                      user_properties: {
                        tenantName
                      }
                    });
                }
                }
              }}
            >
              {option.label}
            </button>
          );
        })}
      </div>
    </>
  );
}