import React, { useState } from "react";
import classNames from "classnames";
import { Switch } from "../../../../UI/Switch/Switch";
import { useSelector } from "react-redux";
import { selectDataSourceID } from "../../../../../store/common-slice";
import { selectTable } from "../../../../../store/settingsSlice";
import { setDataSourceID } from "../../../../../utils/data-source-id";
import styles from "./Group.module.scss";

type GroupProps = {
  id:   number;
  name: string;
  ids:  number[];
  onChange?: (id: number) => void;
};

export default function Group(props: GroupProps) {
  const { onChange } = props;

  const dataSourceID = useSelector(selectDataSourceID);

  const table = useSelector(selectTable);
  const rows = table.filter(row =>
    props.ids.find(id => id === row.id
  ));
  const itemsCount = rows.length;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.group}>
      <div className={classNames(styles.groupTitle, !isOpen && "collapsed")}>
        <button
          className={classNames(styles.collapseButton, !isOpen && "collapsed")}
          onClick={e => setIsOpen(!isOpen)}
          aria-label={(isOpen ? 'Hide' : 'Show') + ' content'}
        >
          <img src="/assets/svg/dashboard/collapse.svg" alt="" />
        </button>
        <span className={styles.groupName}>
          {props.name ?? 'My group'}
        </span>

        <span className={styles.number} title={`Has ${itemsCount} item${itemsCount > 1 ? 's' : ''} inside`}>
          {itemsCount}
        </span>

        <Switch
          className={styles.switch}
          checked={dataSourceID === props.id}
          onChange={e => {
            const { checked } = e.target;
            if (checked) {
              setDataSourceID(props.id);
            }
          }}
        />
      </div>
      {isOpen && rows.map(row =>
        <div
          key={row.id}
          className={styles.item}
          onClick={e => {
            if (onChange) {
              onChange(row.id);
            }
          }}
        >
          {row.name}
          {row.id === dataSourceID &&
            <img src="/assets/svg/dashboard/check-mark.svg" alt="" aria-label="Active" />
          }
        </div>
      )}
    </div>
  )
}