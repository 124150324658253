import React, { HTMLAttributes } from "react";

export default function Logo(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      {...props}
      width="112"
      height="25"
      fill="none"
    >
      <path d="M54.705 7.795c-1.728 0-3.16.955-3.932 2.432-.637-1.477-2.182-2.454-4-2.454-1.432 0-2.637.772-3.364 2.045v-1.59H41v11.545h2.477V13c0-1.818 1.091-3.023 2.728-3.023 1.59 0 2.613 1.205 2.613 3.023v6.773h2.477V13c0-1.75 1.16-3.023 2.773-3.023C55.636 10 56.75 11.25 56.75 13v6.773h2.455v-6.977c0-2.91-1.887-5-4.5-5Z" fill="url(#a)"/>
      <path d="M64.263 12.932c.318-1.841 1.682-3.091 3.545-3.091 1.91 0 3.364 1.273 3.637 3.09h-7.182Zm7 3.045c-.432 1.273-1.864 2.182-3.41 2.182-1.885 0-3.317-1.363-3.613-3.409l9.728.023c.022-.273.045-.614.045-.887 0-3.568-2.546-6.113-6.136-6.113-3.591 0-6.182 2.545-6.182 6.09 0 3.705 2.59 6.364 6.204 6.364 2.637 0 5.023-1.636 5.682-3.909l-2.318-.34Z" fill="url(#b)"/>
      <path d="M82.279 7.818c-1.341 0-2.432.818-3.137 2.16v-1.75h-2.409v11.545h2.478v-6.319c0-2.045 1.09-3.5 2.727-3.5.454 0 1.159.091 1.795.364V8.114c-.5-.182-1.022-.296-1.454-.296Z" fill="url(#c)"/>
      <path d="M88.465 3h-2.454v16.773h2.454V3Z" fill="url(#d)"/>
      <path d="M94.51 8.227h-2.455v11.546h2.455V8.227Zm-2.773-3.386c0 .886.636 1.523 1.545 1.523.887 0 1.523-.637 1.523-1.523 0-.864-.636-1.477-1.523-1.477-.909 0-1.545.613-1.545 1.477Z" fill="url(#e)"/>
      <path d="M103.721 9.977c1.727 0 2.75 1.227 2.75 2.932v6.864h2.454v-7.046c0-2.863-1.818-4.954-4.636-4.954-1.523 0-2.977.818-3.727 2.136V8.227h-2.477v11.546h2.477v-6.637c0-1.84 1.432-3.159 3.159-3.159Z" fill="url(#f)"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.922 20.41a3.573 3.573 0 0 1-2.605 1.09c-1.787 0-2.938-1.27-3.62-2.457-.702-1.222-1.16-2.763-1.467-4.18a35.025 35.025 0 0 1-.597-3.921 38.487 38.487 0 0 1-.13-1.754L2.5 9.16v-.01s0-.002 1.499-.06l1.499-.057v.024a14.189 14.189 0 0 0 .023.407c.018.284.049.693.097 1.185.097.99.264 2.291.543 3.578.284 1.31.663 2.498 1.136 3.321.493.859.856.952 1.019.952.207 0 .33-.056.47-.199.186-.187.404-.54.621-1.15.374-1.046.605-2.4.873-3.97.052-.305.105-.619.161-.94.324-1.85.74-3.952 1.607-5.596.445-.845 1.044-1.642 1.877-2.228.852-.598 1.866-.917 3.025-.917 2.439 0 3.997 1.383 4.97 3.107.921 1.635 1.415 3.729 1.808 5.582.068.32.133.633.196.938.326 1.57.608 2.928 1.022 3.979.504 1.277.915 1.394 1.196 1.394h.007a.16.16 0 0 0 .029-.01.729.729 0 0 0 .177-.115c.182-.15.412-.421.669-.85.514-.86.974-2.079 1.36-3.4.38-1.302.663-2.614.852-3.61a43.04 43.04 0 0 0 .261-1.523l.013-.083.003-.02v-.004L31 9.09l1.486.207-.002.01-.004.028a22.718 22.718 0 0 1-.07.462c-.05.31-.125.75-.225 1.277-.2 1.05-.504 2.465-.92 3.89-.411 1.407-.956 2.915-1.666 4.1-.646 1.081-1.756 2.435-3.456 2.435-2.249 0-3.38-1.758-3.986-3.293-.519-1.315-.864-2.985-1.185-4.544l-.178-.852c-.402-1.897-.823-3.553-1.487-4.73-.613-1.089-1.312-1.581-2.356-1.581-.581 0-.985.15-1.3.372-.334.234-.652.609-.948 1.17-.615 1.17-.973 2.817-1.306 4.716l-.145.851c-.264 1.56-.547 3.233-1.019 4.552-.285.797-.686 1.616-1.311 2.248Z" fill="url(#g)"/>
      <defs>
        <linearGradient id="a" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="b" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="c" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="d" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="e" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="f" x1="63.965" y1="-2.168" x2="82.939" y2="33.893" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
        <linearGradient id="g" x1="12.639" y1="-1.9" x2="36.126" y2="16.959" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF"/>
          <stop offset="1" stopColor="#3736FF"/>
        </linearGradient>
      </defs>
    </svg>
  );
}