import * as React from "react";
import { Range } from 'react-range';
import styles from "../RangeInput/RangeInput.module.scss";


const RangeInputTwoThumbs = (props) => {
  const { rtl, step = 0.1, min = 0, max = 100, defaultValueFirstThumb = min, defaultValueSecondThumb = max } =  props
  const [values, setValues] = React.useState([defaultValueFirstThumb, defaultValueSecondThumb]);

  const handleChange = (val) => {
    setValues(val)
    if (props.onChange) props.onChange(val);
  }

  return (
    <div
      className={styles.rangeInput}
      style={{
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        minWidth: '200px'
      }}
    >
      {(values[0] - min) > (max - min) * 0.1 &&
        <span className={styles.rangeInput__min}>{min}</span>
      }
      {(values[1] - min) < (max - min) * 0.9 &&
        <span className={styles.rangeInput__max}>{max}</span>
      }
      <Range
        values={values}
        step={step}
        min={min}
        max={max}
        rtl={rtl}
        onChange={handleChange}
        
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              className={styles.rangeInput__track}
              style={{
                background: (() => {
                  const start = (values[0] - min) / (max - min) * 100;
                  const end   = (values[1] - min) / (max - min) * 100;
                  return `
                    linear-gradient(
                      to right,
                      transparent      0%,
                      transparent      ${start}%,
                      rgb(220, 1, 255) ${start}%,
                      rgb(55, 54, 255) ${end}%,
                      transparent      ${end}%,
                      transparent      100%
                    )
                  `
                })(),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ index, props, isDragged }) => (
          <div
            {...props}
            className={styles.rangeInput__thumb}
            style={{
              ...props.style,
              backgroundColor: index === 0 ? "#DC01FF" : "#3736FF",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div className={styles.rangeInput__tooltip}>
              <span 
                style={{
                  backgroundColor: index === 0 ? "#DC01FF" : "#3736FF",
                  transform: (() => {
                    let offset = '0';
                    if (values[index] > (min + max) * 0.9) {
                      offset = '-50%';
                    }
                    else if (values[index] < (min + max) * 0.1) {
                      offset = '50%';
                    }

                    if (offset !== '0') {
                      offset = `calc(${offset} ${offset.startsWith('-') ? '+' : '-'} 13px)`;
                    }

                    return `translateX(${offset})`
                  })()
                }}
              >
                {values[index].toFixed(1)}
              </span>
              <svg 
                viewBox="0 5 10 5" 
                preserveAspectRatio="none"
                fill={index === 0 ? "#DC01FF" : "#3736FF"}
              >
                <polygon points="0,10 5,5 10,10" />
              </svg>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default RangeInputTwoThumbs;
