import React from 'react';
import ButtomRead from '../../ButtonRead';

import styles from './IBMBlock.module.scss';

const IBMBlock = ({ currentBtn }) => {
  const stylesStepsLoading =
    currentBtn == '1'
      ? '33%'
      : currentBtn == '2'
      ? '66%'
      : '100%';
  return (
    <div className={styles.ibmBlock}>
      <div className={styles.ibmBlockLeft}>
        <img
          className={styles.ibmImg}
          src='assets/svg/figures/foundation.png'
          alt='Ibm'
        />
        {currentBtn === '1' && (
            <div className={styles.title}>
              What’s the distribution
              of monthly active
              users on Foundation?
            </div>
        )}
        {currentBtn === '2' && (
            <div className={styles.title}>
              IBM2 TEXT TEXT TEXT TEXT
              of monthly active
              users on Foundation?
            </div>
        )}
        {currentBtn === '3' && (
            <div className={styles.title}>
              3th TEXT TEXT TEXT TEXT
              of monthly active
              users on Foundation?
            </div>
        )}
{/*        {currentBtn === '1' && (
          <div className={styles.titleTwo}>
            1th TEXT
          </div>
        )}*/}
        {currentBtn === '2' && (
            <div className={styles.titleTwo}>
              2th TEXT
            </div>
        )}
        {currentBtn === '3' && (
            <div className={styles.titleTwo}>
              3th TEXT
            </div>
        )}
{/*        <div className={styles.steps}>
          <img
            className={styles.stepsLoading}
            src={'assets/svg/figures/loadingLine' + `${currentBtn}` + '.svg'}
            alt='Ibm'
          />
          <div className={styles.stepsCount}>{currentBtn} / 3</div>
        </div>*/}
      </div>
      <div className={styles.ibmBlockRight}>
        <div className={styles.ibmBlockRightLeft}>
          <img
            className={styles.logoMeglin}
            src='assets/svg/figures/logo-merlin2.svg'
            alt='Ibm'
          />
          <div className={styles.ibmBlockRightTitleWrapper}>
            {currentBtn === '1' && (
              <div className={styles.ibmBlockRightTitle}>
                35% of the wallets are new users, while 65% are repeat users
              </div>
            )}
            {currentBtn === '2' && (
                <div className={styles.ibmBlockRightTitle}>
                  77% of the TEXT are TEXT users, while 77% are repeat users formation.
                </div>
            )}
            {currentBtn === '3' && (
                <div className={styles.ibmBlockRightTitle}>
                  99% of the wallets TEXT3 are new users, while 99% are repeat users formation.
                </div>
            )}
            {currentBtn === '1' && (
              <div className={styles.ibmBlockRightTitleBottom}>
                Foundation brings back a range of early users. They can further use Merlin to find user cohorts and refine their outreach strategy.
              </div>
            )}
            {currentBtn === '2' && (
                <div className={styles.ibmBlockRightTitleBottom}>
                  2th TEXT brings back a range of early users. They can further use Merlin to find user cohorts and refine their outreach strategy.
                  users. They can further use Merlin to
                  find user cohorts and refine their outreach strategy.
                </div>
            )}
            {currentBtn === '3' && (
                <div className={styles.ibmBlockRightTitleBottom}>
                  TEXT3 brings TEXT3 a range TEXT3 early TEXT3. They can further use Merlin to find user cohorts and refine their outreach strategy.
                  users. They can further use Merlin to
                  find user cohorts and refine their outreach strategy.
                </div>
            )}
            <div className={styles.ibmBlockTabletImg}>
              <img
                src={
                  'assets/svg/figures/foundation_graph.png'
                }
                alt='Ibm'
              />
            </div>
            <div className={styles.ibmBlockRightBottom}>
              <a href='/form'>
                <ButtomRead>Read the story</ButtomRead>
              </a>
            </div>
          </div>
        </div>
        <div className={styles.ibmBlockRightRight}>
          <img
            className={styles.logoFrame41}
            src={'assets/svg/figures/Frame4.png'}
            // src={'assets/svg/figures/Frame' + `${currentBtn}` + '.svg'}
            alt='Ibm'
          />
        </div>
      </div>
    </div>
  );
};

export default IBMBlock;
