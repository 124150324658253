import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const SimpleOnChain = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.topBlock}>
          <h1>Privacy Policy</h1>
          <p className={styles.pBlock}>
          Last Updated: June 21, 2022
          </p>
          <span>
          This Privacy Policy describes the privacy practices of Buidl Labs, Inc. (“Company,” “we”, “us”, or “our”) and how we handle personal information that we collect through this web site (the “Web Site”) and the product demonstration accessible via the Web Site (the “Platform”).  We refer to your ability to access the Web Site and interact with the Platform as our “Services''.
          </span>
        </div>
        <div className={styles.centerBlock}>
        <span>
          Applicability: This Privacy Policy applies to personal data that Company is the controller. This Privacy Policy does not apply to the following information:
          </span>
          <div className={styles.firstList}>
          <ul>
            <li>Personal information about Company employees and candidates, and certain contractors and agents acting in similar roles.</li>
            <li>Personal information that Company processes on behalf of our customers.</li>
          </ul>
          </div>
          <h2>Personal information we collect</h2>
          <span>
          Information you provide to us:
          </span>
          <div className={styles.firstList}>
            <ul>
              <li>Contact information, such as your first and last name, and email address.</li>
              <li>Content you upload through the Services, such as any images through the Services.</li>
              <li>Feedback or correspondence, such as information you provide when you contact us with questions, feedback, product reviews, or otherwise correspond with us online.</li>
              
              <li>Usage information, such as information about how you use the Services and interact with us, including information associated with any content you upload to the Services or otherwise submit to us, and information you provide when you use any interactive features of the Services.  </li>
              <li>Marketing information, such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications.</li>
              <li>Other information that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.</li>
            </ul>
          </div>
          <span>
          Automatic data collection. We and our service providers may automatically log information about you, your computer or mobile device, and your interaction over time with our Services, our communications and other online services, such as:
          </span>
          <div className={styles.firstList}>
            <ul>
              <li>Device data, such as your computer’s or mobile device’s operating system type and version, device id, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings, mobile device carrier, radio/network information (e.g., WiFi, LTE, 4G), and general location information such as city, state or geographic area.</li>
              <li>Online activity data, such as pages or screens you viewed, how long you spent on a page or screen, browsing history, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access, and whether you have opened our marketing emails or clicked links within them.</li>
              
            </ul>
          </div>
          {/* <div className={styles.secondList}>
            <ul>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
              <li>Lorem ipsum dolor sit amet</li>
            </ul>
          </div> */}
        </div>
        <div className={styles.centerBlock}>
          <h2>How we use your personal information</h2>
          <span>
          To operate our Services:
          </span>
          <div className={styles.firstList}>
            <ul>
              <li>Provide, operate, maintain, secure and improve our Services</li>
              <li>Provide information about our Services</li>
              <li>Communicate with you about our Services, including by sending you announcements, updates, security alerts, and support and administrative messages</li>
              <li>Understand your needs and interests, and personalize your experience with our Services and our communications</li>
              <li>Respond to your requests, questions and feedback</li>
            </ul>
          </div>
          <span>
            For research and development. To analyze and improve the Services and to develop new products and Services, including by studying use of our Services.
          </span>
          <span>
            For marketing. We may from time-to-time send you direct marketing communications as permitted by law, including, but not limited to, notifying you of special promotions, offers and events via email and in-app notifications. You may opt out of our marketing communications as described in the “Opt out of marketing communications” section below.
          </span>
          <span>
          To comply with law. As we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.
          </span>
          <span>
          For compliance, fraud prevention, and safety. To: (a) protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); (b) enforce the terms and conditions that govern our Services; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.
          </span>
          <span>
          To create anonymous data. To create anonymous data from your personal information and other individuals whose personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve our Services and promote our business.
          </span>
        </div>

        <div className={styles.centerBlock}>
          <h2>How we share your personal information</h2>
          <span>
          Note that we only share personal information as expressly set forth in this Section. We do not otherwise disclose your personal information to any third parties .
          </span>
          <span>
          Service providers. We may share your personal information with third party companies and individuals that provide services on our behalf or help us operate our Services (such as customer support, hosting, analytics, email delivery, marketing, identity verification, and database management services).
          </span>
          <span>
          Professional advisors. We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.
          </span>
          <span>
          For compliance, fraud prevention and safety. We may share your personal information for the compliance, fraud prevention and safety purposes described above. 
          </span>
          <span>
          Business transfers. We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. In such a case, we will make reasonable efforts to require the recipient to honor this Privacy Policy.
          </span>
         
        </div>

        <div className={styles.centerBlock}>
          <h2>Your choices</h2>
       
          <span>
          Access or update your information. If you have requested marketing materials from us, you may review and update certain personal information by contacting us.
          </span>
          <span>
          pt out of marketing communications. You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email. You may continue to receive service-related and other non-marketing emails. 
          </span>
          <span>
          Opt out of push notifications. If you opt-in to receive push notifications within the application, we may send push notifications or alerts to your mobile device from time to time. You can deactivate push notifications and alerts at any time by changing your device settings, changing the push notification settings within the application, or deleting the application.
          </span>
          <span>
          Do Not Track. Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit.  We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit http://www.allaboutdnt.com.
          </span>
          <span>
          You may submit a complaint about our use of your personal information or our response to your requests by contacting us. European residents may also submit a complaint to the data protection regulator in your jurisdiction. 
          </span>
          
        </div>
        <div className={styles.centerBlock}>
          <h2>Legal Bases for Processing</h2>
       
          <span>
          We use your personal information only as permitted by law. Our legal bases for processing the personal information described in this Privacy Policy are described in the table below.
          </span>

        </div>
        <div className={styles.centerBlock}>
          <h2>Processing purpose</h2>
       
          <span>
          1. Services delivery
          </span>
          <span>
          - Legal basis – Processing is necessary to perform the marketing you have agreed to in connection with the Services. If we have not received your consent, we process your personal information based on our legitimate interest in providing the services you access and request in connection with the Services.
          </span>

          <span>
          2. Research and development
          </span>
          <span>
          - Legal basis – These activities constitute our legitimate interests. We do not use your personal information for these activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).
          </span>

          <span>
          3. Compliance and protection
          </span>
          <span>
          - Legal basis – TThese activities constitute our legitimate interests. We do not use your personal information for these activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). Processing is also necessary to comply with our legal obligations.
          </span>

          <span>
          4. Marketing and advertising
          </span>
          <span>
          - Legal basis – These activities constitute our legitimate interests. We do not use your personal information for these activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).
          </span>

          <span>
          5. With your consent
          </span>
          <span>
          - Legal basis – Processing is based on your consent. Where we rely on your consent you have the right to withdraw it any time in the manner indicated when you consent or in the Services.
          </span>

        </div>
        <div className={styles.centerBlock}>
          <h2>Processing Personal Information in the US</h2>
       
          <span>
          We are headquartered in the United States. To provide our Services and operate our website, it is necessary for us to process personal information in the United States. 
          </span>
          <span>
          If we transfer your personal information to another country such that we are required to apply appropriate safeguards to your personal information under applicable data protection laws, we will do so. Please contact us for further information about any such transfers or the specific safeguards applied.
          </span>
        </div>

        <div className={styles.centerBlock}>
          <h2>Other sites, mobile applications and services</h2>
       
          <span>
          Our Services may contain links to other websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or online services that are not associated with us. We do not control third party websites, mobile applications or online services, and we are not responsible for their actions. Other websites and services follow different rules regarding the collection, use and sharing of your personal information. We encourage you to read the privacy policies of the other websites and mobile applications and online services you use.
          </span>
        
        </div>
        <div className={styles.centerBlock}>
          <h2>Security practices</h2>
       
          <span>
          We use reasonable organizational, technical and administrative measures designed to protect against unauthorized access, misuse, loss, disclosure, alteration and destruction of personal information we maintain. Unfortunately, data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee the security of personal information. 

          </span>
        
        </div>
        <div className={styles.centerBlock}>
          <h2>Children </h2>
       
          <span>
          Our Services are not intended for use by children under 13 years of age. If we learn that we have collected personal information through the Services from a child under 13 without the consent of the child’s parent or guardian as required by law, we will delete it.

          </span>
        
        </div>

        <div className={styles.centerBlock}>
          <h2>Changes to this Privacy Policy</h2>
       
          <span>
          We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on our Services. 

          </span>
        
        </div>

        <div className={styles.centerBlock}>
          <h2>How to contact us</h2>
       
          <span>
          Please direct any questions or comments about this Policy or privacy practices to info@buidllabs.xyz. You may also write to us via postal mail at:
548 Market St, PMB 66389
San Francisco, California, 94104-5401 US

          </span>
        
        </div>
        {/* <div className={styles.bottomBlock}>
          <h2>Lorem ipsum</h2>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default SimpleOnChain;
