import React from 'react';
import Navigation from '../../Navigation/Navigation';
import SubnavPanel from '../SubnavPanel/SubnavPanel';
import Logo from './logo';

import styles from './Sidebar.module.scss'

const Sidebar = () => {
  return (
    <aside className={styles.sidebar}>
      <Logo className={styles.logo} />

      <Navigation />
      <hr />
      <SubnavPanel />

      <a 
        className={styles.link}
        href="https://merlin.canny.io/feature-requests"
        target="_blank"
        rel="noreferrer"
      >
        Submit Feedback
      </a>
    </aside>
  )
}

export default Sidebar;