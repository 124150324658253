import React, { useState } from "react";

import axios from 'axios';


import styles from './FormReg.module.scss';
import Select from 'react-select';
import { PurpleBtn } from "../../../components/UI/Button/PurpleBtn";
import { PurpleBtnDisabled } from "../../../components/UI/ButtonDisabled/PurpleBtnDisabled";
import BlockSent from "../BlockSent/BlockSent";

const FormReg = () => {
    const [showForm, setShowForm] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [showActiveButton, setShowActiveButton] = useState(true);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [extraText, setExtraText] = useState("");
    const [isNameFieldInvalid, setIsNameFieldInvalid] = useState(true);
    const [isEmailFieldInvalid, setIsEmailFieldInvalid] = useState(true);
    


    const options = [
        { value: 'organization1', label: 'Organization1', color: "#498205" },
        { value: 'organization2', label: 'Organization2' },
        { value: 'organization3', label: 'Organization3' }
    ]
    const optionsOrganization = [
        { value: '1-5', label: '1-5', color: "#498205" },
        { value: '6-30', label: '6-30' },
        { value: '31-100', label: '31-100' },
        { value: 'More than 100', label: 'More than 100' },
    ]

    const optionsWhat = [
        { value: 'TrackKeyMetrics', label: 'Track Key Metrics', color: "#498205" },
        { value: 'GetWalletLabels', label: 'Get Wallet Labels' },
        { value: 'CreateUserCohorts', label: 'Create User Cohorts' },
        { value: 'FindTransactionContext', label: 'Find Transaction Context' },
        { value: 'Something else? Mention below', label: 'Something else? Mention below' },
    ]

    const handleSubmit = async (event) => {
        event.preventDefault();

        

        // setShowForm(false);
         if(isNameFieldInvalid === false && isEmailFieldInvalid === false) {
             const token = "keyBGd3kYO2S6XgWd";
                const config = {
                    headers: { 
                        Authorization: `Bearer ${token}`, 
                        'Content-Type': 'application/json' 
                    },
                    
                };
            
            const data = await axios.post(`https://api.airtable.com/v0/appuJxQSSZuUPY5dC/user-signups-prod`, {
                "records": [
                  {
                "fields": {
                    "Name": name,
                    "Email": email,
                    "Project": organization,
                    "Additional Comments": extraText
                }}]}, config)
            console.log("data", data)

            setShowForm(false);
         } else {
            setIsDirty(true);
         }

    };

    const handleCheck = () => {
        setShowActiveButton(!showActiveButton);
    };

    const customStyles = {
        valueContainer: (provided, state) => ({
            ...provided,
            height: "auto",
            paddingTop: "0px",
            paddingBottom: "0px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            fontsize: "10px",
            fontweight: "400",
            fontfamily: "arial",
            color: "#FFFFFF",
        }),
        control: (provided, state) => ({
            ...provided,
            // none of react-select's styles are passed to <Control />
            borderRadius: "12px",
            width: "100%",
            height: "auto",
            minHeight: "24px",
            backgroundColor: "#3F424D",
            padding: "10px 0px",
            marginTop: "15px",
            border: "1px solid rgba(242, 242, 242, 0.15)",
        }),
        placeholder: (provided, state) => ({
            ...provided,
            backgroundColor: "#3F424D",
            lineHeight: "140%",
            // color: "#ffffff",
        }),
        input: (provided, state) => ({
            ...provided,
            paddingBottom: "0px",
            paddingTop: "0px",
            margin: "0px",
            height: "auto",
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: "24px",
            margin: "auto",
            
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
            
        }),
    }

    return (
        showForm ? (
            <div className={styles.wrapper}>
                <div className={styles.wrapperTitle}>
                    Request Access to Merlin
                </div>
                <div className={styles.wrapperTitleTwo}>
                    We are welcoming few teams to our early access program. Fill this out and we’ll reach out shortly.
                </div>
                <div className={styles.wrapperOwner}>
                    <form className={isDirty === true ? styles.dirtyForm : ""} onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="formreg[userName]"
                            placeholder="What do people call you?"
                            className={styles.input}
                            required
                            value={name}
                            onChange={(e) => {setName(e.target.value); setIsNameFieldInvalid(e.target.matches(":invalid"))}}
                        />
                        
                        
                        <input
                            type="email"
                            name="formreg[contactInfo]"
                            placeholder="Email (we won't spam, promise)"
                            className={styles.input}
                            required
                            value={email}
                            onChange={(e) => {setEmail(e.target.value); setIsEmailFieldInvalid(e.target.matches(":invalid")) }}
                            
                        />
                       
                        <input
                            type="text"
                            name="formreg[organization]"
                            placeholder="What are you working on?"
                            className={styles.input}
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                        />
                        {/* <Select styles={customStyles} options={optionsOrganization} placeholder={"Team size"}/>
                        <Select styles={customStyles} options={optionsWhat} placeholder={"What would you like to use Merlin for?"}/> */}
                        <textarea
                            name="formreg[textInfo]"
                            placeholder="Anything else to add?"
                            className={styles.inputTextAreaInfo}
                            wrap={"hard"}
                            value={extraText}
                            onChange={(e) => setExtraText(e.target.value)}

                        />
                        <div className={styles.Btn}>
                            <PurpleBtn onClick={handleSubmit}>Get early access</PurpleBtn>
                        </div>
                        {/* {!showActiveButton && (
                            <div className={styles.Btn}>
                                <PurpleBtn onClick={handleSubmit}>Get early access</PurpleBtn>
                            </div>
                        )}
                        {showActiveButton && (
                            <div className={styles.Btn}>
                                <PurpleBtnDisabled disabled={true}>Get early access</PurpleBtnDisabled>
                            </div>
                        )} */}
                        {/* <div className={styles.wrapperOwner}>
                            <label className={styles.container}>Agree to our Privacy Policy
                                <input type="checkbox" onClick={handleCheck}></input>
                                <span className={styles.checkmark}></span>
                            </label>
                        </div> */}
                    </form>
                </div>
                <div>

                </div>
            </div>
        ) : (
            <BlockSent />
        )
    );
};

export default FormReg;