import React, { useEffect } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import {
  MainPage,
  FormPage,
  DemoPage,
  PrivacyPolicyPage,
  TermsOfServicePage
} from "./pages/index";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { WalletAnalysis } from "./pages/walletAnalysis/WalletAnalysis";
import { UIExamplesPage } from "./pages/uiExamplesPage/UIExamplesPage";
import { AuthPage } from "./pages/authPage/authPage";
import DeepDive from "./pages/deep-dive/DeepDive";
import Settings from "./pages/settings";
import WebEvents from "./pages/web-events";
import UserJourney from "./pages/user-journey";
import NotificationsRecipes from "./pages/notifications-recipes/NotificationsRecipes";
import Partnerships from "./pages/partnerships/Partnerships";

function App() {
  useEffect(() => {
    localStorage.removeItem('certificate');
    localStorage.removeItem('savedPath');
  }, []);

  return (
    <div>
      <Router>
        <Switch>
          <Route exact path={"/"} component={MainPage} />
          <Route path={"/form"} component={FormPage} />
          <Route path={"/demo"} component={DemoPage} />
          <Route path={"/terms-of-service"} component={TermsOfServicePage} />
          <Route path={"/privacy_policy"} component={PrivacyPolicyPage} />
          <Route path={"/dashboard"} component={Dashboard} />
          <Route path={"/wallet-analysis"} component={WalletAnalysis} />
          <Route path={"/web-events"} component={WebEvents} />
          <Route path={"/notifications-recipes"} component={NotificationsRecipes} />
          <Route path={"/partnerships"} component={Partnerships} />
          <Route path={"/deep-dive"} component={DeepDive} />
          <Route path={"/user-journey"} component={UserJourney} />
          <Route path={"/settings"} component={Settings} />
          <Route path={"/auth"} component={AuthPage} />
          <Route path={"/ui-examples"} component={UIExamplesPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
