import { configureStore } from "@reduxjs/toolkit";
import walletAnalysis from "./walletAnalysisSlice";
import settings from "./settingsSlice";
import labels from "./labels-slice";
import cohorts from "./cohorts-slice";
import user from "./userSlice";
import common from "./common-slice";
import webEvents from "./web-events-slice";
import notificationsRecipes from "./notifications-recipes-slice";

export default configureStore({
  reducer: {
    walletAnalysis,
    settings,
    labels,
    cohorts,
    user,
    common,
    webEvents,
    notificationsRecipes
  },
});
