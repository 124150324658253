import axios, { AxiosRequestConfig } from "axios";
import { root } from ".";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";

export const fetchMessage = async (): Promise<{
  id: number;
  login: string;
  has_message: boolean;
}> => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/user_message/get/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession()
    }
  };

  try {
    const { data } = await axios.request(options);
    if (data.value) {
      return data.value;
    }
    return data;
  }
  catch (error) {
    console.log('/user_message/get failed: ', error);
  }
};

export const readMessage = async (): Promise<{
  id: number;
  login: string;
  has_message: boolean;
}> => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/user_message/change/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      has_message: "False"
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  }
  catch (error) {
    console.log('/user_message/change failed: ', error);
  }
};