import styles from './GetStartedTitle.module.scss';

export const GetStartedTitle = () => {
  return (
    <div className={styles.title}>
      <span>Get started in minutes</span>
      <span>Quick & powerful data access options </span>
    </div>
  );
};
