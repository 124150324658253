import React, { useState } from 'react';
import styles from './InfoBlock.module.scss';
import LottieExample from '../../Lottie-emample';

export const InfoBlock = ({
  title,
  subtitle,
  img,
  jsonName,
  widthLottie,
  heightLottie,
  speed,
  isShowOnlyAnimation = false,
  classNameImg,
}) => {
  const lottieProps = {
    jsonName: jsonName,
    width : widthLottie,
    heightLottie: heightLottie,
    speed,
  };

  const [showAnimation, setShowAnimation] = useState(false);

  const handleOnMouseEnter = () => {
    setShowAnimation(true);
  };
  const onMouseLeave = () => {
    setShowAnimation(false);
  };

  return (
    <div 
      className={`${styles.infoBlock}`}
      onMouseLeave={onMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      
    >
      <div className={styles.blockWrap}>
        <div className={`${styles.img} ${ classNameImg }`}>
          {(showAnimation || isShowOnlyAnimation) && <LottieExample lottieProps={lottieProps} />}
          {(!showAnimation && isShowOnlyAnimation == false) && (
            <img src={`assets/svg/figures/${img}`} alt='Search' />
          )}
        </div>
        <div className={styles.description}>
          <span>{title}</span>
          <span>{subtitle}</span>
        </div>
      </div>
    </div>
  );
};
