import React, { HTMLProps, SVGProps } from 'react';
import { Stack } from '@mui/material';
import styles from './Pagination.module.scss';
import classNames from 'classnames';

type PaginationProps = HTMLProps<HTMLDivElement> & {
  itemsPerPage: number;
  total:        number;
  page:         number;
  onChange?:   (page: number) => void;
};

const ArrowIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="7" height="11" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M.901.297a.73.73 0 0 1 1.031 0l-.451.451.451-.451.048.048.134.134A116.462 116.462 0 0 1 4.03 2.461 42.091 42.091 0 0 1 5.49 4.078c.2.238.38.464.513.655.066.094.13.195.181.294.036.068.128.25.128.473 0 .222-.092.405-.128.473-.05.099-.115.2-.181.294a9.481 9.481 0 0 1-.513.655c-.404.48-.935 1.06-1.459 1.617a106.956 106.956 0 0 1-1.917 1.982l-.134.134-.047.047-.516-.515L.9 9.672l.046-.046.13-.132A114.812 114.812 0 0 0 2.97 7.54a40.73 40.73 0 0 0 1.406-1.557c.16-.191.29-.354.384-.482a9.262 9.262 0 0 0-.384-.483 40.737 40.737 0 0 0-1.406-1.556 105.413 105.413 0 0 0-1.891-1.955l-.131-.132-.045-.045a.73.73 0 0 1 0-1.032ZM4.89 5.696c.007.019.01.02 0 0ZM.9 10.703a.73.73 0 0 1 0-1.031l.516.515.515.516a.73.73 0 0 1-1.03 0Z" />
    </svg>
  );
};

const { format } = Intl.NumberFormat();

export default function Pagination(props: PaginationProps) {
  const { itemsPerPage, total, page, onChange } = props;
  const lastPage = Math.ceil(total / itemsPerPage);
  
  if (total === 0) {
    return null;
  }

  return (
    <Stack
      className={classNames(styles.wrapper, props.className)}
      direction="row"
      spacing="10px"
      justifyContent="center"
      style={props.style}
    >
      <button
        disabled={page === 1}
        className={styles.button}
        onClick={e => {
          onChange(1);
        }}
      >
        First
      </button>

      <button
        disabled={page - 1 < 1}
        className={styles.button}
        onClick={e => {
          onChange(page - 1);
        }}
        aria-label="Previous page"
      >
        <ArrowIcon fill="#003BD2" style={{ transform: 'rotate(180deg)' }} />
      </button>

      <span className={classNames(styles.button, styles.display)}>
        {format(page * itemsPerPage - itemsPerPage + 1)}
        {' '}
        -
        {' '}
        {format(Math.min(page * itemsPerPage, total))}
        {' '}
        of {format(total)}
      </span>

      <button
        disabled={page + 1 > lastPage}
        className={styles.button}
        onClick={e => {
          onChange(page + 1);
        }}
        aria-label="Next page"
      >
        <ArrowIcon fill="#003BD2" />
      </button>

      <button
        disabled={page === lastPage}
        className={styles.button}
        onClick={e => {
          onChange(lastPage);
        }}
      >
        Last
      </button>
    </Stack>
  );
}
