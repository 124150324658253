import React from 'react';
import styles from './SubnavPanel.module.scss';

const SubnavPanel = () => {
  return (
    <div className={styles.subnavPanelWrapper}>
      <ul className={styles.bookmarksList}>
        <li>
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.612 15.443c-.386.197-.824-.15-.746-.592l.83-4.73L.173 6.764c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.442-.36.79-.746.591L8 13.188l-4.389 2.256h.001Z" fill="#B9BDC8" />
          </svg>
          <button disabled>
            Pinned Views
          </button>
        </li>

        <li>
          <button disabled>
            My Power Users
          </button>
        </li>

        <li>
          <button disabled>
            Total Revenue View
          </button>
        </li>

        <li>
          <button disabled>
            Wallet Dropoffs
          </button>
        </li>
      </ul>
    </div>
  )
}

export default SubnavPanel;