import React from "react";
import { useState } from "react";
import classNames from "classnames";
import styles from "./style.module.scss";

export default function CopyButton(props) {
  const [success, setSuccess] = useState(false);
  return (
    <button
      aria-label="Скопировать адрес"
      {...props}
      className={classNames(styles.copyButton, props.className)}
      onClick={e => {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 750);
        props?.onClick(e);
      }}
    >
      {success
        ?
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" >
          <path d="M20.664 5.253a1 1 0 0 1 .083 1.411l-10.666 12a1 1 0 0 1-1.495 0l-5.333-6a1 1 0 0 1 1.494-1.328l4.586 5.159 9.92-11.16a1 1 0 0 1 1.411-.082z" fill="currentColor" />
        </svg>
        :
        <svg
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
        >
          <path d="M1.5 2.574v5.614a2.187 2.187 0 0 0 2.044 2.183l.143.004H7.55a1.312 1.312 0 0 1-1.237.875H3.25A2.625 2.625 0 0 1 .625 8.625V3.812A1.312 1.312 0 0 1 1.5 2.575ZM8.063.75a1.313 1.313 0 0 1 1.312 1.313v6.124A1.313 1.313 0 0 1 8.062 9.5H3.689a1.313 1.313 0 0 1-1.313-1.313V2.064A1.313 1.313 0 0 1 3.688.75h4.374Zm0 .875H3.686a.437.437 0 0 0-.437.438v6.124a.437.437 0 0 0 .438.438h4.374a.437.437 0 0 0 .438-.438V2.064a.437.437 0 0 0-.438-.438Z" fill="#B9BDC8"/>
        </svg>
      }
    </button>
  )
}