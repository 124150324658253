import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BarCustom } from '../Charts/CustomBar/BarCustom';
import { ScatterCustom } from '../Charts/ScatterCustom/ScatterCustom';
import { Card } from './Card/Card';
import { ContentTitles } from '../ContentTitles/ContentTitles';
import { downloadImgById } from "../../../utils"
import { fetchQuadrant, fetchInsights, fetchBarcharts } from '../../../api';
import { fetchDataSources } from '../../../api/fetch-data-sources';
import { fetchMessage, readMessage } from '../../../api/message';
import { selectDataSourceID } from '../../../store/common-slice';
import FallbackUI from '../FallbackUI';
import Prelaoder from '../../Preloader/Preloader';
import NotificationPopup from '../../NotificationPopup/NotificationPopup';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../../store/userSlice';

import styles from './OverviewContent.module.scss';

const initialScatterCustomData = {
  axis: {},
  data: [],
  title: {
    main: '',
    subtext: ''
  }
};

const initialBarCustomData = {
  axis: {},
  data: [],
  title: {}
};

const initialContentClockInfo = {
  popular: {},
  trending: {},
  anomalies: {},
  last_updated: ''
};

export const OverviewContent = () => {
  const downloadScatterGraphPng = useRef();
  const downloadBarGraphPng = useRef();

  const [scatterCustomData, setScatterCustomData] = useState(initialScatterCustomData);
  const [isScatterLoading, setIsScatterLoading] = useState(true);
  const [contentClockInfo, setContentClockInfo] = useState(initialContentClockInfo);
  const [barCustomData, setBarCustomData] = useState(initialBarCustomData);
  const [isBarLoading, setIsBarLoading] = useState(true);

  const [error, setError] = useState(null);

  const [needToReload, setNeedToReload] = useState(false);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);
  const dataSourceID = useSelector(selectDataSourceID);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    /*eslint-disable */
    merlin.send({
      event_name: 'insight_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    if (isScatterLoading) return;

    const dots = document.querySelectorAll('.recharts-scatter-symbol');
    const hoverHandle = () => {
      if (isAnalyticsInitialized) {
        /*eslint-disable */
        merlin.send({
          event_name: 'insight_page_quadrant_hover',
          event_type: 'click',
          event_properties: {
            data_source_id: dataSourceID,
          },
          user_properties: {
            tenantName: tenantName
          }
        });
      }
    };

    dots.forEach(element => {
      element.addEventListener('mouseover', hoverHandle, true);
    });

    return () => {
      dots.forEach(element => {
        element.removeEventListener('mouseover', hoverHandle, true);
      });
    };
  }, [isScatterLoading]);

  useEffect(() => {
    fetchDataSources();

    setInterval(async () => {
      const response = await fetchMessage();
      setNeedToReload(response.has_message);
    }, 30_000);
  }, []);

  useEffect(() => {
    if (dataSourceID === -1) return;

    fetchQuadrant()
      .then(response => setScatterCustomData(response))
      .then(() => setIsScatterLoading(false))
      .catch(setError);

    fetchInsights()
      .then(response => setContentClockInfo(response))
      .catch(setError);

    fetchBarcharts()
      .then(response => setBarCustomData(response))
      .then(() => setIsBarLoading(false))
      .catch(setError);
  }, [dataSourceID]);

  const DownloadButton = () => (
    <button className={styles.downloadButton}>
      <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path fill="#B9BDC8" d="M6.124 19.25h11.75a.875.875 0 0 1 .072 1.747l-.072.003H6.124a.875.875 0 0 1-.071-1.747l.071-.003h11.75-11.75Zm5.679-16.122.071-.003a.875.875 0 0 1 .872.803L12.75 4v10.666l2.397-2.396a.875.875 0 0 1 1.237 1.238l-3.889 3.889a.874.874 0 0 1-1.237 0l-3.89-3.889a.875.875 0 0 1 1.238-1.237L11 14.663V4a.875.875 0 0 1 .804-.872l.071-.003-.071.003Z" />
      </svg>
    </button>
  );

  if (error) {
    return <FallbackUI />
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.chartsWrap}>
        <div id="scatter-graph" className={styles.scatterWrap}>
          <div className={styles.flexRow}>
            <ContentTitles
              title={scatterCustomData.title.main}
              subtitle={scatterCustomData.title.subtext}
            />
            <div className={styles.btnWrap}>
              <button
                className={styles.btnDownload}
                onClick={() => downloadImgById('scatter-graph')}
              >
                <DownloadButton />
              </button>
            </div>
          </div>
          <div className={styles.scatterChart}>
            <Prelaoder hidden={!isScatterLoading} />
            <ScatterCustom
              downloadGraphPng={downloadScatterGraphPng}
              scatterCustomData={scatterCustomData}
            />
          </div>
        </div>
        <div id="bar-graph" className={styles.barWrap}>
          <div className={styles.flexRow}>
            <ContentTitles
              title={barCustomData.title.main}
              subtitle={barCustomData.title.subtext}
            />
            <div className={styles.btnWrap}>
              <button
                className={styles.btnDownload}
                onClick={() => downloadImgById('bar-graph')}
              >
                <DownloadButton />
              </button>
            </div>
          </div>
          <div className={styles.barChart}>
            <Prelaoder hidden={!isBarLoading} />
            <BarCustom
              barCustomData={barCustomData}
              downloadGraphPng={downloadBarGraphPng}
            />
          </div>
        </div>
      </div>
      <div className={styles.blocksInfo}>
        <div className={styles.popular}>
          <Card
            title="Popular"
            headline={contentClockInfo?.popular?.headline}
            subtitle={contentClockInfo?.popular?.subtext}
            img="star"
            lastUpdated={contentClockInfo?.last_updated}
          />
        </div>
        <div className={styles.trending}>
          <Card
            title="Trending"
            headline={contentClockInfo?.trending?.headline}
            subtitle={contentClockInfo?.trending?.subtext}
            img="vector"
            lastUpdated={contentClockInfo?.last_updated}
          />
        </div>
        <div className={styles.outliers}>
          <Card
            title="Outliers"
            headline={contentClockInfo?.anomalies?.headline}
            subtitle={contentClockInfo?.anomalies?.subtext}
            img="ring"
            lastUpdated={contentClockInfo?.last_updated}
          />
        </div>

        {/* <EmailSubscribeForm /> */}

        {needToReload &&
          // @ts-ignore
          <NotificationPopup
            title="Data has changed"
            visible={true}
            onClose={readMessage}
          >
            Please reload this window to update.
          </NotificationPopup>
        }
      </div>
    </div>
  );
};
