import React, { useState } from "react";
import { recoverPassword } from "../../../api";
import { TextInput, Button } from '../../../components/UI';
import Logo from "../logo";

import styles from './ForgotPasswordPage.module.scss';

export const ForgotPasswordPage = ({ onBackToLogin }) => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState('');

  function validateEmail(email: string) {
    if (email.length === 0) {
      return 'Please enter email';
    }
    else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      return 'Email address is not valid';
    }
  }

  return (
    <div className={styles.forgotPasswordPage}>
      <div className={styles.wrapper}>
        <Logo className={styles.logo} />
        {isSent
          ?
          <>
            <h3>Password recovery instructions have been sent to your email.</h3>
            <button 
              className={styles.backButton}
              onClick={onBackToLogin}
            >
              Back to Log in
            </button>
          </>
          :
          <div className={styles.content}>
            <h1>Forgot password</h1>
            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>

            <TextInput
              inputType="email"
              placeholder="Email"
              value={email}
              onChange={setEmail}
              status={error ? 'error' : ''}
              style={{
                width: '98%',
                marginBottom: '30px'
              }}
            />

            {error &&
              <p className={styles.error}>{error}</p>
            }

            <Button
              className={styles.reset}
              onClick={async () => {
                const error = validateEmail(email);
                if (error) {
                  setError(error);
                  return;
                }

                const response = await recoverPassword(email);
                if (response.message !== 'Send email with new password') {
                  setError(response.message);
                }
                else {
                  setIsSent(true);
                }
              }}
            >
              Reset password
            </Button>

            <button 
              className={styles.backButton}
              onClick={onBackToLogin}
            >
              Back to Log in
            </button>
          </div>
        }
      </div>
    </div>
  )
}