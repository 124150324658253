import React from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  LineChart as Chart,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
  Legend
} from "recharts";

export default function LineChart({ data, axis, legend = false }) {
  const has2Lines = data[0]?.line2 != null;
  
  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <Chart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey='name'
          tickLine={false}
          axisLine={false}
          tick={false}
        >
          <Label
            value={axis.line1}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
            dy={5}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: '8px' }}
        >
          <Label
            value={axis.line2}
            angle={-90}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
          />
        </YAxis>
        <Tooltip />
        {legend && <Legend />}
        <Line type="linear" dataKey="line1" stroke="#FF6BB0" strokeWidth={2} dot={false} />
        {has2Lines &&
          <Line type="linear" dataKey="line2" stroke="#6C95FF" strokeWidth={2} dot={false} />
        }
      </Chart>
    </ResponsiveContainer>
  )
}