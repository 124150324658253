import React, { useState, useEffect } from "react";
import { Checkbox } from "../"
import styles from './CheckboxMultitude.module.scss';

const data = [
  "Lorem",
  "Ipsum"
];

function CheckboxMultitude(props) {
  const { title, valueList = data, defaultValues = [], onChange = () => { } } = props;

  const checkboxStatusData = valueList.reduce((acc, curr) => {
    acc[curr] = !!defaultValues.includes(curr);
    return acc;
  }, {})

  const [checkboxsStatus, setCheckboxsStatus] = useState(checkboxStatusData);

  const switchCheckboxStatusByKey = (key) => {
    const tempCheckboxsStatus = { ...checkboxsStatus };

    tempCheckboxsStatus[key] = !tempCheckboxsStatus[key];
    setCheckboxsStatus(tempCheckboxsStatus);
  };

  const setCheckboxStatusByKey = async (key, value) => {
    const tempCheckboxsStatus = { ...checkboxsStatus };
    tempCheckboxsStatus[key] = value;
    setCheckboxsStatus(tempCheckboxsStatus);
  };

  useEffect(() => {
    handleChange();
  }, [checkboxsStatus])

  const handleChange = () => {
    const newValues = selectedCheckbox();

    onChange(newValues);
  }

  const selectedCheckbox = () => {
    return Object.keys(checkboxsStatus).map((key) => {
      return key
    }).filter(key => checkboxsStatus[key] === true)
  }

  const selectAll = () => {
    let tempCheckboxsStatus = { ...checkboxsStatus };

    Object.keys(tempCheckboxsStatus).forEach((key) => {
      tempCheckboxsStatus[key] = true;
    });

    setCheckboxsStatus(tempCheckboxsStatus);
  }

  const clearAll = () => {
    let tempCheckboxsStatus = { ...checkboxsStatus };

    Object.keys(tempCheckboxsStatus).forEach((key) => {
      tempCheckboxsStatus[key] = false;
    });

    setCheckboxsStatus(tempCheckboxsStatus);
  };

  const renderCheckboxList = () => {
    return Object.keys(checkboxsStatus)
      .filter(Boolean)
      .map(label => {
        return (
          <Checkbox
            key={label}
            checked={checkboxsStatus[label]}
            onChange={() => switchCheckboxStatusByKey(label)}
          >
            <span title={label}>
              {label}
            </span>
          </Checkbox>)
      });
  };

  return (

    <div className={styles.checkboxMultitude}>
      <div className={styles.checkboxMultitudeHeader}>
        {title && <h4>{title}</h4>}
        {selectedCheckbox().length === Object.keys(checkboxsStatus).length &&
          <a onClick={() => clearAll()}>Clear all</a>
        }
        {selectedCheckbox().length !== Object.keys(checkboxsStatus).length &&
          <a onClick={() => selectAll()}>Select all</a>
        }
      </div>
      <div className={styles.checkboxMultitudeCheckboxList}>
        {renderCheckboxList()}
      </div>
    </div>
  );
}

export default CheckboxMultitude;