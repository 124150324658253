import React, { useState, useEffect, useRef, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './AddDataDropdown.module.scss';

interface AddDataDropdownProps extends HTMLAttributes<HTMLDivElement> {
  onAddContract?: () => void;
  onAddWallet?:   () => void;
  disabled?:         boolean;
}

const AddDataDropdown = ({
  onAddContract,
  onAddWallet,
  disabled = false,
  className
}: AddDataDropdownProps) => {
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);
  const dropdown       = useRef<HTMLDivElement>();
  const dropdownButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне дропдауна
      if (!dropdown.current?.contains(e.target) && !dropdownButton.current?.contains(e.target)) {
        setIsDropdownOpen(false)
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={classNames(styles.addDataDropdownWrapper, className)}>
      <button
        ref={dropdownButton}
        className={classNames(styles.addData, disabled && styles.disabled)}
        disabled={disabled}
        onClick={() => setIsDropdownOpen(!isDropdownOpen) }
      >
        <img
          src={`/assets/svg/dashboard/${disabled ? 'gray' : 'purple'}-plus.svg`}
          alt="Add"
          aria-hidden="true"
        />
        Add data source
      </button>

      {isDropdownOpen && 
        <div 
          ref={dropdown}
          className={styles.selection}
        >
          <ul>
            <li>
              <button
                onClick={() => {
                  setIsDropdownOpen(false);
                  onAddContract();
                }}
                aria-label="Add smart contract"
              >
                <span>Smart contract</span>
                <img src="/assets/svg/dashboard/check-mark.svg" alt="" />
              </button>
            </li>

            <li >
              <button
                onClick={() => {
                  setIsDropdownOpen(false);
                  onAddWallet();
                }}
                aria-label="Add a wallet"
              >
                <span>Wallet</span>
                <div className={styles.comingSoon}>
                  <img src="/assets/svg/dashboard/white-star.svg" alt="white-star" />
                  Coming soon
                </div>
              </button>
            </li>
          </ul>
        </div>
      }
    </div>
  );
};

export default AddDataDropdown;
