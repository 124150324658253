import React from 'react';
import styles from './SimpleOnChain.module.scss';

const SimpleOnChain = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <img
          className={styles.smallStartImage}
          src='assets/svg/figures/small_star.svg'
          alt='small_star'
        />
        <img
          className={styles.littleStar}
          src='assets/svg/figures/not_only_little_star.svg'
          alt='BackgroundImg'
        />
        <div className={styles.title}>
          <span className={styles.titleText}>
            Simple on-chain product analytics
          </span>
        </div>
      </div>
      <div className={styles.titleWrapper}>
        <div className={styles.titleSecond}>
          <span>
            Map wallet journeys, curate incentive campaigns, and augment off-chain data 
            to supercharge marketing and product strategy
          </span>
        </div>
      </div>
    </div>
  );
};

export default SimpleOnChain;
