import React from 'react';
import { HeaderBoard } from '../HeaderBoard/HeaderBoard';
import { OverviewContent } from '../OverviewContent/OverviewContent';
import styles from './Main.module.scss';

export const Main = () => {
  return (
    <div className={styles.wrapMain}>
      <HeaderBoard />
      <OverviewContent />
    </div>
  );
};
