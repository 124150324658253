import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import store from '../store';
import { setStats } from '../store/walletAnalysisSlice';
import { getDataSourceID } from '../utils/data-source-id';
import { getSession, setSession } from '../utils/session';
import { getToken, setToken } from '../utils/token';
import redirectToAuth from '../utils/redirect-to-auth';

const { dispatch } = store;

// За дев считаем сайт на Vercel'е и localhost:3000
export const root = window.location.href.search(/(vercel.app|:3000)/g) !== -1
    ? 'https://dev.getmerlin.site/api'
    : 'https://getmerlin.site/api'

type LoginResponse = {
    token:   string;
    session: string;
    need_change_pass: boolean;
    message: string;
};

export const login = async (username: string, password: string): Promise<LoginResponse> => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/login/`,
        headers: {
            Accept: 'application/json'
        },
        auth: {
            username,
            password
        }
    };

    try {
        const response  = await axios.request(options);
        const data: LoginResponse = response.data;
        localStorage.setItem('username', username);
        setToken(data.token);
        setSession(data.session);
        return data;
    }
    catch (error) {
        console.log('/login failed: ', error);
        throw error?.message;
    }
};

export const logout = async () => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/logout/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession()
        }
    };

    try {
        const { data } = await axios.request(options);
        localStorage.removeItem('username');
        return data;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const changePassword = async (password: string) => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/password-change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            password
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const recoverPassword = async (email: string): Promise<{
    message: string
}> => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/password-recovery/`,
        headers: {
            Accept: 'application/json'
        },
        data: {
            email
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('/password-recovery failed: ', error);
    }
};

export const checkStatus = async (): Promise<{}> => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/merlin_status_checker/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const fetchFilter = async (query: string) => {
    const options: AxiosRequestConfig = {
        method: 'POST',
        url: `${root}/merlin_fetcher/filter/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const filter = data.body.body
        if (filter.message?.search('not in tenant-db')) {
            throw new Error('Not in tenant db');
        }
        return filter;
    }
    catch (error) {
        console.log('/merlin_fetcher/filter failed: ', error);
        throw error?.message;
    }
};

export const fetchQuadrant = async (query: string) => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_fetcher/quadrant/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const quadrant = data.body.body;
        if (quadrant.message?.search('not in tenant-db')) {
            throw new Error('Not in tenant db');
        }
        return quadrant;
    }
    catch(error) {
        console.log('/merlin_fetcher/quadrant failed: ', error);
        throw error;
    }
};

export const fetchBarcharts = async (query: string) => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_fetcher/barchart/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const barchart = data.body.body;
        if (barchart.message?.search('not in tenant-db')) {
            throw new Error('Not in tenant db');
        }
        return barchart;
    }
    catch (error) {
        console.log('/merlin_fetcher/barchart failed: ', error);
        throw error;
    }
};

export const fetchInsights = async (query: string) => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_fetcher/insights/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const insights = data.body.body;
        if (insights.message?.search('not in tenant-db')) {
            throw new Error('Not in tenant db');
        }
        return insights;
    }
    catch (error) {
        console.log('/merlin_fetcher/insights failed: ', error);
        throw error;
    }
};

export const fetchHistogram = async (query?: string) => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_fetcher/histogram/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const histogram = data.body.body;
        return histogram;
    }
    catch (error) {
        console.log('/merlin_fetcher/histogram failed: ', error);
    }
};

export const fetchTable = async (query: string) => {
    const data = {};
    for (let pair of new URLSearchParams(query)) {
        if (data[pair[0]]) {
            data[pair[0]] += '|' + pair[1];
        }
        else {
            data[pair[0]] = pair[1];
        }
    }

    const options = {
        method: 'POST',
        url: `${root}/merlin_fetcher/raw_data/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`,
            'Content-Type': 'multipart/form-data'
        },
        data: {
            session: getSession(),
            ...data,
            data_source_id: getDataSourceID() + ""
        }
    };

    try {
        const { data } = await axios.request(options);
        const table: {
            message?: string;
            data: {
                account_age: number;
                labels:      string;
                source_fund: string;
                syndicate_club_unique_holders: number;
                /** Массив значений. Разделяются символом `;` */
                tokens_top_5: string;
                total_trx_value: number;
                /** Адрес кошелька. Может содержать `|`, после него идет имя кошелька */
                wallet: string;
                /** Имя кошелька */
                ens:    string;
            }[];
            stat1: { title: string, value: number };
            stat2: { title: string, value: number };
            stat3: { title: string, value: number };
        } = data.body.body;
        if (table.message?.search('not in tenant-db')) {
            throw new Error('Not in tenant db');
        }

        table.data = table.data.map(r => {
            const index = r.wallet.indexOf('|');
            if (index !== -1) {
                r.ens = r.wallet.slice(index + 1);
            }
            return r;
        });

        const { stat1, stat2, stat3 } = table;
        dispatch(setStats([stat1, stat2, stat3]));
        return table;
    }
    catch (error) {
        console.log('/merlin_fetcher/raw_data/ failed: ', error);
        throw error?.message;
    }
};


type InitResponse = {
    status:  string;
    task_id: string;
}

export const fetchInit = async (): Promise<InitResponse> => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_init/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            data_source_id: getDataSourceID()
        }
    };

    try {
        const { data } = await axios.request(options);
        const response: InitResponse = data.body;
        if (response.status !== 'ready') {
            throw new Error('Not authorized');
        }
        return response;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

type ContractInfo = {
    /** Название контракта */
    contract_name: string;

    /** Адрес контракта */
    contract_adress: string;

    /** Типа контракта */
    track_type?: 'Wallet' | 'Deployed entities' | 'Contract' | 'Wallet' | 'Group';

    /** Флаг активности, по умолчанию равен `true` */
    active?: "True" | "False";
};

type Contract = {
    active: boolean;
    contract_adress: string;
    date_created: string;
    date_last_edited: string;
    date_updated: string | null;
    id: number;
    name: string;
    num_order: number;
    tenant: string;
    track_type: string;
    type: 'wallet' | 'contract';
};

export const fetchContracts = async (): Promise<{
    message: string;
    value: {
        is_default?:      boolean;
        active:           boolean;
        contract:         Contract[];
        contract_adress:  string;
        /** Путь до CSV файла */
        s3location?:      string;
        date_created:     string;
        date_last_edited: string;
        date_updated:     string | null;
        id:               number;
        name:             string;
        num_order:        number;
        tenant:           string;
        track_type:       'Wallet' | 'Deployed entities' | 'Contract' | 'Wallet' | 'Group';
        type:             'wallet' | 'contract' | 'contract_group';
    }[];
}> => {
    const options = {
        method: 'POST',
        url: `${root}/data_source/view/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession()
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const saveContract = async (body: ContractInfo) => {
    const options = {
        method: 'POST',
        url: `${root}/contract/change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            'contract_name':   body.contract_name,
            'contract_adress': body.contract_adress,
            'track_type':      body.track_type ?? 'Contract',
            'active':          body.active ?? 'True',
        }
    };

    try {
        const { data } = await axios.request(options);
        return data.body;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const changeContract = async (body: ContractInfo & { id: number }) => {
    const options = {
        method: 'POST',
        url: `${root}/contract/change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            'contract_name':   body.contract_name,
            'contract_adress': body.contract_adress,
            'track_type':      body.track_type ?? 'Contract',
            'active':          body.active ?? 'True',
            'id':              body.id
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

type WalletInfo = {
    wallet_name: string;
    s3location:  string;
    id?:         number;
    active?:     'True' | 'False';
};

export const changeWallet = async (body: WalletInfo) => {
    const { wallet_name, s3location, id, active } = body;
    const options = {
        method: 'POST',
        url: `${root}/wallet_list/change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            wallet_name,
            s3location,
            id,
            active: active ?? 'True'
        }
    };

    try {
        const { data } = await axios.request(options);
        return data;
    }
    catch (error) {
        console.log('/wallet_list/change/ failed: ', error);
    }
};

export const saveGroup = async (body: {
    /** Название группы */
    group_name: string;

    /**
     * Список контрактов, разделенный символом `|`
     * 
     * Контракт: `{"contract_adress": "adress", "track_type": "Contract"}`
     * 
     * Если нет контрактов - пустая строка
     **/
	contract: string;

    /** `'False'` при удалении */
	active?: "True" | "False";

    /** Порядковый номер при сортировке (1, 2, 3 или др.) */
	num_order?: number;

    /** При добавление не передаем, при изменении обязательно указываем */
    id?: number;
}) => {
    const options = {
        method: 'POST',
        url: `${root}/contract_group/change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            group_name: body.group_name,
            contract:   body.contract,
            active:     body.active ?? "True",
            num_order:  body.num_order ?? 0,
            id:         body.id
        }
    };

    try {
        const { data } = await axios.request(options);
        return data.body;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const removeGroup = async (body: {
    /** Название группы */
    group_name: string;

    /**
     * Список контрактов, разделенный символом `|`
     * 
     * Контракт: `{"contract_adress": "adress", "track_type": "Contract"}`
     * 
     * Если нет контрактов - пустая строка
     **/
	contract: string;

    /** Порядковый номер при сортировке (1, 2, 3 или др.) */
	num_order?: number;

    /** При добавление не передаем, при изменении обязательно указываем */
    id: number;
}) => {
    const options = {
        method: 'POST',
        url: `${root}/contract_group/change/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            group_name: body.group_name,
            contract:   body.contract,
            active:     'False',
            num_order:  body.num_order ?? 0,
            id:         body.id
        }
    };

    try {
        const { data } = await axios.request(options);
        return data.body;
    }
    catch (error) {
        console.log('Error: ', error);
    }
};

export const fetchUserInfo = async (): Promise<{
    message: string;
    value: {
        date_joined:      string;
        email:            string;
        firstname:        string;
        id:               number;
        is_staff:         boolean
        lastname:         string;
        login:            string;
        need_change_pass: boolean
        tenant:           string;
        tenant_id:        number;
    };
}> => {
    const url = 'get_user_info';
    const options = {
        method: 'POST',
        url: `${root}/${url}/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession()
        }
    };

    try {
        const response = await axios.request(options);
        const { data } = response;
        if (data.message?.startsWith('Session not found')) {
            redirectToAuth();
        }
        return data;
    }
    catch (error) {
        const { response } = (error as AxiosError);
        const { status, statusText } = response;
        console.error(`${url}/ failed: `, status, statusText);
        if (status === 401) {
            redirectToAuth();
        }
    }
};

export const fetchCoverage = async (query: string): Promise<{
    /** Целое значение от 0 до 100 */
    coverage: number;

    /** Пример: `'Based on 40% of the userbase'` */
    ui_text_coverage: string;
    
    /** Дата последнего обновления в формате `dd/mm/yyy` */
    last_updated: string;
    
    /** Пример: `'Last updated: 05/25/2022'` */
    ui_text_last_updated: string;
}> => {
    const options = {
        method: 'POST',
        url: `${root}/merlin_coverage/`,
        headers: {
            Accept: 'application/json',
            Authorization: `Token ${getToken()}`
        },
        data: {
            session: getSession(),
            ...Object.fromEntries(new URLSearchParams(query)),
        }
    };

    try {
        const { data } = await axios.request(options);
        return data?.body?.body;
    }
    catch (error) {
        console.log('merlin_coverage/ failed: ', error?.message);
        return {
            coverage: 0,
            ui_text_coverage: 'Based on 0% of the userbase',
            last_updated: '05/25/2022',
            ui_text_last_updated: 'Last updated: 05/25/2022',
        };
    }
};