import React from "react";
import { DataType } from "../../../store/web-events-slice";

const preventOverflowCSS: React.CSSProperties = {
  overflow:     'hidden',
  textOverflow: 'ellipsis',
  whiteSpace:   'nowrap'
};

const baseColumnStyle: React.CSSProperties = {
  display:  'block',
  maxWidth: '14.2857%',
  minWidth: '14.2857%',
  ...preventOverflowCSS
};

export const columns: {
  name:          string;
  prop:          string;
  style:         React.CSSProperties;
  tdStyle?:      React.CSSProperties | ((data: DataType) => React.CSSProperties);
  render:        Function;
  renderColumn?: Function;
}[] = [
  {
    name: 'Date/Time',
    prop: 'timestamp',
    style: {
      maxWidth:   '14.2857%',
      minWidth:   '14.2857%',
      padding:    '0 .75em',
      display:    'flex',
      alignItems: 'flex-start'
    },
    render: (timestamp: number) => {
      function formatAMPM(date: Date) {
        let hours   = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        // @ts-ignore
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes + ' ' + ampm;
      }

      const date = new Date(timestamp);
      const month = ('' + date).split(' ')[1];
      const day   = ('' + date).split(' ')[2];
      return `${month} ${day}, ${formatAMPM(date)}`;
    }
  },
  {
    name: 'Event name',
    prop: 'name',
    style: {
      maxWidth:   '14.2857%',
      minWidth:   '14.2857%',
      padding:    '0 .75em',
      ...baseColumnStyle
    },
    render: (name: string) => name
  },
  {
    name: 'Event type',
    prop: 'type',
    style: {
      boxSizing: 'border-box',
      padding:   '0 .75em',
      ...baseColumnStyle
    },
    render: (type: string) => type
  },
  {
    name: 'Wallet address/UserID',
    prop: 'id',
    style: {
      boxSizing: 'border-box',
      padding:   '0 .75em',
      ...baseColumnStyle
    },
    render: (id: string, data: DataType) => {
      const { wallet } = data;
      return wallet ? wallet : id;
    }
  },
  {
    name: 'User properties',
    prop: 'user',
    style: {
      boxSizing: 'border-box',
      padding:   '0 .75em',
      ...baseColumnStyle
    },
    render: (user: string) => {
      try {
        const obj = JSON.parse(user);
        const keys = Object.keys(obj);

        if (keys.length === 0) {
          return '-';
        }

        return keys.map((key, index) =>
          <dl key={index}>
            <dt>{key}: </dt>
            {typeof obj[key] === 'object'
              ? JSON.stringify(obj[key])
              : obj[key]
            }
          </dl>
        )
      }
      catch (e) {
        return user;
      }
    }
  },
  {
    name: 'Event properties',
    prop: 'event',
    style: {
      boxSizing: 'border-box',
      padding:   '0 .75em',
      ...baseColumnStyle
    },
    render: (event: string) => {
      try {
        const obj = JSON.parse(event);
        const keys = Object.keys(obj);

        if (keys.length === 0) {
          return '-';
        }
        
        return keys.map(key =>
          <dl>
            <dt>{key}: </dt>
            <dd style={preventOverflowCSS}>
              {typeof obj[key] === 'object'
                ? JSON.stringify(obj[key])
                : obj[key]
              }
            </dd>
          </dl>
        );
      }
      catch (e) {
        return event;
      }
    }
  },
  {
    name: 'Browser Type',
    prop: 'browser',
    style: {
      boxSizing: 'border-box',
      padding:   '0 .75em',
      ...baseColumnStyle
    },
    render: (browser: string) => browser
  }
];