import React, { useEffect, useRef, useState } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { addRecipe, setIsPopupActive } from '../../../store/notifications-recipes-slice';
import { SelectInput, TextInput } from '../../../components/UI';
import PlusIcon from '../../../components/UI/Icons/PlusIcon';

import styles from './NotificationsPopup.module.scss';

export default function NotificationsPopup({ className }, props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [name, setName] = useState('');
  const [eventType, setEventType] = useState('');
  const [daysAgo, setDaysAgo] = useState('');
  const [token, setToken] = useState('');
  const [address, setAddress] = useState('');
  const [cohort, setCohort] = useState('Power User');
  const [conditions, setConditions] = useState<{
    filed: string;
    operator: string;
    value: string;
  }[]>([{
    filed:    'Wallet Age',
    operator: 'equal',
    value:    '0'
  }]);
  const [message, setMessage] = useState('');
  const [action, setAction] = useState('telegram');
  const [icon, setIcon] = useState('/assets/socials/telegram.svg');

  const dispatch = useDispatch();
  const popup = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleClickOutside = e => {
      // клик вне
      if (!popup.current?.contains(e.target)) {
        dispatch(setIsPopupActive(false));
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const steps = ['Events', 'Parameters', 'Message'];

  return (
    <div
      {...props}
      ref={popup}
      className={classNames(className, styles.NotificationsRecipesPopup)}
    >
      <div className={styles.steps}>
        {(() => {
          return (
            steps.map((name, index) =>
              <div
                key={index}
                className={classNames(
                  styles.step,
                  (index + 1 > currentStep) && styles.stepInactive
                )}
              >
                <span className={styles.stepNumber}>{index + 1}</span>
                <span className={styles.stepName}>{name}</span>
              </div>
            )
          )
        })()}
      </div>

      {currentStep === 1 &&
        <>
          <TextInput
            placeholder="Add a recipe name"
            value={name}
            onChange={setName}
            style={{ width: '335px' }}
          />

          <div className={styles.eventTypesContainer}>
            <span className={styles.mainTitle}>Event type</span>
            <div className={styles.eventTypesContainer}>
              {(() => {
                let eventTypes = [
                  'Deposit',
                  'Withdraw',
                  'Interacted with a contract',
                  'Started NFT creation',
                  'Connect Wallet',
                  'Complete NFT creation'
                ];
                return (
                  eventTypes.map((eventName, index) => [
                    <button
                      key={index}
                      className={eventType === eventTypes[index] && styles.selected}
                      onClick={() => setEventType(eventName)}
                    >
                      {eventName}
                    </button>
                  ])
                )
              })()}
            </div>
          </div>

          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <div className={styles.inputGroup}>
              <span className={styles.inputGroupTitle}>
                Days ago
              </span>

              <input
                type="number"
                placeholder='enter number'
                value={daysAgo}
                onChange={e => {
                  const { value } = e.target;
                  setDaysAgo(value);
                }}
                style={{ width: '158px' }}
              />
            </div>

            {['Deposit', 'Withdraw'].indexOf(eventType) !== -1 &&
              <div className={styles.inputGroup}>
                <span className={styles.inputGroupTitle}>
                  Token
                </span>

                <input
                  value={token}
                  onChange={e => {
                    const token = e.target.value;
                    setToken(token);
                  }}
                  style={{ width: '158px' }}
                />
              </div>
            }

          {['Interacted with a contract'].indexOf(eventType) !== -1 &&
            <div className={styles.inputGroup}>
              <span className={styles.inputGroupTitle}>
                Contract address
              </span>

              <input
                value={address}
                onChange={e => {
                  const address = e.target.value;
                  setAddress(address);
                }}
                style={{ width: '158px' }}
              />
            </div>
          }
          </div>
        </>
      }

      {currentStep === 2 &&
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <div className={styles.inputGroup} style={{ gap: 0 }}>
              <span className={styles.inputGroupTitle}>
                Cohort
              </span>
              <SelectInput
                options={[
                  'Power User',
                  'Active on NFT Platform',
                  'Recently Active',
                  'Airdrop Farmer'
                ]}
                defaultValue={cohort}
                onChange={setCohort}
                width="310px"
              />
            </div>

            <div className={styles.inputGroup} style={{ gap: 0 }}>
              <span className={styles.inputGroupTitle}>
                Wallet Conditions
              </span>
              {conditions.map((condition, index) =>
                <div key={index} style={{ display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
                  <SelectInput
                    options={[
                      'Wallet Age',
                      'ETH balance',
                      'NFT Balance',
                      'L2 chain',
                      'NFT Project Created'
                    ]}
                    defaultValue={condition.filed}
                    onChange={filed => {
                      const nextConditions = produce(conditions, draft => {
                        draft[index].filed = filed;
                      });
                      setConditions(nextConditions);
                    }}
                    width="235px"
                  />
                  <SelectInput
                    options={['>', '<', 'equal', 'not equal']}
                    defaultValue={condition.operator}
                    onChange={operator => {
                      const nextConditions = produce(conditions, draft => {
                        draft[index].operator = operator;
                      });
                      setConditions(nextConditions);
                    }}
                    width="130px"
                  />
                  <TextInput
                    value={condition.value}
                    onChange={value => {
                      const nextConditions = produce(conditions, draft => {
                        draft[index].value = value;
                      });
                      setConditions(nextConditions);
                    }}
                    style={{ width: '115px' }}
                  />

                  {index === conditions.length - 1
                    ?
                    <button
                      className={styles.addConditionButton}
                      onClick={e => {
                        const nextConditions = produce(conditions, draft => {
                          draft.push({
                            filed: 'Wallet Age',
                            operator: 'equal',
                            value: '0'
                          });
                        });
                        setConditions(nextConditions);
                      }}
                    >
                      <PlusIcon />
                    </button>
                    :
                    <span className={styles.conditionsSeparator}>&</span>
                  }
                </div>
              )}
            </div>
          </div>
        </>
      }

      {currentStep === 3 &&
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <div className={styles.info}>
              {name &&
                <h4 className={styles.infoName}>{name}</h4>
              }

              <div className={styles.infoLists}>
                <dl>
                  <div>
                    <dt>Event type:</dt>
                    <dd>{eventType}</dd>
                  </div>
                  <div>
                    <dt>Days ago:</dt>
                    <dd>{daysAgo}</dd>
                  </div>
                  {token &&
                    <div>
                      <dt>Token:</dt>
                      <dd>{token}</dd>
                    </div>
                  }
                </dl>

                <dl>
                  <div>
                    <dt>Cohort:</dt>
                    <dd>{cohort}</dd>
                  </div>
                  <div>
                    <dt>Wallet Conditions</dt>
                    <dd>
                      {conditions
                        .map(c => `${c.filed} ${c.operator} ${c.value}`)
                        .reduce((acc, x) => {
                          const separator = <>&<br /></>;
                          return acc === null
                            ? x
                            : <>{acc} {separator} {x}</>
                        }, null)
                      }
                    </dd>
                  </div>
                </dl>
              </div>
            </div>

            <div className={styles.inputGroup} style={{ width: '100%' }}>
              <span className={styles.inputGroupTitle}>
                Message template
              </span>

              <textarea
                value={message}
                onChange={e => {
                  const { value } = e.target;
                  setMessage(value);
                }}
                style={{ boxSizing: 'border-box', width: '100%' }}
              />
            </div>

            <div className={styles.inputGroup} style={{ width: '100%' }}>
              <span className={styles.inputGroupTitle}>
                Choose an action
              </span>

              <ul className={styles.socials}>
                {[
                  {
                    name: 'Blockscan',
                    icon: '/assets/socials/blockscan.svg',
                    subtitle: 'Send a sweet'
                  },
                  {
                    name: 'EPNS',
                    icon: '/assets/socials/epns.svg',
                    subtitle: 'Send an email'
                  },
                  {
                    name: 'Webhook',
                    icon: '/assets/socials/webhook.svg',
                    subtitle: 'Setup a request'
                  }
                ].map((option, index) =>
                  <li key={index}>
                    <button
                      className={classNames(styles.socialsTile, option.name.toLowerCase() === action && 'active')}
                      onClick={e => {
                        setAction(option.name.toLowerCase());
                        setIcon(option.icon);
                      }}
                    >
                      <span className={styles.socialsTileIconWrapper}>
                        <img src={option.icon} alt="" />
                      </span>
                      <span className={styles.socialsTileStack}>
                        <h5 className={styles.socialsTileTitle}>{option.name}</h5>
                        <span className={styles.socialsTileSubtitle}>{option.subtitle}</span>
                      </span>
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </>
      }

      <div className={styles.bottomSection}>
        <small>
          Have suggestions? Submit
          <a href='https://merlin.canny.io/feature-requests'>
            feedback
          </a>.
        </small>

        <div className={styles.buttonsContainer}>
          <button
            className={styles.cancel}
            onClick={() => {
              if (currentStep === 1) {
                dispatch(setIsPopupActive(false));
              }
              else {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            {currentStep === 1 ? 'Cancel' : 'Back'}
          </button>
          <button
            className={styles.nextButton}
            onClick={e => {
              if (currentStep === steps.length) {
                dispatch(addRecipe({ name, icon, description: message }));
                dispatch(setIsPopupActive(false));
              }
              else {
                setCurrentStep(currentStep + 1);
              }
            }}
          >
            {currentStep === steps.length ? 'Save' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  )
}