import React from "react";
import { Column } from "../column";
import styles from "./TableHead.module.scss";

export default function TableHead({ columns }: { columns: Column[] }) {
  return (
    <div className={styles.tableHead}>
      {columns.map(column => (
        <div key={column.prop} style={column.style}>
          {typeof column.name === 'function' ? column.name() : column.name}
        </div>
      ))}
    </div>
  )
}