import React, { useEffect, useCallback } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
  Legend,
} from 'recharts';
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import './BarCustom.scss';

export const BarCustom = (props) => {
  const { barCustomData, downloadGraphPng, legend } = props;

  const [getAreaPng, { ref: areaRef }] = useCurrentPng();

  useEffect(() => {
    if (downloadGraphPng) {
      downloadGraphPng.current = handleAreaDownload;
    }
  }, []);

  const handleAreaDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      FileSaver.saveAs(png, "bar-chart.png");
    }
  }, [getAreaPng]);

  const CustomTooltip = ({ payload }) => {
    if (payload?.length) {
      return (
        <div className="custom-tooltip custom-tooltip--bar">
          <p className="label">{payload[0].payload.name}</p>
          <p><span>{payload[0].name}:</span> {payload[0].payload[payload[0].name]}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <ResponsiveContainer className='bar-chart'>
      <BarChart data={barCustomData.data} ref={areaRef}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' tickLine={false} tick={{ fontSize: '8px' }}>
          <Label
            value={barCustomData.data && barCustomData.axis['x-axis']}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
            dy={10}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          type='number'
          tick={{ fontSize: '8px' }}
        >
          <Label
            value={barCustomData.data && barCustomData.axis['y-axis']}
            angle={-90}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
          />
        </YAxis>
        <Tooltip 
          cursor={{ fill: 'rgba(73, 122, 248, 0.05)' }}
          allowEscapeViewBox={{ x: true, y: true }}
          content={<CustomTooltip />}
        />
        {legend && <Legend />}
        <Bar dataKey='wallets' fill='#497AF8' radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
