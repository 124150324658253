import React from 'react';
import styles from './AnswerBlock.module.scss';
import { ContentBlock } from './ContentBlock/ContentBlock';

const AnswerBlock = ({ title, subtitle, btnText, children, secondary }) => {
  return (
    <>
      <div className={styles.wrapperBlocks}>
        <div className={styles.answerBlock}>
          <ContentBlock
            title={title}
            subtitle={subtitle}
            btnText={btnText}
            secondary={secondary}
          />
          {children}
        </div>
      </div>
    </>
  );
};

export default AnswerBlock;
