import React, {useRef, useEffect, useState} from 'react';

import { ResponsiveSankey } from '@nivo/sankey'
import { linearGradientDef } from '@nivo/core'

import styles from './SankeyChart.module.scss';

export const SankeyChart = () => {




    const [followMe, setFollowMe] = useState(false);

    useEffect(() => setFollowMe(true), []);
    useEffect(() => { if(followMe === true) {
      setTimeout(() => {
      const sankeyChartWrapper = document.getElementById('sankeyChartWrapperId')
          const SVGs = sankeyChartWrapper.getElementsByTagName("svg")
          if(SVGs.length > 0) {
            const mainSVG = SVGs[0];
            console.log("mainSVG", mainSVG)
            const texts = mainSVG.getElementsByTagName('text')
            console.log("texts", texts)
            
            for (let index = 0; index < texts.length; index++) {
              const element = texts[index];
              if(element.innerHTML === "MetaMask 62%") {
                element.setAttribute("transform", "translate(140, 100) rotate(0)");
              }
              if(element.innerHTML === "WalletConnect 38%") {
                element.setAttribute("transform", "translate(130, 195) rotate(0)");
              }
              // if(element.innerHTML === "Place Bid 42%") {
              //   element.setAttribute("transform", "translate(320, 130) rotate(0)");
              // }
            }
          }
        }, 100);
    //doSomething
    }}, [followMe]);


    const data ={
        "nodes": [
            {
                "id": "Visitors 100%",
                "nodeColor": "#003BD2",
            },
            {
                "id": "MetaMask 62%",
                "nodeColor":"#DC01FF"
            },
            {
                "id": "WalletConnect 38%",
                "nodeColor": "#DC01FF"
            },
            {
                "id": "Vote 12%",
                "nodeColor": "hsl(244, 70%, 50%)"
            },
            {
                "id": "Mint NFT 23%",
                "nodeColor": "hsl(270, 70%, 50%)"
            },
            {
              "id": "Place Bid 42%",
              "nodeColor": "#003BD2"
            },
            {
              "id": "Create Bounty 15%",
              "nodeColor": "#DC01FF"
            }
        ],
        "links": [
          {
            "source": "Visitors 100%",
            "target": "MetaMask 62%",
            "value": 100,
            "nodeColor": "#003BD2",
          },
          {
            "source": "Visitors 100%",
            "target": "WalletConnect 38%",
            "value": 38
          },
          {
            "source": "MetaMask 62%",
            "target": "Vote 12%",
            "value": 12
          },
          {
            "source": "MetaMask 62%",
            "target": "Mint NFT 23%",
            "value": 30
          },
          {
            "source": "MetaMask 62%",
            "target": "Place Bid 42%",
            "value": 60
          },
          {
            "source": "WalletConnect 38%",
            "target": "Place Bid 42%",
            "value": 30
          },
          {
            "source": "WalletConnect 38%",
            "target": "Create Bounty 15%",
            "value": 15
          },
        ]
      } 
      const tooltip = ({node}) => {
        if(node.label === 'Visitors 100%') {
          return (
            <div className={styles.tooltipBar}>
              <div className={styles.flexRow}>           
                <span>Unique visits:</span>
                <span className={styles.tooltipProp}>20,321</span>
              </div>
            </div>
          )
        }
        if(node.label === "MetaMask 62%") {
          return (
            <div className={styles.tooltipBar}>
              <div className={styles.flexRow}>   
                <span>Unique users:</span><span className={styles.tooltipProp}> 12,600</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value:</span><span className={styles.tooltipProp}>1.2ETH</span>
              </div>  
              <div className={styles.flexRow}>
                <div className={styles.boxGreen}>Newbie</div>
                <div className={styles.boxPink}>NFT Explorer</div>
              </div>
            </div>
          )
        }
        if(node.label === "WalletConnect 38%") {
          return (
            <div className={styles.tooltipBar}>
              <div className={styles.flexRow}>   
                <span>Unique Users :</span><span className={styles.tooltipProp}>7,721</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value :</span><span className={styles.tooltipProp}>2.5ETH</span>
              </div>  
              <div className={styles.flexRow}>
              <div className={styles.boxGreen}>Developer</div>
                <div className={styles.boxPink}>L2 Explorer</div>
                </div>
            </div>
          )
        }
        if(node.label === "Mint NFT 23%") {
          return (
            <div className={styles.tooltipBar}>
        <div className={styles.flexRow}>   
                <span> Unique Users :</span><span className={styles.tooltipProp}>4670</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value :</span><span className={styles.tooltipProp}>0.8ETH</span>
              </div> 
              <div className={styles.flexRow}>
              <div className={styles.boxGreen}>Explorer</div>
                </div>
            </div>
          )
        }
        if(node.label === "Place Bid 42%") {
          return (
            <div className={styles.tooltipBar}>
              <div className={styles.flexRow}>   
                <span>Unique Users</span><span className={styles.tooltipProp}>8534</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value :</span><span className={styles.tooltipProp}>2.8ETH</span>
              </div> 
              <div className={styles.flexRow}>
              <div className={styles.boxGreen}>NFT Newbie</div>
                <div className={styles.boxPink}>L2 Explorer</div>
                </div>
            </div>
          )
        }
        if(node.label ===  "Vote 12%") {
          return (
            <div className={styles.tooltipBar}>
                  <div className={styles.flexRow}>   
                <span>Unique Users :</span><span className={styles.tooltipProp}>2440</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value :</span><span className={styles.tooltipProp}>1.3ETH</span>
              </div> 
              <div className={styles.flexRow}>
              <div className={styles.boxGreen}>Governance Participant</div>
                <div className={styles.boxPink}>Newbie</div>
                </div>
            </div>
          )
        }
        if(node.label === "Create Bounty 15%") {
          return (
            <div className={styles.tooltipBar}>
              <div className={styles.flexRow}>   
                <span> Unique Users :</span><span className={styles.tooltipProp}>3050</span>
              </div>
              <div className={styles.flexRow}>   
                <span>Average transaction value :</span><span className={styles.tooltipProp}>2.2ETH</span>
              </div> 
              <div className={styles.flexRow}>
              <div className={styles.boxGreen}>Developer</div>
                <div className={styles.boxPink}>Staker</div>
                </div>
            </div>
          )
        }
      }
  return (
    <div id="sankeyChartWrapperId" className={styles.SankeyChartWrapper}>
      
        <div className={styles.HomeLabel}>
          Home
        </div>
        
        <div className={styles.ConnectWalletLabel}>
          Connect Wallet
        </div>
        
        <div className={styles.FirstActionLabel}>
          First Action
        </div>
      
      <ResponsiveSankey
      
      data={data}
      margin={{ top: 28, right: 28, bottom: 28, left: 28 }}
      align="end"
      colors={[ "#003BD2", "#DC01FF", "#DC01FF", "#003BD2", "#003BD2", "#003BD2"]}
      linkOpacity={0.5}
      nodeSpacing={15}
      sort="input"
      nodeOpacity={1}
      nodeHoverOthersOpacity={0.35}
      nodeThickness={18}
      nodeBorderWidth={0}
      
      nodeBorderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  0.8
              ]
          ]
      }}
      nodeBorderRadius={7}
      linkHoverOpacity={1}
      linkHoverOthersOpacity={0.3}
      linkContract={5}
      linkBlendMode="normal"
      enableLinkGradient={true}
      labelPosition="inside"
      labelOrientation="horizontal"
      labelPadding={6}
      labelTextColor="#ffffff"
      nodeTooltip={tooltip}
      linkTooltip={()=>(<div></div>)}
      onClick={(data, event) => console.log({ data, event })} 
  />
</div>
  )
};
