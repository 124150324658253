import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

type UserState = {
  id:          number;
  name:        string;
  lastName:    string;
  email:       string;
  tenant_id:   number;
  tenant_name: string;
  isAdmin:     boolean;
  isAnalyticsInitialized: boolean;
};

const initialState: UserState = {
  id:          -1,
  name:        '',
  lastName:    '',
  email:       '',
  tenant_id:   -1,
  tenant_name: '',
  isAdmin:     false,
  isAnalyticsInitialized: false
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAnalyticsInitialized(state, action: PayloadAction<boolean>) {
      const boolean = action.payload;
      state.isAnalyticsInitialized = boolean;
    },
    setID(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.id = id;
    },
    setName(state, action: PayloadAction<string>) {
      const name = action.payload;
      state.name = name;
    },
    setLastName(state, action: PayloadAction<string>) {
      const lastName = action.payload;
      state.lastName = lastName;
    },
    setEmail(state, action: PayloadAction<string>) {
      const email = action.payload;
      state.email = email;
    },
    setTenantID(state, action: PayloadAction<number>) {
      const tenant_id = action.payload;
      state.tenant_id = tenant_id;
    },
    setTenantName(state, action: PayloadAction<string>) {
      const tenant_name = action.payload;
      state.tenant_name = tenant_name;
    },
    setIsAdmin(state, action: PayloadAction<boolean>) {
      const isStaff = action.payload;
      state.isAdmin = isStaff;
    }
  }
});

// Actions

export const {
  setID,
  setName,
  setLastName,
  setEmail,
  setTenantID,
  setTenantName,
  setIsAdmin,
  setIsAnalyticsInitialized
} = userSlice.actions;

// Selectors

export const userSelector = state => state.user;

export const selectIsAnalyticsInitialized = createSelector(
  userSelector,
  (state: UserState) => state.isAnalyticsInitialized
);

export const selectID = createSelector(
  userSelector,
  (state: UserState) => state.id
);

export const selectName = createSelector(
  userSelector,
  (state: UserState) => state.name
);

export const selectLastName = createSelector(
  userSelector,
  (state: UserState) => state.lastName
);

export const selectEmail = createSelector(
  userSelector,
  (state: UserState) => state.email
);

export const selectTenantID = createSelector(
  userSelector,
  (state: UserState) => state.tenant_id
);

export const selectTenantName = createSelector(
  userSelector,
  (state: UserState) => state.tenant_name
);

export const selectIsAdmin = createSelector(
  userSelector,
  (state: UserState) => state.isAdmin
);

export default userSlice.reducer;