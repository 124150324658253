import React from 'react';
import styles from './SourseBlock.module.scss';
export const SourseBlock = ({ company, value, suffix, icon }) => {
  return (
    <div className={styles.sourceBlock}>
      <img src={`/assets/svg/dashboard/table-icons/${icon.toLowerCase()}.png`} alt='' />
      <div className={styles.wrapContent}>
        <span className={styles.text}>{company}:</span>
        <span>{value}{suffix}</span>
      </div>
    </div>
  );
};
