import React from 'react';
import UIExamples  from '../../components/UI/UIExamples/UIExamples';

import styles from './UIExamplesPage.module.scss';

export const UIExamplesPage = () => {
  return (
    <div>
      <UIExamples />
    </div>
  );
};
