import React from 'react';
import AnswerBlock from './AnswerBlock/AnswerBlock';

import AnswerHorizontal from './AnswerHorizontal/AnswerHorizontal';
import LottieExample from '../Lottie-emample';
import { SankeyChart } from '../SankeyChart/SankeyChart';

import styles from './AnswerQuestion.module.scss';
import BtnSwitchGraph from '../UI/BtnSwitchGraph/BtnSwitchGraph';
import { SwitchBlock } from './SwitchBlock/SwitchBlock';

export const AnswerQuestion = () => {
  const lottiePropsMeasure = {
    jsonName: 'lottieJsonMeasureProtocolImpact',
    widthLottie: 463,
    heightLottie: 347,
    speed: 1,
  };

  const lottiePropsTrack = {
    jsonName: 'lottieJsonTrackKeyMetrics',
    widthLottie: 452,
    heightLottie: 289,
    speed: 0.5,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titles}>
        <h1>All you need, in one place</h1>
        <h2>Measure, Track, and Compare Everything that Matters</h2>
      </div>
      <div className={styles.blockList}>
        <AnswerBlock
          btnText='Get Started'
          title='Infinite Wallet Intelligence'
          subtitle='Understand who your users, where they come from, and how they interact on-chain. Uncover the truth behind hashes.'
        >
          <div className={styles.infinityBlock}>
            <SwitchBlock />
          </div>
        </AnswerBlock>
        <AnswerBlock
          title='Measure Protocol Impact'
          subtitle='See the performance of all your contracts, and summarize second and third order activities across the network.'
        >
          <div className={styles.measureBlock}>
            <LottieExample lottieProps={lottiePropsMeasure} />
          </div>
        </AnswerBlock>
        <AnswerHorizontal />
        <AnswerBlock
          title='Map user journeys'
          subtitle='Find retention drivers and drop-off points to optimize user flow. Build and compare cohorts to experiment at scale.'
          secondary={true}
        >
          <div className={styles.mapUserBlock}>
          <SankeyChart/>
          </div>
        </AnswerBlock>
        <AnswerBlock
          btnText='Get Started'
          title='Track key metrics'
          secondary={true}
          subtitle='Set up smart alerts for meaningful changes to product usage. Spot anomalies and act quickly with confidence.'
        >
          <div className={styles.trackKeyBlock}>
            <LottieExample lottieProps={lottiePropsTrack} />
          </div>
        </AnswerBlock>
      </div>
    </div>
  );
};
