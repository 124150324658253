import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import styles from './Header.module.scss';
import { Button } from '../UI/';
import { Link } from 'react-router-dom';

export const Header = ({ scrollToElem, isAnnoncementShow = false }) => {
  const [offset, setOffset] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const getHeaderClasses = () => {
    const headerClasses = [styles.header, isAnnoncementShow === true ? styles.isAnnoncementShow : ''];

    if (offset > 1) {
      headerClasses.push(styles.headerScrolled);
    }

    return headerClasses.join(' ');
  };

  return (
    <header className={getHeaderClasses()}>
      <div className={styles.logo}>
        <Link to='/'>
          <img src='assets/svg/logo-merlin.svg' alt='logo' />
        </Link>
      </div>
      <div className={styles.nav}>
        <ul className={styles.navlist}>
          <li ><a href="#IBM-section">Blog</a></li>
          <li><a href='mailto: hello@getmerlin.xyz'>Careers</a></li>
          <li ><a href='/form'>Contact Us</a></li>
          <li className={styles.navlistIconItem}>
            <a href="https://twitter.com/merlindotxyz" target="_blank"><img
              className={styles.twitterImg}
              src='assets/svg/social_footer/twitter.svg'
              alt=''
            />
            </a>
          </li>
        </ul>
        <div className={styles.btn}>
          <Button hasHoverStars={true}>
            <a href='https://calendly.com/merlinxyz/30min' target="_blank">
            Schedule demo
            </a>
          </Button>
        </div>
      </div>
    </header>
  );
};
