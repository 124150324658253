import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { changeCohort } from "../api/cohorts";

export type DataType = {
  id?:            number;
  status?:        'Active' | 'In progress' | 'Error';
  name:           string;
  type?:          string;
  condition:      ConditionType[];
  logicOperator:  '&' | '||';
  editing?:       boolean;
  /* Нужен для отката данных в случае отмены редактирования */
  unsavedData?:   DataType;
};

export type ConditionType = {
  id:       number;
  field:    string;
  operator: '==' | '<' | '>';
  value:    string;
};

type CohortsStateType = {
  data: DataType[];
  status: string;
};

const initialState: CohortsStateType = {
  data: [],
  status: 'Processing'
};

export const cohortsSlice = createSlice({
  name: "cohorts",
  initialState,
  reducers: {
    setCohortsData(state, action: PayloadAction<DataType[]>) {
      const data = action.payload;
      state.data = data;
    },
    setCohortsStatus(state, action) {
      const value = action.payload;
      state.status = value;
    },
    addCohortsRow(state, action: PayloadAction<{ id?: number; data?: DataType }>) {
      const { id } = action.payload;
      let data;
      if (action.payload?.data) {
        data = action.payload.data;
      }
      else {
        data = state.data.find(row => row.id === id);
      }

      if (data) {
        data.editing = false;
        
        const { name, type, logicOperator, condition } = data;
        changeCohort({
          cohort_name: name,
          cohort_type: type,
          logic_operator: logicOperator,
          cohort_logic: condition
            .map(c => ({
              cohortlogic_id: c.id,
              field:         c.field,
              operator:      c.operator,
              value:         c.value
            }))
            .map(c => JSON.stringify(c))
            .join('|')
            .replace(/"/g, '"')
        })
          .then(console.log)
          .catch(console.error)
      }
    },
    changeCohortsRow(state, action: PayloadAction<{ id: number; changes: {}, shouldSave?: boolean }>) {
      const { id, changes, shouldSave } = action.payload;
      const data = state.data.find(row => row.id === id);
      if (data) {
        for (let key in changes) {
          data[key] = changes[key];
        }

        if (shouldSave) {
          const { id, name, type, logicOperator, condition } = data;
          changeCohort({
            id,
            cohort_name: name,
            cohort_type: type,
            logic_operator: logicOperator,
            cohort_logic: condition
              .map(c => ({
                cohortlogic_id: c.id,
                field:         c.field,
                operator:      c.operator,
                value:         c.value
              }))
              .map(c => JSON.stringify(c))
              .join('|')
              .replace(/"/g, '"')
          })
            .then(console.log)
            .catch(console.error)
        }
      }
    },
    removeCohortsRow(state, action: PayloadAction<{ id: number; shouldSave?: boolean }>) {
      const { id, shouldSave = true } = action.payload;
      const index = state.data.findIndex(row => row.id === id);
      if (index !== -1) {
        const data = state.data[index];
        state.data.splice(index, 1);

        if (shouldSave) {
          const { id, name, type, logicOperator, condition } = data;

          changeCohort({
            id,
            cohort_name: name,
            cohort_type: type,
            logic_operator: logicOperator,
            active: 'False',
            cohort_logic: condition
              .map(c => ({
                cohortlogic_id: c.id,
                field:         c.field,
                operator:      c.operator,
                value:         c.value
              }))
              .map(c => JSON.stringify(c))
              .join('|')
              .replace(/"/g, '"')
          })
            .catch(console.error)
        }
      }
    },
    addEmptyCohort(state) {
      const id = Math.max(...state.data.map(r => r.id)) + 1;
      state.data.unshift({
        id,
        status:         'In progress',
        name:           '',
        type:           '',
        condition:      [{
          id:       0,
          field:    '',
          operator: '==',
          value:    ''
        }],
        logicOperator:  '&',
        // Автоматически включаем режим редактирования
        editing:        true,
        unsavedData:    null
      });
    }
  }
});

// Actions

export const {
  setCohortsData,
  addCohortsRow,
  changeCohortsRow,
  removeCohortsRow,
  addEmptyCohort,
  setCohortsStatus 
} = cohortsSlice.actions;

// Selectors

export const cohortsSelector = state => state.cohorts;

export const cohortsStatusSelector = state => state.cohorts.status;

export const selectCohortsData = createSelector(
  cohortsSelector,
  (state: CohortsStateType) => state.data
);

export default cohortsSlice.reducer;