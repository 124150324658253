import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export const Button = (props) => {
  const { children, icon, onClick, disabled, size = "default", type="default", hasHoverStars = false } = props;

  const getClassOfSize = () => {
    if(size === "large") {
      return styles.btnWrapperLarge;
    } if(size === "small") {
        return styles.btnWrapperSmall;
    } else {
      return styles.btnWrapperDefault;
    }
  }

  const getClassOfType = () => {
    if(type === "white") {
      return styles.btnWrapperTypeWhite;
    } else {
      return "";
    }
  }

  return (
    
    <div className={classNames(
      styles.btnWrapper,
      getClassOfSize(),
      getClassOfType(),
      icon && styles.btnWrapperHasIcon,
      props.className
    )}>
        <button
        onClick={onClick}
        className={styles.btn}
        type={'button'}
        disabled={disabled}
        >
          {icon  && (
            <div className={styles.btnIcon}>
              {icon}
            </div>)
          }
          {children}
          {hasHoverStars === true && (
            <div className={styles.leftStar}></div>
          )}
          {hasHoverStars === true && (
            <div className={styles.topStar}></div>
          )}
          {hasHoverStars === true && (
            <div className={styles.bottomStar}></div>
          )}
          
        </button>
  </div>
  );
};
