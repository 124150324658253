import React from "react";
import classNames from "classnames";
import { LabelBlock } from "../../../components/Dashboard/TableContent/LabelBlock/LabelBlock";
import { Checkbox, DateInput, Progress, SelectInput, TextInput } from "../../../components/UI";
import store from "../../../store";
import {
  addTableRow,
  changeCustomTableRow,
  changeTableRow,
  clearCustomTable,
  removeTableRows,
  TableData,
} from "../../../store/settingsSlice";
import shortenContract from "../../../utils/shorten-contract";
import { getDataSourceID, setDataSourceID } from "../../../utils/data-source-id";
import OptionsMenu from "../Table/TableRow/OptionsMenu/OptionsMenu";
import ActiveIcon from "../Table/ActiveIcon/ActiveIcon";

import styles from './style.module.scss';

const { dispatch } = store;

export const columns = [
  {
    name: '',
    prop: 'selected',
    style: {
      height: '100%',
      width:  '3ch'
    },
    render: (selected: boolean, data: TableData, id: number, isHovered: boolean) =>
      (isHovered || selected)
        ?
        <Checkbox
          checked={selected}
          onChange={selected => dispatch(changeTableRow({ id, changes: { selected } }))}
          style={{ height: '1em', marginTop: '-5px', marginBottom: '0' }}
        />
        :
        (getDataSourceID() === data.id) &&
          <ActiveIcon
            style={{
              position: 'relative',
              left: '0',
              top:  '-4px',
              transform: 'translate(50%, 50%)'
            }}
          />
  },
  {
    name: () =>
      <span style={{ position: 'relative', left: '-2.5ch' }}>Name</span>,
    prop: 'name',
    style: {
      width: '22.5%',
      display: 'flex',
      alignItems: 'center'
    },
    render: (name: string, data: TableData, id?: number) =>
      data.options?.editing
        ?
        <TextInput
          placeholder="Name"
          value={name}
          onChange={name => dispatch(changeTableRow({ id, changes: { name }, save: false }))}
          height="30px"
          style={{ position: 'relative', left: '-1.75ch', marginLeft: '17px' }}
        />
        :
        <b
          className={classNames(getDataSourceID() === data.id && styles.activeName)}
          style={{ marginLeft: '17px' }}
        >
          {name}
        </b>
  },
  {
    name: 'Address',
    prop: 'address',
    style: {
      width: '22.5%'
    },
    render: (address: string, data: TableData) => {
      const { s3location } = data;
      if (s3location) {
        const filename = s3location.slice(s3location.lastIndexOf('/'));
        return (
          <a
            href={'https://walletlists.s3.amazonaws.com' + filename}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: 'underline',
              color: '#003BD2'
            }}
          >
            {data.s3location}
          </a>
        )
      }

      return address.length > 23
        ? shortenContract(address, 16)
        : address
    }
  },
  {
    name: 'Track',
    prop: 'track',
    style: {
      width: '20%'
    },
    render: (track: string) =>
      <LabelBlock
        color={
          track === 'Deployed entities' ? '#628BF5' : track === 'Contracts' ? '#DBE2EF' : '#F8CAFF'
        }
        textColor={track !== 'Deployed entities' && 'black'}
      >
        {track}
      </LabelBlock>
  },
  {
    name: 'Last upload',
    prop: 'lastUpload',
    style: {
      width: '20%'
    },
    render: (date: string) =>
      <DateInput
        value={date}
        disabled={true}
      />
  },
  {
    name: 'Coverage',
    prop: 'coverage',
    style: {
      width: '15%'
    },
    render: (coverage, data: TableData, id: number) => {
      return (
        <>
          <Progress
            className={styles.progressBar}
            value={+coverage || 0}
          />

          <div className={styles.coverageContaier} >
            {!data.options.editing &&
              <OptionsMenu
                options={[
                  {
                    icon: <ActiveIcon />,
                    text: 'Make Active',
                    callback: () => {
                      dispatch(changeTableRow({ id, changes: { isActive: true } }));
                      setDataSourceID(id);
                    }
                  },
                  {
                    text: 'Edit',
                    icon:
                      <img
                        src="/assets/svg/dashboard/edit.svg"
                        alt="edit-icon"
                      />,
                    callback() {
                      dispatch(
                        changeTableRow({
                          id,
                          changes: {
                            options: { ...data.options, editing: true }
                          }
                        })
                      );
                    }
                  },
                  {
                    text: 'Delete',
                    icon:
                      <img
                        src="/assets/svg/dashboard/trash.svg"
                        alt="delete-icon"
                      />,
                    callback() {
                      dispatch(removeTableRows({ ids: [id] }))
                    }
                  }
                ]}
                style={{ marginLeft: 'auto', marginRight: '5px' }}
              />
            }

            {data.options.editing && 

              <div className={styles.buttons}>
                <button
                  className={styles.cancel}
                  onClick={() => {
                    dispatch(changeTableRow({
                      id,
                      changes: {
                        options: { editing: false }
                      },
                      save: false
                    }))
                  }}
                >
                  Cancel
                </button>

                <button
                  className={styles.save}
                  onClick={() => {
                    dispatch(changeTableRow({
                      id,
                      changes: {
                        options: { ...data.options, editing: false }
                      },
                      save: true
                    }))
                  }}
                >
                  Save
                </button>
              </div>
            }
          </div>
        </>
      )
    }
  }
];

export const customColumns = [
  {
    name: '',
    prop: '',
    style: {
      width: '3ch'
    }
  },
  {
    name: 'Name',
    prop: 'name',
    style: {
      width: '22.5%'
    },
    render: (name: string) =>
      <TextInput
        placeholder="Name"
        value={name}
        onChange={name => dispatch(changeCustomTableRow({ name }))}
        height='36px'
        style={{ position: 'relative', left: '-1.75ch' }}
      />
  },
  {
    name: 'Address',
    prop: 'address',
    style: {
      width: '22.5%'
    },
    render: (address: string) =>
      <TextInput
        value={address}
        placeholder="Address"
        onChange={(address, isValid) => {
          console.log('isValid', isValid);
          dispatch(changeCustomTableRow({ address, isValid }));
        }}
        height='36px'
        validateAs='address'
        style={{ position: 'relative', left: '-1.75ch' }}
      />
  },
  {
    name: 'Track',
    prop: 'track',
    style: {
      width: '20%'
    },
    render: (track, data: TableData) => {
      return (
        <SelectInput
          disabled={data.options.trackDisabled}
          options={[
            'Deployed entities',
            'Contract',
            'Wallet',
            'Group'
          ]}
          defaultValue={track}
          onChange={track => dispatch(changeCustomTableRow({ track }))}
          style={{ width: '25ch', marginTop: 0, height: '36px', pointerEvents: 'all' }}
        />
      )
    }
  },
  {
    name: 'Last upload',
    prop: 'lastUpload',
    style: {
      width: '20%'
    },
    render: (date: string) =>
      <DateInput
        value={date}
        disabled={true}
      />
  },
  {
    name: 'Coverage',
    prop: 'coverage',
    style: {
      width: '15%'
    },
    render: (coverage: number, name: string, data: TableData, id?: number) => {
      const buffer = store.getState().settings.customTable[0];
      
      return (
        <div className={styles.coverageContent}>
          <Progress
            className={styles.progressBar}
            value={+coverage}
          />
          <div className={styles.buttons}>
            <button
              className={styles.cancel}
              onClick={() => {
              }}
            >
              Cancel
            </button>

            <button
              className={styles.save}
              disabled={!buffer.isValid}
              onClick={() => {
                dispatch(changeTableRow({
                  id,
                  changes: {
                    options: { ...data.options, editing: false }
                  },
                  save: true
                }))
              }}
            >
              Save
            </button>
          </div>
        </div>
      )
    }
  }
]