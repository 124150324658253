import React from "react";
import styles from "./DemoPage.module.scss";
import {Header} from "../../components/Header/Header";
import Footer from "../../components/Footer";
import Media from "react-media";

const DemoPage = () => {
    return (
        <div>
            <Media queries={{
                desktop: "(min-width: 1350px)",
                tablet: "(min-width: 500px) and (max-width: 1349px)",
                mobile: "(max-width: 499px)",
            }}>
                {matches => (
                    <React.Fragment>
                        {matches.desktop && (
                            <div>
                                <Header />
                                DEMO
                                <Footer />
                            </div>
                        )}
                        {matches.tablet && (
                            <div>
                                <Header />
                                DEMO
                                <Footer />
                            </div>
                        )}
                        {matches.mobile && (
                            <div>
                                <Header />
                                DEMO
                                <Footer />
                            </div>
                        )}
                    </React.Fragment>
                )}
            </Media>
        </div>
    );
};

export default DemoPage;