import React from 'react';
import styles from './PrivacyPolicyPage.module.scss';
import { Header } from '../../components/Header/Header';
import PrivacyPolicy from '../../components/PrivacyPolicy';
import Footer from '../../components/Footer';
import Media from 'react-media';

const PrivacyPolicyPage = () => {
  return (
    <div className={styles.appWrapperOwner}>
      <Media
        queries={{
          desktop: '(min-width: 1350px)',
          tablet: '(min-width: 500px) and (max-width: 1349px)',
          mobile: '(max-width: 499px)',
        }}
      >
        {(matches) => (
          <React.Fragment>
            {matches.desktop && (
              <div className={styles.appWrapper}>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </div>
            )}
            {matches.tablet && (
              <div className={styles.appWrapper}>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </div>
            )}
            {matches.mobile && (
              <div className={styles.appWrapper}>
                <Header />
                <PrivacyPolicy />
                <Footer />
              </div>
            )}
          </React.Fragment>
        )}
      </Media>
    </div>
  );
};

export default PrivacyPolicyPage;
