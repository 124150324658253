import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '../../../api';
import { setName, setLastName, setEmail, setTenantID, setIsAdmin, setID, setTenantName } from '../../../store/userSlice';
import ActiveContractsViewer from './ActiveContractsViewer/ActiveContractsViewer';
import Account from './Account/Account';
import LogoutButton from './LogoutButton/LogoutButton';
import { useAnalyticsInit } from '../../../utils/use-analytics-init';

import styles from './HeaderBoard.module.scss';

export const HeaderBoard = ({ title }) => {
  const dispatch = useDispatch();

  useAnalyticsInit();

  useEffect(() => {
    fetchUserInfo()
      .then(response => {
        const user = response.value;
        dispatch(setID(user.id));
        dispatch(setName(user.firstname));
        dispatch(setLastName(user.lastname));
        dispatch(setEmail(user.email));
        dispatch(setTenantID(user.tenant_id));
        dispatch(setTenantName(user.tenant));
        dispatch(setIsAdmin(user.is_staff));
      });
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.titlePage}>{title ?? "Overview"}</div>
      <div className={styles.certicifate}>
        <ActiveContractsViewer />
        <Account />
        <LogoutButton />
      </div>
    </div>
  );
};
