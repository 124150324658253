import { InvestorBlock } from './InvestorBlock/InvestorBlock';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Autoplay  } from "swiper";
import 'swiper/css';

import styles from './Slider.module.scss';

export const Slider = (props) => {
  return (
    <div className={styles.SliderWrapper}>
      <Swiper
        {...props}
        modules={[FreeMode, Autoplay]}
        pagination={{
          clickable: true,
        }} 
        className="mySwiper"
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <InvestorBlock
            img='arth-patel.jpg'
            comment='“I often find myself juggling between multiple block explorer tabs to get context behind who is using my protocol.”'
            name='Arth Patel'
            jobInfo='Head Of Engineering @ Socket'
          />
        </SwiperSlide>

        <SwiperSlide className={styles.SwiperSlide}>
          <InvestorBlock
              img='andrew-hong.jpg'
              comment='“You dont control the data infrastructure anymore - as soon as your product is live, your data is live everywhere. Your team is thrown into the deep end of data infra”'
              name='Andrew Hong'
              jobInfo='Data @ Mirror'
            />
        </SwiperSlide>

        <SwiperSlide className={styles.SwiperSlide}>
          <InvestorBlock
            img='eshita.jpg'
            comment='“Surprised how few web3 projects are hiring data scientists”'
            name='Eshita'
            jobInfo='Research @ Messari'
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
