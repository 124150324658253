import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { closeFiltersDropdown, selectIsFiltersDropdownOpen, selectSelectedFilters, toggleFiltersDropdown } from '../../store/walletAnalysisSlice';
import FilterDropdownForm from './FilterDropdownForm/FilterDropdownForm';

import styles from './FilterDropdown.module.scss';

const FilterDropdown = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsFiltersDropdownOpen);
  const selectedFilters = useSelector(selectSelectedFilters);
  // Считает только те поля, в которых не лежит false
  const numberOfSelectedFilters = Object.keys(selectedFilters).filter(prop => !!selectedFilters[prop]).length;

  const controlRef = useRef(null);
  const filterOptionsRef = useRef(null);

  const handleMouseLeave = () => {
    setIsMouseOver(false)
  };

  const handleOnMouseEnter = () => {
    setIsMouseOver(true)
  };

  const handleDone = () => dispatch(closeFiltersDropdown());

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик на контрол - toggle
      if (controlRef.current?.contains(e.target)) {
        dispatch(toggleFiltersDropdown());
      }
      // Клик вне селекта - скрываем
      else if (!filterOptionsRef.current?.contains(e.target) && !isMouseOver) {
        dispatch(closeFiltersDropdown());
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.FilterDropdownWrapper}>
      <div className={styles.FilterDropdownControlWrapper}>
        <div
          ref={controlRef}
          className={styles.FilterDropdownControl}
          onMouseLeave={handleMouseLeave}
          onMouseEnter={handleOnMouseEnter}
        >
          <div className={styles.FilterDropdownControlFilterIcon}>
            <svg width="20" height="17" viewBox="0 0 20 17" fill="none">
              <path d="M12.5357 9.03571L18.1106 4.20412C18.9931 3.43935 19.5 2.32916 19.5 1.16145C19.5 0.519998 18.9814 0 18.34 0H1.66004C1.01859 0 0.5 0.519998 0.5 1.16145C0.5 2.32916 1.00695 3.43935 1.88937 4.20412L7.46429 9.03571C7.80453 9.33059 8 9.75866 8 10.2089V16.0979C8 16.4906 8.43198 16.73 8.765 16.5219L11.06 15.0875C11.6448 14.722 12 14.0811 12 13.3915V10.2089C12 9.75866 12.1955 9.33059 12.5357 9.03571Z" fill="#1A1D21" />
            </svg>
          </div>
          <p className={styles.FilterDropdownControlText}>Add filter</p>
          <div
            className={
              classNames(
                styles.FilterDropdownControlIcon,
                isOpen && styles.FilterDropdownControlIconTurned
              )
            }
          >
            <svg width="10" height="6" viewBox="0 0 10 6" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M9.1104 0.920849C9.3382 1.14865 9.3382 1.518 9.1104 1.74581L8.74921 1.38462C9.11039 1.74581 9.11043 1.74577 9.1104 1.74581L9.072 1.7841L8.96452 1.89086C8.87187 1.98269 8.73921 2.11371 8.57878 2.27087C8.25815 2.58496 7.82547 3.00453 7.37896 3.42478C6.93395 3.84362 6.46894 4.26903 6.08538 4.59203C5.89485 4.75247 5.71459 4.89601 5.5618 5.00229C5.48624 5.05486 5.4056 5.10663 5.32624 5.14759C5.27189 5.17564 5.12559 5.25 4.94792 5.25C4.77025 5.25 4.62395 5.17564 4.5696 5.14759C4.49023 5.10663 4.40959 5.05486 4.33404 5.00229C4.18125 4.89601 4.00098 4.75247 3.81045 4.59203C3.42689 4.26903 2.96189 3.84362 2.51687 3.42478C2.07036 3.00453 1.63769 2.58496 1.31706 2.27087C1.15662 2.11371 1.02396 1.98269 0.931318 1.89087L0.823833 1.7841L0.786147 1.74652L0.785502 1.74587C0.785463 1.74583 0.785438 1.74581 1.19792 1.33333L1.61041 0.920863L1.64707 0.957428L1.7526 1.06225C1.84388 1.15272 1.97489 1.28211 2.13347 1.43745C2.45086 1.74837 2.87756 2.16212 3.31646 2.57521C3.75686 2.98971 4.20332 3.39763 4.56194 3.69963C4.71514 3.82864 4.84527 3.93233 4.94792 4.00721C5.05057 3.93233 5.1807 3.82864 5.33389 3.69963C5.69252 3.39763 6.13897 2.98971 6.57937 2.57521C7.01828 2.16212 7.44498 1.74836 7.76237 1.43745C7.92094 1.28211 8.05196 1.15272 8.14323 1.06225L8.24876 0.957427L8.28493 0.921355C8.51274 0.693549 8.88259 0.693043 9.1104 0.920849ZM4.79115 4.11087C4.77614 4.11686 4.7754 4.119 4.79115 4.11087C4.79135 4.11076 4.79095 4.11097 4.79115 4.11087ZM0.785438 0.92085C1.01324 0.693044 1.3826 0.693057 1.61041 0.920863L1.19792 1.33333L0.785502 1.74587C0.557696 1.51807 0.557632 1.14866 0.785438 0.92085Z" fill="#7A7E89" />
            </svg>
          </div>
        </div>

        {numberOfSelectedFilters > 0 && (
          <div className={styles.FilterDropdownControlNumberOfActiveFilters}>
            {numberOfSelectedFilters}
          </div>
        )}
      </div>

      <div
        ref={filterOptionsRef}
        className={styles.FilterDropdownContentWrapper}
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <div className={styles.FilterDropdownContent}>
          <FilterDropdownForm onDone={handleDone} />
        </div>
      </div>

    </div>
  );
};

export default FilterDropdown;
