import { setDataSourceID as setDataSourceIDAction } from "../store/common-slice";
import store from "../store";
const { dispatch } = store;

export default function useDataSourceID() {
  return getDataSourceID();
}

export function getDataSourceID() {
  let { dataSourceID } = store.getState().common;
  if (dataSourceID === -1) {
    const { search } = window.location;
    const dataSourceID = new URLSearchParams(search).get('data_source_id');
    return +dataSourceID?.replace(/['"«»]/g, '') || -1;
  }
  return dataSourceID;
}

export function setDataSourceID(id: number) {
  const state = store.getState();
  const { common, user } = state;
  const tenantName = user.tenant_name.split('_').join(' ');
  const currentDataSourceID = common.dataSourceID;

  if (currentDataSourceID === id) {
    return;
  }

  if (currentDataSourceID !== -1) {
    // @ts-ignore
    merlin.send({
      event_name: 'active_data_source_change',
      event_type: 'click',
      event_properties: {
        old_data_source_id: currentDataSourceID,
        new_data_source_id: id
      },
      user_properties: {
        tenantName
      }
    });
  }

  dispatch(setDataSourceIDAction(id));
  // Меняем URL
  const { history, location } = window;
  const url = new URL(location.href);
  url.searchParams.set('data_source_id', String(id));
  history.pushState(null, null, url);
}