import React from "react";
import styles from './BlockSent.module.scss';
import { PurpleBtnDisabled } from "../../../components/UI/ButtonDisabled/PurpleBtnDisabled";


const BlockSent = () => {
    return (
        <div className={styles.wrapper}>
            <div>
                <img
                    className={styles.smallStartImage}
                    src='assets/svg/figures/small_star.svg'
                    alt='small_star'
                />
                <img
                    className={styles.littleStar}
                    src='assets/svg/figures/little_star.svg'
                    alt='BackgroundImg'
                />
                <div className={styles.wrapperTitle}>
                    <span>Application sent!</span>
                </div>
            </div>

            <div className={styles.wrapperCenterRow}>
                <div className={styles.title}>
                    <span>Thank you for your interest. Look out for an email shortly ✨</span>
                    <span></span>
                </div>
            </div>
            <div className={styles.wrapperBtn}>
                <a href={"/"}><PurpleBtnDisabled disabled={false}>Back to main page</PurpleBtnDisabled></a>
            </div>
        </div>
    );
};

export default BlockSent;