import React, { useState } from 'react';
import styles from './UIExamples.module.scss';

import {
  TextInput,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  RangeInput,
  SelectInput,
  CheckboxMultitude,
  RollingText,
  RangeInputTwoThumbs,
  Progress,
  DateInput,
  Circle,
  ContextMenu,
  ColorPicker,
  PageStatus
} from '../';
import { FilterDropdown, data as filterDropdownData } from "../../FilterDropdown/"
import NotificationPopup from '../../NotificationPopup/NotificationPopup'

const UIExamples = () => {
  const [value, setValue] = useState('');

  const [chekboxValue, setCheckboxValue] = useState('');
  const [inputSelectValue, setInputSelectValue] = useState('1-5');
  const [rangeValue, setRangeValue] = useState(5);
  const [rangeTwoThumbsValue, setRangeTwoThumbsValue] = useState([20, 80]);

  const [gender, setGender] = useState("Male");

  const [selectedCheckboxList, setSelectedCheckboxList] = useState([]);


  const SearchIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6794 12.9295C15.8894 11.278 16.4313 9.23055 16.1967 7.19667C15.9621 5.16246 14.9681 3.29185 13.4135 1.95907C11.8589 0.626286 9.85845 -0.070371 7.81228 0.0084716C5.76611 0.0873142 3.82516 0.935842 2.37774 2.3843C0.930326 3.83275 0.0831867 5.77431 0.00580825 7.82053C-0.0715702 9.86676 0.626518 11.8668 1.96041 13.4204C3.2943 14.974 5.16563 15.9667 7.2 16.1999C9.23405 16.433 11.2811 15.8896 12.9318 14.6784C12.9691 14.7282 13.009 14.7755 13.0538 14.8216L17.8664 19.6342C18.1008 19.8687 18.4187 20.0006 18.7503 20.0007C19.0819 20.0008 19.4 19.8692 19.6345 19.6348C19.8691 19.4004 20.0009 19.0824 20.001 18.7508C20.0012 18.4193 19.8696 18.1012 19.6352 17.8666L14.8226 13.0541C14.7781 13.009 14.7302 12.9674 14.6794 12.9295ZM14.4777 10.7562C14.8232 9.92209 15.001 9.02808 15.001 8.12522C15.001 6.30183 14.2767 4.55312 12.9874 3.26379C11.698 1.97446 9.94933 1.25012 8.12594 1.25012C6.30255 1.25012 4.55384 1.97446 3.2645 3.26379C1.97517 4.55312 1.25083 6.30183 1.25083 8.12522C1.25083 9.02808 1.42866 9.92209 1.77417 10.7562C2.11967 11.5903 2.62609 12.3482 3.2645 12.9867C3.90292 13.6251 4.66082 14.1315 5.49495 14.477C6.32907 14.8225 7.22308 15.0003 8.12594 15.0003C9.02879 15.0003 9.9228 14.8225 10.7569 14.477C11.5911 14.1315 12.349 13.6251 12.9874 12.9867C13.6258 12.3482 14.1322 11.5903 14.4777 10.7562Z" fill="#B9BDC8" />
    </svg>
  )
  const SearchIconSecond = () => (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
    <path fillRule="evenodd" clipRule="evenodd" d="M16.6794 14.9295C17.8894 13.278 18.4313 11.2305 18.1967 9.19667C17.9621 7.16246 16.9681 5.29185 15.4135 3.95907C13.8589 2.62629 11.8585 1.92963 9.81228 2.00847C7.76611 2.08731 5.82516 2.93584 4.37774 4.3843C2.93033 5.83275 2.08319 7.77431 2.00581 9.82053C1.92843 11.8668 2.62652 13.8668 3.96041 15.4204C5.2943 16.974 7.16563 17.9667 9.2 18.1999C11.234 18.433 13.2811 17.8896 14.9318 16.6784C14.9691 16.7282 15.009 16.7755 15.0538 16.8216L19.8664 21.6342C20.1008 21.8687 20.4187 22.0006 20.7503 22.0007C21.0819 22.0008 21.4 21.8692 21.6345 21.6348C21.8691 21.4004 22.0009 21.0824 22.001 20.7508C22.0012 20.4193 21.8696 20.1012 21.6352 19.8666L16.8226 15.0541C16.7781 15.009 16.7302 14.9674 16.6794 14.9295ZM16.4777 12.7562C16.8232 11.9221 17.001 11.0281 17.001 10.1252C17.001 8.30183 16.2767 6.55312 14.9874 5.26379C13.698 3.97446 11.9493 3.25012 10.1259 3.25012C8.30255 3.25012 6.55384 3.97446 5.2645 5.26379C3.97517 6.55312 3.25083 8.30183 3.25083 10.1252C3.25083 11.0281 3.42866 11.9221 3.77417 12.7562C4.11967 13.5903 4.62609 14.3482 5.2645 14.9867C5.90292 15.6251 6.66082 16.1315 7.49495 16.477C8.32907 16.8225 9.22308 17.0003 10.1259 17.0003C11.0288 17.0003 11.9228 16.8225 12.7569 16.477C13.5911 16.1315 14.349 15.6251 14.9874 14.9867C15.6258 14.3482 16.1322 13.5903 16.4777 12.7562Z" fill="white" />
  </svg>
  )
  const DownloadIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" >
      <path d="M6.12411 19.25H17.8741C18.0999 19.2501 18.3169 19.3375 18.4797 19.4938C18.6426 19.6502 18.7387 19.8634 18.748 20.089C18.7573 20.3145 18.679 20.535 18.5296 20.7042C18.3802 20.8734 18.1711 20.9783 17.9461 20.997L17.8741 21H6.12411C5.8984 20.9998 5.6815 20.9123 5.51874 20.7559C5.35599 20.5995 5.25996 20.3863 5.25073 20.1608C5.2415 19.9353 5.31978 19.7149 5.46921 19.5457C5.61865 19.3766 5.82767 19.2717 6.05261 19.253L6.12411 19.25H17.8741H6.12411ZM11.8026 3.128L11.8741 3.125C12.0937 3.12501 12.3053 3.20759 12.4668 3.35634C12.6283 3.50509 12.728 3.70915 12.7461 3.928L12.7491 4V14.6665L15.1456 12.2705C15.3097 12.1064 15.5323 12.0142 15.7644 12.0142C15.9964 12.0142 16.219 12.1064 16.3831 12.2705C16.5472 12.4346 16.6394 12.6572 16.6394 12.8892C16.6394 13.1213 16.5472 13.3439 16.3831 13.508L12.4941 17.397C12.4129 17.4783 12.3164 17.5427 12.2102 17.5867C12.1041 17.6307 11.9903 17.6533 11.8754 17.6533C11.7604 17.6533 11.6467 17.6307 11.5405 17.5867C11.4343 17.5427 11.3379 17.4783 11.2566 17.397L7.36761 13.508C7.20351 13.3439 7.11132 13.1213 7.11132 12.8892C7.11132 12.6572 7.20351 12.4346 7.36761 12.2705C7.53171 12.1064 7.75429 12.0142 7.98636 12.0142C8.21844 12.0142 8.44101 12.1064 8.60511 12.2705L10.9991 14.6645V4C10.9991 3.78033 11.0818 3.56869 11.2306 3.40715C11.3795 3.2456 11.5837 3.14595 11.8026 3.128L11.8741 3.125L11.8026 3.128Z" fill="#B9BDC8" />
    </svg>
  )

  const ButtonGo = () => (<Button>GO</Button>);

  return (
    <div className={styles.uiExamplesWrapper}>
      <div className={styles.uiExamplesColumn}>
        <div className={styles.uiExamplesTitle}>
          TextInput
        </div>
        <div className={styles.uiExamplesSection}>
          <TextInput size="large" placeholder="Enter something..." />
          <TextInput size="large" icon={<SearchIcon />} placeholder="Search" />
          <TextInput size="large" icon={<SearchIcon />} placeholder="Add sertificate" button={<ButtonGo />} />
          <TextInput size="large" status="error" icon={<SearchIcon />} placeholder="Add sertificate" value={value} button={<ButtonGo />} textError={`Your value: ${value}`} onChange={setValue} />
        </div>

        <div className={styles.uiExamplesSection}>
          <TextInput placeholder="Add sertificate" />
          <TextInput status="error" icon={<SearchIcon />} placeholder="Add sertificate" value={value} textError={`Your value: ${value}`} onChange={setValue} />
          <TextInput icon={<SearchIcon />} placeholder="Add sertificate" />
        </div>

        <div className={styles.uiExamplesTitle}>
          Button
        </div>
        <div className={styles.uiExamplesSection}>
          <Button size="small">Done</Button>

        </div>
        <div className={styles.uiExamplesSection}>
          <Button size="small"><DownloadIcon /></Button>

        </div>
        <div className={styles.uiExamplesSection}>
          <Button size="default">GO</Button>
        </div>

        <div className={styles.uiExamplesSection}>
          <Button icon={<SearchIconSecond />}>Search</Button>
        </div>

        <div className={styles.uiExamplesSection}>
          <Button disabled >Disabled</Button>
        </div>

        <div className={styles.uiExamplesTitle}>
          Button type="white"
        </div>
        <div className={styles.uiExamplesSection}>
          <Button size="small" type="white">Clear</Button>

        </div>
        <div className={styles.uiExamplesSection}>
          <Button size="default" type="white">GO</Button>
        </div>

        <div className={styles.uiExamplesSection}>
          <Button icon={<SearchIconSecond />} type="white">Search</Button>
        </div>

        <div className={styles.uiExamplesSection}>
          <Button disabled type="white">model text</Button>
        </div>


        <div className={styles.uiExamplesTitle}>
          Checkbox
        </div>
        <div className={styles.uiExamplesSection}>
          <Checkbox>Select</Checkbox>

          <Checkbox checked={chekboxValue} disabled onChange={setCheckboxValue} >Twin 1</Checkbox>

          <Checkbox checked={chekboxValue} onChange={setCheckboxValue} >Twin 2</Checkbox>

        </div>

        <div className={styles.uiExamplesTitle}>
          Radio with RadioGroup
        </div>
        <div className={styles.uiExamplesSection}>

          <RadioGroup onChange={setGender}>

            <Radio value="Male" isSelected={gender === "Male"}>Male</Radio>
            <Radio value="Female" isSelected={gender === "Female"}>Female</Radio>
          </RadioGroup>
          Your gender: {gender}

        </div>

        <div className={styles.uiExamplesTitle}>
          SelectInput
        </div>
        <div className={styles.uiExamplesSection}>
          <SelectInput defaultValue={inputSelectValue} onChange={setInputSelectValue}></SelectInput>

        </div>

        <div className={styles.uiExamplesTitle}>
          RangeInput
        </div>
        <div className={styles.uiExamplesSection}>

          <RangeInput defaultValue={rangeValue} onChange={setRangeValue}></RangeInput>
          {rangeValue}

        </div>


        <div className={styles.uiExamplesTitle}>
          RangeInputTwoThumbs
        </div>
        <div className={styles.uiExamplesSection}>

          <RangeInputTwoThumbs defaultValueFirstThumb={rangeTwoThumbsValue[0]} defaultValueSecondThumb={rangeTwoThumbsValue[1]} onChange={setRangeTwoThumbsValue} ></RangeInputTwoThumbs>
          {JSON.stringify(rangeTwoThumbsValue)}
        </div>

        <div className={styles.uiExamplesTitle}>
          CheckboxMultitude
        </div>
        <div className={styles.uiExamplesSection}>
          <CheckboxMultitude valueList={[
            "Newbie",
            "NFT Whale",
            "Developer",
            "Bridger",
            "Staker",
            "Yield Master",
            "Governance Participant",
            "L2 Explorer"
          ]} onChange={setSelectedCheckboxList} title="Wallets"></CheckboxMultitude>


          Selected: {selectedCheckboxList}
        </div>
      </div>
      <div className={styles.uiExamplesColumn}>
        <div className={styles.uiExamplesTitle}>
          Filter Dropdown
        </div>
        <div className={styles.uiExamplesSection}>
          <FilterDropdown filterData={filterDropdownData} />
        </div>

        <div className={styles.uiExamplesTitle}>
          Rolling Text
        </div>
        <div className={styles.uiExamplesSection}>
          <RollingText duration="120s" text="We just raised $X with participation from South Park Commons and Script Capital. ㅤRead moreㅤ→ㅤ" />
        </div>

        <div className={styles.uiExamplesTitle}>
          Redux Toolkit Counter Example
        </div>

        <div className={styles.uiExamplesSection} style={{ width: '350px' }}>
          <div className={styles.uiExamplesTitle}>
            Progress
          </div>
          <pre><code>type="line"</code></pre>
          <Progress type='line' value={100} />
          <Progress type='line' value={75} />
          <Progress type='line' value={0} />
          <pre><code>type="circle"</code></pre>
          <div style={{ gap: '2px' }}>
            <div className={styles.circleProgress} >
              <Progress type='circle' value={0} />
              <p>Text</p>
            </div>
            <div className={styles.circleProgress} >
              <Progress type='circle' value={25} />
              <p>Text</p>
            </div>
            <div className={styles.circleProgress} >
              <Progress type='circle' value={50} />
              <p>Text</p>
            </div>
            <div className={styles.circleProgress} >
              <Progress type='circle' value={100} />
              <p>Text</p>
            </div>
          </div>
        </div>

        <div className={styles.uiExamplesSection} style={{ width: '350px' }}>
          <div className={styles.uiExamplesTitle}>
            Progress
          </div>
          <DateInput disabled={true} />
          <DateInput disabled={true} value='27062022' />
        </div>

        <div className={styles.uiExamplesSection} style={{ width: '350px', gap: '5px' }}>
          <div className={styles.uiExamplesTitle}>
            Circle
          </div>
          <Circle color={null} />
          <Circle color='#00A5A5' />
        </div>
        
        <div className={styles.uiExamplesSection} style={{ width: '350px', gap: '5px' }}>
          <div className={styles.uiExamplesTitle}>
            ContextMenu
          </div>
          <ContextMenu />
        </div>

        <div className={styles.uiExamplesSection} style={{ width: '350px', gap: '5px' }}>
          <div className={styles.uiExamplesTitle}>
            ColorPicker
          </div>
          <ColorPicker 
            options={
              ['#003BD2','#6C3299', '#00A5A5', '#CC027B', '#DE4AF5', '#00C466', '#3B6FF5', '#5600C4', '#0055A6', '#36CFCF']
            }
          />
        </div>

        <div className={styles.uiExamplesSection} style={{ width: '350px', gap: '5px' }}>
          <div className={styles.uiExamplesTitle}>
            PageStatus
          </div>
          <PageStatus status='Processing' />
          <PageStatus status='Completed' />
          <PageStatus status='Any' />
        </div>
        
        <div className={styles.uiExamplesSection} style={{marginLeft: '300px'}}>
          <NotificationPopup 
            visible
            title="Data has changed"
            children="Please reload this window how to update."
          />
        </div>
      </div>

    </div>

  );
};

export default UIExamples;