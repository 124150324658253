import React from 'react';
import classNames from 'classnames';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import { WalletContent } from '../../components/Dashboard/WalletContent/WalletContent';
import { getToken } from '../../utils/token';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import redirectToAuth from '../../utils/redirect-to-auth';
import { useSelector } from 'react-redux'
import CohortAddPopup from '../../components/Dashboard/CohortAddPopup/CohortAddPopup';
import { selectIsCohortPopupActive } from '../../store/walletAnalysisSlice';
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup';
import { readMessage } from '../../api/message';
import useNeedToReload from '../../utils/use-need-to-reload';

import styles from './WalletAnalysis.module.scss';

const pageName = 'Wallet Analysis';

export const WalletAnalysis = () => {
  const isCohortPopupActive = useSelector(selectIsCohortPopupActive);
  const needToReload = useNeedToReload();

  if (!getToken()) {
    redirectToAuth();
    return;
  }
  
  return (
    <div className={classNames(styles.bg, 'scroller')}>
      {isCohortPopupActive && <CohortAddPopup />}
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          <WalletContent />
        </div>
      </div>
      
      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }
    </div>
  );
};
