import React, { useState } from 'react';
import styles from './NotificationPopup.module.scss'

const NotificationPopup = ({ 
  visible = false,
  title = '',
  children = '',
  reloadButtonVisible = true,
  onClose
}) => {
  const [show, setShow] = useState(visible);

  const handelClosePopup = async () => {
    if (onClose) {
      await onClose();
    };
    setShow(false);
  }

  return (
    <div className={show ? styles.showPopup : styles.noShowPopup}>
      <div className={styles.overlay}>
        <div className={styles.headerWrapWithClose}>
          <header className={styles.headerWrap}>
            <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
              <path fill="url(#a)" d="M9.223 4.444a1.778 1.778 0 1 0 0-3.555 1.778 1.778 0 0 0 0 3.555Z" />
              <path fill="url(#b)" d="M11.04 11.658a.445.445 0 0 0-.621.129 7.378 7.378 0 0 1-1.56 1.675c-.147.111-.694.534-.925.445-.16-.05-.067-.365-.035-.498l.235-.698c.098-.284 1.8-5.333 1.987-5.911.275-.844.155-1.676-1.102-1.476-.343.036-3.814.485-3.876.49a.445.445 0 1 0 .058.888s1.333-.173 1.48-.186a.391.391 0 0 1 .378.195c.08.249.07.519-.032.76-.115.445-1.942 5.591-2 5.889a1.235 1.235 0 0 0 .525 1.333c.404.27.885.399 1.369.37.47-.007.936-.1 1.373-.276 1.107-.445 2.262-1.627 2.871-2.556a.445.445 0 0 0-.124-.573Z" />
              <defs>
                <linearGradient id="a" x1="8.647" x2="12.365" y1="-.178" y2="1.614" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#DC01FF" />
                  <stop offset="1" stopColor="#3736FF" />
                </linearGradient>
                <linearGradient id="b" x1="6.922" x2="14.511" y1="2.363" y2="4.794" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#DC01FF" />
                  <stop offset="1" stopColor="#3736FF" />
                </linearGradient>
              </defs>
            </svg>
            <h3 className={styles.header}>{title}</h3>
          </header>
          <button
            className={styles.closeButton}
            onClick={handelClosePopup}
            aria-label="Close"
          >
            <svg width="16" height="16" fill="none" viewBox="0 0 16 16">
              <path d="M11.158 11.159a.467.467 0 0 1-.66 0L4.841 5.502a.467.467 0 1 1 .66-.66l5.657 5.657a.466.466 0 0 1 0 .66Z" clipRule="evenodd" />
              <path d="M11.158 4.842a.467.467 0 0 1 0 .66l-5.657 5.657a.467.467 0 0 1-.66-.66l5.657-5.657a.467.467 0 0 1 .66 0Z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        {reloadButtonVisible 
          ?
            <div className={styles.wrapMessage}>
              <p className={styles.message}>{children}</p>
              <button
                className={styles.reload}
                onClick={async () => {
                  if (onClose) {
                    await onClose();
                  }
                  window.location.reload()
                }}
              >
                Reload
              </button>
            </div>
          :
            <p className={styles.message}>{children}</p>
        }
    </div>
  </div>
  )
}

export default NotificationPopup;