import React from "react";
import styles from "./FallbackUI.module.scss";

export default function FallbackUI() {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>Something went wrong.</h1>
      <p className={styles.subtitle}>
        Please email <a href="mailto:hello@getmerlin.xyz">hello@getmerlin.xyz</a>,
        or submit a <a href="https://merlin.canny.io/bug-report" target="_blank" rel="noreferrer">bug report</a>
      </p>
    </div>
  );
}