import React, { HTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { selectLastName, selectName } from "../../../../store/userSlice";
import { getDataSourceID } from "../../../../utils/data-source-id";
import styles from "./Account.module.scss";

export default function Account(props: HTMLAttributes<HTMLButtonElement>) {
  const name = useSelector(selectName);
  const lastName = useSelector(selectLastName);
  return (
    <button
      {...props}
      className={styles.account}
      onClick={e => {
        window.location.href = `/settings?data_source_id=${getDataSourceID()}#account`;
      }}
    >
      {(!name && !lastName)
        ? localStorage.getItem('username') ?? 'username'
        :
        (name && lastName)
          ? name + " " + lastName
          :
          name || lastName
      }
    </button>
  )
}