import React, { HTMLAttributes, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import produce, { enableMapSet } from "immer";
import {
  addGroup,
  changeGroup,
  closeEditor,
  selectCurrentEditingGroupID,
  selectGroups,
  selectTable,
  setCurrentEditingGroupID
} from "../../../../store/settingsSlice";
import { Checkbox, TextInput } from "../../../../components/UI";
import TableHead from "../../Table/TableHead";
import TableRow from "../../Table/TableRow";
import { columns } from "../columns";
import styles from "./GroupEditor.module.scss";

enableMapSet();

interface GroupEditorProps extends HTMLAttributes<HTMLDivElement> {

}

export default function GroupEditor(props: GroupEditorProps) {
  const dispatch = useDispatch();
  const tableData = useSelector(selectTable);
  const groups = useSelector(selectGroups);
  const currentEditingGroupID = useSelector(selectCurrentEditingGroupID);

  let initialName = '';
  let initialIDs = [];
  if (currentEditingGroupID) {
    // Находим группу с текущим ID
    const currentGroup = groups.find(group => group.id === currentEditingGroupID);
    // Берем название группы и передаем его в стейт
    initialName = currentGroup.name;
    // Берем все ids внутри группы и передаем их в стейт
    initialIDs = [...currentGroup.ids];
  }

  const [name, setName] = useState(initialName);
  const [selectedIDs, setSelectedIDs] = useState(new Set<number>(initialIDs));

  const modifiedColumns = [...columns];
  modifiedColumns[0] = {
    name: '',
    prop: '',
    style: {
      width: '4ch'
    },
    render: (selected, options, id) =>
      <Checkbox
        checked={selectedIDs.has(id)}
        onChange={value => {
          const nextSelectedIDs = produce(selectedIDs, draft => {
            if (draft.has(id)) {
              draft.delete(id);
            }
            else {
              draft.add(id);
            }
          });
          setSelectedIDs(nextSelectedIDs);
        }}
        style={{ marginTop: '-1.35em', marginBottom: '0' }}
      />
  };

  return (
    <div
      className={styles.modal}
      onClick={e => {
        if (e.target === e.currentTarget) {
          dispatch(closeEditor());
          dispatch(setCurrentEditingGroupID(null));
        }
      }}
    >
      <div className={styles.modalContent}>
        <h2 className={styles.title}>Title</h2>
        <p className={styles.subtitle}>Subtitle</p>

        <TextInput
          className={styles.search}
          value={name}
          placeholder="Name new group"
          onChange={name => setName(name)}
        />

        <TableHead columns={columns} />
        <div className={styles.tableWrap}>
          {tableData
            .map(row =>
              <TableRow
                key={row.id}
                columns={modifiedColumns}
                data={row}
              />
            )
          }
        </div>

        <footer className={styles.footer}>
          <button
            className={styles.cancel}
            onClick={e => {
              dispatch(closeEditor())
              dispatch(setCurrentEditingGroupID(null));
            }}
          >
            Cancel
          </button>

          <button 
            className={styles.save}
            onClick={e => {
              if (currentEditingGroupID) {
                dispatch(changeGroup({ id: currentEditingGroupID, name, ids: [...selectedIDs] }));
              }
              else {
                dispatch(addGroup({ name, ids: [...selectedIDs], save: true }));
              }
              
              dispatch(closeEditor());
              dispatch(setCurrentEditingGroupID(null));
            }}
          >
            Save
          </button>
        </footer>
      </div>
    </div>
  )
}