import axios from "axios";
import { root } from ".";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";


export const fetchCohorts = async () => {
  const options = {
    method: 'POST',
    url: `${root}/cohort/view/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession()
    }
  };

  try {
    const response = await axios.request(options);
    const data: {
      message: string;
      status:  string;
      value: {
        colour: string;
        date_updated?: string;
        cohort_id: number
        cohort_logic: {
          cohortlogic_id: number;
          field:         string;
          operator:      '==' | '<' | '>';
          value:         string;
        }[],
        cohort_name: string;
        cohort_type: string;
        logic_operator: '&' | '||'
        status: 'Active' | 'In progress' | 'Error';
      }[]
    } = response.data;

    return data;
  }
  catch (error) {
    console.log('/cohort/view/ failed: ', error);
    throw error?.message;
  }
};

export const changeCohort = async (params: {
  id?:            number;
  cohort_type:     string;
  cohort_name:     string;
  cohort_logic?:   string;
  logic_operator: string;
  status?:        'Active' | 'In progress' | 'Error';
  active?:        'True' | 'False';
}) => {
  const { id, cohort_logic, cohort_type, cohort_name, logic_operator, status, active } = params;
  const options = {
    method: 'POST',
    url: `${root}/cohort/change/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      id,
      cohort_type,
      cohort_name,
      cohort_logic,
      logic_operator,
      status,
      active: active ?? 'True'
    }
  };

  try {
    const response = await axios.request(options);
    const data = response.data;

    return data.value;
  }
  catch (error) {
    console.log('/cohort/view/ failed: ', error);
    throw error?.message;
  }
};