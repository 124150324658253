import Media from "react-media";
import React from "react";
import {Header} from "../../components/Header/Header";
import Footer from "../../components/Footer";
import FormReg from "./FormReg";
import styles from './FormPage.module.scss';

const FormPage = () => {
    return (
        <div>
            <Media queries={{
                desktop: "(min-width: 1350px)",
                tablet: "(min-width: 500px) and (max-width: 1349px)",
                mobile: "(max-width: 499px)",
            }}>
                {matches => (
                    <React.Fragment>
                        {matches.desktop && (
                            <div className={styles.appWrapperOwner}>
                                <div className={styles.imgFormWrapper}>
                                    <a href={"/"}>
                                        <img
                                            className={styles.imgForm}
                                            src='assets/svg/figures/logo-merlin.svg'
                                            alt='Merlin'
                                        />
                                    </a>
                                </div>
                                <div className={styles.imgFormWrapper}>
                                    <FormReg />
                                </div>
                            </div>
                        )}
                        {matches.tablet && (
                            <div className={styles.appWrapperOwner}>
                                <div className={styles.imgFormWrapper}>
                                    <a href={"/"}>
                                        <img
                                            className={styles.imgForm}
                                            src='assets/svg/figures/logo-merlin.svg'
                                            alt='Merlin'
                                        />
                                    </a>
                                </div>
                                <div className={styles.imgFormWrapper}>
                                    <FormReg />
                                </div>
                            </div>
                        )}
                        {matches.mobile && (
                            <div className={styles.appWrapperOwner}>
                                <div className={styles.imgFormWrapper}>
                                    <a href={"/"}>
                                        <img
                                            className={styles.imgForm}
                                            src='assets/svg/figures/logo-merlin.svg'
                                            alt='Merlin'
                                        />
                                    </a>
                                </div>
                                <div className={styles.imgFormWrapper}>
                                    <FormReg />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </Media>
        </div>
    );
};

export default FormPage;