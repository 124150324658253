import React from 'react';

import styles from './InvestorBlock.module.scss';

export const InvestorBlock = ({ img, name, jobInfo, comment }) => {
  return (
    <div className={styles.investorBlock}>
      <div className={styles.top}>
        <div className={styles.avatar}>
          <img src={`assets/investors/${img}`} alt='Investor' />
        </div>
        <div>
          <span>{name}</span>
          <span>{jobInfo}</span>
        </div>
      </div>
      <div className={styles.comment}>{comment}</div>
    </div>
  );
};
