import React, { useState, useEffect, HTMLProps } from 'react';
import classNames from 'classnames';
import styles from './Checkbox.module.scss';

type CheckboxProps = HTMLProps<HTMLInputElement> & {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
};

const Checkbox = (props: CheckboxProps) => {
  const { disabled = false, onChange, children, style } = props;

  const [checked, setChecked] = useState(props.checked ?? false);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked])

  const handleChange = () => {
    if (onChange) {
      onChange(!checked);
    }
    setChecked(!checked);
  };

  return (
    <label
      className={classNames(
        styles.containerCheckbox,
        disabled && styles.containerCheckboxDisabled
      )}
      style={style}
    >
      {children}
      <input
        className={styles.checkboxInput}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      />
      <span className={styles.checkmark}></span>
    </label>
  );
};

export default Checkbox
