import React, { useEffect, useState } from 'react';
import { isEqual } from "lodash";
import { Pagination } from '../../../components/Dashboard/Pagination/Pagination2';
import Group from '../Table/Group';
import { columns, customColumns } from './columns';
import NotificationPopup from '../../../components/NotificationPopup/NotificationPopup';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import GroupEditor from './GroupEditor/GroupEditor';
import Options from '../../../components/UI/Options/Options';
import ActiveIcon from '../Table/ActiveIcon/ActiveIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomTable,
  selectTable,
  selectGroups,
  selectIsEditorOpen,
  changeTableRow,
  selectPopupText,
  setPopupText,
  removeTableRows,
  TableData,
  addGroup
} from '../../../store/settingsSlice';
import { selectDataSourceID } from '../../../store/common-slice';
import { selectID, selectIsAnalyticsInitialized, selectTenantID, selectTenantName } from '../../../store/userSlice';
import { fetchCoverage } from '../../../api';
import { readMessage } from '../../../api/message';
import usePrevious from '../../../utils/use-previous';
import useForceUpdate from '../../../utils/use-force-update';
import { setDataSourceID } from '../../../utils/data-source-id';
import useNeedToReload from '../../../utils/use-need-to-reload';

import styles from './style.module.scss';

const ITEMS_PER_PAGE = 10;

export default function DataSources() {
  const dispatch = useDispatch();
  const tableData = useSelector(selectTable);
  const customTableData = useSelector(selectCustomTable);
  const isEditorOpen = useSelector(selectIsEditorOpen);
  const groups = useSelector(selectGroups);
  const tenant_id = useSelector(selectTenantID);

  const [currentPage, setCurrentPage] = useState(1);
  const popupText = useSelector(selectPopupText)

  const addresses = tableData.map(r => r.address);
  const prevAddresses = usePrevious(addresses);
  const needToReload = useNeedToReload();
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const dataSourceID = useSelector(selectDataSourceID);
  const tenantName = useSelector(selectTenantName);

  const forceUpdate = useForceUpdate();
  useEffect(forceUpdate, [dataSourceID]);

  useEffect(() => {
    if (tenant_id === -1 || isEqual(prevAddresses, addresses)) return;

    for (let row of tableData) {
      fetchCoverage(`data_source_id=${row.id}&tenant_id=${tenant_id}`)
        .then(response => {
          if (!response) {
            console.log("fetchCoverage не отдал response", response);
            return;
          }
          const { coverage, last_updated } = response;
          const { id } = row;
          dispatch(changeTableRow({
            id,
            changes: {
              coverage: coverage,
              lastUpload: last_updated?.replace(/\//g, '')
            }
          }));
        });

      }
  }, [tenant_id, tableData]);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'settings_data_source_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  const DataSourcesOptions = (props: {
    data:    TableData;
    inGroup?:  boolean;
    selected?: boolean;
  }) => {
    const { data, inGroup, selected } = props;
    
    if (selected) {
      return (
        <Options
          data={[
            {
              icon:
                <img
                  className={styles.plusIcon}
                  src="/assets/svg/dashboard/plus.svg"
                  alt="Plus icon"
                  aria-hidden="false"
                />
              ,
              text: (() => {
                const itemsInGroup = tableData.filter(r => r.selected).length;
                return (
                  <>
                    <span>Create group</span>
                    <span
                      style={{
                        marginLeft: '7px',
                        color: '#B9BDC8',
                        fontWeight: 400,
                        fontSize: '12px',
                      }}
                    >
                      {itemsInGroup}
                    </span>
                  </>
                )
              })(),
              callback: e => {
                const ids = tableData.filter(r => r.selected).map(r => r.id);
                dispatch(addGroup({ name: 'New group', ids, save: true }));
              }
            },
            {
              icon: <img src="/assets/svg/dashboard/edit.svg" alt="Edit icon" />,
              text: 'Edit',
              callback: e => {

                const { id } = data;
                dispatch(
                  changeTableRow({
                    id,
                    changes: {
                      options: { ...data.options, editing: true }
                    }
                  })
                );
              }
            }
          ]}
        />
      )
    }

    return (
      <Options
        data={[
          {
            icon: <ActiveIcon />,
            text: 'Make Active',
            callback: e => {
              const { id } = data;
              dispatch(changeTableRow({ id, changes: { isActive: true } }));
              setDataSourceID(id);
            }
          },
          {
            icon: <img src="/assets/svg/dashboard/edit.svg" alt="Edit icon" />,
            text: 'Edit',
            callback: e => {

              const { id } = data;
              dispatch(
                changeTableRow({
                  id,
                  changes: {
                    options: { ...data.options, editing: true }
                  }
                })
              );
            }
          },
          {
            icon:
              <img
                src="/assets/svg/dashboard/trash.svg"
                alt="Delete icon"
              />
            ,
            text: 'Delete',
            callback: e => {
              if (inGroup) {
                dispatch(setPopupText(`You need to remove a contract from group to delete it.`))
              }
              else {
                dispatch(removeTableRows({ ids: [data.id] }));
              }
            }
          }
        ]}
      />
    )
  }

  return (
    <>
      <div className={styles.tableWrap}>
        <TableHead columns={columns} />

        {groups.map((group, index) => {
          const data = tableData.filter(item =>
            group.ids.find(id => id === item.id
          ));
          return (
            <Group
              key={index}
              id={group.id}
              name={group.name}
              withBackground
            >
              {data.map(data =>
                <TableRow
                  key={data.id}
                  columns={columns}
                  data={data}
                  inGroup
                  // getContextUI={selected =>
                  //   <DataSourcesOptions
                  //     data={data}
                  //     inGroup
                  //     selected={selected}
                  //   />
                  // }
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                />
              )}
            </Group>
          )
        })}

        {customTableData
          .map(row =>
            <TableRow
              key={row.id}
              columns={customColumns}
              data={row}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            />
          )
        }

        {tableData
          .slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
          )
          .map(data =>
            <TableRow
              key={data.id}
              columns={columns}
              data={data}
              // getContextUI={selected =>
              //   <DataSourcesOptions
              //     data={data}
              //     selected={selected}
              //   />
              // }
              style={{
                height: '100%',
                display: 'flex',
                alignItems: 'center'
              }}
            />
          )
        }
      </div>
      <Pagination
        itemPerPage={10}
        totalItems={tableData.length}
        onSwitchPage={pagination => setCurrentPage(pagination.currentPage)}
      />

      {isEditorOpen && <GroupEditor />}

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }

      {popupText.length > 0 &&
        // @ts-ignore
          <NotificationPopup
            reloadButtonVisible={false}
            title="Warning"
          >
            {popupText}
          </NotificationPopup>
      }
    </>
  );
};
