import React, { useEffect, useState } from 'react';
import { getToken } from '../../utils/token';
import { useSelector } from 'react-redux';
import { selectDataSourceID } from '../../store/common-slice';
import { fetchDataSources } from '../../api/fetch-data-sources';
import { fetchMessage, readMessage } from '../../api/message';
import fetchCharts from '../../api/fetch-charts';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import { BarCustom } from '../../components/Dashboard/Charts/CustomBar/BarCustom';
import { OverviewChart } from '../../components/Dashboard/Charts/OverviewChart/OverviewChart';
import { ScatterCustom } from '../../components/Dashboard/Charts/ScatterCustom/ScatterCustom';
import LineChart from '../../components/LineChart/LineChart';
import PieChart from '../../components/Dashboard/PieChart/PieChart';
import Prelaoder from '../../components/Preloader/Preloader';
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup';
import redirectToAuth from '../../utils/redirect-to-auth';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../store/userSlice';

import s from "./DeepDive.module.scss";
import styles from './style.module.scss';

const pageName = 'Graphs monitoring';

export default function DeepDive(props) {
  const [charts, setCharts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [needToReload, setNeedToReload] = useState(false);

  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'custom_view_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  },[isAnalyticsInitialized]);

  useEffect(() => {
    fetchDataSources();

    setInterval(async () => {
      const response = await fetchMessage();
      setNeedToReload(response.has_message);
    }, 30_000);
  }, []);

  const dataSourceID = useSelector(selectDataSourceID);
  useEffect(() => {
    fetchCharts()
      .then(response => setCharts(response.details))
      .then(() => setIsLoading(false));
  }, [dataSourceID]);

  if (!getToken()) {
    redirectToAuth();
    return;
  }

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          <div className={styles.contentWrap}>

            <div className={s.content}>

              {charts.map((chart, index) => {
                const { type, title, data, legend } = chart;
                const showLegend = legend === 'True';
                return (
                  <div key={index} className={s.chartWrapper}>
                    <h2 className={s.chartTitle}>{title.main}</h2>
                    {(() => {
                      switch (type) {
                        case 'bar': return (
                          <BarCustom
                            barCustomData={chart}
                            downloadGraphPng={null}
                            legend={showLegend}
                          />
                        );
                        case 'line': return (
                          <LineChart
                            data={data}
                            axis={chart.axis}
                            legend={showLegend}
                          />
                        );
                        case 'pie': return (
                          <PieChart
                            data={data}
                            legend={showLegend}
                          />
                        );
                        case 'histogram': return (
                          <OverviewChart
                            histogramData={chart}
                            downloadGraphPng={null}
                          />
                        );
                        case 'scatter': return (
                          <ScatterCustom
                            hideGrid
                            dotColor="#003BD2"
                            scatterCustomData={chart}
                            downloadGraphPng={null}
                          />
                        );
                        default: return null;
                      }
                    })()}
                  </div>
                );
              })}
            </div>

            <Prelaoder hidden={!isLoading} />

          </div>
        </div>
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }
    </div>
  );
};
