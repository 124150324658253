import React, { useState, useEffect, useRef, HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './ContextMenu.module.scss';

interface ContextMenuProps extends HTMLAttributes<HTMLDivElement> {
  onAddContract?: () => void;
  onEdit?:        () => void;
  onDelete?:      () => void;
}

const ContextMenu = ({ onAddContract, onEdit, onDelete, className }: ContextMenuProps) => {
  const [ isOpen , setIsOpen ] = useState(false);

  const contextMenu = useRef<HTMLDivElement>();
  const contextMenuButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне дропдауна
      if (!contextMenu.current?.contains(e.target) && !contextMenuButton.current?.contains(e.target)) {
        setIsOpen(false)
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className={classNames(styles.contextMenuWrapper, className)}>
      <button
        ref={contextMenuButton}
        className={styles.addData}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img 
          src="/assets/svg/dashboard/context-menu-icon.svg" 
          alt="Add" 
          aria-hidden="true" 
        />
      </button>

      {isOpen  && 
        <div 
          className={styles.popup}
          ref={contextMenu}
        >
          <ul>
            <li className={styles.contract} >
              <button
                className={styles.smartContract}
                onClick={() => {
                  if (onAddContract) onAddContract();
                  setIsOpen(false);
                }}
                aria-label="Add smart contract"
              >
                <img
                  className={styles.plusIcon}
                  src="/assets/svg/dashboard/plus.svg"
                  alt="Plus icon"
                />
                <span>Smart contract</span>
              </button>
            </li>

            <li >
              <button
                onClick={() => {
                  if (onEdit) onEdit();
                  setIsOpen(false);
                }}
                aria-label="Edit row"
              >
                <img src="/assets/svg/dashboard/edit.svg" alt="edit-icon" />
                <span>Edit</span>
              </button>
            </li>

            <li >
              <button
                disabled
                onClick={() => {
                  if (onDelete) onDelete();
                  setIsOpen(false);
                }}
                aria-label="Delete row"
              >
                <img
                  src="/assets/svg/dashboard/trash.svg"
                  alt="delete-icon"
                />
                <span>Delete</span>
              </button>
            </li>
          </ul>
        </div>
      }
    </div>
  );
};

export default ContextMenu;
