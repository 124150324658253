import React from 'react';
import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

export type Recipe = {
  name:         string;
  icon:         string;
  description?: string;
  active?:      boolean;
};

type State = {
  isPopupActive: boolean;
  recipes: Recipe[];
};

const initialState: State = {
  isPopupActive: false,
  recipes: [
    {
      name: 'Onboarding Flow',
      icon: '/assets/socials/epns.svg',
    },
    {
      name: 'Nudge Whales',
      icon: '/assets/socials/webhook.svg',
    }
  ]
};

export const notificationsRecipesSlice = createSlice({
  name: "notificationsRecipes",
  initialState,
  reducers: {
    setIsPopupActive: (state, action: PayloadAction<boolean>) => {
      state.isPopupActive = action.payload;
    },
    setRecipes: (state, action: PayloadAction<Recipe[]>) => {
      const recipes = action.payload;
      state.recipes = recipes;
    },
    addRecipe: (state, action: PayloadAction<Recipe>) => {
      const data = action.payload;
      state.recipes.push(data);
      localStorage.setItem('recipes', JSON.stringify(state.recipes));
    }
  }
});

// Actions

export const {
  setIsPopupActive,
  setRecipes,
  addRecipe
} = notificationsRecipesSlice.actions;

// Selectors

export const notificationsRecipesSelector = state => state.notificationsRecipes;

export const selectIsPopupActive = createSelector(
  notificationsRecipesSelector,
  (state: State) => state.isPopupActive
);

export const selectRecipes = createSelector(
  notificationsRecipesSelector,
  (state: State) => state.recipes
);

export default notificationsRecipesSlice.reducer;