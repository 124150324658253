import React, { useState } from "react";

import styles from './RollingText.module.scss';

const loremIpsum = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "

const textDefault = "We just raised $X with participation from South Park Commons and Script Capital. ㅤRead moreㅤ→ㅤ"

const RollingText = ({ duration = "120s", text = textDefault }) => {

  

  const repeatChildren = () => {
    return [...Array(30).keys()].map(() => {
      return (<div style={{"marginRight": "8px"}}>{text}</div>) 
    })
  }


  return (
    
    // <marquee Scrollamount={duration} className={styles.marquee} >
    //   <div className={styles.marqueeChildrenWrapper}>
    //   {repeatChildren()}
    //   </div>
        
    // </marquee>
    <div className={styles.marqueeChildrenWrapper}>
      <div className={styles.m} style={{"animationDuration": duration}} data-text={text}>
       {repeatChildren()}
      </div>
    </div>
  );
};

export default RollingText;