import React from 'react';
import { useSelector } from 'react-redux';
import { selectDataSourceID } from '../../../../store/common-slice';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../../../store/userSlice';
import { AssetsBlock } from '../AssetsBlock/AssetsBlock';
import { LabelBlock } from '../LabelBlock/LabelBlock';
import { SourseBlock } from '../SourseBlock/SourseBlock';
import CopyButton from './CopyButton';
import Stacked from '../LabelBlock/Stacked';

import styles from './RowContent.module.scss';

export const RowContent = ({ tableColumns = [], rowContent, isLast, onClick }) => {
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);
  const dataSourceID = useSelector(selectDataSourceID);

  const colorOfLabel = (label) => {
    switch (label) {
      case 'Developer':
        return '#386DF7';
      case 'Newbie':
        return '#DE4AF5';
      case 'L2_Pro' || 'L2 Explorer':
        return '#5600C5';
      case 'Browsing':
        return '#00A5A5';
      case 'Token_Collectooor':
        return '#07BDCA';
      case 'NFT_Collectooor':
        return '#07BDCA';
      default:
        return '#386DF7';
    }
  };

  return (
    <tr
      className={styles.wrapper}
      onMouseEnter={() => {
        const pageUrl = document.location.href;
        if (pageUrl.includes('wallet-analysis') && isAnalyticsInitialized) {
          /*eslint-disable*/
          merlin.send({
            event_name: 'wallet_explorer_table_hover',
            event_type: 'hover',
            event_properties: {
              data_source_id: dataSourceID,
              wallet_address: rowContent.wallet
            },
            user_properties: {
              tenantName: tenantName
            }
          })
        }
      }}
      onClick={onClick}
    >
      {tableColumns.map((tableColumn) => {
        const cellData = rowContent[tableColumn.column_id];
        const walletData = rowContent.wallet.split('|', 2);

        switch (tableColumn.column_type) {
          case 'wallet': {
            const shortenedAddress = `${walletData[0].slice(0, 3)}...${walletData[0].slice(-6)}`;
            return (
              <td className={styles.walletInfo}>
                {walletData[1] &&
                  <p className={styles.textBold} title={walletData[1]}>{walletData[1]}</p>
                }
                <div className={styles.address}>
                  <span title={walletData[0]}>{shortenedAddress}</span>
                  <CopyButton
                    className={styles.address__copyButton}
                    onClick={e => {
                      const pageUrl = document.location.href;
                      const address = walletData[0];
                      navigator.clipboard.writeText(address)
                        .then(() => {
                          console.log('Copying to clipboard was successful!');
                        })
                        .catch(error => {
                          console.error('Could not copy text: ', error);
                        });

                      if (pageUrl.includes('wallet-analysis') && isAnalyticsInitialized) {
                        /*eslint-disable */
                        merlin.send({
                          event_name: 'wallet_explorer_table_address_copy',
                          event_type: 'click',
                          user_properties: {
                            tenantName
                          }
                        });
                      }
                    }}
                  />
                </div>
              </td>
            );
          }
          case 'string': {
            if (tableColumn.suffix === '%') {
              return <td>{`${cellData}${tableColumn.suffix}`}</td>;
            }
            else if (tableColumn.suffix) {
              return <td>{cellData === null ? '--' : `${cellData}  ${tableColumn.suffix}`}</td>;
            }
            return <td>{cellData}</td>;
          }
          case 'string_bold': {
            if (tableColumn.suffix === '%') {
              return <td className={styles.string_bold}>{`${cellData}${tableColumn.suffix}`}</td>;
            }
            else if (tableColumn.suffix) {
              return <td className={styles.string_bold}>{cellData === null ? '--' : `${cellData}  ${tableColumn.suffix}`}</td>;
            }
            return <td className={styles.string_bold}>{cellData}</td>;
          }
          case 'labels': {
            const assets = cellData?.split(';');
            return (
              <td className={styles.assetsBlock}>
                <div style={{ display: 'flex', gap: '2px' }}>
                  {assets
                    .slice(0, 2)
                    .map((asset, index) => (
                      <AssetsBlock
                        key={index}
                        text={asset}
                      />
                    ))
                  }
                  {assets.length > 2 &&
                    <AssetsBlock text="..." />
                  }
                </div>
              </td>
            )
          }
          case 'labels_color': {
            return (
              <td>
                <Stacked>
                  {cellData?.split(';').map(label => {
                    const isCohort = tableColumn.column_id === 'cohort_name';
                    return (
                      <LabelBlock
                        key={label}
                        text={label}
                        color={isCohort ? 'white' : colorOfLabel(label)}
                        textColor={isCohort ? 'black' : 'white'}
                        style={{ boxShadow: isCohort && '0 0 0 1px #dfe3eccc' }}
                      />
                    )
                  }
                  )}
                </Stacked>
              </td>
            );
          }
          case 'icons': {
            return (
              <td>
                {(() => {
                  const alts = {
                    'up':       'Arrow up',
                    'down':     'Arrow down',
                    'constant': 'Constant'
                  };
                  const icon = cellData;
                  switch (icon) {
                    case 'none': return '—';
                    default: return (
                      <img
                        src={`/assets/svg/dashboard/table-icons/${icon}.svg`}
                        alt={alts[icon]}
                      />
                    );
                  }
                })()}
              </td>
            );
          }
          case 'icons_with_text': {
            if (tableColumn.suffix) {
              return (
                <td>
                  <div>
                    <div className={styles.sourseWrapper}>
                      {cellData?.split(';').map((found, index) => {
                        const [name, value] = found.split(':');
                        return (
                          <SourseBlock
                            key={index}
                            icon={name === 'Others' ? 'other' : name}
                            company={name}
                            value={value}
                            suffix={tableColumn.suffix}
                          />
                        );
                      })}
                    </div>
                  </div>
                </td>
              );
            }
          }
          default: return <td>{cellData}</td>;
        }
      })}
      {!isLast && <div className={styles.line} />}
    </tr>
  );
};
