import React from 'react';
import { round } from 'lodash';
import { BarChart as Chart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts';
import styles from './BarChart.module.scss';

type Props = {
  data: {
    /** Event name */
    name:  string;
    /** Current value (e.g. number of unique users) */
    value: number;
    /** Previous value */
    prev:  number;
    vp?:   number;
    pp?:   number;
    step?: number;
  }[];
  onClick?: (step: number) => void;
};

const { format } = Intl.NumberFormat();

export default function BarChart(props: Props) {
  let { data, onClick } = props;
  if (!data || data.length === 0) {
    return null;
  }

  data = data.map((d, index) => {
    let vp = 0;
    if (index === 0) {
      if (d.value !== 0) {
        vp = 100;
      }
    }
    else {
      vp = d.value / data[0].value * 100;
      if (isNaN(vp)) {
        vp = 0;
      }
    }
    d.vp = round(vp, 1);
    d.pp = index === 0 ? 0 : round(data[index - 1].vp - d.vp, 1);
    d.step = index + 1;
    return d;
  });

  const CustomTooltip = (props) => {
    const { active, payload } = props;
    if (active && payload?.length) {
      const { value } = payload[0].payload;
      let percent = value / data[0].value * 100;
      if (!isFinite(percent)) {
        percent = 0;
      }

      return (
        <div
          className="custom-tooltip"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '10px',
            color: '#7A7E89'
          }}
        >
          <span style={{ fontSize: '12px', color: '#6C95FF' }}>
            {format(percent)}%
          </span>
          <span>{format(value)}</span>
        </div>
      );
    }
    return null;
  };

  const CustomizedLabel = (props) => {
    const { width, height, index } = props;
    
    const wrapperW = 50;

    let x = props.x + width / 2 - wrapperW / 2;
    let y = props.y + height - 10;
    const percent = data[index]?.vp;
    if (percent < 10) {
      y -= 40;
    }

    return (
      <foreignObject
        x={x}
        y={y}
        width={wrapperW}
        height={100}
      >
        <div
          className={styles.customTooltip}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: '10px',
            color: '#7A7E89'
          }}
        >
          <span style={{ fontSize: '12px', color: '#6C95FF' }}>
            {format(percent)}%
          </span>
          <span>{format(data[index]?.value)}</span>
        </div>
      </foreignObject>
    );
  }

  return (
    <ResponsiveContainer width="100%" height="100%">
      <Chart
        data={data}
        margin={{
          top:    20,
          right:  30,
          left:   20,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey="name"
          tick={{ color: '#1A1D21', fontSize: '12px' }}
          tickFormatter={(value: string, index: number) => {
            const limit = 20;
            if (value.length > limit) {
              value = value.substring(0, limit) + '...';
            }
            return value;
          }}
          tickMargin={5}
          interval={0}
        />
        <YAxis
          tick={{ color: '#7A7E89', fontSize: '10px' }}
          tickFormatter={value => value + '%'}
          axisLine={false}
          tickLine={false}
        />
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={<CustomTooltip />}
        />
        <Bar dataKey="vp" stackId="a" fill='#6C95FF' radius={[10, 10, 0, 0]}
          onClick={data => {
            if (onClick) {
              onClick(data.step);
            }
          }}
        />
        <Bar dataKey="pp" stackId="a" fill='#F4F6FA' radius={[10, 10, 0, 0]} label={<CustomizedLabel />} />
      </Chart>
    </ResponsiveContainer>
  );
}
