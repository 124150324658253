import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import FallbackUI from '../../components/Dashboard/FallbackUI';
import Pagination from '../../components/Dashboard/Pagination';
import Filter from '../../components/Filter/Filter';

import styles from './Partnerships.module.scss';

const ITEMS_PER_PAGE = 9;
const initialData = require('./data.json') as {}[];

const { format } = Intl.NumberFormat();

export default function Partnerships() {
  const pageName = 'Partnerships';
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<{ [key: string]: any; }>({});

  const [cohorts, setCohorts] = useState<string[]>([]);
  useEffect(() => {
    setCohorts([...new Set(initialData.map(d => d["cohort"]))]);
  }, []);
  
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(initialData.filter(d => (d["cohort"] as string).match(filters?.cohort)));
  }, [filters]);

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          {error
            ? <FallbackUI />
            :
            <>
              <h1 className={styles.title}>
                {pageName}
              </h1>

              <Filter
                options={[
                  {
                    label:    'Cohort',
                    prop:     'cohort',
                    type:     'select',
                    options:  cohorts,
                    selected: filters?.cohort != null,
                    value:    filters?.cohort
                  }
                ]}
                onDone={_filters => {
                  setFilters({ ...filters, ..._filters });
                }}
                onClear={() => setFilters({})}
              />

              <div className={styles.pageInfo}>
                <span>
                  Number of potential partnerships: {filteredData.length}
                </span>
                <span>
                  Last updated: May 1st, 2022
                </span>
              </div>

              <div className={styles.wrapper}>
                {filteredData
                  .slice(
                    (currentPage - 1) * ITEMS_PER_PAGE,
                    (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                  )
                  .map((data, index) => 
                    <div
                      key={index}
                      className={styles.tile}
                    >
                      <div className={styles.tileHeader}>
                        <div className={styles.tileImgWrapper}>
                          <img src={data["logo"]} alt="" />
                        </div>
                        <div>
                          <h3 className={styles.tileTitle}>{data["DAO Name"]}</h3>
                          <span className={styles.tileSubtitle}>{data["Type"]}</span>
                        </div>
                      </div>

                      <div className={styles.tileContent}>
                        <div className={styles.tileMembers}>
                          <span className={styles.tileMembersTitle}>{format(data["Potential Members"])}</span>
                          <span className={styles.tileContentSubtitle}>Potential Memebers</span>
                        </div>

                        <div className={styles.tileActivity}>
                          <div className={styles.tileActivityIcon}>
                            {(() => {
                              switch (data["activity"]) {
                                case 'low': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path d="M18.203 11.92c-.8-.4-1.592-1.079-3.201-1-2.013.097-2.793 2.217-4.8 2.4-1.648.15-2.961-.573-3.999-1.4" stroke="#7A7E89" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                )
                                case 'medium': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path d="M2.455 17.24a1.5 1.5 0 0 0 2.12 0l7.426-7.425 7.425 7.425a1.5 1.5 0 0 0 2.12-2.121l-8.485-8.486a1.5 1.5 0 0 0-2.12 0l-8.486 8.485a1.5 1.5 0 0 0 0 2.121Z" fill="url(#a)" />
                                    <defs>
                                      <linearGradient id="a" x1="2.016" y1="11.936" x2="21.968" y2="11.936" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#4034FF" />
                                        <stop offset="1" stopColor="#DC01FF" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                )
                                case 'high': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path d="M2.455 13.486a1.5 1.5 0 0 0 2.12 0l7.426-7.425 7.425 7.425a1.5 1.5 0 0 0 2.12-2.121l-8.485-8.486a1.5 1.5 0 0 0-2.12 0l-8.486 8.486a1.5 1.5 0 0 0 0 2.12Z" fill="url(#a)" />
                                    <path d="M2.455 21.486a1.5 1.5 0 0 0 2.12 0l7.426-7.425 7.425 7.425a1.5 1.5 0 0 0 2.12-2.121l-8.485-8.486a1.5 1.5 0 0 0-2.12 0l-8.486 8.486a1.5 1.5 0 0 0 0 2.12Z" fill="url(#b)" />
                                    <defs>
                                      <linearGradient id="a" x1="-3.97" y1="10.042" x2="-.158" y2="-3.697" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#DC01FF" />
                                        <stop offset="1" stopColor="#3736FF" />
                                      </linearGradient>
                                      <linearGradient id="b" x1="-3.97" y1="18.042" x2="-.158" y2="4.303" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#DC01FF" />
                                        <stop offset="1" stopColor="#3736FF" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                )
                              }
                            })()}
                          </div>
                          <span className={styles.tileContentSubtitle}>
                            Activity
                          </span>
                        </div>

                        <div className={styles.tileRelevance}>
                          <div className={styles.tileRelevanceIcon}>
                            {(() => {
                              switch (data["relavance"]) {
                                case 'low': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21 24.679c-.828 0-1.5-.624-1.5-1.393V2.071c0-.769.672-1.392 1.5-1.392s1.5.623 1.5 1.392v21.215c0 .77-.672 1.393-1.5 1.393ZM14.5 24.679c-.828 0-1.5-.624-1.5-1.393V6.68c0-.77.672-1.393 1.5-1.393S16 5.91 16 6.68v16.607c0 .77-.672 1.393-1.5 1.393ZM8.5 24.679c-.828 0-1.5-.624-1.5-1.393V11.68c0-.77.672-1.393 1.5-1.393s1.5.624 1.5 1.393v11.607c0 .77-.672 1.393-1.5 1.393Z" fill="#DFE3EC" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 24.679c-.828 0-1.5-.624-1.5-1.393V16.68c0-.77.672-1.393 1.5-1.393S4 15.91 4 16.68v6.607c0 .77-.672 1.393-1.5 1.393Z" fill="#DC01FF" />
                                  </svg>
                                )
                                case 'medium': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21 24.12c-.828 0-1.5-.624-1.5-1.393V1.51C19.5.742 20.172.12 21 .12s1.5.623 1.5 1.392v21.216c0 .769-.672 1.392-1.5 1.392ZM14.5 24.12c-.828 0-1.5-.624-1.5-1.393V6.119c0-.769.672-1.392 1.5-1.392S16 5.35 16 6.119v16.608c0 .769-.672 1.392-1.5 1.392Z" fill="#DFE3EC" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M8.5 24.12c-.828 0-1.5-.624-1.5-1.393V11.119c0-.769.672-1.392 1.5-1.392s1.5.623 1.5 1.392v11.608c0 .769-.672 1.392-1.5 1.392ZM2.5 24.12c-.828 0-1.5-.624-1.5-1.393v-6.608c0-.769.672-1.392 1.5-1.392S4 15.35 4 16.119v6.608c0 .769-.672 1.392-1.5 1.392Z" fill="#DC01FF" />
                                  </svg>
                                )
                                case 'high': return (
                                  <svg fill="none" viewBox="0 0 24 25">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M21 24.44c-.828 0-1.5-.623-1.5-1.392V1.833c0-.77.672-1.393 1.5-1.393s1.5.624 1.5 1.393v21.215c0 .77-.672 1.392-1.5 1.392ZM14.5 24.44c-.828 0-1.5-.623-1.5-1.392V6.44c0-.769.672-1.392 1.5-1.392S16 5.671 16 6.44v16.608c0 .77-.672 1.392-1.5 1.392ZM8.5 24.44c-.828 0-1.5-.623-1.5-1.392V11.44c0-.768.672-1.392 1.5-1.392s1.5.623 1.5 1.392v11.608c0 .77-.672 1.392-1.5 1.392ZM2.5 24.44c-.828 0-1.5-.623-1.5-1.392V16.44c0-.769.672-1.392 1.5-1.392S4 15.671 4 16.44v6.608c0 .77-.672 1.392-1.5 1.392Z" fill="#DC01FF" />
                                  </svg>
                                )
                              }
                            })()}
                          </div>
                          <span className={styles.tileContentSubtitle}>
                            Relavance
                          </span>
                        </div>
                      </div>

                      <a
                        className={styles.tileButton}
                        href={data["Social"]}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>Reach out</span>
                      </a>
                    </div>
                  )
                }
              </div>

              <Pagination
                itemsPerPage={ITEMS_PER_PAGE}
                total={filteredData.length}
                page={currentPage}
                onChange={page => {
                  setCurrentPage(page);
                }}
                style={{ margin: '30px 0' }}
              />
            </>
          }
        </div>
      </div>
    </div>
  );
}