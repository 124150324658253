import React from 'react';
import { PieChart as Chart, ResponsiveContainer, Pie, Tooltip, Cell, Legend } from 'recharts';

const COLORS = ['#6C95FF', '#FF6BB0', '#89D0FF', '#8F6FEA', '#DCFEC8', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

export default function PieChart({ data, legend = false }) {
  const r = 120;

  const RADIAN = Math.PI / 180;
  const CustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#1A1D21"
        textAnchor="middle"
        dominantBaseline="central"
        fontFamily="Inter"
        fontWeight="500"
        fontSize="12"
      >
        {value}
      </text>
    );
  };

  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <Chart>
        <Pie
          data={data}
          cy={150}
          innerRadius={r - 40}
          outerRadius={r}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
          label={CustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        {legend && <Legend />}
      </Chart>
    </ResponsiveContainer>
  );
}
