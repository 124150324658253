import React from "react";
import produce from "immer";
import store from "../../../store";
import { Stack } from "@mui/material";
import { LabelBlock } from "../../../components/Dashboard/TableContent/LabelBlock/LabelBlock";
import PlusIcon from "../../../components/UI/Icons/PlusIcon";
import { Circle, ColorPicker, Progress, TextInput } from "../../../components/UI";
import {
  addLabelsRow,
  changeLabelsRow,
  colors,
  ConditionType,
  DataType,
  removeLabelsRow
} from "../../../store/labels-slice";
import OptionsMenu from "../Table/TableRow/OptionsMenu/OptionsMenu";
import styles from './style.module.scss';

const { dispatch } = store;

export const columns: {
  name:          string;
  prop:          string;
  style:         React.CSSProperties;
  tdStyle?:      React.CSSProperties | ((data: DataType) => React.CSSProperties);
  render:        Function;
  renderColumn?: Function;
}[] = [
  {
    name: '',
    prop: 'index',
    style: {
      width: '3ch',
      textAlign: 'center'
    },
    tdStyle: (data: DataType) => ({
      alignItems: data.editing ? 'flex-start' : 'center',
      paddingTop: data.editing && '1.1ch'
    }),
    render: (index: number) => index + 1
  },
  {
    name: '',
    prop: 'status',
    style: {
      width: '3ch'
    },
    tdStyle: (data: DataType) => ({
      alignItems: data.editing ? 'flex-start' : 'center',
      paddingTop: data.editing && '1.1ch'
    }),
    render: (status: 'Active' | 'In progress' | 'Error', data) => {
      switch (data.editing ? 'Empty' : status) {
        case 'Empty': return ('')
        case 'Active': return (
          <svg width="1.25em" height="1.25em" viewBox="0 0 14 14" fill="none">
            <path d="M6.999 1.666a5.333 5.333 0 1 0 0 10.667 5.333 5.333 0 0 0 0-10.667ZM.332 7a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 .332 7Zm9.776-2.165c.275.244.3.666.056.94l-3.556 4a.667.667 0 0 1-.996 0l-1.778-2a.667.667 0 0 1 .996-.885l1.28 1.44 3.057-3.44a.667.667 0 0 1 .941-.055Z" fill="#00C466" />
          </svg>
        )
        case 'In progress': return (
          <Progress
            type="circle"
            value={25}
            width={"1.25em"}
            height={"1.25em"}
          />
        )
        case 'Error': return (
          <svg width="1.25em" height="1.25em" viewBox="0 0 14 14" fill="none">
            <path d="M6.999 1.666a5.333 5.333 0 1 0 0 10.667 5.333 5.333 0 0 0 0-10.667ZM.332 7a6.667 6.667 0 1 1 13.333 0A6.667 6.667 0 0 1 .332 7Z" fill="#E01E5A" />
            <path d="M6.999 8.333a.667.667 0 0 1-.667-.667v-4a.667.667 0 0 1 1.333 0v4A.667.667 0 0 1 7 8.333ZM5.999 10a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z" fill="#E01E5A" />
          </svg>
        )
      }
    }
  },
  {
    name: 'Label name',
    prop: 'name',
    style: {
      width: '17%'
    },
    tdStyle: (data: DataType) => ({
      alignItems: data.editing ? 'flex-start' : 'center'
    }),
    renderColumn() {
      return this.name;
    },
    render: (name: string, data: DataType) =>
      data.editing
      ?
        <TextInput
          value={name}
          onChange={name => {
            const { id } = data;
            dispatch(changeLabelsRow({ id, changes: { name } }));
          }}
          height="30px"
          style={{ width: '17ch' }}
        />
      : name
  },
  {
    name: '',
    prop: 'color',
    style: {
      width: '10%'
    },
    tdStyle: (data: DataType) => ({
      alignItems: data.editing ? 'flex-start' : 'center'
    }),
    render: (color: string, data: DataType) =>
      data.editing
      ?
        <ColorPicker
          options={colors}
          color={color}
          onChange={color => {
            const { id } = data;
            dispatch(changeLabelsRow({ id, changes: { color } }));
          }}
          style={{ position: 'relative', left: '-1.4ch' }}
        />
      : <Circle color={color} />
  },
  {
    name: 'Condition',
    prop: 'condition',
    style: {
      flexGrow: 1
    },
    render: (conditions: ConditionType[], data: DataType) =>
      <>
        {data.editing
        ?
        <div className={styles.conditionsEditingWrapper}>
          <Stack gap=".9em">
            {conditions.map((condition, index) =>
              <div
                key={index}
                className={styles.conditionsEditingRow}
              >
                <TextInput
                  value={condition.field}
                  onChange={field => {
                    const { id, condition } = data;
                    const changes = produce({ condition }, draft => {
                      draft.condition[index].field = field;
                    });
                    dispatch(changeLabelsRow({ id, changes }));
                  }}
                  height="30px"
                  style={{ width: '150px' }}
                />
                <TextInput
                  value={condition.operator}
                  onChange={operator => {
                    const { id, condition } = data;
                    const changes = produce({ condition }, draft => {
                      // @ts-ignore
                      draft.condition[index].operator = operator;
                    });
                    dispatch(changeLabelsRow({ id, changes }));
                  }}
                  height="30px"
                  style={{ width: '90px' }}
                />
                <TextInput
                  value={condition.value}
                  onChange={value => {
                    const { id, condition } = data;
                    const changes = produce({ condition }, draft => {
                      draft.condition[index].value = value;
                    });
                    dispatch(changeLabelsRow({ id, changes }));
                  }}
                  height="30px"
                  style={{ width: '150px' }}
                />
                {index === conditions.length - 1
                  ?
                  <button
                    className={styles.addConditionButton}
                    onClick={e => {
                      const { id, condition } = data;
                      const changes = produce({ condition }, draft => {
                        draft.condition.push({
                          id:       draft.condition.length,
                          field:    '',
                          operator: '==',
                          value:    ''
                        });
                      });
                      dispatch(changeLabelsRow({ id, changes }));
                    }}
                    style={{
                      marginTop: '0.8ch'
                    }}
                    aria-label="Add condition"
                  >
                    <PlusIcon />
                  </button>
                  :
                  <span className={styles.logicOperator}>{data.logicOperator}</span>
                }
              </div>
            )}
          </Stack>
          <div className={styles.buttons}>
            <button
              className={styles.cancel}
              onClick={() => {
                const { id, unsavedData } = data;
                // Если в `unsavedData` пусто, значит это новосозданная строка,
                // которую нужно удалить
                if (!unsavedData) {
                  dispatch(removeLabelsRow({ id }));
                }
                else {
                  dispatch(changeLabelsRow({ id, changes: unsavedData }));
                }
              }}
            >
              Cancel
            </button>

            <button
              className={styles.save}
              onClick={() => {
                const { id, unsavedData } = data;
                // Если в `unsavedData` пусто, значит это новосозданная строка
                if (!unsavedData) {
                  dispatch(addLabelsRow({ id }));
                }
                else {
                  dispatch(changeLabelsRow({ id, changes: { editing: false, unsavedData: null }, shouldSave: true }));
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
        :
        conditions
          .map(condition =>
            <LabelBlock
              color="#F4F6FA"
              textColor="black"
              style={{
                display: 'inline-block',
                marginTop: 'unset',
                maxWidth: 'fit-content',
                overflow: 'initial'
              }}
            >
              {`${condition.field} ${condition.operator} ${condition.value}`}
            </LabelBlock>
          )
          .reduce((acc, x) => {
            const separator = <span style={{ margin: '0 1ch' }}>{data.logicOperator}</span>;
            return acc === null
              ? x
              : <>{acc} {separator} {x}</>
          }, null)
        }
        <OptionsMenu
          options={[
            {
              disabled: true,
              text: 'Edit',
              icon:
                <img
                  src="/assets/svg/dashboard/edit.svg"
                  alt="edit-icon"
                />,
              callback(e?) {
                
              }
            },
            {
              disabled: true,
              text: 'Delete',
              icon:
                <img
                  src="/assets/svg/dashboard/trash.svg"
                  alt="delete-icon"
                />,
              callback(e?) {
                console.log(e);
              }
            }
          ]}
          style={{ marginLeft: 'auto', marginRight: '5px' }}
        />
      </>
  }
];