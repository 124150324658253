import React from 'react';
import { ContentBlock } from '../AnswerBlock/ContentBlock/ContentBlock';
import LottieExample from "../../Lottie-emample";

import styles from './AnswerHorizontal.module.scss';

const AnswerHorizontal = () => {
    const lottieProps = {
        jsonName: 'lottieJsonContextualizeTransactions',
        widthLottie: 546,
        heightLottie: 350,
        speed: 1,
    }

  return (
    <div className={styles.wrapper}>

      <div className={styles.titles}>
        <ContentBlock
          title='Segmentation'
          subtitle='Forget traversing multiple block explorers. Dive into activity with rich context about users and contracts with respect to your product.'
          btnText='Learn more'
        />
      </div>
      <div className={styles.contentImg}>
          <LottieExample lottieProps={lottieProps}/>
      </div>
    </div>
  );
};

export default AnswerHorizontal;
