import React from 'react';
import styles from './ContentTitles.module.scss';

export const ContentTitles = ({ title, subtitle }) => {
  return (
    <div className={styles.titles}>
      <h2>{title}</h2>
      <h3>{subtitle}</h3>
    </div>
  );
};
