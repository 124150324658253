import domtoimage from "dom-to-image";
import watermark from "watermarkjs";

export const downloadImgById = (id) => {
  var node = document.getElementById(id);

  domtoimage.toBlob(node).then(async function (blob) {
    // console.log("blob", blob)

    watermark([blob, "/assets/content/watermark.svg"])
      .image(watermark.image.lowerRight())
      .then(function (img) {
        // console.log("img", img)
        window.saveAs(img.src, `${id}.png`);
      });
  });
};
