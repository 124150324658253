import React, { useEffect, useRef, useState } from 'react';
import produce from 'immer';
import classNames from 'classnames';
import { Button, Checkbox, SelectInput, TextInput } from '../UI';
import styles from './Filter.module.scss';

export type FilterOption = {
  label:    string;
  prop:     string;
  type:     string;
  selected: boolean;
  value?:   any;
  options?: string[];
};

type FilterProps = {
  options:  FilterOption[];
  onDone?:  (filters: { [key: string]: any }) => void;
  onClear?: (filters: { [key: string]: any }) => void;
};

export default function Filter({ options, onDone, onClear }: FilterProps) {
  const [open, setOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState<{
    [key: string]: { selected: boolean; value: any }
  }>({});
  useEffect(() => {
    const map = {};
    for (let option of options) {
      const { selected, value } = option;
      map[option.prop] = {
        selected,
        value
      };
    }
    setActiveFilters(map);
  }, [options]);
  const count = Object.keys(activeFilters).filter(name => activeFilters[name].selected).length;

  function handleSelectedChange(prop: string, selected: boolean) {
    const nextActiveFilters = produce(activeFilters, draft => {
      draft[prop].selected = selected;
    });
    setActiveFilters(nextActiveFilters);
  }

  function handleValueChange(prop: string, value) {
    const nextActiveFilters = produce(activeFilters, draft => {
      draft[prop].value = value;
    });
    setActiveFilters(nextActiveFilters);
  }

  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    const handleClick = e => {
      // Клик вне
      if (!ref.current?.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  return (
    <div ref={ref} className={styles.wrapper}>
      <button
        className={styles.button}
        onClick={e => {
          setOpen(!open);
        }}
      >
        <svg className={styles.filterIcon} width="19" height="19" fill="none">
          <path d="m10.953 9.872 4.18-3.624a3.02 3.02 0 0 0 1.043-2.282.87.87 0 0 0-.87-.87H2.796a.87.87 0 0 0-.87.87c0 .876.38 1.709 1.042 2.282l4.181 3.624c.255.221.402.542.402.88v4.417c0 .294.324.474.574.318l1.72-1.076a1.5 1.5 0 0 0 .706-1.272v-2.387c0-.338.146-.659.402-.88Z" fill="#1A1D21" />
        </svg>
        Add filter
        <svg className={classNames(styles.arrowIcon, open && 'open')} width="21" height="21" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M14.274 8.016a.583.583 0 0 1 0 .825l-.36-.361.36.361-.038.038-.107.107a93.501 93.501 0 0 1-1.586 1.534c-.445.419-.91.844-1.294 1.167-.19.16-.37.304-.523.41a2.154 2.154 0 0 1-.236.146.836.836 0 0 1-.378.102.836.836 0 0 1-.378-.102 2.152 2.152 0 0 1-.236-.146 7.575 7.575 0 0 1-.523-.41A33.712 33.712 0 0 1 7.68 10.52a85.86 85.86 0 0 1-1.586-1.534l-.107-.107-.038-.037.412-.413.412-.413.037.037.106.104A91.932 91.932 0 0 0 8.48 9.67c.44.415.886.823 1.245 1.125.153.129.283.232.386.307.103-.075.233-.178.386-.307.359-.302.805-.71 1.245-1.125a84.465 84.465 0 0 0 1.564-1.513l.106-.104.036-.036a.584.584 0 0 1 .825 0Zm-4.319 3.19c-.015.006-.016.008 0 0ZM5.95 8.016a.583.583 0 0 1 .825 0l-.412.413-.412.412a.583.583 0 0 1 0-.825Z" fill="#7A7E89" />
        </svg>

        {count !== 0 && <span className={styles.count}>{count}</span>}
      </button>
      <div className={styles.dropdown} hidden={!open}>
        <header className={styles.dropdownHeader}>
          <Button
            size="small"
            type="white"
            onClick={e => {
              onClear({});
              setOpen(false);
            }}
          >
            Clear
          </Button>
          
          <Button
            size="small"
            onClick={e => {
              const filters = {};
              // Собирает в `filters` только selected поля
              Object.keys(activeFilters).forEach(key => {
                if (activeFilters[key]?.selected) {
                  filters[key] = activeFilters[key]?.value;
                  const correspondingOption = options.find(o => o.prop === key);
                  if (correspondingOption) {
                    if (!filters[key] && correspondingOption?.options) {
                      filters[key] = correspondingOption.options[0];
                    }
                  }
                }
              });
              onDone(filters);
              setOpen(false);
            }}
          >
            Done
          </Button>
        </header>
        <ul className={styles.filters}>
          {options.map((option, index) =>
            <li
              key={index}
              className={classNames(activeFilters[option.prop]?.selected && 'active')}
            >
              <>
                <Checkbox
                  checked={activeFilters[option.prop]?.selected}
                  onChange={checked => {
                    handleSelectedChange(option.prop, checked);
                  }}
                >
                  {option.label}
                </Checkbox>
                {activeFilters[option.prop]?.selected &&
                  <div 
                    style={{
                      paddingLeft:  '1.6em',
                      marginBottom: '10px'
                    }}
                 >
                    {(() => {
                      const { type, value } = option;
                      switch (type) {
                        case 'string': return (
                          <TextInput
                            value={value ?? ''}
                            onChange={value => {
                              handleValueChange(option.prop, value);
                            }}
                          />
                        );
                        case 'select': return (
                          <SelectInput
                            options={option.options}
                            defaultValue={value}
                            onChange={value => {
                              handleValueChange(option.prop, value || option.options[0]);
                            }}
                          />
                        );
                      }
                    })()}
                  </div>
                }
              </>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}