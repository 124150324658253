import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import produce, { setAutoFreeze } from "immer";
import { fetchHistogram, fetchTable } from '../../../api';
import {
  clearSelectedFilters,
  closeFiltersDropdown,
  selectFilters,
  selectSelectedFilters,
  setChart,
  setIsTableLoading,
  setSelectedFilters,
  setTable
} from '../../../store/walletAnalysisSlice';
import { getDataSourceID } from '../../../utils/data-source-id';
import {
  Button,
  Checkbox,
  TextInput,
  CheckboxMultitude,
  RangeInput,
  SelectInput,
  RangeInputTwoThumbs
} from '../../UI';
import useForceUpdate from '../../../utils/use-force-update';

import styles from './FilterDropdownForm.module.scss';

setAutoFreeze(false);

const FilterDropdownForm = () => {
  let location = useLocation();
  const { search } = location;

  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const selectedFilters = useSelector(selectSelectedFilters);

  const forceUpdate = useForceUpdate();

  function applySelectedFilters() {
    dispatch(setIsTableLoading(true));
    const { search } = window.location;
    const query = decodeURI(search).slice(1).replace(/['"«»]/g, '');
    fetchTable(query)
      .then(response => {
        let { data } = response;

        let wallets = localStorage.getItem('wallets');
        if (wallets) {
          try {
            wallets = JSON.parse(wallets);
            data = data.filter(r => {
              // Gets the first part of the string before '|'
              const wallet = r.wallet.split('|')[0];
              if (wallets.indexOf(wallet) !== -1) {
                return true;
              }
            })
          }
          catch (error) {
            console.error(error);
          }
        }

        dispatch(setTable(data));
      })
      .then(() => dispatch(setIsTableLoading(false)))
      .catch(error => console.error(error));

    fetchHistogram(query)
      .then(response => dispatch(setChart(response)));
  }

  useEffect(() => {
    const foramttedFilters = filters.reduce((acc, curr) => {
      acc[curr["filter_id"]] = !!selectedFilters[curr["filter_id"]];
      return acc;
    }, {});

    const parsedURL = new URLSearchParams(search);

    let _selectedFilters = {};
    const arrayFiltersKey = Object.keys(foramttedFilters);
    if (arrayFiltersKey.length) {
      arrayFiltersKey.forEach(param => {
        const values_value = parsedURL.getAll(param);
        const filter_conditions_value = parsedURL.getAll(`${param}_condition`);

        if (filter_conditions_value[0]) {
          _selectedFilters = {
            ..._selectedFilters,
            [param]: {
              values: values_value.length === 0 ? [] : values_value,
              filter_conditions: filter_conditions_value[0]
            }
          }
        }
        else if (values_value.length) {
          _selectedFilters = {
            ..._selectedFilters,
            [param]: {
              values: values_value[0]
            }
          };
        }
      })
    }

    dispatch(setSelectedFilters(_selectedFilters));
  }, [filters])

  const setFilterStatusByKey = filterId => {
    const isSelected = selectedFilters[filterId];
    const newSelectedFilters = { ...selectedFilters };
    if (isSelected) {
      delete newSelectedFilters[filterId];
    }
    else {
      const defaultFilter = filters.find(filter => filter.filter_id === filterId);
      const newFilter = {};

      if (defaultFilter.filter_conditions.length > 1) {
        newFilter['filter_conditions'] = defaultFilter.filter_conditions[0];
      }

      if (defaultFilter.filter_type === 'dropdown-single-select') {
        newFilter.values = defaultFilter.values[0] || '';
      }
      else if (defaultFilter.filter_type === 'slider') {
        newFilter.values = defaultFilter.values.min || 0;
      }

      newSelectedFilters[filterId] = newFilter;
    }

    dispatch(setSelectedFilters(newSelectedFilters));
  };

  const clear = () => {
    // Меняем URL
    window.history.pushState(null, null, `${location.pathname}?data_source_id=${getDataSourceID()}`);

    // Очищаем выбранные фильтры
    dispatch(clearSelectedFilters());
  };

  const handleDone = () => {
    const newQuery = Object.keys(selectedFilters).reduce((acc, filterID) => {
      let subQuery = '';
      const filter = selectedFilters[filterID];

      // Это примитив
      if (!Object.keys(filter).length) {
        return acc;
      }

      const f = filters.find(filter => filter.filter_id === filterID);
      if (f.filter_type === 'range-slider') {
        const name = filterID.replace('_range', '');
        subQuery += `${acc}${name}_floor=${filter.values[0]}&${name}_ceil=${filter.values[1]}&`;
      }
      else {
        subQuery += Array.isArray(filter.values)
          ? filter.values.reduce((acc, value) => acc + filterID + '=' + value + '&', '')
          : filterID + '=' + filter.values + '&';
      }

      if (filter.filter_conditions) {
        subQuery += `${filterID}_condition=${filter.filter_conditions}&`
      }

      acc += subQuery;

      return [...new Set(acc.split('&'))].join('&');
    }, '')
      // Убирает лишний & в конце
      .slice(0, -1);

    // Меняем URL
    const newSearch = `${location.pathname}?data_source_id=${getDataSourceID()}&${newQuery}`;
    window.history.pushState(null, null, newSearch);

    // Отправляем запрос на новые данные в таблице
    applySelectedFilters();

    // Закрываем окно фильтров
    dispatch(closeFiltersDropdown());
  };

  const handleConditionChange = filterId => newSelectValue => {
    const nextSelectedFilters = produce(selectedFilters, draft => {
      const filter = draft[filterId];
      filter.filter_conditions = newSelectValue;
    });
    dispatch(setSelectedFilters(nextSelectedFilters));
  };

  const handleValueChange = filterId => newSelectValue => {
    const nextSelectedFilters = produce(selectedFilters, draft => {
      const filter = draft[filterId];
      filter.values = newSelectValue;
    });
    dispatch(setSelectedFilters(nextSelectedFilters));
  };

  const listOfIFilters = filters.map(filter => {
    const filterId   = filter["filter_id"];
    const filterName = filter["filter_name"];
    const filterType = filter["filter_type"];
    const values     = filter["values"];
    const filterConditions = filter["filter_conditions"];
    return (
      <div
        className={
          classNames(
            styles.FilterDropdownFormFilterSection,
            selectedFilters[filterId] && styles.FilterDropdownFormFilterFieldSelected
          )
        }
      >
        <Checkbox
          checked={selectedFilters[filterId]}
          onChange={() => setFilterStatusByKey(filterId)}
        >
          {filterName}
        </Checkbox>
        <div className={styles.FilterDropdownFormFilterField}>
          {selectedFilters[filterId] && filterConditions.length > 1 &&
            <div style={{ marginBottom: "12px" }}>
              <SelectInput
                options={[...filterConditions]}
                defaultValue={selectedFilters[filterId]?.filter_conditions || filterConditions[0]}
                onChange={handleConditionChange(filterId)}
                style={{ fontSize: '14px' }}
              />
            </div>
          }
          {selectedFilters[filterId] && filterType === "string_input" &&
            <TextInput
              placeholder="Enter something..."
              defaultValue={selectedFilters[filterId]?.values}
              onChange={handleValueChange(filterId)}
            />
          }
          {selectedFilters[filterId] && filterType === "dropdown-multi-select" &&
            <CheckboxMultitude
              valueList={[...values]}
              defaultValues={selectedFilters[filterId]?.values}
              onChange={handleValueChange(filterId)}
            />
          }
          {selectedFilters[filterId] && filterType === "slider" &&
            <div style={{ marginTop: "36px", marginBottom: "12px" }}>
              <RangeInput
                onChange={handleValueChange(filterId)}
                defaultValue={+selectedFilters[filterId]?.values}
                step={values["increment"]}
                min={values["min"]}
                max={values["max"]}
              />
            </div>
          }
          {selectedFilters[filterId] && filterType === "range-slider" &&
            <div style={{ marginTop: "36px", marginBottom: "12px" }}>
              <RangeInputTwoThumbs
                onChange={handleValueChange(filterId)}
                defaultValueFirstThumb={values["min"]}
                defaultValueSecondThumb={values["max"]}
                step={values["increment"]}
                min={values["min"]}
                max={values["max"]}
              />
            </div>
          }
          {selectedFilters[filterId] &&
            (filterType === "dropdown-single-select" || filterType[0] === "dropdown-single-select") &&
            <div style={{ marginBottom: "12px" }}>
              <SelectInput
                options={[...values]}
                defaultValue={selectedFilters[filterId]?.values || values[0]}
                onChange={handleValueChange(filterId)}
              />
            </div>
          }
        </div>
      </div>
    )
  });

  let wallets = localStorage.getItem('wallets');
  if (wallets) {
    wallets = JSON.parse(wallets);
  }

  return (
    <div className={styles.FilterDropdownFormWrapper}>
      <div className={styles.FilterDropdownFormHeader}>
        <Button size="small" type="white" onClick={clear}>Clear</Button>
        <Button size="small" onClick={handleDone}>Done</Button>
      </div>
      {wallets &&
        <span className={styles.walletsLabel}>
          {wallets.length} {wallets.length === 1 ? 'wallet' : 'wallets'} from step in user journey
          <button
            className={styles.walletsLabelButton}
            onClick={e => {
              localStorage.removeItem('wallets');
              forceUpdate();
              // Закрываем окно фильтров
              dispatch(closeFiltersDropdown());
              applySelectedFilters();
            }}
          >
            <svg width="20" height="20" fill="#7A7E89">
              <path fillRule="evenodd" clipRule="evenodd" d="M13.947 6.052a.583.583 0 0 1 0 .825l-7.071 7.071a.583.583 0 0 1-.825-.825l7.07-7.071a.583.583 0 0 1 .826 0Z" />
              <path fillRule="evenodd" clipRule="evenodd" d="M6.048 6.052a.583.583 0 0 1 .825 0l7.071 7.071a.583.583 0 1 1-.825.825L6.05 6.877a.583.583 0 0 1 0-.825Z" />
            </svg>
          </button>
        </span>
      }
      <div className={styles.FilterDropdownFormContent}>
        {listOfIFilters}
      </div>
    </div>
  );
};

export default FilterDropdownForm;