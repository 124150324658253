import React from 'react';
import styles from './Footer.module.scss';

const Footer = ({scrollToElem}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.topLine} />
      <div className={styles.wrapContent}>
        <div className={styles.contentLeft}>
          <img
            className={styles.bImage}
            src='assets/svg/figures/star_6.svg'
            alt='BackgroundImg'
          />
          <img
            className={styles.logo}
            src='assets/svg/figures/logo-merlin.svg'
            alt='Merlin'
          />
          <div className={styles.contentInfo}>
            <span>Ready to get started?</span>
            <span>
              Empower your team to make data-driven decisions from day one.
              Let's work together to create great decentralized experiences for
              our users.
            </span>
            <a href="/form" className={styles.arrow}>
              <img
                src='assets/svg/figures/arrow_right.svg'
                alt='Arrow'
              />
            </a>
          </div>
        </div>
        <div className={styles.contentRight}>
          <div className={styles.footerContentEmpty}>
            <img
              className={styles.smallStartImage}
              src='assets/svg/figures/small_star.svg'
              alt='small_star'
            />
          </div>
          <div className={styles.innerLeft}>
            <span>Company</span>
            <a href='mailto: hello@getmerlin.xyz'>Careers</a>
            <a href="#IBM-section">Blog</a>
            <a href="/form">Get early access</a>
            <img
              className={styles.littleStar}
              src='assets/svg/figures/little_star.svg'
              alt='BackgroundImg'
            />
          </div>
          <div className={styles.innerRight}>
            <div className={styles.innerRightInfo}>
              <span>Contact Us</span>
              <span>
                548 Market St ,<br /> San Francisco, California, 94104-5401 US <br /> <br /> hello@getmerlin.xyz
              </span>
            </div>
            <div className={styles.followBlock}>
              <span>Follow</span>
              <ul className={styles.contactList}>
                <li>
                  <a
                    href="https://twitter.com/merlindotxyz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg
                      width="26"
                      height="22"
                      viewBox="0 0 26 22"
                      fill="none"
                    >
                    <path d="M26.0001 3.13499C25.0706 3.54686 24.0721 3.82514 23.0224 3.95093C24.1055 3.30285 24.9158 2.28285 25.3021 1.08125C24.2845 1.68567 23.1708 2.11112 22.0095 2.3391C21.2285 1.50521 20.194 0.95249 19.0667 0.76676C17.9394 0.58103 16.7823 0.772679 15.775 1.31195C14.7678 1.85123 13.9668 2.70795 13.4963 3.74912C13.0259 4.79029 12.9124 5.95764 13.1733 7.06995C11.1115 6.96642 9.09439 6.4305 7.25303 5.49697C5.41166 4.56344 3.78717 3.25317 2.48497 1.65118C2.03972 2.41925 1.78369 3.30976 1.78369 4.25815C1.7832 5.11193 1.99345 5.95262 2.39579 6.70565C2.79813 7.45868 3.38012 8.10076 4.09012 8.57491C3.26671 8.54871 2.46146 8.32622 1.74139 7.92595V7.99274C1.74131 9.19019 2.15552 10.3508 2.91374 11.2776C3.67195 12.2044 4.72747 12.8404 5.9012 13.0776C5.13735 13.2843 4.3365 13.3147 3.55915 13.1666C3.89031 14.197 4.53537 15.0979 5.40404 15.7435C6.2727 16.389 7.32148 16.7467 8.40354 16.7665C6.56667 18.2085 4.29815 18.9907 1.96291 18.9872C1.54925 18.9873 1.13593 18.9632 0.725098 18.9149C3.0955 20.439 5.85483 21.2478 8.67292 21.2447C18.2125 21.2447 23.4276 13.3436 23.4276 6.49111C23.4276 6.26849 23.422 6.04363 23.412 5.821C24.4264 5.08741 25.302 4.179 25.9978 3.13833L26.0001 3.13499Z" fill="#2AA9E0"/>
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/merlinxyz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" >
                      <path fillRule="evenodd" clipRule="evenodd" d="M9.78625 9.21125H14.4287V11.5237C15.0975 10.1937 16.8125 8.99875 19.3888 8.99875C24.3275 8.99875 25.5 11.6462 25.5 16.5037V25.5H20.5V17.61C20.5 14.8437 19.8313 13.2837 18.1287 13.2837C15.7675 13.2837 14.7863 14.965 14.7863 17.6087V25.5H9.78625V9.21125ZM1.2125 25.2875H6.2125V8.99875H1.2125V25.2875ZM6.92875 3.6875C6.92894 4.10659 6.84582 4.52154 6.68423 4.90823C6.52265 5.29492 6.28581 5.64564 5.9875 5.94C5.383 6.54078 4.56476 6.87707 3.7125 6.875C2.86174 6.87443 2.04539 6.539 1.44 5.94125C1.14276 5.64589 0.906732 5.29478 0.74543 4.90804C0.584127 4.5213 0.500724 4.10653 0.5 3.6875C0.5 2.84125 0.8375 2.03125 1.44125 1.43375C2.04611 0.835198 2.8628 0.499626 3.71375 0.5C4.56625 0.5 5.38375 0.83625 5.9875 1.43375C6.59 2.03125 6.92875 2.84125 6.92875 3.6875Z" fill="#0A66C2"/>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.bottomLine} />

      <div className={styles.underFooter}>
        <span>© 2022 Merlin. All Rights Reserved.</span>

        <div className={styles.underFoterRight}>
          <span className={styles.underFoterRightOne}>
            <a href={"/privacy_policy"}>Privacy Policy</a>
          </span>
          <span className={styles.underFoterRightTwo}>
            <a href={"/terms-of-service"}>Terms of Service</a>
          </span>
        </div>

      </div>
    </div>
  );
};

export default Footer;
