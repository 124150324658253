import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './ColorPicker.module.scss';

type ColorPickerProps = HTMLAttributes<HTMLDivElement> & {
  options: string[];
  color?:  string;
  onChange?: (color: string) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { options, color, onChange, className } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color ?? options[0] ?? 'gray');

  const contextMenu = useRef<HTMLDivElement>();
  const contextMenuButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне дропдауна
      if (!contextMenu.current?.contains(e.target) && !contextMenuButton.current?.contains(e.target)) {
        setIsOpen(false)
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      {...props}
      className={classNames(styles.colorPicker, className)}
    >
      <div className={styles.selectedColorContainer}>
        <button
          ref={contextMenuButton}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div 
            className={styles.circle}
            style={{ backgroundColor: selectedColor }}
          />
          <svg 
            style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }}
            width="10"
            height="6"
            viewBox="0 0 10 6"
            fill="none"
          ><path fillRule="evenodd" clipRule="evenodd" d="M.839 5.08a.583.583 0 0 1 0-.826l.361.361-.361-.36.038-.04.108-.106A92.594 92.594 0 0 1 2.57 2.575c.445-.419.91-.844 1.294-1.167.19-.16.37-.304.523-.41.076-.053.157-.105.236-.146A.836.836 0 0 1 5.001.75c.178 0 .324.074.379.102.079.041.16.093.235.146.153.106.333.25.524.41.383.323.848.748 1.293 1.167A85.515 85.515 0 0 1 9.018 4.11l.107.107.038.037v.001l-.412.413-.412.412-.037-.036-.105-.105a92.042 92.042 0 0 0-1.564-1.513c-.44-.415-.887-.823-1.246-1.125a7.403 7.403 0 0 0-.386-.307 7.403 7.403 0 0 0-.386.307c-.358.302-.805.71-1.245 1.125a84.355 84.355 0 0 0-1.564 1.513l-.106.105-.036.036a.584.584 0 0 1-.825 0Zm4.32-3.19c.014-.007.015-.009 0 0-.001 0 0-.001 0 0Zm4.005 3.19a.583.583 0 0 1-.825 0l.412-.413.413-.413a.583.583 0 0 1 0 .825Z" fill="#7A7E89"/></svg>
        </button>
      </div>

      {isOpen &&
        <div ref={contextMenu} className={styles.colorSelectContainer}>
          {options.map((color, index) => {
            return (
              <button
                key={index}
                className={styles.circle}
                style={{
                  backgroundColor: color,
                  boxShadow: 
                    `0 0 0 1px white,
                     0 0 0 2px ${color === selectedColor ? color : 'transparent'}`
                }}
                onClick={() => {
                  setSelectedColor(color);
                  setIsOpen(!isOpen);
                  onChange(color);
                }}
              />
            )
          })}
        </div>  
      }
    </div>
  );
};

export default ColorPicker;
