import React, { useState, useCallback, useEffect } from 'react';
import {
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  ScatterChart,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';

import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";
import round from "../../../../utils/round";

import './ScatterCustom.scss';
import '../custom-tooltip.scss';
import classNames from 'classnames';

export const ScatterCustom = (props) => {
  const { scatterCustomData, hideGrid, dotColor, downloadGraphPng, className } = props;

  const [chartHeight, setChartHeight] = useState(367);
  const [chartWidth,  setChartWidth]  = useState(667);

  const [getAreaPng, { ref: areaRef }] = useCurrentPng();

  useEffect(() => {
    if (downloadGraphPng) {
      downloadGraphPng.current = handleAreaDownload;
    }
  }, []);

  const handleAreaDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      console.log("png", png)
      FileSaver.saveAs(png, "scatter-chart.png");
    }
  }, [getAreaPng]);

  const MIN_X = scatterCustomData?.limits?.x?.min ?? Math.min( ...scatterCustomData.data.map(r => r.x) );
  const MIN_Y = scatterCustomData?.limits?.y?.min ?? Math.min( ...scatterCustomData.data.map(r => r.y) );
  const MAX_X = scatterCustomData?.limits?.x?.max ?? Math.max( ...scatterCustomData.data.map(r => r.x) );
  const MAX_Y = scatterCustomData?.limits?.y?.max ?? Math.max( ...scatterCustomData.data.map(r => r.y) );

  function getRange(lower, upper, steps) {
    const difference = upper - lower
    const increment = difference / (steps - 1)
    return [lower, ...Array(steps - 2).fill('').map((_, index) =>
      lower + (increment * (index + 1))
    ), upper]
  }

  const tickXArray = getRange(MIN_X, MAX_X, 5).map(number => round(number, 2));
  const tickYArray = getRange(MIN_Y, MAX_Y, 5).map(number => round(number, 2));

  const colorRange = (x = 0, y = 0) => {
    const X = Math.ceil(x);
    const Y = y.toFixed(3)
    const dividedX = MIN_X + (MAX_X / 2);
    const dividedY = MIN_Y + (MAX_Y / 2);

    if (X >= dividedX && Y >= dividedY) {
      return '#DC01FF';
    }
    if (X < dividedX && Y >= dividedY) {
      return '#003BD2';
    }
    if (X < dividedX && Y < dividedY) {
      return '#EB8280';
    }
    if (X >= dividedX && Y < dividedY) {
      return '#FF007A';
    }
  };

  const CustomTooltip = ({ payload }) => {
    if (payload.length) {
      return (
        <div className="custom-tooltip recharts-tooltip-wrapper-bottom">
          <p className="label" style={{ color: colorRange(payload[0].payload.x, payload[0].payload.y) }}>
            {payload[0].payload.z}
          </p>
          <p><span>{Object.keys(payload[0].payload)[1]}:</span> {payload[0].payload.x}</p>
          <p><span>{Object.keys(payload[0].payload)[2]}:</span> {payload[0].payload.y}</p>
        </div>
      );
    }

    return null;
  };

  return (

    <ResponsiveContainer className={classNames('scatter-wrapper', className)}>

      <ScatterChart
        data={scatterCustomData.data}
        margin={{ left: -25, top: 10 }}
        width={chartWidth}
        height={chartHeight}
        ref={areaRef}
      >
        {!hideGrid &&
          <CartesianGrid />
        }
        <XAxis
          type='number'
          domain={['dataMin', 'dataMax']}
          dataKey='x'
          name='stature'
          ticks={tickXArray}
          tickCount={2}
          tickLine={false}
          tick={{ fontSize: '8px' }}
        >
          <Label
            value={scatterCustomData.data && scatterCustomData.axis['x-axis']}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
            position='bottom'
            offset={-10}
          />
        </XAxis>

        <YAxis
          type='number'
          domain={['dataMin', 'dataMax']}
          dataKey='y'
          name='weight'
          ticks={tickYArray}
          tickCount={5}
          tickLine={false}
          tick={{ fontSize: '8px' }}
        >
          <Label
            value={scatterCustomData.data && scatterCustomData.axis['y-axis']}
            angle={-90}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
            offset={-10}
          />
        </YAxis>

        <ZAxis range={[65, 65]} />

        <Tooltip 
          cursor={{ fill: 'rgba(73, 122, 248, 0.05)' }}
          allowEscapeViewBox={{ x: true, y: true }}
          content={<CustomTooltip />}
        />
        <Scatter name='A school' data={scatterCustomData.data}>
          <LabelList
            dataKey='z'
            position='right'
            content={(props) => {
              const { x, y, width, height, fill, value, index } = props;

              const originalPoint = scatterCustomData.data[index];
              const facingRight = originalPoint.x < (MIN_X + MAX_X * .85);
              const offset = facingRight ? -15 / 2 : 15;

              return (
                <text
                  fontSize={10}
                  x={x + width - offset}
                  y={y + height}
                  fill={fill}
                  textAnchor={facingRight ? "start" : "end"}
                  style={{
                    userSelect: "none",
                    pointerEvents: "none",
                    fontSize: "10px"
                  }}
                >
                  {value}
                </text>
              );
            }}
          />
          {scatterCustomData.data &&
            scatterCustomData.data.map(({ x, y, z }, index) => (
              <Cell key={`cell-${index}`} fill={dotColor ?? colorRange(x, y)} />
            ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>


  );
};