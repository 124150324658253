export function  getSession() {
  const session = localStorage.getItem('session');
  const { location } = window;
  if (!session) {
    location.href = location.origin + '/auth';
  }
  return session;
}

export function setSession(session: string) {
  return localStorage.setItem('session', session);
}