import React, { HTMLAttributes, useState } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { openEditor, removeGroup, setCurrentEditingGroupID } from "../../../../store/settingsSlice";
import { ContextMenu } from "../../../../components/UI";
import ActiveIcon from "../ActiveIcon/ActiveIcon";
import { getDataSourceID } from "../../../../utils/data-source-id";

import styles from "./Group.module.scss";

interface GroupProps extends Omit< HTMLAttributes<HTMLDivElement>, 'id' > {
  /** Уникальный идентификатор группы */
  id: number;

  /** Название группы */
  name?: string;

  /** Если `true`, то все дочерние строки будут иметь фон */
  withBackground?: boolean;
};

export default function Group(props: GroupProps) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const { id, children } = props;
  const itemsCount = Array.isArray(children) ? children.length : 1;

  const isActive = getDataSourceID() === id;
  
  return (
    <>
      <div 
        className={classNames(
          styles.groupTitle,
          !isOpen && "collapsed",
          props.withBackground && 'with-bg'
        )}
        style={{ ...props.style }}
      >
        <button
          className={classNames(styles.collapseButton, !isOpen && "collapsed")}
          onClick={e => setIsOpen(!isOpen)}
          aria-label={(isOpen ? 'Hide' : 'Show') + ' content'}
        >
          <img src="/assets/svg/dashboard/collapse.svg" alt="" />
        </button>
        {isActive && <ActiveIcon style={{ marginRight: '1.4ch' }} />}
        <span className={classNames(styles.name, isActive && 'active')}>
          {props.name ?? 'My group'}
        </span>
        <span className={styles.number} title={`Has ${itemsCount} item${itemsCount > 1 ? 's' : ''} inside`}>
          {itemsCount}
        </span>
        {false &&
        <ContextMenu
          className={styles.contextMenuButton}
          onEdit={() => {
            dispatch(openEditor());
            dispatch(setCurrentEditingGroupID(id));
          }}
          onDelete={() => dispatch(removeGroup({ id }))}
        />
        }
      </div>
      {isOpen &&
        <div className={classNames(styles.contentWrap, props.withBackground && 'with-bg')}>
          {children}
        </div>
      }
    </>
  )
}