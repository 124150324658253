import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import Lottie from 'react-lottie';
import animationData from './animation.json';
import styles from "./Prelaoder.module.scss";

export default function Prelaoder(props: HTMLAttributes<HTMLDivElement>) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div
      {...props}
      className={classNames(styles.preloader, props.className)}
    >
      <Lottie options={defaultOptions} />
    </div>
  )
}