import React, { HTMLAttributes } from "react";

export default function PlusIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.99792 9.4668C4.74018 9.4668 4.53125 9.25786 4.53125 9.00013L4.53125 1.00013C4.53125 0.742397 4.74018 0.533463 4.99792 0.533463C5.25565 0.533464 5.46458 0.742397 5.46458 1.00013L5.46458 9.00013C5.46458 9.25786 5.25565 9.4668 4.99792 9.4668Z" fill="url(#paint0_linear_3575_7782)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.46484 5.00013C9.46484 5.25786 9.25591 5.4668 8.99818 5.4668L0.998177 5.4668C0.740444 5.4668 0.53151 5.25786 0.53151 5.00013C0.53151 4.7424 0.740444 4.53346 0.998177 4.53346L8.99818 4.53346C9.25591 4.53346 9.46484 4.7424 9.46484 5.00013Z" fill="url(#paint1_linear_3575_7782)" />
      <defs>
        <linearGradient id="paint0_linear_3575_7782" x1="4.25125" y1="6.44648" x2="6.63389" y2="5.93006" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF" />
          <stop offset="1" stopColor="#3736FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_3575_7782" x1="6.44453" y1="5.7468" x2="5.9281" y2="3.36415" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DC01FF" />
          <stop offset="1" stopColor="#3736FF" />
        </linearGradient>
      </defs>
    </svg>
  )
};