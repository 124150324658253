import React, { useState, useEffect, useRef, HTMLProps } from "react";
import classNames from "classnames";
import styles from "./SelectWithSearch.module.scss";

type Props = Omit< HTMLProps<HTMLDivElement>, 'label' > & {
  options: string[];
  label?:  JSX.Element | string;
  onChange: (option: string) => void;
}

export default function SelectWithSearch(props: Props) {
  const { options, label, onChange } = props;
  const [searchValue, setSearchValue] = useState('');
  const wrapper = useRef<HTMLDivElement>();
  const input = useRef<HTMLInputElement>();

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      input.current?.focus();
    }
  }, [isOpen]);

  const [filteredOptions, setFilteredOptions] = useState(options);
  useEffect(() => {
    setFilteredOptions(options.filter(o => o.match(searchValue)));
  }, [options, searchValue]);

  useEffect(() => {
    const handleClick = e => {
      // Клик вне дропдауна
      if (!wrapper.current?.contains(e.target)) {
        setIsOpen(false)
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  function handleClose(option: string) {
    setIsOpen(false);
    onChange(option);
  }

  return (
    <div
      ref={wrapper}
      className={classNames(styles.wrapper, props.className)}
    >
      <button
        className={styles.button}
        disabled={props.disabled}
        onClick={e => {
          setIsOpen(true);
        }}
      >
        {label}
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fillRule="evenodd" clipRule="evenodd" d="M7.998 12.467A.467.467 0 0 1 7.53 12V4a.467.467 0 1 1 .934 0v8a.467.467 0 0 1-.467.467Z" fill="#7A7E89" />
          <path fillRule="evenodd" clipRule="evenodd" d="M12.465 8a.467.467 0 0 1-.467.467h-8a.467.467 0 1 1 0-.934h8c.258 0 .467.21.467.467Z" fill="#7A7E89" />
        </svg>
      </button>
      
      {isOpen &&
        <div className={styles.dropdown}>
          <div
            className={classNames(
              styles.searchWrapper,
              !filteredOptions.length && 'no-result'
            )}
          >
            <svg width="20" height="20" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.68 12.93a8.125 8.125 0 1 0-1.748 1.748c.037.05.077.097.122.144l4.812 4.812a1.25 1.25 0 0 0 1.77-1.767l-4.813-4.813a1.257 1.257 0 0 0-.144-.124Zm-.202-2.174A6.875 6.875 0 1 0 1.774 5.494a6.875 6.875 0 0 0 12.704 5.262Z" fill="#B9BDC8" />
            </svg>
            <input
              ref={input}
              className={styles.search}
              type="text"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
          </div>

          <div className={styles.list}>
            {filteredOptions.length === 0
              ? <span className={styles.fallback}>No options available</span>
              :
              filteredOptions.map((option, index) =>
                <button
                  key={index}
                  onClick={e => {
                    handleClose(option);
                  }}
                >
                  {option}
                </button>
              )
            }
          </div>
        </div>
      }
    </div>
  )
}