import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { changeContract, saveContract, saveGroup, removeGroup as fetchRemoveGroup, changeWallet } from "../api";

export type TableData = {
  id:           number;
  name:         string;
  address:      string;
  s3location?:  string;
  is_default?: boolean;
  track:       'Wallet' | 'Deployed entities' | 'Contract' | 'Wallet' | 'Group';
  lastUpload:  string;
  coverage:    number;
  isActive:    boolean;
  selected:    boolean;
  isValid?:    boolean;
  options?: Partial<{
    type: 'wallet' | 'contract' | 'contract_group';
    trackDisabled: boolean;
    editing:       boolean;
    id:             string;
  }>
}

type Group = {
  id:   number;
  name: string;
  ids:  number[]
}

type SettingsState = {
  table:       TableData[],
  customTable: TableData[],
  isEditorOpen: boolean,
  groups: Group[],
  currentEditingGroupID: number | null,
  popupText: string,
};

const initialState: SettingsState = {
  table: [],
  customTable: [],
  isEditorOpen: false,
  groups: [],
  currentEditingGroupID: null,
  popupText: ''
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setPopupText: (state, action) => {
      state.popupText = action.payload;
    },
    setTable: (state, action) => {
      state.table = action.payload;
    },
    addTableRow: (state, action: PayloadAction<TableData>) => {
      const buffer = action.payload;
      state.table.unshift(buffer);

      if (buffer.track === 'Wallet') {
        changeWallet({
          wallet_name: buffer.name,
          s3location:  buffer.s3location,
        })
          .catch(error => {
            console.error('Saving wallet failed:', error);
          })
      }
      else {
        saveContract({
          contract_name:   buffer.name,
          contract_adress: buffer.address,
          track_type:      buffer.track
        })
          .then(response => {
            console.log('Contract saved successfully!', response);
          })
          .catch(error => {
            console.error('Saving contract failed:', error);
          })
      }
    },
    changeTableRow: (state, action: PayloadAction<{ id: number; changes: {}, save?: boolean }>) => {
      const { id, changes, save } = action.payload;
      const data = state.table.find(row => row.id === id);
      if (data) {
        for (let key in changes) {
          data[key] = changes[key];
        }

        if (!save) {
          return;
        }

        if (data.track === 'Wallet') {
          changeWallet({
            wallet_name: data.name,
            s3location:  data.s3location,
            id:          data.id
          })
            .catch(error => {
              console.error('Changing wallet failed!:', error);
            });;
        }
        else {
          changeContract({
            contract_name:   data.name,
            contract_adress: data.address,
            track_type:      data.track,
            id:              data.id
          })
            .catch(error => {
              console.error('Changing contract failed!:', error);
            });
        }
      }
    },
    removeTableRows: (state, action: PayloadAction<{ ids: number[] }>) => {
      const { ids } = action.payload;
      for (let id of ids) {
        const index = state.table.findIndex(r => r.id === id);
        if (index !== -1) {
          const data = state.table[index];
          state.table.splice(index, 1);

          if (data.track === 'Wallet') {
            changeWallet({
              wallet_name: data.name,
              s3location:  data.s3location,
              id:          data.id,
              active:      'False'
            })
              .catch(error => {
                console.error('Deleting wallet failed!:', error);
              });;
          }
          else {
            changeContract({
              contract_name:   data.name,
              contract_adress: data.address,
              active:          'False',
              track_type:      data.track,
              id:              data.id
            })
              .catch(error => {
                console.error('Deleting contract failed!:', error);
              });
          }
        }
      }
    },
    addCustomTableRow: (state, action) => {
      state.customTable.push(
        {
          id: state.customTable.length,
          name:       '',
          address:    '',
          track:      action.payload === 'wallet' ? 'Wallet' : 'Contract',
          lastUpload: '',
          coverage: 0,
          isActive: false,
          selected: false,
          isValid:  false,
          options: {
            trackDisabled: action.payload === 'wallet'
          }
        }
      );
    },
    changeCustomTableRow: (state, action: PayloadAction<{}>) => {
      for (let key in action.payload) {
        state.customTable[0][key] = action.payload[key];
      }
    },
    clearCustomTable: state => {
      state.customTable = [];
    },
    openEditor: (state) => {
      state.isEditorOpen = true;
    },
    closeEditor: (state) => {
      state.isEditorOpen = false;
    },
    addGroup: (state, action: PayloadAction<{ name: string; id?: number; ids: number[], save?: boolean }>) => {
      const { name, id, ids, save } = action.payload;
      state.groups.push({
        id: id ?? state.groups.length,
        name,
        ids
      });

      const group = state.groups[state.groups.length - 1];
      const contract = state.table
        .filter(r =>
          group.ids.find(id => id === r.id)
        )
        .map(r => ({
          "contract_adress": r.address,
          "track_type": r.track
        }))
        .map(r => JSON.stringify(r))
        .join('|');

      if (!save) {
        return;
      }

      saveGroup({
        group_name: name,
        contract
      })
        .then(response => {
          console.log('Group saved', response);
        })
        .catch(error => {
          console.log('Saving group failed:', error);
        });
    },
    removeGroup: (state, action: PayloadAction<{ id: number }>) => {
      const { id } = action.payload;
      const index = state.groups.findIndex(group => group.id === id);
      const group = state.groups[index];
      if (index !== -1) {
        state.groups.splice(index, 1);
      }

      const contract = state.table
        .filter(r =>
          group.ids.find(id => id === r.id)
        )
        .map(r => ({
          "contract_adress": r.address,
          "track_type":      r.track
        }))
        .map(r => JSON.stringify(r))
        .join('|');

      fetchRemoveGroup({
        group_name: group.name,
        contract,
        id
      })
        .then(response => {
          console.log('Group removed successfully!', response);
        })
        .catch(error => {
          console.log('Removing group failed:', error);
        });
    },
    changeGroup: (state, action: PayloadAction<{ id: number, name: string; ids: number[] }>) => {
      const { name, id, ids } = action.payload;
      const group = state.groups.find(group => group.id === id);
      if (group) {
        group.name = name;
        group.ids  = ids;

        const contract = state.table
          .filter(r =>
            group.ids.find(id => id === r.id)
          )
          .map(r => ({
            "contract_adress": r.address,
            "track_type": r.track
          }))
          .map(r => JSON.stringify(r))
          .join('|');

        saveGroup({
          group_name: name,
          contract,
          id
        })
          .then(response => {
            console.log('Group updated', response);
          })
          .catch(error => {
            console.log('Changing group failed:', error);
          });
      }
    },
    setCurrentEditingGroupID: (state, action) => {
      state.currentEditingGroupID = action.payload;
    }
  }
});

// Actions

export const {
  setTable,
  addTableRow,
  changeTableRow,
  removeTableRows,
  addCustomTableRow,
  changeCustomTableRow,
  clearCustomTable,
  openEditor,
  closeEditor,
  addGroup,
  removeGroup,
  changeGroup,
  setCurrentEditingGroupID,
  setPopupText
} = settingsSlice.actions;

// Selectors

export const settingsSelector = state => state.settings;

export const selectPopupText = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.popupText
);

export const selectTable = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.table
);

export const selectCustomTable = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.customTable
);

export const selectIsEditorOpen = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.isEditorOpen
);

export const selectGroups = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.groups
);

export const selectCurrentEditingGroupID = createSelector(
  settingsSelector, 
  (state: SettingsState) => state.currentEditingGroupID
);

export default settingsSlice.reducer;