import React from 'react';
import Lottie from 'react-lottie-player';

import lottieJsonBgLines from './bg-lines.json';
import lottieJsonBgLinesForMobile from './bg-lines-mobile.json';
import lottieJsonuserBehavior from './user-behavior.json';
import lottieJsonInfiniteIntegrations from './infinite-integrations.json';
import lottieJsonNodeSearch from './node-search.json';
import lottieJsonLinkSessionData from './link-session-data.json';
import lottieJsonContextualizeTransactions from './contextualize-transactions.json';
import lottieJsonMeasureProtocolImpact from './measure-protocol-impact.json';
import lottieJsonTrackKeyMetrics from './track-key-metrics.json';


const LottieExample = ({lottieProps}) => {
    const { jsonName, width, height, speed } = lottieProps;
    const bgLines = jsonName === 'lottieJsonBgLines' ? lottieJsonBgLines : null;
    const bgLinesForMobile = jsonName === 'lottieJsonBgLinesForMobile' ? lottieJsonBgLinesForMobile : null;
    const userBehavior = jsonName === 'lottieJsonuserBehavior' ? lottieJsonuserBehavior : null;
    const infiniteIntegrations = jsonName === 'lottieJsonInfiniteIntegrations' ? lottieJsonInfiniteIntegrations : null;
    const nodeSearch = jsonName === 'lottieJsonNodeSearch' ? lottieJsonNodeSearch : null;
    const linkSessionData = jsonName === 'lottieJsonLinkSessionData' ? lottieJsonLinkSessionData : null;
    const contextualizeTransactions = jsonName === 'lottieJsonContextualizeTransactions' ? lottieJsonContextualizeTransactions : null;
    const measureProtocolImpact = jsonName === 'lottieJsonMeasureProtocolImpact' ? lottieJsonMeasureProtocolImpact : null;
    const trackKeyMetrics = jsonName === 'lottieJsonTrackKeyMetrics' ? lottieJsonTrackKeyMetrics : null;

    const animationData = bgLines || bgLinesForMobile || userBehavior || infiniteIntegrations || nodeSearch || linkSessionData || contextualizeTransactions || measureProtocolImpact || trackKeyMetrics || null;

    return (
        <Lottie
            background="transparent"
            speed={speed}
            back
            loop
            animationData={animationData}
            play
            style={{ width: width, height: height }}
        />
    )
};

export default LottieExample;