import axios from "axios";
import { root } from ".";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";

export const fetchLabels = async () => {
  const options = {
    method: 'POST',
    url: `${root}/label/view/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession()
    }
  };

  try {
    const response = await axios.request(options);
    const data: {
      message: string;
      value: {
        colour: string;
        date_updated?: string;
        label_id: number
        label_logic: {
          labellogic_id: number;
          field:         string;
          operator:      '==' | '<' | '>';
          value:         string;
        }[],
        label_name: string;
        label_type: string;
        logic_operator: '&' | '||'
        status: 'Active' | 'In progress' | 'Error';
      }[]
    } = response.data;
    console.log(data);

    return data.value;
  }
  catch (error) {
    console.log('/label/view/ failed: ', error);
    throw error?.message;
  }
};

export const changeLabel = async (params: {
  id?:            number;
  label_type:     string;
  label_name:     string;
  label_logic?:   string;
  logic_operator: string;
  colour?:        string;
  status?:        'Active' | 'In progress' | 'Error';
  active?:        'True' | 'False';
}) => {
  const { id, label_logic, label_type, label_name, logic_operator, colour, status, active } = params;
  const options = {
    method: 'POST',
    url: `${root}/label/change/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      id,
      label_type,
      label_name,
      label_logic,
      logic_operator,
      colour,
      status,
      active: active ?? 'True'
    }
  };

  try {
    const response = await axios.request(options);
    const data = response.data;
    console.log(data);

    return data.value;
  }
  catch (error) {
    console.log('/label/change/ failed: ', error);
    throw error?.message;
  }
};