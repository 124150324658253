import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import produce from 'immer';
import moment from 'moment';
import { uniqueId } from 'lodash';
import { Stack } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../store/userSlice';
import { fetchUniqueEvents, fetchUsersCountForEvents, fetchUsersCountForPeriod } from '../../api/events';
import { fetchDataSources } from '../../api/fetch-data-sources';
import { fetchCohorts } from '../../api/cohorts';
import { getToken } from '../../utils/token';
import redirectToAuth from '../../utils/redirect-to-auth';
import { getSearchParam, setSearchParam } from '../../utils/url';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import DateFilter from '../../components/DateFilter/DateFilter';
import BarChart from './BarChart';
import HorizontalBarChart from './HorizontalBarChart';
import SelectWithSearch from './SelectWithSearch/SelectWithSearch';
import FallbackUI from '../../components/Dashboard/FallbackUI';
import { SelectInput } from '../../components/UI';
import Prelaoder from '../../components/Preloader/Preloader';

import styles from './UserJourney.module.scss';

const pageName = 'User Journey';

export default function UserJourney() {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [filters, setFilters] = useState({
    from: moment().subtract(30, 'd').startOf('day').unix() * 1000,
    to:   moment().endOf('day').unix() * 1000
  });

  const [data, setData] = useState<{ name: string, users: number }[]>([]);
  const [conversionMap, setConversionMap] = useState<{ day: number, value: number }[]>([]);

  const [eventsQueue, setEventsQueue] = useState<{ id: string, name: string }[]>([]);
  const [uniqueNames, setUniqueNames] = useState<string[]>([]);
  const [uniqueNamesLoaded, setUniqueNamesLoaded] = useState(false);

  const [cohortsQueue, setCohortsQueue] = useState<{ id: string, name: string }[]>([]);
  const [uniqueCohorts, setUniqueCohorts] = useState<{ id: number, name: string }[]>([]);
  const [uniqueCohortsLoaded, setUniqueCohortsLoaded] = useState(false);

  const [convestionCriteria, setConvestionCriteria] = useState<string | number>(30);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);

  const [currentTab, setCurrentTab] = useState<'funnel' | 'time'>('funnel');

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'user_journey_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    const from = Number(getSearchParam('from'));
    const to   = Number(getSearchParam('to'));

    if (from && to) {
      setFilters({ from, to });
    }

    fetchDataSources();

    fetchUniqueEvents('event_name')
      .then(events => {
        setUniqueNames(events);
        setUniqueNamesLoaded(true);

        const param = getSearchParam('steps');
        if (param) {
          const nextEventsQueue = param
            .split('|')
            // Оставляем только те когорты, которые есть в списке
            .filter(name => events.find(n => n === name))
            .map(name => ({
              id: uniqueId(),
              name
            }));
          setEventsQueue(nextEventsQueue);
        }
      })
      .catch(setError);
    
    fetchCohorts()
      .then(cohorts => {
        const uniqueCohorts = cohorts.value.map(c => ({
          id:   c.cohort_id,
          name: c.cohort_name
        }));
        setUniqueCohorts(uniqueCohorts);
        setUniqueCohortsLoaded(true);

        const param = getSearchParam('cohorts');
        if (param) {
          const nextCohorts = param
            .split('|')
            // Оставляем только те когорты, которые есть в списке
            .filter(name => uniqueCohorts.find(c => c.name === name))
            .map(name => ({
              id: uniqueId(),
              name
            }));
          setCohortsQueue(nextCohorts);
        }
      })
      .catch(setError);
  }, []);

  useEffect(() => {
    const params = {
      events:  eventsQueue.map(e => e.name),
      cohorts: cohortsQueue.map(c => c.id),
      from:    filters.from,
      to:      filters.to,
      period:  convestionCriteria === 'uniques' ? undefined : +convestionCriteria
    };

    fetchUsersCountForPeriod(params)
      .then(response => {
        setConversionMap(
          response
            .map(d => {
              if (d.days === '-') {
                return null;
              }

              return {
                day: d.days,
                value: d.count
              }
            })
            .filter(Boolean)
        );
      });

    if (eventsQueue.length === 0) {
      setData([]);
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    fetchUsersCountForEvents(params)
      .then(response => {
        const events = response
          .map(event => ({
            name:  event.name,
            users: event.count
          }))
          .filter(e => e.name);
        setData(events);
      })
      .then(() => setIsLoading(false))
      .catch(setError);
  }, [filters, cohortsQueue, eventsQueue, convestionCriteria]);

  useEffect(() => {
    if (!uniqueNamesLoaded) {
      return;
    }
    setSearchParam('steps', eventsQueue.map(e => e.name).join('|'));
  }, [eventsQueue]);

  useEffect(() => {
    if (!uniqueCohortsLoaded) {
      return;
    }
    setSearchParam('cohorts', cohortsQueue.map(c => c.name).join('|'));
  }, [cohortsQueue]);

  if (!getToken()) {
    redirectToAuth();
    return;
  }

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />

          {error
            ? <FallbackUI />
            :
            <>
              <h1 className={styles.tableTitle}>{pageName}</h1>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '20px',
                  marginBottom: '50px'
                }}
              >
                <div className={styles.switch} style={{ display: 'none' }}>
                  <button
                    className={classNames(currentTab === 'funnel' && 'active')}
                    onClick={e => setCurrentTab('funnel')}
                  >
                    Funnel
                    <svg width="16" height="16" viewBox="0 0 16 16">
                      <path d="M5.969 2.5c0-.828.668-1.5 1.49-1.5h.994c.823 0 1.491.672 1.491 1.5v11c0 .828-.668 1.5-1.49 1.5H7.46c-.823 0-1.491-.672-1.491-1.5v-11ZM1 8.5C1 7.672 1.667 7 2.49 7h.994c.823 0 1.491.672 1.491 1.5v5c0 .828-.668 1.5-1.49 1.5H2.49C1.667 15 1 14.328 1 13.5v-5ZM13.422 3c.823 0 1.491.672 1.491 1.5v9c0 .828-.668 1.5-1.49 1.5h-.994c-.823 0-1.491-.672-1.491-1.5v-9c0-.828.668-1.5 1.49-1.5h.994Z" />
                    </svg>
                  </button>
                  <button
                    className={classNames(currentTab === 'time' && 'active')}
                    onClick={e => setCurrentTab('time')}
                  >
                    Time
                    <svg width="16" height="16" viewBox="0 0 16 16">
                      <path d="M13.5 5.969c.828 0 1.5.668 1.5 1.49v.994c0 .823-.672 1.491-1.5 1.491h-11c-.828 0-1.5-.668-1.5-1.49V7.46c0-.823.672-1.491 1.5-1.491h11ZM7.5 1C8.328 1 9 1.667 9 2.49v.994c0 .823-.672 1.491-1.5 1.491h-5c-.828 0-1.5-.668-1.5-1.49V2.49C1 1.667 1.672 1 2.5 1h5ZM13 13.422c0 .823-.672 1.491-1.5 1.491h-9c-.828 0-1.5-.668-1.5-1.49v-.994c0-.823.672-1.491 1.5-1.491h9c.828 0 1.5.668 1.5 1.49v.994Z" />
                    </svg>
                  </button>
                </div>

                <DateFilter
                  period={[filters.from, filters.to]}
                  // @ts-ignore
                  onChange={(type: string, range?: number[]) => {
                    setSearchParam('from', String(filters.from));
                    setSearchParam('to'  , String(filters.to));

                    const period = String(type).toLowerCase();
                    if (period === 'custom') {
                      const from = moment(range[0]).startOf('day').unix() * 1000;
                      const to   = moment(range[1]).endOf('day').unix() * 1000;
                      setFilters({ ...filters, from, to });
                    }
                    else if (period === 'today') {
                      const from = moment().startOf('day').unix() * 1000;
                      const to   = moment().endOf('day').unix() * 1000;
                      setFilters({ ...filters, from, to });
                    }
                    else if (period === 'yesterday') {
                      const from = moment().subtract(1, 'd').startOf('day').unix() * 1000;
                      const to   = moment().endOf('day').unix() * 1000;
                      setFilters({ ...filters, from, to });
                    }
                    else if (period.endsWith('d')) {
                      const from = moment().subtract(parseInt(period), 'd').startOf('day').unix() * 1000;
                      const to   = moment().endOf('day').unix() * 1000;
                      setFilters({ ...filters, from, to });
                    }
                    else if (period.endsWith('m')) {
                      const from = moment().subtract(parseInt(period), 'M').startOf('day').unix() * 1000;
                      const to   = moment().endOf('day').unix() * 1000;
                      setFilters({ ...filters, from, to });
                    }
                  }}
                />
              </div>

              <div className={styles.pageContent}>

                <div className={styles.chart}>
                  {isLoading
                    ? <Prelaoder />
                    :
                    currentTab === 'funnel'
                    ?
                    <BarChart
                      data={data.map((event, index) => ({
                        name:  event.name,
                        value: event.users,
                        prev:  (index > 0 ? data[index - 1].users : event.users)
                      }))}
                      onClick={async step => {
                        // try {
                        //   setIsLoading(true);
                        //   const wallets = await fetchWalletsForEvent({
                        //     events:  eventsQueue.map(e => e.name),
                        //     cohorts: cohortsQueue.map(c => c.id),
                        //     from:    filters.from,
                        //     to:      filters.to,
                        //     period:  convestionCriteria === 'uniques' ? undefined : +convestionCriteria,
                        //     step
                        //   });
                        //   localStorage.setItem('wallets', JSON.stringify(wallets));
                        //   const { location } = window;
                        //   location.href = '/wallet-analysis';
                        // }
                        // catch (error) {
                        //   console.error(error);
                        // }
                      }}
                    />
                    :
                    <HorizontalBarChart
                      data={(() => {
                        const sum = conversionMap.reduce((acc, curr) => acc + curr.value, 0);
                        return conversionMap.map(event => {
                          let value = event.value / sum * 100;
                          if (sum === 0 || !Number.isFinite(value)) {
                            value = 0;
                          }
                          return { day: event.day, value };
                        });
                      })()}
                    />
                  }
                </div>

                <div className={styles.sidebar}>
                  <Stack spacing="30px">
                    <section className={styles.sidebarSection}>
                      <header className={styles.sidebarSectionHeader}>
                        <SelectWithSearch
                          label={
                            <h3 className={styles.sidebarSectionTitle}>Steps</h3>
                          }
                          disabled={eventsQueue.length >= 5}
                          options={uniqueNames ?? []}
                          onChange={name => {
                            const id = uniqueId();
                            const nextEventQueue = produce(eventsQueue, draft => {
                              draft.push({ id, name });
                            });
                            setEventsQueue(nextEventQueue);

                            if (isAnalyticsInitialized) {
                              // @ts-ignore
                              merlin.send({
                                event_name: 'user_journey_steps_add',
                                event_type: 'click',
                                event_properties: {
                                  step_number: id,
                                },
                                user_properties: {
                                  tenantName
                                }
                              });
                            }
                          }}
                        />
                      </header>

                      <DragDropContext
                        onDragEnd={result => {
                          const items = Array.from(eventsQueue);
                          const [reorderedItem] = items.splice(result.source.index, 1);
                          items.splice(result.destination.index, 0, reorderedItem);
                          setEventsQueue(items);
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {provided =>
                            <>
                              <ol
                                className={styles.sidebarSectionList}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {eventsQueue.map((event, index) =>
                                  <Draggable key={event.id} draggableId={event.id} index={index}>
                                    {provided =>
                                      <li
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <span className={styles.sidebarSectionListNumber}>
                                          {index + 1}
                                        </span>
                                        {event.name}
                                        <button
                                          className={styles.sidebarSectionRemoveButton}
                                          onClick={e => {
                                            const nextEventQueue = produce(eventsQueue, draft => {
                                              draft.splice(index, 1);
                                            });
                                            setEventsQueue(nextEventQueue);
                                          }}
                                          aria-label="Remove step"
                                        >
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.158 11.159a.467.467 0 0 1-.66 0L4.841 5.502a.467.467 0 1 1 .66-.66l5.657 5.657a.466.466 0 0 1 0 .66Z" fill="#7A7E89" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.158 4.842a.467.467 0 0 1 0 .66l-5.657 5.657a.467.467 0 0 1-.66-.66l5.657-5.657a.467.467 0 0 1 .66 0Z" fill="#7A7E89" />
                                          </svg>
                                        </button>
                                      </li>
                                    }
                                  </Draggable>
                                )}
                              </ol>
                              {provided.placeholder}
                            </>
                          }
                        </Droppable>
                      </DragDropContext>
                    </section>

                    <section className={styles.sidebarSection}>
                      <header className={styles.sidebarSectionHeader}>
                        <SelectWithSearch
                          label={
                            <h3 className={styles.sidebarSectionTitle}>Cohort</h3>
                          }
                          options={uniqueCohorts.map(c => c.name) ?? []}
                          onChange={name => {
                            const nextCohorts = produce(cohortsQueue, draft => {
                              draft.push({
                                id: String(uniqueCohorts.find(c => c.name === name).id),
                                name
                              });
                            });
                            setCohortsQueue(nextCohorts);

                            if (isAnalyticsInitialized) {
                              // @ts-ignore
                              merlin.send({
                                event_name: 'user_journey_cohort_add',
                                event_type: 'click',
                                event_properties: {
                                  cohort_name: name,
                                },
                                user_properties: {
                                  tenantName
                                }
                              });
                            }
                          }}
                        />
                      </header>
                      <DragDropContext
                        onDragEnd={result => {
                          const items = Array.from(cohortsQueue);
                          const [reorderedItem] = items.splice(result.source.index, 1);
                          items.splice(result.destination.index, 0, reorderedItem);
                          setCohortsQueue(items);
                        }}
                      >
                        <Droppable droppableId="droppable">
                          {provided =>
                            <>
                              <ol
                                className={styles.sidebarSectionList}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {cohortsQueue.map((event, index) =>
                                  <Draggable key={event.id} draggableId={event.id} index={index}>
                                    {provided =>
                                      <li
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        {event.name}
                                        <button
                                          className={styles.sidebarSectionRemoveButton}
                                          onClick={e => {
                                            const nextCohorts = produce(cohortsQueue, draft => {
                                              draft.splice(index, 1);
                                            });
                                            setCohortsQueue(nextCohorts);
                                          }}
                                          aria-label="Remove step"
                                        >
                                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.158 11.159a.467.467 0 0 1-.66 0L4.841 5.502a.467.467 0 1 1 .66-.66l5.657 5.657a.466.466 0 0 1 0 .66Z" fill="#7A7E89" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.158 4.842a.467.467 0 0 1 0 .66l-5.657 5.657a.467.467 0 0 1-.66-.66l5.657-5.657a.467.467 0 0 1 .66 0Z" fill="#7A7E89" />
                                          </svg>
                                        </button>
                                      </li>
                                    }
                                  </Draggable>
                                )}
                              </ol>
                              {provided.placeholder}
                            </>
                          }
                        </Droppable>
                      </DragDropContext>
                    </section>

                    <section className={styles.sidebarSection}>
                      <header className={styles.sidebarSectionHeader}>
                        <h3 className={styles.sidebarSectionTitle} style={{ padding: '10px' }}>
                          Conversion Criteria
                        </h3>
                      </header>
                      <Stack spacing="5px">
                        <button
                          disabled
                          className={classNames(
                            styles.conversionButton,
                            convestionCriteria === 30 && 'active'
                          )}
                          onClick={e => {
                            setConvestionCriteria(30);
                          }}
                        >
                          Within 30 days
                        </button>
                       
                        <SelectInput
                          disabled
                          style={{
                            marginTop: '5px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            color: '#1A1D21'
                          }}
                          options={['Uniques', 'Total']}
                          defaultValue={'Uniques'}
                          onChange={setConvestionCriteria}
                        />
                      </Stack>
                    </section>
                  </Stack>
                </div>
                
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};
