import React, { HTMLAttributes } from 'react';
import styles from './Card.module.scss';

type CardProps = HTMLAttributes<HTMLDivElement> & {
  title:       string;
  headline:    string;
  img:         string;
  subtitle:    string;
  lastUpdated: string;
};

export const Card = ({ title, headline, img, subtitle, lastUpdated }: CardProps) => {
  return (
    <div className={styles.contentBlock}>
      <div className={styles.title}>{title}</div>
      <div className={styles.headline}>
        <h3>{headline}</h3>
        <img src={`assets/svg/dashboard/content/${img}.svg`} alt="" />
      </div>
      <div className={styles.subtitle}>{subtitle}</div>
      <span className={styles.lastUpdated}>
        Last updated<br/>
        {lastUpdated}
      </span>
    </div>
  );
};
