import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"
import { selectID, selectTenantName, setIsAnalyticsInitialized } from "../store/userSlice";
import { isDev } from "./is-dev";

export const useAnalyticsInit = () => {
  const dispatch = useDispatch();
  const userID = useSelector(selectID);
  const tenantName = useSelector(selectTenantName);

  useEffect(() => {
    if (userID === -1) return;

    const dev = isDev();
    // Initializing Merlin Project with authorization details
    // @ts-ignore
    merlin.init({
      dev,
      tenant_name: 'Merlin',
      user_id: userID,
      wallet_address: userID,
      api_key: dev ? 'QW93DLs6z39geUZmdpp6' : 'DiZZapAbJ6iydj21VHAJ',
      user_properties: {
        tenantName
      }
    });

    dispatch(setIsAnalyticsInitialized(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);
} 