import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { getToken } from '../../utils/token';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import Prelaoder from '../../components/Preloader/Preloader';
import DataSources from './DataSources';
import Labels from './Labels';
import Account from './Account/Account';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataSources } from '../../api/fetch-data-sources';
import redirectToAuth from '../../utils/redirect-to-auth';
import AddDataDropdown from './AddDataDropdown/AddDataDropdown';
import { addEmptyLabel, selectLabelsData } from '../../store/labels-slice';
import { addCustomTableRow, openEditor } from '../../store/settingsSlice';
import PlusIcon from '../../components/UI/Icons/PlusIcon';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import Cohorts from './Cohorts';

import styles from './style.module.scss';

const pageName = 'Settings';

const toKebabCase = (string: string) => string
  .replace(/([a-z])([A-Z])/g, "$1-$2")
  .replace(/[\s_]+/g, '-')
  .toLowerCase();

const tabs = ['Data sources', 'Labels', 'Cohorts' , 'Account'];

export default function Settings(props) {
  const dispatch = useDispatch();

  const labels = useSelector(selectLabelsData);

  const hash = window.location.hash.slice(1);
  const initialCurrentTab = tabs.map(toKebabCase).indexOf(hash) !== -1 ? hash : 'data-sources';
  const [currentTab, setCurrentTab] = useState(initialCurrentTab);

  const [loading, setLoading] = useState(true);

  function onHashChange() {
    const hash = window.location.hash.slice(1);
    if (tabs.map(toKebabCase).indexOf(hash) !== -1) {
      setCurrentTab(hash);
    }
  }

  useEffect(() => {
    fetchDataSources().then(() => setLoading(false));

    onHashChange();
    window.addEventListener('hashchange', onHashChange);
  }, []);

  if (!getToken()) {
    redirectToAuth();
    return;
  }

  return (
    <div className={styles.bg}>
      <Sidebar/>
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          
          <div className={styles.contentWrap}>

            <Prelaoder hidden={!loading} />

            <header className={styles.header}>
              <div className={styles.tab}>
                {tabs.map((label, index) =>
                  <button
                    key={label}
                    className={classNames(toKebabCase(label) === currentTab && 'active')}
                    onClick={e => {
                      window.location.hash = toKebabCase(label);
                    }}
                  >
                    {label}
                  </button>
                )}
              </div>

              <div className={styles.headerSpacing}>
                {(() => {
                  switch (currentTab) {
                    case 'data-sources': return (
                      <>
                        <button
                          className={styles.addContractButton}
                          onClick={e => dispatch(openEditor())}
                          disabled
                        >
                          <img src="/assets/svg/dashboard/gray-plus.svg" alt="Add" />
                          Group
                        </button>

                        <AddDataDropdown
                          onAddContract={() => {
                            dispatch(addCustomTableRow('contract'))
                          }}
                          onAddWallet={() => {
                            dispatch(addCustomTableRow('wallet'))
                          }}
                          disabled
                        />
                      </>
                    );
                    case 'labels': return (
                      <button
                        className={styles.addContractButton}
                        disabled={true || labels.some(r => r.editing)}
                        onClick={e => dispatch(addEmptyLabel())}
                      >
                        <PlusIcon />
                        Label
                      </button>
                    )
                  }
                })()}
              </div>

            </header>

            {currentTab === 'data-sources' &&
              <DataSources />
            }

            {currentTab === 'labels' &&
              <Labels />
            }


            {currentTab === 'cohorts' &&
              <div>
                <Cohorts />
              </div>
            }

            {currentTab === 'account' &&
              <Account />
            }
          </div>
        </div>
      </div>
    </div>
  );
};
