import React, { useState, useEffect } from 'react';
import styles from '../WalletContent/WalletContent.module.scss';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

export const Pagination = ({
    totalItems = 0,
    itemPerPage = 30,
    neighbours = 1,
    onSwitchPage = f => f,
}) => {
    const pageNeighbours = typeof neighbours === 'number' // pageNeighbours can be: 0, 1 or 2
        ? Math.max(0, Math.min(neighbours, 2))
        : 0;
    const totalPages = Math.ceil(totalItems / itemPerPage);
    const [currentPage, setCurrentPage] = useState(1);
    const gotoPage = (page) => {
        const currentPage = Math.max(0, Math.min(page, totalPages));
        const paginationData = {
            currentPage,
            totalPages,
            itemPerPage,
            totalItems
        };
        setCurrentPage(currentPage);
        onSwitchPage(paginationData);
    }

    const handleClick = (page) => (evt) => {
        evt.preventDefault();
        gotoPage(page);
    }

    const handleMoveLeft = (evt) => {
        evt.preventDefault();
        gotoPage(currentPage - (pageNeighbours * 2) - 1);
    }

    const handleMoveRight = (evt) => {
        evt.preventDefault();
        gotoPage(currentPage + (pageNeighbours * 2) + 1);
    }

    /**
     * Let's say we have 10 pages and we set pageNeighbours to 2
     * Given that the current page is 6
     * The pagination control will look like the following:
     *
     * (1) < {4 5} [6] {7 8} > (10)
     *
     * (x) => terminal pages: first and last page(always visible)
     * [x] => represents current page
     * {...x} => represents page neighbours
     */
    const fetchPageNumbers = () => {
        /**
         * totalNumbers: the total page numbers to show on the control
         * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
         */
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            /**
             * hasLeftSpill: has hidden pages to the left
             * hasRightSpill: has hidden pages to the right
             * spillOffset: number of hidden pages either to the left or to the right
             */
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    }

    useEffect(() => {
        // TODO: обработать смену кол-ва элементов в массиве
    }, [totalItems]);

    if (!totalItems || totalPages === 1) return null;

    const pages = fetchPageNumbers();

    return (
        <div className={styles.pagination}>
            <ul className={styles.list}>
                {pages.map((page, index) => {
                    if (page === LEFT_PAGE) return (
                        <li key={index} onClick={handleMoveLeft}>
                            <img src='/assets/svg/dashboard/navigate/left.svg' alt='Back'/>
                        </li>
                    );

                    if (page === RIGHT_PAGE) return (
                        <li key={index} onClick={handleMoveRight}>
                            <img src='/assets/svg/dashboard/navigate/right.svg' alt='Forward'/>
                        </li>
                    );

                    return (
                        <li key={index} className={currentPage === page ? styles.active : ''} onClick={handleClick(page)}>
                            {page}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}
