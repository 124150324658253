import axios from "axios";
import { root } from ".";
import { getDataSourceID } from "../utils/data-source-id";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";

export default async function fetchCharts() {
  const options = {
    method: 'POST',
    url: `${root}/merlin_fetcher/customview/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      data_source_id: getDataSourceID() + ""
    }
  };

  try {
    const response = await axios.request(options);
    const { data } = response;
    return data?.body?.body;
  }
  catch (error) {
    console.log('/merlin_fetcher/customview/ failed: ', error);
    throw error?.message;
  }
};