import React, {useState, useEffect, useContext} from 'react';
import styles from './Radio.module.scss';

const Radio = (props) => {
  const { children, onClick, disabled = false, value, isSelected = false } = props;

  return (
    
    <label className={`${styles.containerCheckbox} ${disabled ? styles.containerCheckboxDisabled : ''}`}>
        {children}
        <input
        type="radio"
        disabled={disabled}
        className={styles.checkboxInput}
        checked={props.isSelected}
        value={value}
        
        />
        <span className={styles.checkmark}></span>
    </label>

  );
};

export default Radio
