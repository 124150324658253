import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import { logout } from "../../../../api";
import styles from "./LogoutButton.module.scss";

export default function LogoutButton(props: HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={classNames(styles.button, props.className)}
      onClick={async e => {
        try {
          try {
            const response = await logout();
            console.log(response);
            window.location.href = '/auth';
          }
          catch (error) {
            console.log(error);
            // TODO: Вывести сообщение с ошибкой в интерфейс
          }
        }
        catch (error) {
          console.log(error);
        }
        props.onClick(e);
      }}
    >
      <img src="/assets/svg/dashboard/logout.svg" alt="Logout" />
    </button>
  )
}