import React, { useState, useCallback, useEffect } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';

import './OverviewChart.scss';
import { useCurrentPng } from "recharts-to-png";
import FileSaver from "file-saver";

export const OverviewChart = ({ histogramData, downloadGraphPng }) => {

  const [getAreaPng, { ref: areaRef }] = useCurrentPng();

  useEffect(() => {
    if (downloadGraphPng) {
      downloadGraphPng.current = handleAreaDownload;
    }
  }, []);

  const handleAreaDownload = useCallback(async () => {
    const png = await getAreaPng();
    if (png) {
      
      FileSaver.saveAs(png, "overview-chart.png");
    }
  }, [getAreaPng]);

  // const CustomTooltip = (histogramData) => {
  //   return (
  //     <div
  //       className='custom-tooltip'
  //       style={{
  //         width: '86px',
  //         height: '54px',
  //         background: 'white',
  //         borderRadius: '10px',
  //         border: '1px solid #D9D9D9',
  //       }}
  //     >
  //       <p className='desc'></p>
  //     </div>
  //   );
  // };
  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
      className='overview-chart-wrapper'
    >
      <BarChart
        data={histogramData.data}
        ref={areaRef}
        margin={{
          left: 0,
          right: 30,
        }}
      >
        <CartesianGrid />

        <XAxis
          dataKey='name'
          tickLine={false}
          axisLine={false}
          tick={{ fontSize: '8px' }}
        >
          <Label
            value={histogramData.data && histogramData.axis['x-axis']}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
            dy={5}
          />
        </XAxis>
        <YAxis tickLine={false} axisLine={false} tick={{ fontSize: '8px' }}>
          <Label
            value={histogramData.data && histogramData.axis['y-axis']}
            angle={-90}
            style={{
              fontSize: '8px',
              fill: '#7A7E89',
            }}
          />
        </YAxis>
        <Tooltip
          cursor={{ fill: 'rgba(73, 122, 248, 0.05)' }}
          labelStyle={{ display: 'none' }}
          allowEscapeViewBox={{ x: true, y: true }}
          // content={CustomTooltip}
        />
        <Legend
          layout='horizontal'
          verticalAlign=''
          align='right'
          wrapperStyle={{ top: -40, left: 10 }}
        />
        <Bar dataKey='cohort' fill='#6C95FF' radius={[10, 10, 0, 0]} />
        <Bar dataKey='population' fill='#FF6BB0' radius={[10, 10, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};
