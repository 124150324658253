import React, { useEffect, useState } from 'react';
import './SwitchBlock.scss';

const imageList = [
  {
    id: 1,
    img: '/assets/interactive/wallets.svg',
  },
  {
    id: 2,
    img: '/assets/interactive/сontracts.svg',
  },
  {
    id: 3,
    img: '/assets/interactive/nft.svg',
  },
  {
    id: 4,
    img: '/assets/interactive/transactions.svg',
  },
];

export const SwitchBlock = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const changeActiveSlide = (index) => {
    setActiveSlideIndex(index);
  };

  useEffect(() => {
    setActiveSlideIndex(1);
  }, []);
  return (
    <>
      <div className='switch-wrapper'>
        <div className='btn-list'>
          <ul>
            <li>
              <button
                className={
                  activeSlideIndex === 1 ? 'btn-active' : 'button-default'
                }
                onClick={() => changeActiveSlide(1)}
              >
                Wallet
              </button>
            </li>
            <li>
              <button
                className={
                  activeSlideIndex === 2 ? 'btn-active' : 'button-default'
                }
                onClick={() => changeActiveSlide(2)}
              >
                Contracts
              </button>
            </li>
            <li>
              <button
                className={
                  activeSlideIndex === 3 ? 'btn-active' : 'button-default'
                }
                onClick={() => changeActiveSlide(3)}
              >
                NFT
              </button>
            </li>
            <li>
              <button
                className={
                  activeSlideIndex === 4 ? 'btn-active' : 'button-default'
                }
                onClick={() => changeActiveSlide(4)}
              >
                Transactions
              </button>
            </li>
          </ul>
        </div>
        <div className='slider-wrapper'>
          <div className='slider'>
            {imageList.map(({ id, img }, index) => {
              return (
                <div
                  key={id}
                  className={
                    activeSlideIndex === index + 1
                      ? 'slider__slide slide__active '
                      : 'slider__slide  '
                  }
                >
                  <img
                    src={img}
                    alt='img'
                    className={
                      (activeSlideIndex === 1 && 'slider-wallet') ||
                      (activeSlideIndex === 2 && 'slider-contracts') ||
                      (activeSlideIndex === 3 && 'slider-nft') ||
                      (activeSlideIndex === 4 && 'slider-transactions')
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
