import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const TermsOfService = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.textWrapper}>
        <div className={styles.topBlock}>
          <h1>Website Terms of Use Agreement</h1>
          <p className={styles.pBlock}>
          Last Updated: June 21, 2022
          </p>
          {/* <span>
          This Privacy Policy describes the privacy practices of Buidl Labs, Inc. (“Company,” “we”, “us”, or “our”) and how we handle personal information that we collect through this web site (the “Web Site”) and the product demonstration accessible via the Web Site (the “Demo”).  We refer to your ability to access the Web Site and interact with the Demo as our “Services''.
          </span> */}
        </div>
       

        <div className={styles.centerBlock}>
          <h2>1. Acceptance of the Terms and Conditions.</h2>
       
          <span>
          1.1. Buidl Labs, Inc. (herein referred to as the “Company,” “we,” “us” or “our”) provides and makes available this web site (the “Web Site”) or the (“Service”). All access and use of the Service is subject to the terms and conditions contained in this Terms of Use Agreement (the “Agreement”).  Please read this Agreement carefully.  By accessing, browsing or otherwise using the Service, you acknowledge that you have read, understood, and agree to be bound by this Agreement.  If you do not accept the terms and conditions of this Agreement, you shall not access, browse or use the Service.
          </span>
          <span>
          1.2. You understand and agree that we may change this Agreement at any time without prior notice.  You may read a current, effective copy of this Agreement at any time by selecting the “Terms of Use” link on the Service.  The revised terms and conditions will become effective at the time of posting.  Any use of the Service after such date shall constitute your acceptance of such revised terms and conditions. If any change to this Agreement is not acceptable to you, your sole remedy is to cease accessing, browsing and otherwise using the Service.
          </span>
          <span>
          1.3. Your access to and use of the Service is also subject to the Company’s Privacy Policy located <a href='https://www.getmerlin.xyz/privacy_policy'> here </a> (the “Privacy Policy”).
          </span>

        </div>
        <div className={styles.centerBlock}>
          <h2>2. Use of the Service.</h2>
       
          <span>
          2.1. You may be required to register with Company or provide information about yourself (e.g., name and email address) in order to access and use certain features of the Service. If you choose to register for the Service, you agree to provide and maintain true, accurate, current, and complete information about yourself as prompted by the Service’s registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 13 years of age, you are not authorized to use the Service, with or without registering. In addition, if you are under 18 years old, you may use the Service, with or without registering, only with the approval of your parent or guardian.
          </span>
          <span>
          2.2. You are responsible for maintaining the confidentiality of your password and account details, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify Company of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Service. Company will not be liable for any loss or damage arising from your failure to comply with this paragraph.
          </span>
          <span>
          2.3. This Service contains material, including but not limited to software, text, graphics and images (collectively referred to as the “Content”). We may own the Content or portions of the Content may be made available to us through arrangements that we have with third-parties.  The Content is protected by United States and foreign intellectual property laws.  Unauthorized use of the Content may result in violation of copyright, trademark, and other laws.  You have no rights in or to the Content, and you will not use, copy or display the Content except as permitted under this Agreement.  No other use is permitted without our prior written consent.  You must retain all copyright and other proprietary notices contained in the original Content on any copy you make of the Content. You may not sell, transfer, assign, license, sublicense, or modify the Content or reproduce, display, publicly perform, make a derivative version of, distribute, or otherwise use the Content in any way for any public or commercial purpose.  The use or posting of any of the Content on any other web site or in a networked computer environment for any purpose is expressly prohibited. If you violate any part of this Agreement, your right to access and/or use the Content and Service shall automatically terminate and you shall immediately destroy any copies you have made of the Content.
          </span>
          <span>
          2.4. The trademarks, service marks, and logos of the Company (the “Company Trademarks”) used and displayed on this Service are registered and unregistered trademarks or service marks of the Company.  Other company, product, and service names located on the Service may be trademarks or service marks owned by third-parties (the “Third-Party Trademarks”, and, collectively with the Company Trademarks, the “Trademarks”).  Nothing in the Service or in this Agreement should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any Trademark displayed on this Service without the prior written consent of the Company specific for each such use.  The Trademarks may not be used to disparage the Company or the applicable third-party, the Company’s or third-party’s products or services, or in any manner (using commercially reasonable judgment) that may damage any goodwill in the Trademarks.  Use of any Trademarks as part of a link to or from any Service is prohibited without the Company’s prior written consent.  All goodwill generated from the use of any Company Trademark shall inure to the Company’s benefit.
          </span>
          <span>
          2.5. You agree not to: (a) take any action that imposes an unreasonable load on the Service’s infrastructure, (b) use any device, software or routine to interfere or attempt to interfere with the proper working of the Service or any activity being conducted on the Service, (c) attempt to decipher, decompile, disassemble or reverse engineer any of the software comprising or making up the Service, (d) delete or alter any material posted on the Service by the Company or any other person or entity, or (e) frame or link to any of the materials or information available on the Service.
          </span>
          <span>
          2.6. The Service contains links to third-party Services (“External Sites”). These links are provided solely as a convenience to you and not as an endorsement by us of the content on such External Sites.  The content of such External Sites is developed and provided by others.  You should contact the site administrator or Webmaster for those External Sites if you have any concerns regarding such links or any content located on such External Sites.
          </span>
          <span>
          We are not responsible for the content of any linked External Sites and do not make any representations regarding the content or accuracy of any materials on such External Sites. You should take precautions when downloading files from all Services to protect your computer from viruses and other destructive programs.  If you decide to access any External Sites, you do so at your own risk.
          </span>
          <span>
          2.7. Certain elements of the Service are protected by trade dress, trademark, unfair competition, and other state and federal laws and may not be copied or imitated in whole or in part, by any means, including but not limited to, the use of framing or mirrors, except as otherwise expressly permitted by Section 2.1 of the Agreement. None of the Content for this Service may be retransmitted without the express written consent from the Company for each and every instance.
          </span>

        </div>

        <div className={styles.centerBlock}>
          <h2>3. Limitation of Liability and Disclaimer of Warranties.</h2>
       
          <span>
          3.1. THE COMPANY, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, OR LICENSORS (COLLECTIVELY, THE “COMPANY PARTIES“) MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE CONTENT, INCLUDING BUT NOT LIMITED TO ITS ACCURACY, RELIABILITY, COMPLETENESS, TIMELINESS OR RELIABILITY.  THE COMPANY PARTIES SHALL NOT BE SUBJECT TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS OF THE CONTENT OR ANY OTHER INFORMATION CONVEYED TO THE USER OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN OR FOR ANY DELAYS OR INTERRUPTIONS OF THE DATA OR INFORMATION STREAM FROM WHATEVER CAUSE.  YOU AGREE THAT YOU USE THE SERVICE AND THE CONTENT AT YOUR OWN RISK.
          </span>

          <span>
          THE COMPANY PARTIES DO NOT WARRANT THAT THE SERVICE WILL OPERATE ERRORFREE OR THAT THE SERVICE, ITS SERVER, OR THE CONTENT ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR THE CONTENT RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, NO COMPANY PARTY SHALL BE RESPONSIBLE FOR THOSE COSTS.
          </span>

          <span>
          THE SERVICE AND CONTENT ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. THE COMPANY PARTIES DISCLAIM ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE, MERCHANTABILITY, NONINFRINGEMENT OF THIRD PARTIES RIGHTS, AND FITNESS FOR PARTICULAR PURPOSE. 
          </span>
          <span>
          3.2. IN NO EVENT SHALL ANY COMPANY PARTY BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL AND CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE THE SERVICE AND THE CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, EVEN IF SUCH COMPANY PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
          </span>
          <span>
          3.3. SOME STATES DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.  ACCORDINGLY, IN SUCH STATES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU, AND THE LIABILITY OF THE COMPANY PARTIES SHALL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </span>
          <span>
          3.4. IF YOU ARE FROM NEW JERSEY, THE FOREGOING SECTIONS 3.1 AND 3.2 ARE INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY.  IF ANY PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION SHALL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE APPLICABLE SECTIONS.
          </span>

        </div>

        <div className={styles.centerBlock}>
          <h2>4. Indemnification.</h2>
       
          <span>
          You agree to defend, indemnify, and hold harmless the Company Parties from and against any claims, actions or demands, including, without limitation, reasonable legal and accounting fees, arising or resulting from your breach of this Agreement or your access to, use or misuse of the Content or Service.  The Company shall provide notice to you of any such claim, suit, or proceeding.  The Company reserves the right to assume the exclusive defense and control of any matter which is subject to indemnification under this section. In such case, you agree to cooperate with any reasonable requests assisting the Company’s defense of such matter.
          </span>

        </div>

        <div className={styles.centerBlock}>
          <h2>5. Termination of the Agreement.</h2>
       
          <span>
          5.1. The Company reserves the right, in its sole discretion, to restrict, suspend, or terminate this Agreement and your access to all or any part of the Service or the Content at any time and for any reason without prior notice or liability. The Company reserves the right to change, suspend, or discontinue all or any part of the Service or the Content at any time without prior notice or liability.
          </span>
          <span>
          5.2. Sections 2 (Use of the Service), 3 (Limitation of Liability and Warranty), 4 (Indemnification), 5 (Termination of Agreement), and 7 (Miscellaneous) shall survive the termination of this Agreement.  
          </span>

        </div>

        <div className={styles.centerBlock}>
          <h2>6. User Must Comply with Applicable Laws.</h2>
       
          <span>
          6.1. This Service is directed to users in the United States.  We make no claims concerning whether the Content may be downloaded, viewed, or be appropriate for use outside of the United States.  If you access the Service or the Content from outside of the United States, you do so at your own risk.  Whether inside or outside of the United States, you are solely responsible for ensuring compliance with the laws of your specific jurisdiction.
          </span>
          <span>
          6.2. The United States controls the export of products and information. You expressly agree to comply with such restrictions and not to export or re-export any of the Content to countries or persons prohibited under the export control laws. By downloading the Content, you are expressly agreeing that you are not in a country where such export is prohibited or are a person or entity for which such export is prohibited. You are solely responsible for compliance with the laws of your specific jurisdiction regarding the import, export, or re-export of the Content.
          </span>

        </div>

        <div className={styles.centerBlock}>
          <h2>7. Miscellaneous.  </h2>
       
          <span>
          This Agreement is governed by the internal substantive laws of the State of New York, without respect to its conflict of laws provisions. You expressly agree to submit to the exclusive personal jurisdiction of the state and federal courts sitting in the City of New York in the State of New York.   If any provision of this Agreement is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect.   Failure of the Company to act on or enforce any provision of the Agreement shall not be construed as a waiver of that provision or any other provision in this Agreement.  No waiver shall be effective against the Company unless made in writing, and no such waiver shall be construed as a waiver in any other or subsequent instance. Except as expressly agreed by the Company and you, this Agreement constitutes the entire Agreement between you and the Company with respect to the subject matter, and supercedes all previous or contemporaneous agreements, whether written or oral, between the parties with respect to the subject matter.  The section headings are provided merely for convenience and shall not be given any legal import.   This Agreement will inure to the benefit of our successors, assigns, licensees, and sublicensees.  Any information submitted or provided by you to the Service might be publicly accessible.  Important and private information should be protected by you.  The Company is not liable for protection of privacy of electronic mail or other information transferred through the Internet or any other network that you may use.
          </span>

        </div>

      </div>
    </div>
  );
};

export default TermsOfService;
