import React, { useState, useEffect } from 'react';
import styles from './IBM.module.scss';
import IBMBlock from './IBMBlock';

const IBM = ({ ibmRef }) => {
  const [srcBtn1, setSrcBtn1] = useState('assets/svg/figures/activeBtn.svg');
  const [srcBtn2, setSrcBtn2] = useState('assets/svg/figures/disBtn.svg');
  const [srcBtn3, setSrcBtn3] = useState('assets/svg/figures/disBtn.svg');
  const [currentBtn, setCurrentBtn] = useState('1');
  const [notShowAnimation, setNotShowAnimation] = useState(false);

  const handleOnMouseEnter = () => {
    setNotShowAnimation(true);
  };
  const onMouseLeave = () => {
    setNotShowAnimation(false);
  };

/*  useEffect(() => {
    const interval = setInterval(() => handleCheckoutOnTimer(), 7000);
    return () => {
      clearInterval(interval);
    };
  }, [currentBtn, notShowAnimation]);*/

  const handleCheckoutOnTimer = () => {
    if ( currentBtn === '1' && !notShowAnimation) {
      handleClickBtn2();
    }
    if ( currentBtn === '2' && !notShowAnimation) {
      handleClickBtn3();
    }
    if ( currentBtn === '3' && !notShowAnimation) {
      handleClickBtn1();
    }
  }

  const handleClickBtn1 = () => {
    setSrcBtn1('assets/svg/figures/activeBtn.svg');
    setSrcBtn2('assets/svg/figures/disBtn.svg');
    setSrcBtn3('assets/svg/figures/disBtn.svg');
    setCurrentBtn('1');
  };
  const handleClickBtn2 = () => {
    setSrcBtn2('assets/svg/figures/activeBtn.svg');
    setSrcBtn1('assets/svg/figures/disBtn.svg');
    setSrcBtn3('assets/svg/figures/disBtn.svg');
    setCurrentBtn('2');
  };

  const handleClickBtn3 = () => {
    setSrcBtn3('assets/svg/figures/activeBtn.svg');
    setSrcBtn1('assets/svg/figures/disBtn.svg');
    setSrcBtn2('assets/svg/figures/disBtn.svg');
    setCurrentBtn('3');
  };

  return (
    <div className={styles.wrapper} ref={ibmRef}>
{/*      <div className={styles.ibmBtn}>
        <div onMouseLeave={onMouseLeave} onMouseEnter={handleOnMouseEnter}>
          <img
              className={styles.Btn1}
              src={srcBtn1}
              onClick={handleClickBtn1}
              alt='Ibm'
          />
        </div>
        <div onMouseLeave={onMouseLeave} onMouseEnter={handleOnMouseEnter}>
          <img
            className={styles.Btn2}
            src={srcBtn2}
            onClick={handleClickBtn2}
            alt='Ibm'
          />
        </div>
        <div onMouseLeave={onMouseLeave} onMouseEnter={handleOnMouseEnter}>
          <img
            className={styles.Btn3}
            src={srcBtn3}
            onClick={handleClickBtn3}
            alt='Ibm'
          />
        </div>
      </div>*/}
      <div className={styles.ibmBlockWrapper} onMouseLeave={onMouseLeave} onMouseEnter={handleOnMouseEnter}>
        <IBMBlock currentBtn={currentBtn} />
      </div>
    </div>
  );
};

export default IBM;
