import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from 'react-redux'
import { selectFilters, selectSelectedFilters, setIsCohortPopupActive } from '../../../store/walletAnalysisSlice'
import { addCohortsRow, DataType } from "../../../store/cohorts-slice";
import { selectIsAnalyticsInitialized, selectTenantName } from "../../../store/userSlice";

import styles from './CohortAddPopup.module.scss';

export default function CohortAddPopup() {
  const dispatch = useDispatch();
  const popup = useRef<HTMLDivElement>();

  const filters = useSelector(selectFilters);
  const tenantName = useSelector(selectTenantName);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const selectedFilters = useSelector(selectSelectedFilters);

  const operatorsData = {};

  filters.forEach(item => {
    const name  = item.filter_id;
    const value = item.filter_conditions[0];

    operatorsData[name] = value;
  });

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне
      if (!popup.current?.contains(e.target)) {
        dispatch(setIsCohortPopupActive(false));
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const [name, setName] = useState('');
  const [isSaved, setIsSaved] = useState(false);

  const href = window.location.search.slice(1);

  const details = {};

  for (let pair of new URLSearchParams(href)) {
    if (details[pair[0]]) {
      details[pair[0]] += ';' + pair[1];
    }
    else {
      details[pair[0]] = pair[1];
    }
  }

  // @ts-ignore
  delete details.data_source_id;

  Object.keys(details).forEach(field => {
    const index = field.indexOf('_condition');
    if (index !== -1) {
      details[field.slice(0, index)] = details[field] + ": " + details[field.slice(0, index)];
      delete details[field];
    }
  });

  const getOperator = (field, operatorsData) => {
    const name = field.replaceAll(' ', '_').replace('_condition', '');
    const operatorFromBase = operatorsData[name];

    if (operatorFromBase) return operatorFromBase;
    if (field.includes('_floor')) return 'greater than';
    if (field.includes('_ceil'))  return 'less than';
  }

  return (
    <div className={styles.mainContainer}>
      <div ref={popup} className={styles.CohortAddPopup}>
        {isSaved
          ?
          <h1 className={styles.extraInfo}>
            The new cohort is being processed and should be ready in 5 minutes.<br />
            You will be notified when they are ready.
          </h1>
          :
          <>
            <h1>Create cohort</h1>
            <input
              placeholder="Add name"
              value={name}
              style={{ width: '100%' }}
              onChange={e => {
                const { value } = e.target;
                if (/^[A-Za-zА-Яа-я\d_' ']*$/g.test(value)) {
                  setName(value);
                }
              }}
            />

            <div className={styles.detailedInfoContainer}>
              {Object.keys(details).map((key, index) => {
                if (key.includes('cohort')) return null;

                let field = key;
                const value = details[key];

                field = field[0].toUpperCase() + field.slice(1);
                field = field.replace(/_/g, ' ');

                return (
                  <div key={index} className={styles.detailedInfoContainer__item}>
                    <span>{field}</span>
                    <span>{value}</span>
                  </div>
                )
              })}
            </div>
          </>
        }

        <div className={styles.buttonsContainer}>
          <button
            className={styles.confirmButton}
            onClick={() => {
              dispatch(setIsCohortPopupActive(false));
            }}
          >
            {isSaved ? 'Okay' : 'Cancel'}
          </button>
          {!isSaved &&
            <button
              disabled={
                name.length === 0 || Object.keys(details).length === 0
              }
              className={
                classNames(
                  styles.saveButton,
                  (name.length === 0 || Object.keys(details).length === 0) && styles.disabled
                )
              }
              onClick={() => {
                const pageUrl = document.location.href;
                const condition = Object.keys(details).map((field, id) => ({
                  id,
                  field,
                  operator: getOperator(field, operatorsData),
                  value: details[field]
                }));

                for (let c of condition) {
                  const index = c.value.indexOf(':');
                  if (index !== -1) {
                    const operator = c.value.slice(0, index);
                    c.value = c.value.slice(index + 2); // + 2, потому что после двоеточия есть пробел
                    c.operator = operator;
                  }
                }

                const data: DataType = {
                  name,
                  logicOperator: '&',
                  condition
                };

                dispatch(addCohortsRow({ data }));
                setIsSaved(true);

                if (pageUrl.includes('wallet-analysis') && isAnalyticsInitialized) {
                  // @ts-ignore
                  merlin.send({
                    event_name: 'wallet_explorer_save_cohorts',
                    event_type: 'click',
                    event_properties: {
                      list_of_filters: selectedFilters,
                      cohort_name: data.name
                    },
                    user_properties: {
                      tenantName: tenantName
                    }
                  });
                }
              }}
            >
              Save
            </button>
          }
        </div>
      </div>
    </div>
  );
};
