import React from 'react';

import styles from './BackedBy.module.scss';

export const BackedBy = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.investorsLeft}>
        <a target="_blank" className={styles.topInv}>Raj Gokal</a>
        <a target="_blank">Anatoly Yakovenko</a>
        <a target="_blank" className={styles.mediumInv}>Jaynti Kanani</a>
        <a target="_blank">Emilie Choi</a>
        <a target="_blank" className={styles.bottomInv}>Nikil Viswanathan</a>
      </div>
      <div className={styles.centerContent}>
        <h1 className={styles.title}>Backed By</h1>
        <h2 className={styles.subtitle}>
          We're proud to be joined by FF3 DAO, a syndicate of&nbsp;
          <br />
          forward-thinking web3 minds and product experts.
        </h2>
        <div className={styles.expertsImg}>
          
          <img
            onClick={() => window.open('https://www.southparkcommons.com/', '_blank')}
            className={styles.imgSouth}
            src='assets/svg/social/south-park.svg'
            alt='South Park'
          />
          <img onClick={() => window.open('https://script.capital/', '_blank')} src='assets/svg/social/script.svg' alt='Script' />
        </div>
      </div>
      <div className={styles.investorsRight}>
        <a target="_blank" className={styles.topInv}>Alex Atallah</a> 
        <a target="_blank" >Joe Lau</a>
        <a target="_blank" className={styles.mediumInv}>Roneil Rumburg</a>
        <a target="_blank">Dylan Field</a>
        <a target="_blank" className={styles.bottomInv}>Pete Kim</a>
        <a target="_blank">Elad Gil</a>
      </div>
    </div>
  );
};
