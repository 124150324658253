import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './PageStatus.module.scss';

interface PageStatusProps extends HTMLAttributes<HTMLDivElement> {
  status: string;
}

const PageStatus = ({ status, className }: PageStatusProps) => {

  const getStatus = status => {
    switch (String(status).toLowerCase()) {
      case 'processing': return 'Processing';
      case 'create':     return 'Processing';
      case 'completed':  return 'Ready';
      case 'null':  return '';
      case 'none':  return '';
      default: return 'Error';
    }
  }

  const parsedStatus = getStatus(status);

  return (
    <div 
      className={classNames(styles.pageStatus, className)}
      style={{ display: parsedStatus.length === 0 && 'none' }}
    >
      {parsedStatus === 'Processing' &&
        <>
          <span style={{ color: '#B9BDC8' }}>
            Processing
          </span>
          
          <svg
            className={styles.processingSvg}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path
              opacity=".3"
              d="M3 9a6 6 0 1 0 6-6V1.5A7.5 7.5 0 1 1 1.5 9H3Z"
              fill="#B9BDC8"
            /><path d="M9 3a6 6 0 0 0-6 6H1.5A7.5 7.5 0 0 1 9 1.5V3Z" fill="url(#a)"/><defs><linearGradient id="a" x1="-.75" y1="6.464" x2="3.03" y2="-1.377" gradientUnits="userSpaceOnUse"><stop stop-color="#DC01FF"/><stop offset="1" stop-color="#3736FF"/></linearGradient></defs>
          </svg>
        </>
      }

      {parsedStatus === 'Ready' &&
        <>
          <span style={{ color: '#00C466' }}>
            Ready
          </span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <path d="M9 3a6 6 0 1 0 0 12A6 6 0 0 0 9 3ZM1.5 9a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Zm10.998-2.436a.75.75 0 0 1 .063 1.06l-4 4.5a.75.75 0 0 1-1.122 0l-2-2.25a.75.75 0 1 1 1.122-.997L8 10.497l3.44-3.87a.75.75 0 0 1 1.058-.063Z" fill="#00C466"/>
          </svg>
        </>
      }

      {parsedStatus === 'Error' &&
        <>
          <span style={{ color: '#E01E5A' }}>
            Error
          </span>

          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path 
              d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2ZM.5 8a7.5 7.5 0 1 1 15 0 7.5 7.5 0 0 1-15 0Z"
              fill="#E01E5A"
            /><path d="M8 9.5a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 1.5 0v4.5A.75.75 0 0 1 8 9.5ZM6.875 11.375a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" fill="#E01E5A"/>
          </svg>
        </>
      }
    </div>
  )
};

export default PageStatus;