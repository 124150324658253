import React from 'react';
import { useHistory } from "react-router-dom";

import { Button } from '../../../UI/';
import styles from './ContentBlock.module.scss';
// : styles.blockImage
export const ContentBlock = ({
  title,
  subtitle,
  btnText = false,
  secondary = false,
}) => {
  const history = useHistory();

  const goToFormPage = () => {
   history.push("/form")
  }
  return (
    <div className={styles.answerTitles}>
      <img
        className={styles.star}
        src='assets/svg/figures/little_star.svg'
        alt='Star'
      />
      <h1 className={secondary ? styles.secondaryTitle : styles.primaryTitle}>
        {title}
      </h1>
      <h2
        className={
          secondary ? styles.secondarySubtitle : styles.primarySubtitle
        }
      >
        {subtitle}
      </h2>
      {btnText && <Button hasHoverStars={true} onClick={() => goToFormPage()} className={styles.btn}>{btnText}</Button>}
    </div>
  );
};
