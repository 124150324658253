import React from 'react';

import styles from './ReadyToGetStarted.module.scss';

export const ReadyToGetStarted = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.wrapperBlocks}>
          <div className={styles.topBlock}>
            <span className={styles.title}><i>Ready</i> to get started?</span>
            <span className={styles.subtitle}>
              We're rooting for you to build generational, decentralized
              companies. Let us help you reach there efficiently. WAGMI
            </span>
          </div>
          <div className={styles.rightBlockStar}>
            <a href="https://calendly.com/merlinxyz/30min" target="_blank" className={styles.starWrap}>

                <img
                  className={styles.star}
                  src='assets/svg/social_upper_footer/star.svg'
                  alt='star'
                />

              <div className={styles.starText}>
                <p>
                  Schedule
                  <br /> a demo
                </p>
                <img
                  className={styles.arrow}
                  src='assets/svg/figures/arrow_right.svg'
                  alt='arrow'
                />
              </div>
            </a>
          </div>
        </div>
        <div className={styles.bottomBlock}>
          <span className={styles.textList}>Builders from</span>
          <ul className={styles.list}>
            <li>
              
                <img
                    src='assets/svg/social_upper_footer/twitter.svg'
                    alt='twitter'
                  />
              

            </li>
            <li>
              
                <img
                  src='assets/svg/social_upper_footer/amazon.svg'
                  alt='amazon'
                />
              
            </li>
            <li>
              
                <img
                  src='assets/svg/social_upper_footer/paypal.svg'
                  alt='paypal'
                />
              
            </li>
            <li>
              
                <img
                  src='assets/svg/social_upper_footer/microsoft.svg'
                  alt='microsoft'
                />
              
            </li>
          </ul>
        </div>
      </div>
      <div  className={styles.bottomGradient}>

      </div>
    </div>
  );
};
