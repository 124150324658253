import React, {useState,  useEffect} from 'react';
import styles from './SimpleOnChainContent.module.scss';
import LottieExample from '../Lottie-emample';
import { Button } from '../UI/';
import { useHistory } from "react-router-dom";


const SimpleOnChain = ({ width }) => {
  const history = useHistory();

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  const lottieProps = {
    jsonName: 'lottieJsonBgLines',
    width: width,
    height: 400,
    speed: 0.25,
  };

  const lottiePropsForMobile = {
    jsonName: 'lottieJsonBgLinesForMobile',
    width: width,
    height: 400,
    speed: 0,
  };

  const getLottieAnimation = () => {
    if(windowDimenion.winWidth >= 500) {
      return (<div className={styles.bg}>
        <LottieExample lottieProps={lottieProps} />
      </div>);
    } else {
      return (<div className={styles.bgMobile}>
        <LottieExample lottieProps={lottiePropsForMobile} />
      </div>);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.btn}>
        <a onClick={() => window.location ="/form"}>
          <Button hasHoverStars={true}>
            <p>Get early access</p>
            <div className={styles.rightButtonStar}></div>
          </Button>
        </a>
      </div>
      <div className={styles.wrapperImg}>
        {getLottieAnimation()}
        
        <img
          className={styles.bgTwo}
          src='assets/svg/figures/hero-img.png'
          alt='BackgroundImg'
        />
      </div>
    </div>
  );
};

export default SimpleOnChain;
