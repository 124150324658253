import React, { useEffect, useState } from 'react';
import produce from 'immer';
import moment from 'moment';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  ITEMS_PER_PAGE,
  selectCurrentPage,
  selectData,
  selectSelectedRowIndex,
  setData,
  setSelectedRowIndex,
  setTotal
} from '../../store/web-events-slice';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../store/userSlice';
import { selectDataSourceID } from '../../store/common-slice';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import { columns } from './Table/columns';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import Table from './Table';
import { getToken } from '../../utils/token';
import redirectToAuth from '../../utils/redirect-to-auth';
import useNeedToReload from '../../utils/use-need-to-reload';
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup';
import { readMessage } from '../../api/message';
import {
  downloadEvents,
  fetchEvents,
  fetchUniqueEvents,
  Filters
} from '../../api/events';
import { fetchDataSources } from '../../api/fetch-data-sources';
import DateFilter from '../../components/DateFilter';
import Filter from '../../components/Filter/Filter';
import CopyButton from '../../components/Dashboard/TableContent/RowContent/CopyButton';
import { Switch } from '../../components/UI/Switch/Switch';
import FetchButton from '../../components/UI/FetchButton/FetchButton';
import Prelaoder from '../../components/Preloader/Preloader';

import styles from './WebEvents.module.scss';

const pageName = 'Web Events';

const WebEvents = () => {
  const dispatch = useDispatch();

  const selectedRowIndex = useSelector(selectSelectedRowIndex);
  const data = useSelector(selectData);
  const selectedRow = data[selectedRowIndex];
  
  const currentPage = useSelector(selectCurrentPage);
  const dataSourceID = useSelector(selectDataSourceID);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);

  const needToReload = useNeedToReload();
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<Filters>({
    from: moment().subtract(30, 'd').startOf('day').unix() * 1_000,
    to:   moment().endOf('day').unix() * 1_000,
    itemsPerPage: ITEMS_PER_PAGE,
    currentPage
  });
  const [uniqueNames, setUniqueNames] = useState<string[]>([]);
  const [uniqueTypes, setUniqueTypes] = useState<string[]>([]);
  const [uniqueBrowsers, setUniqueBrowsers] = useState<string[]>([]);
  const [uniqueEnvironments, setUniqueEnvironments] = useState<string[]>([]);
  const [uniqueAppVersions, setUniqueAppVersions] = useState<string[]>([]);
  const [uniqueCountries, setUniqueCountries] = useState<string[]>([]);

  const [isJsonViewActive, setIsJsonViewActive] = useState(false);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'web_events_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    setLoading(true);
    fetchEvents(filters)
      .then(response => {
        const { events } = response;
        const data = events.map(e => ({
          raw:       JSON.stringify(e),
          timestamp: +e.auto_track_timestamp,
          name:      e.event_name,
          type:      e.event_type,
          id:        e.user_id,
          wallet:    e.wallet_address,
          event:     JSON.stringify(e.event_properties),
          user:      JSON.stringify(e.user_properties),
          browser:   e.browser_type
        }));
        dispatch(setData(data));

        const { total } = response;
        dispatch(setTotal(total));

        setLoading(false);
      })
      .catch(console.error)
  }, [filters]);

  useEffect(() => {
    const nextFilters = produce(filters, draft => {
      draft.currentPage = currentPage;
    })
    setFilters(nextFilters);
  }, [currentPage]);

  useEffect(() => {
    fetchDataSources();

    fetchUniqueEvents('event_type')
      .then(setUniqueTypes)
      .catch(console.error)

    fetchUniqueEvents('browser_type')
      .then(setUniqueBrowsers)
      .catch(console.error)

    fetchUniqueEvents('event_name')
      .then(setUniqueNames)
      .catch(console.error)

    fetchUniqueEvents('environment_type')
      .then(setUniqueEnvironments)
      .catch(console.error)

    fetchUniqueEvents('app_version')
      .then(setUniqueAppVersions)
      .catch(console.error)

    fetchUniqueEvents('country')
      .then(setUniqueCountries)
      .catch(console.error)
  }, []);

  if (!getToken()) {
    redirectToAuth();
    return;
  }

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          <h1 className={styles.tableTitle}>Web Events</h1>

          <div style={{ position: 'relative' }}>
            {loading && <Prelaoder />}

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Filter
                options={[
                  {
                    label:    'Event name',
                    prop:     'event_name',
                    type:     'select',
                    options:  uniqueNames,
                    selected: filters?.event_name != null,
                    value:    filters?.event_name
                  },
                  {
                    label:   'Type of event',
                    prop:    'event_type',
                    type:    'select',
                    options:  uniqueTypes,
                    selected: filters?.event_type != null,
                    value:    filters?.event_type,
                  },
                  {
                    label:    'Browser',
                    prop:     'browser_type',
                    type:     'select',
                    options:  uniqueBrowsers,
                    selected: filters?.browser_type != null,
                    value:    filters?.browser_type
                  },
                  {
                    label:    'Wallet address',
                    prop:     'wallet_address',
                    type:     'string',
                    selected: filters?.wallet_address != null,
                    value:    filters?.wallet_address
                  },
                  {
                    label:    'Environment Type',
                    prop:     'environment_type',
                    type:     'select',
                    options:  uniqueEnvironments,
                    selected: filters?.environment_type != null,
                    value:    filters?.environment_type
                  },
                  {
                    label:    'App Version',
                    prop:     'app_version',
                    type:     'select',
                    options:  uniqueAppVersions,
                    selected: filters?.app_version != null,
                    value:    filters?.app_version
                  },
                  {
                    label:    'Country',
                    prop:     'country',
                    type:     'select',
                    options:  uniqueCountries,
                    selected: filters?.country != null,
                    value:    filters?.country
                  }
                ]}
                onDone={_filters => {
                  setFilters({
                    from: filters?.from,
                    to:   filters?.to,
                    ..._filters
                  });
                }}
                onClear={() => setFilters({})}
              />

              <FetchButton
                onClick={async e => {
                  const response = await downloadEvents(filters);
                  const { url } = response;
                  if (url) {
                    // Create a new link
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'csv';
                    // Append to the DOM
                    document.body.appendChild(a);
                    // Trigger `click` event
                    a.click();
                    // Remove element from DOM
                    document.body.removeChild(a);
                  }

                  if (isAnalyticsInitialized) {
                    // @ts-ignore
                    merlin.send({
                      event_name: 'web_events_csv_download',
                      event_type: 'click',
                      event_properties: {
                        data_source_id: dataSourceID,
                        list_of_filters: {...filters}
                      },
                      user_properties: {
                        tenantName
                      }
                    });
                  }
                }}
                style={{ marginLeft: 'auto', marginRight: '15px' }}
              >
                Download CSV
              </FetchButton>

              <DateFilter
                period={[filters.from, filters.to]}
                // @ts-ignore
                onChange={(type: string, range?: number[]) => {
                  const period = String(type).toLowerCase();
                  if (period === 'custom') {
                    const from = moment(range[0]).startOf('day').unix() * 1000; // set to 12:00 am today
                    const to   = moment(range[1]).endOf('day').unix() * 1000; // set to 23:59 pm today
                    setFilters({ ...filters, from, to });
                  }
                  else if (period === 'today') {
                    const from = moment().startOf('day').unix() * 1000; // set to 12:00 am today
                    const to   = moment().endOf('day').unix() * 1000; // set to 23:59 pm today
                    setFilters({ ...filters, from, to });
                  }
                  else if (period === 'yesterday') {
                    const from = moment().subtract(1, 'd').startOf('day').unix() * 1000; // set to 12:00 am today
                    const to   = moment().endOf('day').unix() * 1000; // set to 23:59 pm today
                    setFilters({ ...filters, from, to });
                  }
                  else if (period.endsWith('d')) {
                    const from = moment().subtract(parseInt(period), 'd').startOf('day').unix() * 1000; // set to 12:00 am today
                    const to   = moment().endOf('day').unix() * 1000; // set to 23:59 pm today
                    setFilters({ ...filters, from, to });
                  }
                  else if (period.endsWith('m')) {
                    const from = moment().subtract(parseInt(period), 'M').startOf('day').unix() * 1000; // set to 12:00 am today
                    const to   = moment().endOf('day').unix() * 1000; // set to 23:59 pm today
                    setFilters({ ...filters, from, to });
                  }
                }}
              />
            </div>

            <div className={styles.pageContent}>
              <Table />
              {selectedRow &&
                <div className={styles.sidebar}>
                  <button 
                    className={styles.sidebarCloseButton}
                    onClick={() => dispatch(setSelectedRowIndex(-1))}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.948 13.948a.583.583 0 0 1-.825 0L6.052 6.877a.583.583 0 1 1 .825-.825l7.07 7.07a.583.583 0 0 1 0 .826Z"
                        fill="#7A7E89"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.947 6.052a.583.583 0 0 1 0 .825l-7.071 7.07a.583.583 0 1 1-.825-.824l7.07-7.071a.583.583 0 0 1 .826 0Z"
                        fill="#7A7E89"
                      />
                    </svg>
                  </button>
                  <header>
                    <label>
                      <span className={styles.jsonTitle}>JSON mode</span>
                      <Switch
                        disabled={!selectedRow.raw}
                        checked={isJsonViewActive}
                        onChange={() => {
                          setIsJsonViewActive(!isJsonViewActive)
                        }}
                      />
                    </label>
                  </header>

                  <div className={styles.data}>
                    {(() => {
                      const address = selectedRow.wallet ? selectedRow.wallet : selectedRow.id;
                      if (!address) {
                        return null;
                      }

                      const makeWalletAddressShorter = (address: string) =>
                          address.length > 16
                            ? `${address.slice(0, 9)}...${address.slice(-5)}` 
                            : address;

                      return (
                        <div className={styles.sidebar__userNumber}>
                          <h3>
                            {makeWalletAddressShorter(address)}
                          </h3> 
                          <CopyButton
                              className={styles.copyButton}
                              onClick={() => {
                                navigator.clipboard.writeText(address)
                                  .then(() => {
                                      console.log('Copying to clipboard was successful!');
                                  })
                                  .catch(error => {
                                      console.error('Could not copy text: ', error);
                                  });
                              }}
                          />
                        </div>
                      )
                    })()}
                    
                    <span className={styles.sidebar__date}>
                      <>
                        <svg
                          width="13"
                          height="11"
                          viewBox="0 0 13 11"
                          fill="none"
                        >
                          <path 
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.031 3.62C.996 4.122.996 4.754.996 5.6c0 1.678 0 2.517.274 3.178a3.6 3.6 0 0 0 1.949 1.949c.662.274 1.5.274 3.178.274h1.2c1.678 0 2.517 0 3.179-.274a3.601 3.601 0 0 0 1.949-1.949c.274-.661.274-1.5.274-3.178 0-.845 0-1.477-.035-1.98H1.03Zm.121-.84h11.69a2.611 2.611 0 0 0-.117-.358 3.6 3.6 0 0 0-1.95-1.95C10.116.2 9.276.2 7.599.2h-1.2c-1.678 0-2.517 0-3.18.274A3.6 3.6 0 0 0 1.27 2.422a2.608 2.608 0 0 0-.118.357ZM2.977 5.6a.42.42 0 0 1 .42-.42h.6a.42.42 0 1 1 0 .84h-.6a.42.42 0 0 1-.42-.42Zm7.021-.42a.42.42 0 1 0 0 .84h.6a.42.42 0 0 0 0-.84h-.6Zm-3.72.42a.42.42 0 0 1 .42-.42h.6a.42.42 0 1 1 0 .84h-.6a.42.42 0 0 1-.42-.42ZM3.396 7.58a.42.42 0 1 0 0 .84h.6a.42.42 0 0 0 0-.84h-.6ZM9.578 8a.42.42 0 0 1 .42-.42h.6a.42.42 0 0 1 0 .84h-.6a.42.42 0 0 1-.42-.42Zm-2.88-.42a.42.42 0 1 0 0 .84h.6a.42.42 0 0 0 0-.84h-.6Z"
                            fill="#B9BDC8"
                          />
                        </svg>
                        {
                          (() => {
                            let dateNumber = selectedRow.timestamp;

                            function formatAMPM(date) {
                              let hours   = date.getHours();
                              let minutes = date.getMinutes();
                              let ampm = hours >= 12 ? 'PM' : 'AM';
                              hours = hours % 12;
                              hours = hours ? hours : 12; // the hour '0' should be '12'
                              minutes = minutes < 10 ? '0' + minutes : minutes;
                              return hours + ':' + minutes + ' ' + ampm;
                            }
                      
                            const date = new Date(dateNumber);
                            const month = ('' + date).split(' ')[1];
                            const day   = ('' + date).split(' ')[2];
                            return `${month} ${day}, ${formatAMPM(date)}`;
                          })()
                        }
                      </>
                    </span>

                    {
                      isJsonViewActive
                      ?
                        <div className={styles.jsonData}>
                          <pre>{JSON.stringify(JSON.parse(selectedRow.raw), null, 2)}</pre>
                        </div>
                      :
                        <Stack>
                          {(() => {
                            const clone = {...selectedRow};
                            delete clone.raw;
                            delete clone.id;
                            delete clone.name;
                            delete clone.timestamp
                            delete clone.wallet

                            return Object.keys(clone).map((key, index) =>
                              <section key={index}>
                                <h4 className={styles.sidebar__header}>
                                  {columns.find(c => c.prop === key)?.name ?? 'Unknown'}
                                </h4>
                                <p className={styles.sidebar__details}>
                                  {(() => {
                                    console.log(key, clone);
                                    
                                    try {
                                      const obj = JSON.parse(selectedRow[key]);
                                      const keys = Object.keys(obj);
                                      
                                      if (keys.length === 0) return '-';
                                      else return keys.map((key, index) =>
                                        <dl key={index}>
                                          <dt>{key}: </dt>
                                          <dd>
                                            {typeof obj[key] === 'object'
                                              ? JSON.stringify(obj[key])
                                              : obj[key]
                                            }
                                          </dd>
                                        </dl>
                                      )
                                    }
                                    catch (e) {
                                      return selectedRow[key];
                                    }
                                  })()}
                                </p>
                              </section>
                            )
                          })()}
                        </Stack>
                    }

                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }
    </div>
  );
};

export default WebEvents;
