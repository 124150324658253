import React, { useEffect, useRef, useState } from 'react';
import { fetchTable, fetchFilter, fetchCoverage } from '../../../api';
import { fetchDataSources } from '../../../api/fetch-data-sources';
import { fetchMessage, readMessage } from '../../../api/message';
import { downloadTable } from '../../../api/download-table';
import { CustomTable } from '../Table/CustomTable';
import Pagination from '../Pagination';
import FilterDropdown from "../../FilterDropdown/FilterDropdown"
import PlusIcon from '../../UI/Icons/PlusIcon';
import { useSelector, useDispatch } from 'react-redux'
import {
  setTable,
  selectTable,
  setFilters,
  setCoverage,
  selectCoverage,
  selectStats,
  selectIsTableLoading,
  setIsTableLoading,
  setIsCohortPopupActive,
  selectSelectedFilters,
} from '../../../store/walletAnalysisSlice'
import { selectIsAnalyticsInitialized, selectTenantID, selectTenantName } from '../../../store/userSlice';
import { selectDataSourceID } from '../../../store/common-slice';
import FallbackUI from '../FallbackUI';
import Prelaoder from '../../Preloader/Preloader';
import FetchButton from '../../UI/FetchButton/FetchButton';
import NotificationPopup from '../../NotificationPopup/NotificationPopup';
import { getDataSourceID } from '../../../utils/data-source-id';
import CopyButton from '../TableContent/RowContent/CopyButton';
import shortenContract from '../../../utils/shorten-contract';
import { AssetsBlock } from '../TableContent/AssetsBlock/AssetsBlock';
import { LabelBlock } from '../TableContent/LabelBlock/LabelBlock';
import { SourseBlock } from '../TableContent/SourseBlock/SourseBlock';
import { Stack } from '@mui/material';

import styles from './WalletContent.module.scss';

const ITEMS_PER_PAGE = 20;

const { format } = Intl.NumberFormat();

export const WalletContent = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const [sortedTable, setSortedTable] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [pagination, setPagination] = useState({ currentPage: 1 });
  const [selectedRow, setSelectedRow] = useState();
  const [error, setError] = useState(null);

  const coverage = useSelector(selectCoverage);
  const table = useSelector(selectTable);
  const loading = useSelector(selectIsTableLoading);
  const tenant_id = useSelector(selectTenantID);
  const stats = useSelector(selectStats);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);
  const selectedFilters = useSelector(selectSelectedFilters);
  const dataSourceID = useSelector(selectDataSourceID);

  const [needToReload, setNeedToReload] = useState(false);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    /*eslint-disable */
    merlin.send({
      event_name: 'wallet_explorer_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  useEffect(() => {
    const $table = document.querySelector('table');
    const tableOffsetY = $table.getBoundingClientRect().top;
    const scroller = document.querySelector('.scroller');
    const callback = e => {
      const $th = document.querySelectorAll('th');
      if (scroller.scrollTop > tableOffsetY) {
        $th.forEach(th => {
          th.classList.add('sticky');
          th.style.transform = `translateY(${scroller.scrollTop - tableOffsetY + 12}px)`;
        });
      }
      else {
        $th.forEach(th => {
          th.classList.remove('sticky');
          th.style.transform = `translateY(0px)`;
        });
      }
    };

    if (scroller && table.length) {
      scroller.addEventListener('scroll', callback);
    }

    return () => {
      scroller.removeEventListener('scroll', callback);
    };
  }, [table]);

  useEffect(() => {
    fetchDataSources();

    setInterval(async () => {
      const response = await fetchMessage();
      setNeedToReload(response.has_message);
    }, 30_000);
  }, []);

  useEffect(() => {
    if (dataSourceID === -1) {
      return;
    }

    dispatch(setIsTableLoading(true));
    const { search } = window.location;
    const query = decodeURI(search).slice(1).replace(/['"«»]/g, '');
    fetchTable(query)
      .then(response => {
        const { columns } = response;
        let { data } = response;
        setTableColumns(columns.map(column => {
          return {
            ...column,
            sortType: 'NONE',
            isFilter: false
          };
        }));

        let wallets = localStorage.getItem('wallets');
        if (wallets) {
          try {
            wallets = JSON.parse(wallets);
            data = data.filter(r => {
              // Gets the first part of the string before '|'
              const wallet = r.wallet.split('|')[0];
              if (wallets.indexOf(wallet) !== -1) {
                return true;
              }
            })
          }
          catch (error) {
            console.error(error);
          }
        }

        setSortedTable(data);
        dispatch(setTable(data));
      })
      .then(() => dispatch(setIsTableLoading(false)))
      .catch(setError);

    fetchFilter(query)
      .then(response => {
        const { filter_data } = response;
        dispatch(setFilters(filter_data));
      })
      .catch(setError);
  }, [dataSourceID]);

  useEffect(() => {
    if (tenant_id === -1) return;
    fetchCoverage(`data_source_id=${getDataSourceID()}&tenant_id=${tenant_id}`)
      .then(response => {
        dispatch(setCoverage(response));
      });
  }, [tenant_id, dataSourceID]);

  const handleSwitchPage = (newPagination) => {
    setPagination(newPagination);
  };

  const sortByDirection = (value, direction) => {
    if (direction === 'NONE') {
      direction = 'ASC';
    }
    else if (direction === 'ASC') {
      direction = 'DESC';
    }
    else if (direction === 'DESC') {
      direction = 'NONE';
    }

    setTableColumns(tableColumns.map(col => ({
      ...col,
      sortType: value === col.column_id ? direction : 'NONE',
      isFilter: value === col.column_id
    })));
  }

  useEffect(() => {
    let t = [...table];
    const sortElement = tableColumns.find(el => !!el.isFilter);
    if (sortElement) {
      let sortProp = sortElement.column_id;

      if (table.length) {
        switch (sortElement.sortType) {
          case 'ASC': {
            if (sortProp === 'trend') {
              t = t.sort((a, b) => b[sortProp].localeCompare(a[sortProp]));
            }
            else if (sortProp === 'wallet') {
              t = t.sort((a, b) => {
                if (!a[sortProp]) {
                  return -1;
                }
                else if (!b[sortProp]) {
                  return 1;
                }
                return a[sortProp].localeCompare(b[sortProp]);
              });
            }
            else {
              t = t.sort((a, b) => a[sortProp] - b[sortProp]);
            }

            t = t.reverse();
            break;
          }
          case 'DESC': {
            t = [...sortedTable].reverse();
            break;
          }
          default: {
            console.log('Incorrect sorting direction', sortElement.sortType);
          }
        }
      }
    }

    setSortedTable(t);
  }, [table, tableColumns])

  if (error) {
    return <FallbackUI />;
  }

  const colorOfLabel = label => {
    switch (label) {
      case 'Developer':
        return '#386DF7';
      case 'Newbie':
        return '#DE4AF5';
      case 'L2_Pro' || 'L2 Explorer':
        return '#5600C5';
      case 'Browsing':
        return '#00A5A5';
      case 'Token_Collectooor':
        return '#07BDCA';
      case 'NFT_Collectooor':
        return '#07BDCA';
      default:
        return '#386DF7';
    }
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <h1 className={styles.tableTitle}>Wallet Explorer</h1>
      <div style={{ position: 'relative' }}>
        <Prelaoder hidden={!loading} />

        <div className={styles.header}>
          <div className={styles.flexRowHeader}>
            <div style={{ width: "395px" }}>
              <FilterDropdown />
            </div>
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <FetchButton
              onClick={async e => {
                const { location } = window;
                const { search } = location;
                const query = decodeURI(search).slice(1).replace(/['"«»]/g, '');
                const response = await downloadTable(query);
                const { url } = response;
                if (url) {
                  // Create a new link
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = 'csv';
                  // Append to the DOM
                  document.body.appendChild(a);
                  // Trigger `click` event
                  a.click();
                  // Remove element from DOM
                  document.body.removeChild(a);
                }

                if (location.href.includes('wallet-analysis') && isAnalyticsInitialized) {
                  // @ts-ignore
                  merlin.send({
                    event_name: 'wallet_explorer_csv_download',
                    event_type: 'custom',
                    event_properties: {
                      data_source_id:  dataSourceID,
                      list_of_filters: selectedFilters
                    },
                    user_properties: {
                      tenantName
                    }
                  });
                }
              }}
              style={{ marginLeft: 'auto', marginRight: '15px' }}
            >
              Download CSV
            </FetchButton>

            <button
              className={styles.addButton}
              onClick={() => {
                dispatch(setIsCohortPopupActive(true));
                /*eslint-disable */
                merlin.send({
                  event_name: 'wallet_explorer_add_cohorts',
                  event_type: 'click',
                  event_properties: {
                    list_of_filters: selectedFilters
                  },
                  user_properties: {
                    tenantName
                  }
                });
              }}
            >
              <PlusIcon />
              Create Cohort
            </button>
          </div>
        </div>

        <div className={styles.stats}>
          <span className={styles.statsLabel}>
            {stats[0].title}
            <span>{format(stats[0].value)}</span>
          </span>
          <span className={styles.statsLabel}>
            {stats[1].title}
            <span>{format(stats[1].value)}</span>
          </span>
          <span className={styles.statsLabel}>
            {stats[2].title}
            <span>{format(stats[2].value)}</span>
          </span>
        </div>

        <div className={styles.coverage}>
          <span className={styles.shortDes}>{coverage.ui_text_coverage}</span>
          <span className={styles.shortDes}>{coverage.ui_text_last_updated}</span>
        </div>

        <div className={styles.conentWrapper}>
          <div style={{ width: '100%', overflow: 'hidden' }}>
            <CustomTable
              tableColumns={tableColumns}
              tableData={
                sortedTable.slice(
                  (pagination.currentPage - 1) * ITEMS_PER_PAGE,
                  (pagination.currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
              }
              sortFunc={sortByDirection}
              onRowClick={setSelectedRow}
            />
            <Pagination
              className={styles.pagination}
              itemsPerPage={ITEMS_PER_PAGE}
              page={pagination.currentPage}
              total={sortedTable.length}
              onChange={currentPage => {
                if (isAnalyticsInitialized) {
                  // @ts-ignore
                  merlin.send({
                    event_name: 'wallet_explorer_pagination',
                    event_type: 'click',
                    event_properties: {
                      page_number: currentPage
                    },
                    user_properties: {
                      tenantName
                    }
                  });
                };
                handleSwitchPage({ currentPage });
              }}
            />
          </div>

          {selectedRow &&
            <div className={styles.sidebar}>
              <button
                className={styles.sidebarCloseButton}
                onClick={() => setSelectedRow(null)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.948 13.948a.583.583 0 0 1-.825 0L6.052 6.877a.583.583 0 1 1 .825-.825l7.07 7.07a.583.583 0 0 1 0 .826Z"
                    fill="#7A7E89"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.947 6.052a.583.583 0 0 1 0 .825l-7.071 7.07a.583.583 0 1 1-.825-.824l7.07-7.071a.583.583 0 0 1 .826 0Z"
                    fill="#7A7E89"
                  />
                </svg>
              </button>

              {(() => {
                let ens = '';
                let wallet = '';
                const walletColumn = tableColumns.find(c => c.column_id === 'wallet');
                if (walletColumn) {
                  wallet = selectedRow[walletColumn.column_id];
                  const separatorIndex = wallet.indexOf('|');
                  if (separatorIndex !== -1) {
                    ens = wallet.slice(separatorIndex + 1);
                    wallet = wallet.slice(0, separatorIndex);
                  }
                }

                return (
                  <>
                    {ens &&
                      <h3 className={styles.sidebar__ens}>
                        {ens}
                      </h3>
                    }
                    <div className={styles.sidebar__address}>
                      {(() => {
                        const address = wallet;
                        return (
                          <>
                            <h3>
                              {shortenContract(address, 16)}
                            </h3>
                            <CopyButton
                              className={styles.copyButton}
                              onClick={() => {
                                navigator.clipboard.writeText(address)
                                  .then(() => {
                                    console.log('Copying to clipboard was successful!');
                                  })
                                  .catch(error => {
                                    console.error('Could not copy text: ', error);
                                  });
                              }}
                            />
                          </>
                        )
                      })()}
                    </div>
                  </>
                )
              })()}


              <Stack spacing="20px" style={{ marginTop: '30px' }}>
                {tableColumns.map((tableColumn, index) => {
                  if (tableColumn.column_type === 'wallet') {
                    return;
                  }

                  const cellData = selectedRow[tableColumn.column_id];

                  return (
                    <div key={index}>
                      <h4 className={styles.sidebarTitle}>{tableColumn.column_ui_name}</h4>
                      {(() => {
                        switch (tableColumn.column_type) {
                          case 'string': {
                            if (tableColumn.suffix === '%') {
                              return <td>{`${cellData}${tableColumn.suffix}`}</td>;
                            }
                            else if (tableColumn.suffix) {
                              return <td>{cellData === null ? '--' : `${cellData} ${tableColumn.suffix}`}</td>;
                            }
                            return <td>{cellData}</td>;
                          }
                          case 'string_bold': {
                            if (tableColumn.suffix === '%') {
                              return (
                                <td className={styles.string_bold}>{`${cellData}${tableColumn.suffix}`}</td>
                              );
                            }
                            else if (tableColumn.suffix) {
                              return (
                                <td className={styles.string_bold}>
                                  {cellData === null ? '--' : `${cellData}  ${tableColumn.suffix}`}
                                </td>
                              );
                            }
                            return <td className={styles.string_bold}>{cellData}</td>;
                          }
                          case 'labels': {
                            return (
                              <td className={styles.assetsBlock}>
                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                  {cellData?.split(';').map((asset, index) => (
                                    <AssetsBlock
                                      key={index}
                                      text={asset}
                                    />
                                  ))}
                                </div>
                              </td>
                            )
                          }
                          case 'labels_color': {
                            return (
                              <td>
                                <span
                                  className={styles.labels_color}
                                  style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}
                                >
                                  {cellData?.split(';').map((label, index) =>
                                    <LabelBlock
                                      key={label}
                                      color={colorOfLabel(label)}
                                      text={label}
                                      fluid
                                    />
                                  )}
                                </span>
                              </td>
                            );
                          }
                          case 'icons': {
                            return (
                              <td>
                                {(() => {
                                  const alts = {
                                    'up': 'Arrow up',
                                    'down': 'Arrow down',
                                    'constant': 'Constant'
                                  };
                                  const icon = cellData;
                                  switch (icon) {
                                    case 'none': return '—';
                                    default: return (
                                      <img
                                        src={`/assets/svg/dashboard/table-icons/${icon}.svg`}
                                        alt={alts[icon]}
                                      />
                                    );
                                  }
                                })()}
                              </td>
                            );
                          }
                          case 'icons_with_text': {
                            if (tableColumn.suffix) {
                              return (
                                <td>
                                  <div>
                                    <div className={styles.sourseWrapper}>
                                      {cellData?.split(';').map((found, index) => {
                                        const [name, value] = found.split(':');
                                        return (
                                          <SourseBlock
                                            key={index}
                                            icon={name === 'Others' ? 'other' : name}
                                            company={name}
                                            value={value}
                                            suffix={tableColumn.suffix}
                                          />
                                        );
                                      })}
                                    </div>
                                  </div>
                                </td>
                              );
                            }
                          }
                          default:
                            return <td>{cellData}</td>;
                        }
                      })()}
                    </div>
                  )
                })}
              </Stack>
            </div>
          }
        </div>
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }
    </div>
  );
};
