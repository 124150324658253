import React, { HTMLAttributes } from "react";
import styles from "./Options.module.scss";

function clickElsewhere() {
  document.body.click();
}

export type Option = {
  disabled?: boolean;
  icon: JSX.Element;
  text: JSX.Element | string;
  callback: (e?) => void;
};

type OptionsProps = HTMLAttributes<HTMLDivElement> & {
  data: Option[];
};

export default function Options(props: OptionsProps) {
  const { data } = props;
  return (
    <div className={styles.options}>
      <ul>
        {data.map((option, index) =>
          <li key={index}>
            <button
              disabled={option.disabled}
              onClick={e => {
                option.callback(e);
                // Искуственно кликаем за пределы контекстного меню, чтобы оно закрылось
                clickElsewhere();
              }}
            >
              <div className={styles.iconWrapper}>
                {option.icon}
              </div>
              <span>{option.text}</span>
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}