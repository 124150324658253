import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type DataType = {
  raw:       string;
  timestamp: number;
  name:      string;
  type:      string;
  id:        string;
  wallet:    string;
  user:      string;
  event:     string;
  browser:   string;
};

export const ITEMS_PER_PAGE = 10;

type WebEventsStateType = {
  data: DataType[];
  selectedRowIndex: number;

  /* Pagination */

  /** Current page */
  currentPage: number;
  /** Total number of elements */
  total: number;
};

const initialState: WebEventsStateType = {
  data: [],
  selectedRowIndex: -1,
  currentPage: 1,
  total: 0
};

export const webEventsSlice = createSlice({
  name: "webEventsExplorer",
  initialState,
  reducers: {
    setData(state, action: PayloadAction<DataType[]>) {
      const data = action.payload;
      state.data = data;
    },
    setSelectedRowIndex(state, action: PayloadAction<number>) {
      const value = action.payload;
      state.selectedRowIndex = value;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      const value = action.payload;
      state.currentPage = value;
    },
    setTotal(state, action: PayloadAction<number>) {
      const value = action.payload;
      state.total = value;
    }
  }
});

// Actions

export const {
  setSelectedRowIndex,
  setData,
  setCurrentPage,
  setTotal
} = webEventsSlice.actions;

// Selectors

export const webEventsSelector = state => state.webEvents;

export const selectSelectedRowIndex = createSelector(
  webEventsSelector,
  (state: WebEventsStateType) => state.selectedRowIndex
);

export const selectData = createSelector(
  webEventsSelector,
  (state: WebEventsStateType) => state.data
);

export const selectCurrentPage = createSelector(
  webEventsSelector,
  (state: WebEventsStateType) => state.currentPage
);

export const selectTotal = createSelector(
  webEventsSelector,
  (state: WebEventsStateType) => state.total
);

export default webEventsSlice.reducer;