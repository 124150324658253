import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

type Filter = {
  filter_conditions: ('equals')[];
  filter_id:   string;
  filter_name: string;
  filter_type: string;
  values: [] | {
    max:       number;
    min:       number;
    increment: number;
  };
};

type ChartData = Partial<{
  title: {
    main: string,
    subtext?: string
  },
  axis: {
    /** Horizontal axis label */
    "x-axis": string,
    /** Vertical axis label */
    "y-axis": string
  },
  data: {
    name:       string,
    population: number,
    cohort:     number
  }[]
}>;

type Coverage = Partial<{
  coverage:             number,
  last_updated:         string,
  ui_text_coverage:     string,
  ui_text_last_updated: string
}>;

type SelectedFilters = {
  [key: string]: boolean;
};

type WalletAnalysisState = {
  table: {}[],
  isTableLoading: boolean,
  filters: Filter[],
  isFiltersDropdownOpen: boolean;
  selectedFilters: SelectedFilters,
  chart: ChartData,
  isCohortPopupActive: boolean,
  coverage: Coverage,
  stats: {
    title: string,
    value: number
  }[];
};

const initialState: WalletAnalysisState = {
  table: [],
  isTableLoading: true,
  filters: [],
  isFiltersDropdownOpen: false,
  selectedFilters: {},
  chart: {},
  isCohortPopupActive: false,
  coverage: {
    coverage: 0,
    last_updated: '',
    ui_text_coverage: '',
    ui_text_last_updated: ''
  },
  stats: [
    {
        title: "Total Wallets",
        value: 0
    },
    {
        title: "Active Wallets (30d)",
        value: 0
    },
    {
        title: "ETH Spent (30d)",
        value: 0
    }
  ]
};

export const walletAnalysisSlice = createSlice({
  name: "walletAnalysis",
  initialState,
  reducers: {
    setTable: (state, action) => {
      state.table = action.payload;
    },
    setIsTableLoading: (state, action: PayloadAction<boolean>) => {
      state.isTableLoading = action.payload;
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload;
    },
    openFiltersDropdown: state => {
      state.isFiltersDropdownOpen = true;
    },
    closeFiltersDropdown: state => {
      state.isFiltersDropdownOpen = false;
    },
    toggleFiltersDropdown: state => {
      state.isFiltersDropdownOpen = !state.isFiltersDropdownOpen;
    },
    setIsFiltersDropdownOpen: (state, action: PayloadAction<boolean>) => {
      state.isFiltersDropdownOpen = action.payload;
    },
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
    },
    clearSelectedFilters: state => {
      Object.keys(state.selectedFilters).forEach(prop => {
        state.selectedFilters[prop] = false;
      });
    },
    setChart: (state, action) => {
      state.chart = action.payload;
    },
    setCoverage: (state, action) => {
      state.coverage = action.payload;
    },
    setStats: (state, action) => {
      state.stats = action.payload;
    },
    setIsCohortPopupActive: (state, action) => {
      state.isCohortPopupActive = action.payload;
    }
  }
});

// Actions

export const {
  setTable,
  setIsTableLoading,
  setFilters,
  setIsFiltersDropdownOpen,
  setChart,
  setCoverage,
  setSelectedFilters,
  clearSelectedFilters,
  openFiltersDropdown,
  closeFiltersDropdown,
  toggleFiltersDropdown,
  setStats,
  setIsCohortPopupActive
} = walletAnalysisSlice.actions;

// Selectors

export const walletAnalysisSelector = state => state.walletAnalysis;

export const selectTable = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.table
);

export const selectIsTableLoading = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.isTableLoading
);

export const selectFilters = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.filters
);

export const selectIsFiltersDropdownOpen = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.isFiltersDropdownOpen
);

export const selectSelectedFilters = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.selectedFilters
);

export const selectChart = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.chart
);

export const selectCoverage = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.coverage
);

export const selectStats = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.stats
);

export const selectIsCohortPopupActive = createSelector(
  walletAnalysisSelector,
  (state: WalletAnalysisState) => state.isCohortPopupActive
);

export default walletAnalysisSlice.reducer;