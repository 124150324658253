import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTable, TableData as TR } from "../../../../store/settingsSlice";
import classNames from "classnames";
import { Column } from "../column";
import styles from "./TableRow.module.scss";

type TableRowProps = HTMLAttributes<HTMLDivElement> & {
  columns:  Column[];
  data:     any;
  index?:   number;
  inGroup?: boolean;
  getContextUI?: (isSelected?: boolean) => any;
};

export default function TableRow(props: TableRowProps) {
  const { columns, data, getContextUI, className, index, style } = props;

  const ref = useRef<HTMLDivElement>();
  const [isHovered, setIsHovered] = useState(false);

  const contextMenuRef = useRef<HTMLDivElement>();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    const handleClick = e => {
      // Клик вне контекстного меню
      if (!contextMenuRef.current?.contains(e.target)) {
        setIsContextMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClick, true);
    document.addEventListener('contextmenu', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
      document.removeEventListener('contextmenu', handleClick, true);
    };
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(styles.tr, data?.selected && 'selected', className)}
      onMouseEnter={e => setIsHovered(true)}
      onMouseLeave={e => setIsHovered(false)}
      onClick={props.onClick}
      onContextMenu={e => {
        e.preventDefault();
        var rect = ref.current.getBoundingClientRect();
        var x = e.clientX - rect.left; //x position within the element.
        var y = e.clientY - rect.top;  //y position within the element.
        setContextMenuPosition({ x, y });
        setIsContextMenuOpen(true);
      }}
      style={style}
    >
      {columns.map(column =>
        <div
          key={column.prop}
          className={styles.td}
          style={{
            ...column.style,
            ...(typeof column.tdStyle === 'function' ? column.tdStyle(data) : column.tdStyle)
          }}
        >
          {column.render?.(
            column.prop === 'index' ? index : data[column.prop],
            data,
            data.id,
            isHovered
          ) ?? data[column.prop]}
        </div>
      )}

      <div
        ref={contextMenuRef}
        style={{
          display: isContextMenuOpen ? 'block' : 'none',
          position: 'absolute',
          zIndex: 3000,
          top:  contextMenuPosition.y + 'px',
          left: contextMenuPosition.x + 'px'
        }}
      >
        {getContextUI && getContextUI(data?.selected)}
      </div>

    </div>
  )
}