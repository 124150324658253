import React, { useEffect, useState } from 'react';
import { fetchCohorts } from '../../../api/cohorts';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAdmin, selectIsAnalyticsInitialized, selectTenantName, setIsAnalyticsInitialized } from '../../../store/userSlice';
import { 
  addEmptyCohort,
  cohortsStatusSelector,
  DataType,
  removeCohortsRow,
  selectCohortsData,
  setCohortsData,
  setCohortsStatus 
} from '../../../store/cohorts-slice';
import { Pagination } from '../../../components/Dashboard/Pagination/Pagination2';
import { columns } from './columns';
import NotificationPopup from '../../../components/NotificationPopup/NotificationPopup';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import Options from '../../../components/UI/Options/Options';
import PlusIcon from '../../../components/UI/Icons/PlusIcon';
import { PageStatus } from '../../../components/UI';
import { readMessage } from '../../../api/message';
import useNeedToReload from '../../../utils/use-need-to-reload';

import styles from './style.module.scss';

const ITEMS_PER_PAGE = 10;

export default function Lables() {
  const [currentPage, setCurrentPage] = useState(1);
  const needToReload = useNeedToReload();
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);

  const dispatch = useDispatch();
  // const isAdmin = useSelector(selectIsAdmin);
  
  const data = useSelector(selectCohortsData);

  const pageStatus = useSelector(cohortsStatusSelector);
  const tenantName = useSelector(selectTenantName);

  useEffect(() => {
    fetchCohorts()
      .then(response => {
        dispatch(setCohortsStatus(response.status));
        return response;
      })
      .then(response => {
        const data = response.value.map(r => ({
          id:            r.cohort_id,
          type:          r.cohort_type,
          status:        r.status,
          name:          r.cohort_name,
          condition:     r.cohort_logic.map(logic => ({
            id:       logic.cohortlogic_id,
            field:    logic.field,
            operator: logic.operator,
            value:    logic.value
          })),
          logicOperator: r.logic_operator,
          editing:       false
        }));
        
        data.forEach(row => {
          row.condition = row.condition.filter(condition => condition.field !== 'cohort_name');
        });

        dispatch(setCohortsData(data));
      });

      setInterval(() => {
        fetchCohorts()
          .then(response => dispatch(setCohortsStatus(response.status)))
      }, 30_000);
  }, []);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'settings_cohorts_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  }, [isAnalyticsInitialized]);

  const isEditing = data.some(r => r.editing);

  return (
    <>
      <div className={styles.tableWrap}>
        <PageStatus className={styles.pageStatus} status={pageStatus} />
        <TableHead columns={columns} />
        <button
          hidden
          className={styles.addButton}
          disabled={isEditing}
          onClick={e => dispatch(addEmptyCohort())}
        >
          <PlusIcon />
          Add Cohort
        </button>
        {data
          .slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
          )
          .map((row: DataType, index) =>
            <TableRow
              key={index}
              index={index + (currentPage - 1) * ITEMS_PER_PAGE}
              columns={columns}
              data={row}
              // getContextUI={selected =>
              //   <Options
              //     data={[
              //       // {
              //       //   disabled: true,
              //       //   icon: <img src="/assets/svg/dashboard/edit.svg" alt="Edit icon" />,
              //       //   text: 'Edit',
              //       //   callback: e => {
              //       //     const nextData = produce(data, draft => {
              //       //       draft[index].unsavedData = data[index];
              //       //       draft[index].editing = true;
              //       //     });
              //       //     dispatch(setCohortsData(nextData));
              //       //   }
              //       // },
              //       {
              //         disabled: false,
              //         icon:
              //           <img
              //             src="/assets/svg/dashboard/trash.svg"
              //             alt="Delete icon"
              //           />
              //         ,
              //         text: 'Delete',
              //         callback: e => {  
              //           const { id } = row;
              //           dispatch(removeCohortsRow({ id }));
              //           dispatch(setCohortsStatus('Processing'));
              //         }
              //       }
              //     ]}
              //   />
              // }
            />
          )
        }
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }

      <Pagination
        itemPerPage={10}
        totalItems={data.length}
        onSwitchPage={pagination => setCurrentPage(pagination.currentPage)}
      />
      {/* @ts-ignore  */}
      <NotificationPopup
        title="Data has changed"
      >
        Please reload this window to update.
      </NotificationPopup>
    </>
  );
};
