import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../../store/userSlice';
import { RowContent } from '../TableContent/RowContent/RowContent';
import SortButton from './SortButton';

import styles from './CustomTable.module.scss';

export const CustomTable = ({ tableColumns = [], tableData = [], sortFunc, onRowClick }) => {
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const tenantName = useSelector(selectTenantName);

  return (
    <div className={styles.tableWrap}>
      <table>
        <thead>
          <tr>
            {tableColumns.map((tableColumn) => (
              <th key={`${tableColumn.column_type}${tableColumn.column_id}`}>
                <div className={styles.headerTh}>
                  <span>{tableColumn.column_ui_name}</span>
                  {["string", "wallet", "icons"].indexOf(tableColumn.column_type) > -1 &&
                    <SortButton
                      direction={tableColumn.sortType}
                      onClick={() => {
                        const pageUrl = document.location.href;
                        sortFunc(tableColumn.column_id, tableColumn.sortType);

                        if (pageUrl.includes('wallet-analysis') && isAnalyticsInitialized) {
                          /*eslint-disable */
                          merlin.send({
                            event_name: 'wallet_explorer_table_sorting',
                            event_type: 'click',
                            event_properties: {
                              column_name: tableColumn.column_id,
                              sort_type:   tableColumn.sortType
                            },
                            user_properties: {
                              tenantName
                            }
                          });
                        }
                      }}
                    />
                  }
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((rowContent, index) => rowContent
            ?
            <RowContent
              key={rowContent.wallet_address}
              isLast={index === tableData.length - 1}
              tableColumns={tableColumns}
              rowContent={rowContent}
              onClick={() => onRowClick(rowContent)}
            />
            : null
          )}
        </tbody>
      </table>
    </div>
  );
};
