import axios from "axios";
import { root } from ".";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";

export const downloadTable = async (query: string): Promise<{
  message: string;
  url:     string;
}> => {
  const data = {};
  for (let pair of new URLSearchParams(query)) {
    if (data[pair[0]]) {
      data[pair[0]] += '|' + pair[1];
    }
    else {
      data[pair[0]] = pair[1];
    }
  }

  const options = {
    method: 'POST',
    url: `${root}/merlin_fetcher/raw_data/download/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`,
      'Content-Type': 'multipart/form-data'
    },
    data: {
      session: getSession(),
      ...data,
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  }
  catch (error) {
    console.log('/merlin_fetcher/raw_data/download/ failed: ', error);
  }
};