import React, { ComponentProps, useState } from "react";
import classNames from "classnames";
import styles from "./FetchButton.module.scss";

export default function FetchButton(props: ComponentProps<'button'>) {
  const [loading, setLoading] = useState(false);
  return (
    <button
      {...props}
      className={classNames(styles.button, props.className)}
      disabled={loading || props.disabled}
      onClick={async e => {
        setLoading(true);
        await props?.onClick(e);
        setLoading(false);
      }}
    >
      {props.children}
      {loading
        ?
        <svg className={styles.loader} width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path opacity=".3" d="M3 9a6 6 0 1 0 6-6V1.5A7.5 7.5 0 1 1 1.5 9H3Z" fill="#B9BDC8" />
          <path d="M9 3a6 6 0 0 0-6 6H1.5A7.5 7.5 0 0 1 9 1.5V3Z" fill="url(#a)" />
          <defs>
            <linearGradient id="a" x1="-.75" y1="6.464" x2="3.03" y2="-1.377" gradientUnits="userSpaceOnUse">
              <stop stopColor="#DC01FF" />
              <stop offset="1" stopColor="#3736FF" />
            </linearGradient>
          </defs>
        </svg>
        :
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <path d="M4.59308 14.4375H13.4056C13.5749 14.4376 13.7376 14.5031 13.8598 14.6204C13.9819 14.7376 14.054 14.8976 14.061 15.0667C14.068 15.2359 14.0093 15.4012 13.8972 15.5281C13.7851 15.6551 13.6283 15.7337 13.4596 15.7477L13.4056 15.75H4.59308C4.4238 15.7498 4.26113 15.6842 4.13906 15.567C4.01699 15.4497 3.94497 15.2897 3.93805 15.1206C3.93113 14.9514 3.98984 14.7862 4.10191 14.6593C4.21398 14.5324 4.37075 14.4538 4.53946 14.4398L4.59308 14.4375H13.4056H4.59308ZM8.85196 2.346L8.90558 2.34375C9.07028 2.34376 9.22895 2.40569 9.3501 2.51725C9.47126 2.62882 9.54603 2.78186 9.55958 2.946L9.56183 3V10.9999L11.3592 9.20288C11.4823 9.0798 11.6492 9.01065 11.8233 9.01065C11.9973 9.01065 12.1643 9.0798 12.2873 9.20288C12.4104 9.32595 12.4796 9.49288 12.4796 9.66694C12.4796 9.84099 12.4104 10.0079 12.2873 10.131L9.37058 13.0477C9.30964 13.1087 9.2373 13.157 9.15767 13.19C9.07805 13.223 8.99271 13.24 8.90652 13.24C8.82034 13.24 8.73499 13.223 8.65537 13.19C8.57575 13.157 8.5034 13.1087 8.44246 13.0477L5.52571 10.131C5.40263 10.0079 5.33349 9.84099 5.33349 9.66694C5.33349 9.49288 5.40263 9.32595 5.52571 9.20288C5.64879 9.0798 5.81571 9.01065 5.98977 9.01065C6.16383 9.01065 6.33076 9.0798 6.45383 9.20288L8.24933 10.9984V3C8.24935 2.83524 8.31133 2.67652 8.42298 2.55536C8.53462 2.4342 8.68775 2.35946 8.85196 2.346L8.90558 2.34375L8.85196 2.346Z" fill="url(#paint0_linear_3386_17866)" />
          <defs>
            <linearGradient id="paint0_linear_3386_17866" x1="7.36039" y1="-1.67812" x2="18.8776" y2="2.51437" gradientUnits="userSpaceOnUse">
              <stop stopColor="#DC01FF" />
              <stop offset="1" stopColor="#3736FF" />
            </linearGradient>
          </defs>
        </svg>
      }
    </button>
  )
}