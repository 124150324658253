import React from 'react';
import { BarChart as Chart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';

type Props = {
  data: {
    /** (1-based) */
    day: number;
    /** Percent (0-100) */
    value: number;
  }[];
};

const { format } = Intl.NumberFormat('en', { maximumFractionDigits: 2 });

const CustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  let gap = 10;
  if (!width) {
    gap = 5;
  }
  
  return (
    <text
      x={x + width + gap}
      y={y + height / 2 + 3.5}
      fontSize="10"
      fontFamily="Inter"
      fill="#1A1D21"
      textAnchor="start"
    >
      {format(value)}%
    </text>
  );
};

export default function HorizontalBarChart({ data: _data }: Props) {
  let data = [..._data];
  const len = Math.max(...data.map(el => el.day));
  for (let i = 0; i < len; i++) {
    const hasDay = data.find(el => el.day === i + 1);
    if (!hasDay) {
      data.push({ day: i + 1, value: 0 });
    }
  }
  // Сортируем дни по возрастанию
  data = data.sort((a, b) => a.day - b.day);

  return (
    <ResponsiveContainer width="100%" height={len * 20 + len * 10}>
      <Chart
        data={data}
        layout="vertical"
        margin={{ right: 50 }}
        style={{ marginTop: '10px' }}
      >
        <XAxis type="number" hide />
        <YAxis
          type="category"
          tick={{ fontFamily: 'Inter', color: '#7A7E89', fontSize: '10px' }}
          tickFormatter={(_, index) => {
            if (data[index]) {
              return format(data[index].day) + 'd';
            }
            return '';
          }}
          minTickGap={0}
          axisLine={false}
          tickLine={false}
        />
        <Bar
          dataKey="value"
          fill="none"
          stroke="#FF6BB0"
          label={<CustomizedLabel />}
          barSize={20}
          radius={[0, 6, 6, 0]}
        />
      </Chart>
    </ResponsiveContainer>
  );
}
