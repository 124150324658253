import React, { useEffect, useState } from 'react';
import produce from 'immer';
import { fetchLabels } from '../../../api/labels';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsAdmin, selectIsAnalyticsInitialized, selectTenantName } from '../../../store/userSlice';
import { addEmptyLabel, DataType, removeLabelsRow, selectLabelsData, setLabelsData } from '../../../store/labels-slice';
import { Pagination } from '../../../components/Dashboard/Pagination/Pagination2';
import { columns } from './columns';
import NotificationPopup from '../../../components/NotificationPopup/NotificationPopup';
import TableHead from '../Table/TableHead';
import TableRow from '../Table/TableRow';
import Options from '../../../components/UI/Options/Options';
import useNeedToReload from '../../../utils/use-need-to-reload';
import { readMessage } from '../../../api/message';

import styles from './style.module.scss';

const ITEMS_PER_PAGE = 10;

export default function Lables() {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const needToReload = useNeedToReload();

  const data = useSelector(selectLabelsData);
  const tenantName = useSelector(selectTenantName);
  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);

  useEffect(() => {
    if (!isAnalyticsInitialized) return;
    // @ts-ignore
    merlin.send({
      event_name: 'settings_labels_page_visit',
      event_type: 'custom',
      user_properties: {
        tenantName: tenantName
      }
    });
  },[isAnalyticsInitialized]);

  useEffect(() => {
    fetchLabels()
      .then(data => {
        dispatch(setLabelsData(
          data.map(r => ({
            id:            r.label_id,
            type:          r.label_type,
            status:        r.status,
            color:         CSS.supports('color', r.colour) ? r.colour : '#000',
            name:          r.label_name,
            condition:     r.label_logic.map(logic => ({
              id:       logic.labellogic_id,
              field:    logic.field,
              operator: logic.operator,
              value:    logic.value
            })),
            logicOperator: r.logic_operator,
            editing:       false
          }))
        ));
      });
  }, []);

  return (
    <>
      <div className={styles.tableWrap}>
        <TableHead columns={columns} />
        {data
          .slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
          )
          .map((row: DataType, index) =>
            <TableRow
              key={index}
              index={index + (currentPage - 1) * ITEMS_PER_PAGE}
              columns={columns}
              data={row}
              // getContextUI={selected =>
              //   <Options
              //     data={[
              //       {
              //         disabled: true,
              //         icon: <img src="/assets/svg/dashboard/edit.svg" alt="Edit icon" />,
              //         text: 'Edit',
              //         callback: e => {
              //           const nextData = produce(data, draft => {
              //             draft[index].unsavedData = data[index];
              //             draft[index].editing = true;
              //           });
              //           dispatch(setLabelsData(nextData));
              //         }
              //       },
              //       {
              //         disabled: true,
              //         icon:
              //           <img
              //             src="/assets/svg/dashboard/trash.svg"
              //             alt="Delete icon"
              //           />
              //         ,
              //         text: 'Delete',
              //         callback: e => {
              //           const { id } = row;
              //           dispatch(removeLabelsRow({ id }));
              //         }
              //       }
              //     ]}
              //   />
              // }
            />
          )
        }
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }

      <Pagination
        itemPerPage={10}
        totalItems={data.length}
        onSwitchPage={pagination => setCurrentPage(pagination.currentPage)}
      />
      {/* @ts-ignore  */}
      <NotificationPopup
        title="Data has changed"
      >
        Please reload this window to update.
      </NotificationPopup>
    </>
  );
};
