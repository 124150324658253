import React from 'react';
import styles from './PurpleBtnDisabled.module.scss';

export const PurpleBtnDisabled = ({ children, onClick, disabled }) => {
  return (
    <div className={styles.btnBlock}>
      <img
        className={styles.starLeft}
        src='assets/svg/figures/white-little-star.svg'
        alt='star'
      />

      <img
        className={styles.starTop}
        src='assets/svg/figures/white-little-star.svg'
        alt='star'
      />
      <img
        className={styles.starBottom}
        src='assets/svg/figures/white-little-star.svg'
        alt='star'
      />
      <button onClick={onClick} className={styles.btn} type={"button"} disabled={disabled}>{children}</button>
    </div>
  );
};
