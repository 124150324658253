import React, { HTMLAttributes } from "react";
import classNames from "classnames";
import styles from "./ActiveIcon.module.scss";

export default function ActiveIcon(props: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={classNames(styles.icon, props.className)}
      role="img"
      aria-label="Active"
    />
  )
}