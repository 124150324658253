import React, { useState, useEffect, useRef, HTMLProps } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectDataSourceID } from "../../../../store/common-slice";
import { selectGroups, selectTable } from "../../../../store/settingsSlice";
import Group from "./Group/Group";
import ActiveIcon from "../../../../pages/settings/Table/ActiveIcon/ActiveIcon";
import { setDataSourceID } from "../../../../utils/data-source-id";
import styles from "./ActiveContractsViewer.module.scss";

export default function ActiveContractsViewer(props: HTMLProps<HTMLDivElement>) {
  const [searchValue, setSearchValue] = useState('');
  const wrapper = useRef<HTMLDivElement>();
  const input = useRef<HTMLInputElement>();
  
  const table = useSelector(selectTable);
  const [filteredTable, setFilteredTable] = useState(table);
  useEffect(() => {
    setFilteredTable( table.filter(r => r.name.match(searchValue)) );
  }, [table, searchValue]);

  const groups = useSelector(selectGroups);
  const [filteredGroups, setFilteredGroups] = useState(groups);
  useEffect(() => {
    const nextGroups = [];
    for (let g of groups) {
      const ids = table
        .filter(row => g.ids.find(id => id === row.id))
        .filter(r => r.name.match(searchValue))
        .map(r => r.id)
      
      if (ids.length > 0) {
        nextGroups.push({ ...g, ids });
      }
    }
    setFilteredGroups( nextGroups );
  }, [table, groups, searchValue]);

  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      input.current?.focus();
    }
  }, [isOpen]);

  const dataSourceID = useSelector(selectDataSourceID);
  const [activeDataSource, setActiveDataSource] = useState<{ id: number; name: string }>();
  useEffect(() => {
    if (dataSourceID === -1) {
      return;
    }

    const activeDataSource = [...table, ...groups].find(e => e.id === dataSourceID);
    setActiveDataSource(activeDataSource);
  }, [table, groups, dataSourceID]);

  useEffect(() => {
    const handleClick = e => {
      // Клик вне дропдауна
      if (!wrapper.current?.contains(e.target)) {
        setIsOpen(false)
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, []);

  function handleClose(id: number) {
    setIsOpen(false);
    setDataSourceID(id);
  }

  return (
    <div
      {...props}
      ref={wrapper}
      className={classNames(styles.wrapper, props.className)}
    >
      {isOpen
        ?
          <div className={styles.dropdown}>
            <div
              className={classNames(
                styles.searchWrapper,
                (!filteredTable.length && !filteredGroups.length) && 'no-result'
              )}
            >
              <svg width="20" height="20" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.68 12.93a8.125 8.125 0 1 0-1.748 1.748c.037.05.077.097.122.144l4.812 4.812a1.25 1.25 0 0 0 1.77-1.767l-4.813-4.813a1.257 1.257 0 0 0-.144-.124Zm-.202-2.174A6.875 6.875 0 1 0 1.774 5.494a6.875 6.875 0 0 0 12.704 5.262Z" fill="#B9BDC8" />
              </svg>
              <input
                ref={input}
                className={styles.search}
                type="text"
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
              />
            </div>

            <div className={styles.list}>
              {filteredGroups.map((group, index) =>
                <Group
                  key={index}
                  id={group.id}
                  name={group.name}
                  ids={group.ids}
                  onChange={id => {
                    handleClose(id);
                  }}
                />
              )}
              
              {filteredTable.map((row, index) =>
                <div
                  key={index}
                  onClick={e => {
                    handleClose(row.id);
                  }}
                >
                  {row.name}
                  {row.id === dataSourceID &&
                    <img src="/assets/svg/dashboard/check-mark.svg" alt="" aria-label="Active" />
                  }
                </div>
              )}
            </div>
          </div>
        :
          <button
            className={styles.button}
            onClick={e => {
              setIsOpen(true);
            }}
            aria-label={(isOpen ? 'Close' : 'Open') + ' active contracts'}
          >
            <ActiveIcon />
            {activeDataSource?.name ?? 'Name'}
          </button>
      }
    </div>
  )
}