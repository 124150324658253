import React from 'react';

import styles from './Anchor.module.scss';

export const Anchor = ({ id, anchorOffetY = undefined }) => {
  const styleFormed = anchorOffetY !== undefined ? {marginTop: `-${anchorOffetY}px`} : {marginTop: ''};
    return (
    <div className={styles.anchorWrapper}>
        <div id={id} style={styleFormed}  className={styles.anchorBlock}></div>
    </div>
  );
};

