import React from "react";
import classNames from "classnames";
import "./style.scss";

export default function SortButton({ direction = 'NONE', className, onClick }) {
  return (
    <button
      className={classNames('sort-button', direction, className)}
      onClick={onClick}
    >
      <svg width="1em" height="1em" viewBox="0 0 24 24" fill={direction === 'NONE' ? 'currentColor' : '#DC01FF'}>
        <path d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM4 12a1 1 0 0 1 1-1h10a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1ZM5 16a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z" />
      </svg>
    </button>
  )
}