import React from 'react';
import styles from './Understanding.module.scss';
import LottieExample from '../Lottie-emample';

export const Understanding = () => {
  const lottieProps = {
    jsonName: 'lottieJsonuserBehavior',
    width: 630,
    height: 480,
    speed: 1,
  };

  return (
    <div className={styles.wrapper}>
      <img
        className={styles.bgStar}
        src='assets/svg/figures/star.svg'
        alt='Star'
      />
      <div className={styles.info}>
        <span className={styles.title}>
        <span>Understanding</span> <span>user</span> <span>behavior</span>  <i>shouldn’t</i> <i>take</i> weeks 
        </span>
        <span className={styles.desription}>
          Don't waste time on ad-hoc scripts, or complex data infrastructure.
          Empower your team to make data-driven decisions from day one.
        </span>
      </div>
      <div className={styles.scheme}>
        <LottieExample lottieProps={lottieProps} />
      </div>
    </div>
  );
};
