import React from 'react';
import styles from './ButtomRead.module.scss';
const ButtomRead = ({ children }) => {
  return (
    <>
      <button className={styles.btn}>{children}</button>
    </>
  );
};
export default ButtomRead;
