import React, { useState } from 'react';
import Select from 'react-select';

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "auto",
    padding: "0",
    pointerEvents: state.theme.pointerEvents ?? 'all',
    cursor: state.theme.cursor ?? 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontsize: "10px",
    fontweight: "400",
    fontfamily: "arial",
    color: state.theme.color ?? "#1A1D21",
  }),
  control: (provided, state) => {
    return {
      ...provided,
      // none of react-select's styles are passed to <Control />
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: state.theme.height ?? "40px",
      minHeight: "24px",
      backgroundColor: state.theme.backgroundColor ?? "white",
      padding: "0 8px",
      marginTop: state.theme.marginTop ?? "15px",
      border: state.theme.border ?? "0.8px solid #DFE3EC;",
      borderRadius: "8px",

      cursor: "pointer",

      '&:hover': {
        borderColor: "#DFE3EC",
      },

    }
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    height: "auto",
    padding: "0"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    lineHeight: "140%",
  }),
  input: (provided, state) => ({
    ...provided,
    paddingBottom: "0px",
    paddingTop: "0px",
    margin: "0px",
    height: "auto",
    fontSize: "14px"
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "auto",
    margin: "auto",
    marginLeft: ".5em"
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none"
  }),
  option: (provided, state) => {
    return {
      ...provided,
      position: "relative",
      backgroundColor: "none",
      borderRadius: "6px",
      padding: "6px 8px",
      color: "black",
      cursor: state.theme.cursor ?? "pointer",

      '&:hover': {
        background: "#F9FAFC"
      },

      [state.isSelected && '&::before']: {
        content: "''",
        position: "absolute",
        right: "0",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "10px",
        height: "10px",
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none' viewBox='0 0 10 7'%3E%3Cpath stroke='url(%23a)' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M1 3.083 3.947 6 9 1'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='3.705' x2='10.167' y1='-.5' y2='4.484' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23DC01FF'/%3E%3Cstop offset='1' stop-color='%233736FF'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E") no-repeat center / contain`
      }
    }
  },
  menu: (provided, state) => {
    return {
      ...provided,
      marginTop: ".4em",
      padding: "2px 4px",
      border: "0.8px solid #DFE3EC",
      borderRadius: "8px",
    }
  }
}

const optionsDefaultData = [
  '1-5',
  '6-30',
  '31-100',
  'More than 100',
]

const SelectInput = ({
  options = optionsDefaultData,
  placeholder = "Select...",
  defaultValue = "",
  IsOpen = false,
  onChange,
  width = '',
  style = {},
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue || options[0]);
  const convertedOptions = options.map((option) => {
    return { value: option, label: option }
  });
  const handleChange = (newValue, actionMeta) => {
    setValue(newValue.value);
    onChange && onChange(newValue.value);
  };

  return (
    <div style={{ width, ...style }}>
      <Select
        isDisabled={disabled}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#F6F0FE',
            primary: '#b90cff'
          },
          ...Object.assign(style, disabled && {
            backgroundColor: '#F4F6FA',
            color: '#7A7E89',
            border: '0.8px solid #DFE3EC',
            cursor: 'not-allowed'
          })
        })}
        value={{ value, label: value }}
        onChange={handleChange}
        options={convertedOptions}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectInput;