import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectDataSourceID } from '../../store/common-slice';
import useForceUpdate from '../../utils/use-force-update';
import { getDataSourceID } from '../../utils/data-source-id';
import styles from './Navigation.module.scss'

const Navigation = () => {
  const isOnPage = (pageName: string) => window.location.href.includes(pageName);

  const dataSourceID = useSelector(selectDataSourceID);
  const forceUpdate = useForceUpdate();
  useEffect(forceUpdate, [dataSourceID]);

  return (
    <div className={styles.navigation}>
      <nav>
        <ul className={styles.menuList}>

          <li
            className={classNames(isOnPage('dashboard') && styles.activeMenu)}
          >
            {isOnPage('dashboard')
              ?
              <svg width="14" height="13" fill="none">
                <path d="M8.334 3c0-.31 0-.465.014-.595A2.333 2.333 0 0 1 10.406.348c.13-.015.285-.015.594-.015.31 0 .464 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.014-.13-.014-.285-.014-.594ZM8.334 10.333c0-.31 0-.465.014-.595a2.333 2.333 0 0 1 2.058-2.057c.13-.015.285-.015.594-.015.31 0 .464 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.014-.13-.014-.285-.014-.594ZM.333 3c0-.31 0-.465.015-.595A2.333 2.333 0 0 1 2.406.348C2.536.333 2.69.333 3 .333c.31 0 .465 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014A2.333 2.333 0 0 1 .348 3.594C.333 3.464.333 3.31.333 3ZM.333 10.333c0-.31 0-.465.015-.595a2.333 2.333 0 0 1 2.058-2.057c.13-.015.285-.015.594-.015.31 0 .465 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.015-.13-.015-.285-.015-.594Z" fill="#DC01FF" />
              </svg>
              :
              <svg width="14" height="13" fill="none">
                <path d="M8.334 3c0-.31 0-.465.014-.595A2.333 2.333 0 0 1 10.406.348c.13-.015.285-.015.594-.015.31 0 .464 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.014-.13-.014-.285-.014-.594ZM8.334 10.333c0-.31 0-.465.014-.595a2.333 2.333 0 0 1 2.058-2.057c.13-.015.285-.015.594-.015.31 0 .464 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.014-.13-.014-.285-.014-.594ZM.333 3c0-.31 0-.465.015-.595A2.333 2.333 0 0 1 2.406.348C2.536.333 2.69.333 3 .333c.31 0 .465 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014A2.333 2.333 0 0 1 .348 3.594C.333 3.464.333 3.31.333 3ZM.333 10.333c0-.31 0-.465.015-.595a2.333 2.333 0 0 1 2.058-2.057c.13-.015.285-.015.594-.015.31 0 .465 0 .595.015a2.333 2.333 0 0 1 2.057 2.057c.015.13.015.285.015.595s0 .464-.015.594a2.333 2.333 0 0 1-2.057 2.058c-.13.014-.285.014-.595.014s-.464 0-.594-.014a2.333 2.333 0 0 1-2.058-2.058c-.015-.13-.015-.285-.015-.594Z" fill="#7A7E89" />
              </svg>
            }

            <a href={`/dashboard?data_source_id=${getDataSourceID()}`}>
              Insights
            </a>
          </li>

          <li
            className={classNames(isOnPage('wallet-analysis') && styles.activeMenu)}
          >
            {isOnPage('wallet-analysis')
              ?
              <svg width="14" height="14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="m.332 7 .001-.867h6.399v7.534c-2.316-.001-3.505-.02-4.4-.536a4 4 0 0 1-1.464-1.464C.332 10.739.332 9.492.332 7Zm7.333 6.666c2.056-.004 3.159-.05 4-.535a4 4 0 0 0 1.465-1.464c.535-.928.535-2.175.535-4.667s0-3.738-.535-4.667A4 4 0 0 0 11.665.87c-.841-.486-1.944-.53-4-.535v13.332ZM6.732 5.2V.333c-2.316.001-3.505.02-4.4.536A4 4 0 0 0 .868 2.333C.48 3.005.373 3.843.343 5.2h6.389ZM6.934.333h.129-.129Z" fill="#DC01FF" />
              </svg>
              :
              <svg width="14" height="14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="m.332 7 .001-.867h6.399v7.534c-2.316 0-3.505-.02-4.4-.536a4 4 0 0 1-1.464-1.464C.332 10.739.332 9.492.332 7Zm7.333 6.666c2.056-.004 3.159-.05 4-.535a4 4 0 0 0 1.465-1.464c.535-.928.535-2.175.535-4.667s0-3.738-.535-4.667A4 4 0 0 0 11.665.87c-.841-.485-1.944-.53-4-.535v13.332ZM6.732 5.2V.334c-2.316 0-3.505.019-4.4.535A4 4 0 0 0 .868 2.334C.48 3.005.373 3.843.343 5.2h6.389ZM6.934.333h.129-.129Z" fill="#7A7E89" />
              </svg>
            }

            <a href={`/wallet-analysis?data_source_id=${getDataSourceID()}`}>
              Wallet Explorer
            </a>
          </li>

          <li
            className={classNames(isOnPage('deep-dive') && styles.activeMenu)}
          >
            {isOnPage('deep-dive')
              ?
              <svg width="14" height="14" fill="none">
                <path d="M4.969 1.5c0-.828.668-1.5 1.49-1.5h.994c.823 0 1.491.672 1.491 1.5v11c0 .828-.668 1.5-1.49 1.5H6.46c-.823 0-1.491-.672-1.491-1.5v-11ZM0 7.5C0 6.672.667 6 1.49 6h.994c.823 0 1.491.672 1.491 1.5v5c0 .828-.668 1.5-1.49 1.5H1.49C.667 14 0 13.328 0 12.5v-5ZM12.422 2c.823 0 1.491.672 1.491 1.5v9c0 .828-.668 1.5-1.49 1.5h-.994c-.823 0-1.491-.672-1.491-1.5v-9c0-.828.668-1.5 1.49-1.5h.994Z" fill="#DC01FF" />
              </svg>
              :
              <svg width="14" height="14" fill="none">
                <path d="M4.969 1.5c0-.828.668-1.5 1.49-1.5h.994c.823 0 1.491.672 1.491 1.5v11c0 .828-.668 1.5-1.49 1.5H6.46c-.823 0-1.491-.672-1.491-1.5v-11ZM0 7.5C0 6.672.667 6 1.49 6h.994c.823 0 1.491.672 1.491 1.5v5c0 .828-.668 1.5-1.49 1.5H1.49C.667 14 0 13.328 0 12.5v-5ZM12.422 2c.823 0 1.491.672 1.491 1.5v9c0 .828-.668 1.5-1.49 1.5h-.994c-.823 0-1.491-.672-1.491-1.5v-9c0-.828.668-1.5 1.49-1.5h.994Z" fill="#7A7E89" />
              </svg>
            }

            <a href={`/deep-dive?data_source_id=${getDataSourceID()}`}>
              Custom view
            </a>
          </li>

          <li
            className={classNames(isOnPage('web-events') && styles.activeMenu)}
          >
            {isOnPage('web-events')
              ?
              <svg width="16" height="16" fill="none">
                <g opacity="1" fillRule="evenodd" clipRule="evenodd" fill="#dc01ff">
                  <path d="M2.595 9.99c0-.377.306-.682.683-.682h9.624a.683.683 0 0 1 0 1.365H3.278a.683.683 0 0 1-.683-.682Z" />
                  <path d="M9.286 13.534c.259.274.691.286.965.027l3.252-3.075a.683.683 0 1 0-.938-.992l-3.252 3.074a.683.683 0 0 0-.027.966ZM13.392 5.999a.683.683 0 0 1-.683.683H2.303a.683.683 0 1 1 0-1.366h10.406c.377 0 .683.306.683.683Z" />
                  <path d="M1.81 6.482c.267.267.7.267.966 0l2.936-2.937a.683.683 0 1 0-.965-.965L1.81 5.516a.683.683 0 0 0 0 .966Z" />
                </g>
              </svg>
              :
              <svg width="16" height="16" fill="none">
                <g opacity="1" fillRule="evenodd" clipRule="evenodd" fill="#7A7E89">
                  <path d="M2.595 9.99c0-.377.306-.682.683-.682h9.624a.683.683 0 0 1 0 1.365H3.278a.683.683 0 0 1-.683-.682Z" />
                  <path d="M9.286 13.534c.259.274.691.286.965.027l3.252-3.075a.683.683 0 1 0-.938-.992l-3.252 3.074a.683.683 0 0 0-.027.966ZM13.392 5.999a.683.683 0 0 1-.683.683H2.303a.683.683 0 1 1 0-1.366h10.406c.377 0 .683.306.683.683Z" />
                  <path d="M1.81 6.482c.267.267.7.267.966 0l2.936-2.937a.683.683 0 1 0-.965-.965L1.81 5.516a.683.683 0 0 0 0 .966Z" />
                </g>
              </svg>
            }
            
            <a href={`/web-events?data_source_id=${getDataSourceID()}`}>
              Web Events
            </a>
          </li>

          <li
            className={classNames(isOnPage('user-journey') && styles.activeMenu)}
          >
            {isOnPage('user-journey')
              ?
              <svg width="16" height="16" fill="none">
                <path d="M8.967 1.794 5.259.497a1.48 1.48 0 0 0-.964-.008l-2.958.994C.736 1.691.328 2.254.328 2.892v8.787a1.478 1.478 0 0 0 2.017 1.38l2.173-.846a.716.716 0 0 1 .511-.008l3.708 1.298c.311.111.653.111.964.008l2.959-.994c.6-.2 1.008-.771 1.008-1.41V2.322A1.478 1.478 0 0 0 11.651.94l-2.172.846a.757.757 0 0 1-.512.007Zm.26 10.315-4.45-1.565V1.891l4.45 1.564v8.654Z" fill="#DC01FF" />
              </svg>
              :
              <svg width="16" height="16" fill="none">
                <path d="M9.967 2.794 6.259 1.497a1.48 1.48 0 0 0-.964-.008l-2.958.994c-.601.208-1.009.771-1.009 1.409v8.787a1.478 1.478 0 0 0 2.017 1.38l2.173-.846a.716.716 0 0 1 .511-.008l3.708 1.298c.311.111.652.111.964.008l2.959-.994c.6-.2 1.008-.771 1.008-1.41V3.322a1.478 1.478 0 0 0-2.017-1.38l-2.172.846a.757.757 0 0 1-.512.007Zm.26 10.315-4.45-1.565V2.891l4.45 1.564v8.654Z" fill="#7A7E89" />
              </svg>
            }
            
            <a href={`/user-journey?data_source_id=${getDataSourceID()}`}>
              User Journey
            </a>
          </li>

          <li
            className={classNames(isOnPage('notifications-recipes') && styles.activeMenu, styles.disabled)}
          >
            {isOnPage('notifications-recipes')
              ?
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M6 12.667c3.583 0 6-.732 6-2.56s-.667-1.828-1.2-2.193c0-2.181-.8-6.58-4.8-6.58s-4.8 4.399-4.8 6.58C.667 8.279 0 8.76 0 10.107c0 2.194 2.418 2.56 6 2.56Z" fill="#DC01FF"/><path d="M6 14.667c.783 0 1.271-.294 1.57-.606.3-.313.01-.728-.423-.728H4.854c-.433 0-.722.415-.422.728.298.312.786.606 1.569.606Z" fill="#DC01FF"/><path fillRule="evenodd" clipRule="evenodd" d="M5.998.2c.258 0 .467.209.467.467v.666a.467.467 0 1 1-.933 0V.667c0-.258.208-.467.466-.467Z" fill="#DC01FF"/></svg>
              :
              <svg width="16" height="16" viewBox="0 0 16 16" fill="#7A7E89"><path fillRule="evenodd" clipRule="evenodd" d="M8 12.667c3.583 0 6-.732 6-2.56s-.667-1.828-1.2-2.193c0-2.181-.8-6.58-4.8-6.58s-4.8 4.399-4.8 6.58c-.533.365-1.2.847-1.2 2.193 0 2.194 2.418 2.56 6 2.56Z"/><path d="M8 14.667c.783 0 1.271-.294 1.57-.606.3-.313.01-.728-.423-.728H6.854c-.433 0-.722.415-.422.728.298.312.786.606 1.569.606Z"/><path fillRule="evenodd" clipRule="evenodd" d="M8 .2c.258 0 .467.209.467.467v.666a.467.467 0 1 1-.934 0V.667C7.533.409 7.743.2 8 .2Z"/></svg>

            }

            <button disabled>
              Notifications recipes
            </button>
          </li>

          <li
            className={classNames(isOnPage('partnerships') && styles.activeMenu, styles.disabled)}
          >
            {isOnPage('partnerships')
              ?
                <svg width="16" height="12" fill="#DC01FF">
                  <path d="M3.848 9.46c.024-.338.036-.507.063-.627.167-.756.742-1.25 1.515-1.303.121-.008.312.007.692.037.564.045 1.177.078 1.757.078.645 0 1.366-.041 2.023-.093.372-.03.558-.045.685-.036a1.62 1.62 0 0 1 1.503 1.292c.028.124.04.294.064.634.028.384.042.575.028.734a2 2 0 0 1-1.676 1.8c-.157.024-.35.024-.733.024H6.213c-.367 0-.551 0-.702-.023a2 2 0 0 1-1.69-1.815c-.012-.152 0-.335.027-.702ZM5.668 3a2.333 2.333 0 1 1 4.667 0v.334a2 2 0 0 1-2 2h-.667a2 2 0 0 1-2-2V3Z" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M2.577 7.652a.467.467 0 0 0-.59-.295l.148.443a38.041 38.041 0 0 0-.148-.443h-.002l-.003.002-.006.002a.831.831 0 0 0-.068.027 2.03 2.03 0 0 0-.603.415c-.332.332-.637.865-.637 1.663 0 .799.305 1.332.637 1.664a2.032 2.032 0 0 0 .603.415 1.165 1.165 0 0 0 .068.027l.006.002.003.001h.001l.149-.442-.148.443a.467.467 0 1 0 .289-.889 1.101 1.101 0 0 1-.31-.218c-.17-.168-.365-.468-.365-1.003 0-.534.195-.835.364-1.003a1.098 1.098 0 0 1 .323-.222.467.467 0 0 0 .29-.589ZM13.36 7.652a.467.467 0 0 1 .59-.295l-.147.443.148-.443h.002l.002.002.007.002a1.125 1.125 0 0 1 .068.027 2.031 2.031 0 0 1 .603.415c.331.332.636.865.636 1.663 0 .799-.305 1.332-.636 1.664a2.033 2.033 0 0 1-.603.415 1.172 1.172 0 0 1-.068.027l-.007.002-.002.001h-.002l-.148-.442.147.443a.467.467 0 1 1-.288-.889 1.098 1.098 0 0 0 .31-.218c.17-.168.364-.468.364-1.003 0-.534-.195-.835-.363-1.003a1.095 1.095 0 0 0-.324-.222.467.467 0 0 1-.289-.589ZM11.346 1.05a.467.467 0 0 1 .506-.425l-.04.465.04-.465h.004l.007.002.02.002.06.008a2.453 2.453 0 0 1 .796.278c.462.26.953.752 1.056 1.62.102.866-.26 1.46-.65 1.82a2.451 2.451 0 0 1-.766.478l-.018.007-.006.002h-.003v.001h-.001l-.148-.442.147.442a.467.467 0 0 1-.298-.884l.003-.001a1.335 1.335 0 0 0 .13-.06c.093-.048.213-.123.326-.228.216-.2.417-.513.357-1.026-.061-.514-.33-.77-.587-.915a1.522 1.522 0 0 0-.51-.174h-.003a.467.467 0 0 1-.422-.505Z" />
                  <path d="M4.08.583c.257-.027.38.151.359.484-.021.332-.309.447-.309.447a1.384 1.384 0 0 0-.463.178c-.238.147-.496.414-.531.962-.035.548.188.846.406 1.022a1.384 1.384 0 0 0 .46.242h.003a.467.467 0 0 1-.215.909l-.03-.008a2.143 2.143 0 0 1-.249-.087 2.317 2.317 0 0 1-.556-.33c-.417-.338-.807-.914-.75-1.807.056-.893.516-1.415.972-1.697a2.317 2.317 0 0 1 .79-.303l.09-.014.023.002Z" />
                </svg>
              :
              <svg width="16" height="12" fill="#7A7E89">
                <path d="M3.848 9.46c.024-.338.036-.507.063-.627.167-.756.742-1.25 1.515-1.303.121-.008.312.007.692.037.564.045 1.177.078 1.757.078.645 0 1.366-.041 2.023-.093.372-.03.558-.045.685-.036a1.62 1.62 0 0 1 1.503 1.292c.028.124.04.294.064.634.028.384.042.575.028.734a2 2 0 0 1-1.676 1.8c-.157.024-.35.024-.733.024H6.213c-.367 0-.551 0-.702-.023a2 2 0 0 1-1.69-1.815c-.012-.152 0-.335.027-.702ZM5.668 3a2.333 2.333 0 1 1 4.667 0v.334a2 2 0 0 1-2 2h-.667a2 2 0 0 1-2-2V3Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.577 7.652a.467.467 0 0 0-.59-.295l.148.443a38.041 38.041 0 0 0-.148-.443h-.002l-.003.002-.006.002a.831.831 0 0 0-.068.027 2.03 2.03 0 0 0-.603.415c-.332.332-.637.865-.637 1.663 0 .799.305 1.332.637 1.664a2.032 2.032 0 0 0 .603.415 1.165 1.165 0 0 0 .068.027l.006.002.003.001h.001l.149-.442-.148.443a.467.467 0 1 0 .289-.889 1.101 1.101 0 0 1-.31-.218c-.17-.168-.365-.468-.365-1.003 0-.534.195-.835.364-1.003a1.098 1.098 0 0 1 .323-.222.467.467 0 0 0 .29-.589ZM13.36 7.652a.467.467 0 0 1 .59-.295l-.147.443.148-.443h.002l.002.002.007.002a1.125 1.125 0 0 1 .068.027 2.031 2.031 0 0 1 .603.415c.331.332.636.865.636 1.663 0 .799-.305 1.332-.636 1.664a2.033 2.033 0 0 1-.603.415 1.172 1.172 0 0 1-.068.027l-.007.002-.002.001h-.002l-.148-.442.147.443a.467.467 0 1 1-.288-.889 1.098 1.098 0 0 0 .31-.218c.17-.168.364-.468.364-1.003 0-.534-.195-.835-.363-1.003a1.095 1.095 0 0 0-.324-.222.467.467 0 0 1-.289-.589ZM11.346 1.05a.467.467 0 0 1 .506-.425l-.04.465.04-.465h.004l.007.002.02.002.06.008a2.453 2.453 0 0 1 .796.278c.462.26.953.752 1.056 1.62.102.866-.26 1.46-.65 1.82a2.451 2.451 0 0 1-.766.478l-.018.007-.006.002h-.003v.001h-.001l-.148-.442.147.442a.467.467 0 0 1-.298-.884l.003-.001a1.335 1.335 0 0 0 .13-.06c.093-.048.213-.123.326-.228.216-.2.417-.513.357-1.026-.061-.514-.33-.77-.587-.915a1.522 1.522 0 0 0-.51-.174h-.003a.467.467 0 0 1-.422-.505Z" />
                <path d="M4.08.583c.257-.027.38.151.359.484-.021.332-.309.447-.309.447a1.384 1.384 0 0 0-.463.178c-.238.147-.496.414-.531.962-.035.548.188.846.406 1.022a1.384 1.384 0 0 0 .46.242h.003a.467.467 0 0 1-.215.909l-.03-.008a2.143 2.143 0 0 1-.249-.087 2.317 2.317 0 0 1-.556-.33c-.417-.338-.807-.914-.75-1.807.056-.893.516-1.415.972-1.697a2.317 2.317 0 0 1 .79-.303l.09-.014.023.002Z" />
              </svg>
            }

            <button disabled>
              Partnerships
            </button>
          </li>

          <li
            className={classNames(isOnPage('integrations') && styles.activeMenu)}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g opacity="0.5">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.33333 7.99967C1.33333 5.50737 1.33333 4.26121 1.86923 3.33301C2.2203 2.72493 2.72525 2.21998 3.33333 1.86891C4.26153 1.33301 5.50769 1.33301 8 1.33301C10.4923 1.33301 11.7385 1.33301 12.6667 1.86891C13.2747 2.21998 13.7797 2.72493 14.1308 3.33301C14.6667 4.26121 14.6667 5.50737 14.6667 7.99967C14.6667 10.492 14.6667 11.7381 14.1308 12.6663C13.7797 13.2744 13.2747 13.7794 12.6667 14.1304C11.7385 14.6663 10.4923 14.6663 8 14.6663C5.50769 14.6663 4.26153 14.6663 3.33333 14.1304C2.72525 13.7794 2.2203 13.2744 1.86923 12.6663C1.33333 11.7381 1.33333 10.492 1.33333 7.99967ZM5.00334 6.33639C5.18558 6.15415 5.48106 6.15415 5.6633 6.33639L6.99664 7.66972C7.17888 7.85197 7.17888 8.14745 6.99664 8.32969L5.6633 9.66302C5.48106 9.84527 5.18558 9.84527 5.00334 9.66302C4.82109 9.48078 4.82109 9.1853 5.00334 9.00306L6.00669 7.99971L5.00334 6.99636C4.82109 6.81411 4.82109 6.51864 5.00334 6.33639ZM11.3333 9.79964C11.5911 9.79964 11.8 9.59071 11.8 9.33298C11.8 9.07524 11.5911 8.86631 11.3333 8.86631H8.66667C8.40894 8.86631 8.2 9.07524 8.2 9.33298C8.2 9.59071 8.40894 9.79964 8.66667 9.79964H11.3333Z" fill="#B9BDC8" />
              </g>
            </svg>

            <button
              disabled={true}
              onClick={() => {
                window.location.href = `/integrations`
              }}
            >
              Integrations
            </button>
          </li>

          <li
            className={classNames(isOnPage('settings') && styles.activeMenu)}
          >
            {isOnPage('settings')
              ?
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.13931 2.09527C9.03831 1.23139 8.55884 0.333252 7.00161 0.333252C5.56551 0.333252 5.04603 1.09709 4.89467 1.89347C4.81489 2.3133 3.94194 2.80333 3.53957 2.65942C2.80726 2.3975 1.92963 2.46726 1.2281 3.66659C0.409001 5.06691 0.939202 5.91573 1.62748 6.40611C1.96154 6.64413 2.0133 7.47884 1.70386 7.74809C1.08309 8.28823 0.598197 9.1349 1.2281 10.3333C1.95682 11.5139 2.9142 11.4998 3.68228 11.1976C4.069 11.0455 4.81564 11.4918 4.8639 11.9046C4.96491 12.7684 5.44437 13.6666 7.00161 13.6666C8.55884 13.6666 9.03831 12.7684 9.13931 11.9046C9.18757 11.4918 9.93421 11.0455 10.3209 11.1976C11.089 11.4998 12.0464 11.5139 12.7751 10.3333C13.4847 8.98335 12.7796 8.07971 12.0593 7.55779C11.748 7.33225 11.748 6.66759 12.0593 6.44205C12.7796 5.92013 13.4847 5.01649 12.7751 3.66659C12.0464 2.48594 11.089 2.50009 10.3209 2.80219C9.93421 2.9543 9.18757 2.50801 9.13931 2.09527ZM8.06829 6.99992C8.06829 7.58902 7.59073 8.06659 7.00163 8.06659C6.41252 8.06659 5.93496 7.58902 5.93496 6.99992C5.93496 6.41082 6.41252 5.93325 7.00163 5.93325C7.59073 5.93325 8.06829 6.41082 8.06829 6.99992ZM9.00163 6.99992C9.00163 8.10449 8.1062 8.99992 7.00163 8.99992C5.89706 8.99992 5.00163 8.10449 5.00163 6.99992C5.00163 5.89535 5.89706 4.99992 7.00163 4.99992C8.1062 4.99992 9.00163 5.89535 9.00163 6.99992Z" fill="url(#paint0_linear_2527_4082)" />
                <defs>
                  <linearGradient id="paint0_linear_2527_4082" x1="4.9566" y1="-3.66675" x2="18.1563" y2="2.18243" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#DC01FF" />
                    <stop offset="1" stopColor="#3736FF" />
                  </linearGradient>
                </defs>
              </svg>
              :
              <svg width="14" height="14" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.14 2.095C9.037 1.231 8.558.333 7.001.333c-1.436 0-1.956.764-2.107 1.56-.08.42-.953.91-1.355.766-.733-.261-1.61-.192-2.312 1.008-.819 1.4-.289 2.249.4 2.74.334.237.385 1.072.076 1.341-.62.54-1.106 1.387-.476 2.585.729 1.18 1.686 1.167 2.454.865.387-.152 1.134.294 1.182.707.1.863.58 1.762 2.138 1.762 1.557 0 2.036-.899 2.137-1.762.049-.413.795-.86 1.182-.707.768.302 1.725.316 2.454-.865.71-1.35.005-2.253-.716-2.775-.311-.226-.311-.89 0-1.116.72-.522 1.426-1.426.716-2.775-.729-1.181-1.686-1.167-2.454-.865-.387.152-1.133-.294-1.182-.707ZM8.067 7a1.067 1.067 0 1 1-2.133 0 1.067 1.067 0 0 1 2.133 0Zm.934 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" fill="#7A7E89" />
              </svg>
            }

            <a href={`/settings?data_source_id=${getDataSourceID()}`}>
              Settings
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation;