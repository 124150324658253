import axios, { AxiosRequestConfig } from "axios";
import { root } from ".";
import { getSession } from "../utils/session";
import { getToken } from "../utils/token";
import utcOffset from "../utils/utc-offset";

export type Filters = {
  tenant_name?:      string;
  api_key?:          string;
  to?:               number;
  from?:             number;
  event_name?:       string;
  event_type?:       string;
  browser_type?:     string;
  wallet_address?:   string;
  environment_type?: string;
  app_version?:      string;
  country?:          string;
  /** Number of visible items per page */
  itemsPerPage?:     number;
  /** Current page (starting from `1`) */
  currentPage?:      number;
};

export type Event = {
  app_version:          string;
  auto_track_timestamp: number;
  browser_type:         string;
  vountry:              string;
  device_type:          string;
  event_name:           string;
  event_properties:     {};
  event_type:           string;
  id:                   number;
  insert_id:            string;
  language:             string;
  os_name:              string;
  os_version:           string;
  referrer:             string;
  timestamp:            string;
  url:                  string;
  user_id:              string;
  user_properties:      {}
  wallet_address:       string;
};

export const fetchEvents = async (filters?: Filters): Promise<{
  /** Requested events */
  events: Event[];
  /** Total number of elements */
  total:  number;
}> => {
  const { itemsPerPage, currentPage } = filters;
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_events/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      ...filters,
      from: filters.from - utcOffset,
      to:   filters.to   - utcOffset,
      count: itemsPerPage,
      page:  currentPage
    }
  };

  try {
    const response = await axios.request(options);
    type Response = {
      body:      Event[];
      message:   string;
      /** Current page */
      page:      number;
      /** Total number of elements */
      all_count: number;
    };
    const data: Response = response.data;
    return {
      events: data.body,
      total:  data.all_count
    };
  }
  catch (error) {
    console.log('/event/get_events/ failed: ', error);
    throw error;
  }
};

export const fetchUniqueEvents = async (event: string): Promise<string[]> => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_unic_events_field/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      field: event
    }
  };

  try {
    const { data } = await axios.request(options);
    const values = data?.event_value;
    if (values && Array.isArray(values)) {
      return values.filter(Boolean);
    }
    return data;
  }
  catch (error) {
    console.log('/event/get_unic_events_field/ failed: ', error);
    throw error;
  }
};

export const fetchUsersCountForPeriod = async (params: {
  events:  string[];
  cohorts: string[];
  from:    number;
  to:      number;
  period?: number;
}): Promise<{ days: '-' | number; count: number }[]> => {
  const { events, cohorts, from, to, period } = params;

  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_count_step_days/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      event_list:  events.join('|'),
      cohorts:    cohorts.join('|') || undefined,
      date_from:  from - utcOffset,
      date_to:    to   - utcOffset,
      period
    }
  };

  try {
    const { data } = await axios.request(options);
    return data?.count_days;
  }
  catch (error) {
    console.log('/event/get_count_step_days/ failed: ', error);
    throw error;
  }
};

export const fetchUsersCountForEvents = async (params: {
  events:  string[];
  cohorts: string[];
  from:    number;
  to:      number;
  period?: number;
}): Promise<{ name: string, count: number }[]> => {
  const { events, cohorts, from, to, period } = params;

  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_count_step/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      event_list:  events.join('|'),
      cohorts:    cohorts.join('|') || undefined,
      date_from:  from - utcOffset,
      date_to:    to   - utcOffset,
      period
    }
  };

  try {
    const { data } = await axios.request(options);
    return data?.step;
  }
  catch (error) {
    console.log('/event/get_count_step/ failed: ', error);
    throw error;
  }
};

export const fetchWalletsForEvent = async (params: {
  events:  string[];
  cohorts: string[];
  from:    number;
  to:      number;
  period?: number;
  step:    number;
}): Promise<{ name: string, count: number }[]> => {
  const { events, cohorts, from, to, period, step } = params;

  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_count_step/list/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      event_list:  events.join('|'),
      cohorts:    cohorts.join('|') || undefined,
      date_from:  from - utcOffset,
      date_to:    to   - utcOffset,
      period,
      step
    }
  };

  try {
    const { data } = await axios.request(options);
    return data?.wallet;
  }
  catch (error) {
    console.log('/event/get_count_step/list/ failed: ', error);
    throw error;
  }
};

export const downloadEvents = async (filters: Filters): Promise<{
  message: string;
  url:     string;
}> => {
  const options: AxiosRequestConfig = {
    method: 'POST',
    url: `${root}/event/get_events/download/`,
    headers: {
      Accept: 'application/json',
      Authorization: `Token ${getToken()}`
    },
    data: {
      session: getSession(),
      ...filters
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  }
  catch (error) {
    console.log('/event/get_events/download/ failed: ', error);
    throw error;
  }
};