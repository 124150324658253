import React, { HTMLAttributes, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEmail, selectLastName, selectName } from '../../../store/userSlice';
import classNames from 'classnames';
import { changePassword, login } from '../../../api';
import { Button, TextInput } from '../../../components/UI';

import styles from './Account.module.scss';

export default function Account(props: HTMLAttributes<HTMLDivElement>) {
  const name = useSelector(selectName);
  const lastName = useSelector(selectLastName);
  const email = useSelector(selectEmail);
  const avatar = null;

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');

  const [isPasswordChanged, setIsPasswordChanged] = useState(false);

  function validate() {
    if (!password1 || !password2) {
      return 'Fields cannot be empty';
    }
    else if (password1 !== password2) {
      return 'Passwords do not match';
    }
  }

  return (
    <div
      {...props}
      className={classNames(styles.accountWrapper, props.className)}
    >
      {!isPasswordChanged &&
        <div className={styles.userInfo}>
          <img
            src={avatar ?? './assets/svg/dashboard/avatar-placegolder.svg'}
            alt={name + ' photo'}
          />

          <div className={styles.textInfo}>
            <h1>
              {(!name && !lastName)
                ? localStorage.getItem('username') ?? 'username'
                :
                (name && lastName)
                  ? name + " " + lastName
                  :
                  name || lastName
              }
            </h1>
            <h2>{email}</h2>
          </div>
        </div>
      }

      <div className={styles.passwordChangeWrapper}>
        <h3>Reset password</h3>

        {isPasswordChanged ?
          <div className={styles.isChanged}>
            <p>Password changed!</p>
            <svg width="20" height="20" fill="none">
              <path d="m10 0 .624 7.548a2 2 0 0 0 1.828 1.828L20 10l-7.548.624a2 2 0 0 0-1.828 1.828L10 20l-.624-7.548a2 2 0 0 0-1.828-1.828L0 10l7.548-.624a2 2 0 0 0 1.828-1.828L10 0Z" fill="url(#a)" />
              <defs>
                <linearGradient id="a" x1="6.762" y1="-6" x2="27.671" y2="4.079" gradientUnits="userSpaceOnUse">
                  <stop stopColor="#DC01FF" />
                  <stop offset="1" stopColor="#3736FF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          :
          <>
            <div className={styles.inputWrapper}>
              {/* @ts-ignore */}
              <TextInput
                inputType="password"
                placeholder="New password"
                value={password1}
                onChange={setPassword1}
                status={error ? 'error' : ''}
              />
              <div className={styles.inputAndExtraText}>
                {/* @ts-ignore */}
                <TextInput
                  inputType="password"
                  placeholder="Repeat password"
                  value={password2}
                  onChange={setPassword2}
                  status={error ? 'error' : ''}
                />
                <small>Minimum 6 characters</small>
              </div>
              {error &&
                <p className={styles.error}>{error}</p>
              }
            </div>
            <Button
              className={styles.button}
              size='default'
              onClick={async () => {
                const error = validate();
                setError(error);
                if (error) {
                  return;
                }

                setIsPasswordChanged(true);
                await changePassword(password2);

                const username = localStorage.getItem('username');
                await login(username, password1);
              }}
            >
              Change
            </Button>
          </>
        }
      </div>
    </div>
  );
};