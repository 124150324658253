import React from 'react';
import { getToken } from '../../utils/token';
import { Main } from '../../components/Dashboard/Main/Main';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import redirectToAuth from '../../utils/redirect-to-auth';
import useNeedToReload from '../../utils/use-need-to-reload';
import NotificationPopup from '../../components/NotificationPopup/NotificationPopup';
import { readMessage } from '../../api/message';

import styles from './Dashboard.module.scss';

export const Dashboard = () => {
  const needToReload = useNeedToReload();

  if (!getToken()) {
    redirectToAuth();
    return;
  }

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <Main />
      </div>

      {needToReload &&
        // @ts-ignore
        <NotificationPopup
          title="Data has changed"
          visible={true}
          onClose={readMessage}
        >
          Please reload this window to update.
        </NotificationPopup>
      }
    </div>
  );
};
