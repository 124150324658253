import React from 'react';
import styles from './GetStarted.module.scss';
import { InfoBlock } from './InfoBlock/InfoBlock';
import { GetStartedTitle } from './GetStartedTitle/GetStartedTitle';

const GetStarted = (props) => {

  const Title = () => {
    if(props.isTitleShow) {
      return <GetStartedTitle />;
    }
    
  }
  return (
    <div className={styles.wrapper}>
      {/* <img
        className={styles.bgImage}
        src='assets/svg/figures/graph-bg.svg'
        alt='BackgroundImg'
      /> */}
      <Title />
     
      <div className={styles.infoBlocks}>
        <InfoBlock
          img='node-search.svg'
          title='No-code Setup'
          subtitle='Index on-chain data, create relevant schemas, and measure what matters without writing a single line of code.'
          jsonName={'lottieJsonNodeSearch'}
          widthLottie={220}
          heightLottie={43}
          speed={1}
          classNameImg={styles.nodeSearch}
        />
        <InfoBlock
          img='link-session-data.svg'
          title='Link Session Data'
          subtitle='Use our secure SDK to link wallets with encrypted session data to capture end-to-end user lifecycle.'
          jsonName={'lottieJsonLinkSessionData'}
          widthLottie={320}
          heightLottie={80}
          speed={2}
          classNameImg={styles.linkSessionВeta}
        />
        <InfoBlock
          img='infinite-integrations.svg'
          title='Infinite Integrations'
          subtitle={`User behaviour can't be measured in silos. Bring together on-chain and off-chain data points to create one holistic view.`}
          jsonName={'lottieJsonInfiniteIntegrations'}
          widthLottie={247}
          heightLottie={209}
          speed={1}
          classNameImg={styles.infiniteIntegrations}
        />
      </div>
    </div>
  );
};

GetStarted.defaultProps = {
  isTitleShow: true
}

export { GetStarted };
