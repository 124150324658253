export function getSearchParam(param: string) {
  const { search } = window.location;
  return new URLSearchParams(search).get(param);
}

export function setSearchParam(param: string, value: string) {
  const { location, history } = window;
  const url = new URL(location.href);
  url.searchParams.set(param, value);
  history.pushState(null, null, url);
}