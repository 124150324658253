import { useEffect, useState } from "react";
import { fetchMessage } from "../api/message";

export default function useNeedToReload() {
  const [needToReload, setNeedToReload] = useState(false);
  useEffect(() => {
    setInterval(async () => {
      const response = await fetchMessage();
      setNeedToReload(response.has_message);
    }, 5_000);

    (async () => {
      const response = await fetchMessage();
      setNeedToReload(response.has_message);
    })();
  }, []);

  return needToReload;
}