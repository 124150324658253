import React, { useState } from "react";

import Radio from  "../Radio/Radio"

function RadioGroup(props) {
  const [value, setValue] = useState("Male");

  function onChangeValue(event) {
    setValue(event.target.value);
    console.log(event.target.value);
    props.onChange(event.target.value);
  }

  return (
      
    <div onChange={onChangeValue}>
        {props.children}
       
    </div>
  );
}

export default RadioGroup;