import React from 'react';
import classNames from 'classnames';
import styles from './LabelBlock.module.scss';

interface LabelBlockProps {
  /**
   * @deprecated Use React `children` instead:
   * ```jsx
   * <LabelBlock>Text</LabelBlock>
   * ```
   */
  text?:      string;
  color?:     string;
  textColor?: string;
  /** If `true`, the content will not be cropped */
  fluid?:     boolean;
  className?: string;
  children?:     any;
  style?: React.CSSProperties;
}

export const LabelBlock = ({
  text,
  color = '#191b1f',
  textColor = 'white',
  fluid,
  className,
  style,
  children
}: LabelBlockProps) => {
  return (
    <span
      className={classNames(styles.labelBlock, fluid && styles.fluid, className)}
      title={text ?? children}
      style={{ ...style, backgroundColor: color, color: textColor }}
    >
      {text ?? children}
    </span>
  );
};