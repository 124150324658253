import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import styles from './DateInput.module.scss';

interface DateInputProps {
  /**
   * Принимает в себя дату в формате строки
   * 
   * миниму 8 чисел (число, месяц, год)
   */
  value:      string;
  disabled?: boolean;
}

const DateInput = ({ value: _value, disabled = false }: DateInputProps) => {
  const [value, setValue] = useState(_value ?? '');

  useEffect(() => {
    setValue(_value ?? '');
  }, [_value]);

  return (
    <div className={styles.dateInputWrapper}>
      <svg width="14" height="13" fill="none" >
        <path fillRule="evenodd" clipRule="evenodd" d="M.37 4.466c-.038.56-.038 1.262-.038 2.2 0 1.864 0 2.796.305 3.531A4 4 0 0 0 2.8 12.362c.735.304 1.667.304 3.531.304h1.333c1.864 0 2.796 0 3.531-.304a4 4 0 0 0 2.165-2.165c.304-.735.304-1.667.304-3.53 0-.939 0-1.64-.038-2.2H.37Zm.136-.933h12.986a2.89 2.89 0 0 0-.131-.397A4 4 0 0 0 11.196.97C10.461.667 9.53.667 7.666.667H6.331c-1.864 0-2.796 0-3.53.304A4 4 0 0 0 .636 3.136a2.898 2.898 0 0 0-.131.397Zm2.026 3.134c0-.258.209-.467.467-.467h.666a.467.467 0 1 1 0 .933H3a.467.467 0 0 1-.467-.466Zm7.8-.467a.467.467 0 0 0 0 .933h.667a.467.467 0 0 0 0-.933h-.667Zm-4.133.466c0-.257.209-.466.466-.466h.667a.467.467 0 1 1 0 .933h-.667a.467.467 0 0 1-.466-.466Zm-3.2 2.2a.467.467 0 1 0 0 .934h.666a.467.467 0 1 0 0-.934H3Zm6.866.467c0-.258.21-.466.467-.466h.667a.467.467 0 0 1 0 .933h-.667a.467.467 0 0 1-.467-.467Zm-3.2-.466a.467.467 0 0 0 0 .933h.667a.467.467 0 0 0 0-.934h-.667Z" fill="#B9BDC8" />
      </svg>
      <InputMask
        mask="99.99.9999"
        disabled={disabled}
        onChange={e => setValue(e.target.value)}
        value={value}
        alwaysShowMask={true}
      />
    </div >
  );
};

export default DateInput;
