import React, { HTMLProps, SVGProps } from 'react';
import classNames from 'classnames';

import styles from './Progress.module.scss';

type ProgressProps = HTMLProps<SVGElement> & {
  /**
   * Принимает в себя значения от 0 до 100
   * 
   * Значение больше 100 обрезает до 100
   * 
   * Значение меньше 0 до 0
   */
  value: number;

  /** Тип прогресса */
  type?: "circle" | "line";
}

const Progress = (props: ProgressProps) => {
  const { value = 0, type = 'line', className, width, height } = props;
  const val = value > 100 ? 100 : value < 0 ? 0 : value;

  const radius = 54;
  /** Длина окружности */
  const circumference = 2 * Math.PI * radius;

  return (
    <div className={classNames(styles.progressWrap, className)}>
      {(() => {
        switch (type) {
          case 'line': return (
            <>
              <div className={styles.progress}>
                <div className={styles.trail} />
                <div className={styles.filled} style={{ width: (val ?? 0) + "%" }} />
              </div>
              <span className={classNames(styles.label, val === 0 && styles.labelGray)} >
                {val ?? '-'}%
              </span>
            </>
          )
          case 'circle': return (
            <div className={styles.circleWrapper}>
              <svg width={width ?? "1em"} height={height ?? "1em"} viewBox="0 0 120 120">
                <defs>
                  <linearGradient id="linearColors" x1="1" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor="#3736FF"></stop>
                    <stop offset="80%" stopColor="#DC01FF"></stop>
                  </linearGradient>
                </defs>

                <circle
                  cx="60" cy="60" r="54"
                  strokeWidth="10"
                  stroke="rgb(185, 189, 200, 0.3)"
                  fill="none"
                />
                <circle
                  cx="60"
                  cy="60"
                  r="54"
                  fill="none"
                  strokeWidth="10"
                  stroke="url(#linearColors)"
                  strokeLinecap="square"
                  strokeDashoffset={circumference * (1 - value / 100)}
                  strokeDasharray={circumference}
                />
              </svg>
            </div>
          )
        }
      })()}
    </div>
  );
};

export default Progress;
