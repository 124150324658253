import React, { useState, useEffect, HTMLAttributes, useCallback } from 'react';
import classNames from 'classnames';
import usePrevious from '../../../utils/use-previous';
import styles from './TextInput.module.scss';

interface TextInputProps extends Omit< HTMLAttributes<HTMLInputElement>, 'onChange' > {
  textError?:    string | JSX.Element;
  icon?:         JSX.Element;
  iconRight?:    JSX.Element;
  button?:       JSX.Element;
  placeholder?:  string;
  size?:         string;
  status?:       string;
  value?:        string;
  defaultValue?: string;
  inputType?:    string;
  height?:       string;
  onChange?:     (value?: string, isValid?: boolean) => void;
  onBlur?:       () => void;
  validateAs?: string;
}

const TextInput = ({
  style,
  className,
  textError,
  icon,
  iconRight,
  button,
  placeholder = '',
  size = "default",
  status = '',
  value = '',
  defaultValue,
  inputType = "text",
  height,
  onChange,
  onBlur,
  validateAs = '',
}: TextInputProps) => {
  const [localValue, setLocalValue] = useState(value ?? defaultValue ?? '');

  const getClassOfSize = () => {
    if (size === "large") {
      return styles.textInputWrapperLarge;
    } else {
      return styles.textInputWrapperDefault;
    }
  };

  const handleChange = useCallback((value: string) => {
    setLocalValue(value);

    let isValid = true;
    if (validateAs === 'address') {
      isValid = value.startsWith('0x') && value.length === 42;
    }

    if (onChange) {
      onChange(value, isValid);
    }
  }, [onChange, validateAs]);

  const prevValue = usePrevious(value) ?? '';
  useEffect(() => {
    if (value !== prevValue) {
      handleChange(value);
    }
  }, [value]);

  const getClassOfStatus = () => {
    if (status === "error") {
      return styles.textInputWrapperStatusError;
    }
    else {
      return "";
    }
  };

  return (
    <div style={style}>
      <div
        className={
          classNames(
            styles.textInputWrapper,
            getClassOfSize(),
            getClassOfStatus(),
            className
          )
        }
      >
        <input
          type={inputType}
          className={classNames(
            styles.textInput,
            (icon && localValue.length === 0) && styles.textInputHasIcon
          )}
          placeholder={placeholder}
          value={localValue ?? ''}
          onChange={e => handleChange(e.target.value)}
          onBlur={() => onBlur && onBlur()}
          style={{ height: button ? '50px' : height ? height : '40px' }}
        />

        {validateAs === 'address' &&
          <span className={styles.textError}>
            {(() => {
              if (value.length === 0) {
                return 'Can\'t be empty';
              }
              else if (!value.startsWith('0x')) {
                return 'Must start with "0x"';
              }
              else if (value.length !== 42) {
                return 'Must be exactly 42 characters long';
              }
            })()}
          </span>
        }

        {icon && localValue.length === 0 &&
          <div className={styles.textInputIcon}>
            {icon}
          </div>
        }

        {button &&
          <div className={styles.textInputButton}>
            {button}
          </div>
        }

        {iconRight &&
          <div className={`${styles.textInputIconPositionRight}`}>
            {iconRight}
          </div>
        }
      </div>
      {textError &&
        <p className={styles.textError}>{textError}</p>
      }
    </div>
  );
};

export default TextInput;