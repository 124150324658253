import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";

type CommonStateType = {
  dataSourceID: number;
};

const initialState: CommonStateType = {
  dataSourceID: -1
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDataSourceID(state, action: PayloadAction<number>) {
      const id = action.payload;
      state.dataSourceID = id;
    }
  }
});

// Actions

export const { setDataSourceID } = commonSlice.actions;

// Selectors

export const userSelector = state => state.common;

export const selectDataSourceID = createSelector(
  userSelector,
  (state: CommonStateType) => state.dataSourceID
);

export default commonSlice.reducer;