import { fetchContracts } from ".";
import store from "../store";
import { addGroup, setTable, TableData } from "../store/settingsSlice";
import { getDataSourceID, setDataSourceID } from "../utils/data-source-id";

const { dispatch } = store;

/**
 * Делает запрос на `/api/data_source/view` на получение всех контрактов и групп
 * 
 * Затем пушит их в Redux store
 */
export async function fetchDataSources() {
  const response = await fetchContracts();

  const { groups } = store.getState().settings;

  const rawGroups = [];

  // @ts-ignore
  const data: TableData[] = response.value
    .map(obj => {
      const { is_default, id, type, name } = obj;
      if (type === 'contract_group') {
        rawGroups.push(obj);
        return null;
      }

      return {
        is_default,
        name,
        id,
        address:    obj.contract_adress,
        s3location: obj.s3location,
        track:      type.slice(0, 1).toUpperCase() + type.slice(1),
        lastUpload: new Date(obj.date_last_edited).toLocaleDateString('ru-RU').replace(/\//g, ''),
        coverage: 0,
        isActive: obj.active,
        selected: false,
        options: {
          type,
          trackDisabled: type === 'wallet'
        }
      };
    })
    .filter(Boolean);

  rawGroups.forEach(g => {
    // Проверяем, есть ли уже такая группа
    const found = groups.find(group => group.id === g.id);
    // Если нет, создаем ее
    if (!found) {
      // Пока что работает тупая система поиска IDшников по адресам
      // Да, это создаст баги и не универсально
      const ids = g.contract
        .map(c => c.contract_adress)
        .map(address => data.find(r => r.address === address)?.id);
      dispatch(addGroup({ name: g.name, id: g.id, ids }));
    }
  });

  dispatch(setTable(data));

  let dataSourceID = getDataSourceID();
  // Ищем строку в таблице с нужным `data_source_id`
  let found = false;
  const dataSources = [...data, ...rawGroups];
  for (let r of dataSources) {
    if (r.id === dataSourceID) {
      found = true
      break;
    }
  }

  if (found) {
    setDataSourceID(dataSourceID);
  }
  else if ((!found || dataSourceID === -1) && dataSources.length) {
    const defaultDataSource = dataSources.find(item => item?.is_default) || dataSources[0];
    setDataSourceID(defaultDataSource.id);
  }
  else if (dataSourceID === -1) {
    if (window.location.pathname !== '/settings') {
      window.location.href = '/settings';
    }
    return;
  }
}