import React, { useState, useEffect, useRef, HTMLAttributes } from 'react';
import classNames from 'classnames';
import Options, { Option } from '../../../../../components/UI/Options/Options';
import styles from './OptionsMenu.module.scss';

interface OptionsMenuProps extends HTMLAttributes<HTMLDivElement> {
  options: Option[];
}

const OptionsMenu = ({
  className,
  options,
  style
}: OptionsMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const contextMenu = useRef<HTMLDivElement>();
  const contextMenuButton = useRef<HTMLButtonElement>();

  useEffect(() => {
    const handleClickOutside = e => {
      // Клик вне дропдауна
      if (!contextMenu.current?.contains(e.target) && !contextMenuButton.current?.contains(e.target)) {
        setIsOpen(false)
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    document.addEventListener('contextmenu', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      document.removeEventListener('contextmenu', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={classNames(styles.contextMenuWrapper, className)}
      style={style}
    >
      <button
        ref={contextMenuButton}
        className={styles.addData}
        onClick={() => setIsOpen(!isOpen)}
        aria-label={(isOpen ? 'Close' : 'Open') + ' menu'}
      >
        <img
          src="/assets/svg/dashboard/context-menu-icon.svg"
          alt="Add"
          aria-hidden="true"
        />
      </button>

      {isOpen &&
        <div
          className={styles.popup}
          ref={contextMenu}
        >
          <Options data={options} />
        </div>
      }
    </div>
  );
};

export default OptionsMenu;
