import classNames from 'classnames';
import React from 'react';

import styles from './Circle.module.scss';

interface CircleProps {
  /** Цвет круга */
  color?:     string;
  className?: string;
}

const Circle = ({ color, className }: CircleProps) => {
  return (
    <div
      className={classNames(styles.circle, className)}
      style={{ backgroundColor: color ?? 'black' }}
    />
  );
};

export default Circle;
