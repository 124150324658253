import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Dashboard/Sidebar/Sidebar';
import { HeaderBoard } from '../../components/Dashboard/HeaderBoard/HeaderBoard';
import FallbackUI from '../../components/Dashboard/FallbackUI';
import PlusIcon from '../../components/UI/Icons/PlusIcon';
import NotificationsPopup from './NotificationsPopup/NotificationsPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRecipes,
  selectIsPopupActive,
  setIsPopupActive,
  setRecipes
} from '../../store/notifications-recipes-slice';

import styles from './NotificationsRecipes.module.scss';

export default function NotificationsRecipes() {
  const pageName = 'Notifications Recipes';
  const [error, setError] = useState(false);
  const isPopupActive = useSelector(selectIsPopupActive);
  const dispatch = useDispatch();

  const recipes = useSelector(selectRecipes);

  useEffect(() => {
    let recipes = localStorage.getItem('recipes');
    if (recipes) {
      try {
        recipes = JSON.parse(recipes);
        // @ts-ignore
        dispatch(setRecipes(recipes));
      }
      catch (error) {
        console.error(error);
      }
    }
  }, []);

  return (
    <div className={styles.bg}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.wrapMain}>
          <HeaderBoard title={pageName} />
          {error
            ? <FallbackUI />
            :
            <>
              <h1 className={styles.tableTitle}>
                Notifications recipes
              </h1>

              <button
                className={styles.addButton}
                onClick={() => dispatch(setIsPopupActive(true))}
              >
                <PlusIcon />
                Create Recipe
              </button>

              <div className={styles.pageInfo}>
                <span>
                  Number of total notifications: {recipes.length}
                </span>
                <span>
                  Last updated: May 1st, 2022
                </span>
              </div>

              <div className={styles.notificationsContainer}>
                {/* @ts-ignore */}
                {recipes.map((tile, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.notificationsTile}
                    >
                      <div className={styles.logo}>
                        <img src={tile.icon} alt="" />
                      </div>

                      <div className={styles.info}>
                        <span className={styles.name}>
                          {tile.name}

                          {/* Сказали всегда рисовать зеленый кружок */}
                          {true || tile?.active
                            ?
                            <svg width="7" height="7" viewBox="0 0 7 7" fill="#2EB67D">
                              <circle cx="3.5" cy="3.5" r="3.5" />
                            </svg>
                            :
                            <svg width="7" height="7" viewBox="0 0 7 7" fill="#B9BDC8">
                              <circle cx="3.5" cy="3.5" r="3.5"/>
                            </svg>
                          }
                          
                        </span>

                        {/* <span className={styles.description}>{tile.description}</span> */}
                        {/* <span className={styles.extraInfo}></span> */}
                      </div>
                    </div>
                  )
                })

                }
              </div>
              {isPopupActive &&
                <div className={styles.popupContainer}>
                  <NotificationsPopup className={styles.notificationAddPopup} />
                </div>
              }
            </>
          }
        </div>
      </div>
    </div>
  );
}