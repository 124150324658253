import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../../components/Dashboard/Pagination';
import { columns } from './columns';
import TableRow from '../../settings/Table/TableRow';
import TableHead from '../../settings/Table/TableHead';
import { ITEMS_PER_PAGE, selectCurrentPage, selectData, selectTotal, setCurrentPage, setSelectedRowIndex } from '../../../store/web-events-slice';
import { selectIsAnalyticsInitialized, selectTenantName } from '../../../store/userSlice';

import styles from './style.module.scss';

export default function Table() {
  const dispatch = useDispatch();

  const isAnalyticsInitialized = useSelector(selectIsAnalyticsInitialized);
  const data = useSelector(selectData);
  const currentPage = useSelector(selectCurrentPage);
  const total = useSelector(selectTotal);
  const tenantName = useSelector(selectTenantName);

  return (
    <div style={{ flexGrow: '1' }}>
      <div className={styles.tableWrap}>
        <TableHead columns={columns} />
        {data.map((data, index) =>
          <TableRow
            key={index}
            columns={columns}
            data={data}
            onClick={e => dispatch(setSelectedRowIndex(index))}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>

      <Pagination
        page={currentPage}
        itemsPerPage={ITEMS_PER_PAGE}
        total={total}
        onChange={currentPage => {
          if (isAnalyticsInitialized) {
            // @ts-ignore
            merlin.send({
              event_name: 'web_events_pagination',
              event_type: 'click',
              event_properties: {
                page_number: currentPage
              },
              user_properties: {
                tenantName
              }
            });
          }
          dispatch(setCurrentPage(currentPage));
        }}
        style={{ margin: '30px 0' }}
      />
    </div>
  );
};
