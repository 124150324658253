export default function operatorToSymbol(operator: string) {
  switch (operator) {
    case 'within':       return '>';
    case 'contains':     return '==';
    case 'not_contains': return '!=';
    case 'equals':       return '=';
    case 'greater than': return '>';
    case 'less than':    return '<';
    default: {
      console.error('unknown operator');
      return operator;
    }
  }
};