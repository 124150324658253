import React, { HTMLProps } from 'react';
import classNames from 'classnames';
import styles from './Stacked.module.scss';

type StackedProps = HTMLProps<HTMLDivElement> & {
  /** Maximum number of elements to show */
  max: number;
}

export const Stacked = (props: StackedProps) => {
  const { className, children } = props;
  const max = props?.max ?? 3;
  const numberOfChildren = Array.isArray(children) ? children.length : 1;

  return (
    <div
      {...props}
      className={classNames(styles.wrapper, className)}
    >
      {Array.isArray(children)
        ?
        children
          .slice(0, max)
          .map((child, index) =>
            <div
              className={styles.child}
              style={{
                zIndex:  index > 0 && -index,
                right: -(index * 5) + 'px'
              }}
            >
              {child}
            </div>
          )
        : children
      }

      {numberOfChildren !== 1 &&
        <span
          className={styles.count}
          style={{ right: -(max * 5) - 20 + 'px' }}
        >
          {numberOfChildren}
        </span>
      }
    </div>
  );
};