import React, { useState, useRef, useEffect } from 'react';
import styles from './MainPage.module.scss';

import {RollingText} from "../../components/UI"

import { ReadyToGetStarted } from '../../components/ReadyToGetStarted/ReadyToGetStarted';
import { AnswerQuestion } from '../../components/AnswerQuestion/AnswerQuestion';
import { Anchor } from '../../components/Anchor/Anchor';
import { Slider } from '../../components/Slider/Slider';
// MOBILES
// import { TabletSlider } from "../../mobile/Tablet/Slider/TabletSlider";
import { MSizeSlider } from '../../mobile/MSize/Slider/MSizeSlider';
import { GetStartedMobileSlider } from '../../mobile/MSize/GetStartedMobileSlider/GetStartedMobileSlider';
import { TabletSlider } from '../../mobile/Tablet/Slider/TabletSlider';
import { BackedBy } from '../../components/BackedBy/BackedBy';
import { Header } from '../../components/Header/Header';
import { Understanding } from '../../components/Understanding/Understanding';
import { GetStarted } from '../../components/GetStarted/GetStarted';
import Footer from '../../components/Footer/Footer';
import SimpleOnChain from '../../components/SimpleOnChain';
import SimpleOnChainContent from '../../components/SimpleOnChainContent';
import IBM from '../../components/IBM';

import Media from 'react-media';
import { LineChart } from 'recharts';

const MainPage = () => {
  const ibmRef = useRef(null);

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  })

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimenion])

  const isAnnoncementShow = false; // the variable is to switch showing Announcement

  const Annoncement = () => {
      return (
        <div style={{position: "absolute", width: "100%", top: "0", left: "0", zIndex: "100"}}>
          {isAnnoncementShow === true && (
            <div onClick={() => {window.open('/privacy_policy', '_blank')}} style={{"cursor": "pointer"}}>
            <RollingText duration="120s" text="We just raised $X with participation from South Park Commons and Script Capital.  Read moreㅤ→ㅤ">
            </RollingText>
            </div>
          )}
        </div>
      )
  }

  const scrollToElem = () =>
    window.scrollTo({
      top: ibmRef.current.offsetTop - 100,
      behavior: 'smooth',
    });
  return (
      <Media
        queries={{
          desktop: '(min-width: 1350px)',
          tablet: '(min-width: 500px) and (max-width: 1349px)',
          mobile: '(max-width: 499px)',
        }}
      >
        {(matches) => (
          <React.Fragment>
            {matches.desktop && (
              
              <div className={styles.appWrapperOwner}>
                <div 
                  className={styles.bgStarWrapper} 
                >
                </div>
                <Annoncement />
                <div className={styles.appWrapper}>
                  
                  <Header isAnnoncementShow={isAnnoncementShow} scrollToElem={scrollToElem} />
                  <SimpleOnChain />
                  <SimpleOnChainContent />
                  <BackedBy />
                  <Understanding />
                  <Slider 
                    slidesPerView={3}
                    spaceBetween={20}
                    freeMode={false}
                    centerInsufficientSlides={true}
                    pagination={{
                      clickable: true,
                    }} 
                  />
                  <GetStarted />
                  <AnswerQuestion />
                  
                  <Anchor id={"IBM-section"} anchorOffetY={30} />
                  <IBM ibmRef={ibmRef} />
                  <ReadyToGetStarted />
                  <Footer  scrollToElem={scrollToElem} />
                </div>
              </div>
            )}
            {matches.tablet && (
              <div className={styles.appWrapperOwner}>
                <div 
                  className={styles.bgStarWrapper} 
                >
                </div>
                <Annoncement />
                <div className={styles.appWrapper}>
                  <Header isAnnoncementShow={isAnnoncementShow}  />
                  <SimpleOnChain />
                  <SimpleOnChainContent />
                  <BackedBy />
                  <Understanding />
                  <Slider
                    slidesPerView={windowDimenion.winWidth <= 1000 ? 2 : 3}
                    spaceBetween={20}
                    freeMode={false}
                    centerInsufficientSlides={true}
                    pagination={{
                      clickable: true,
                    }}
                  />
                  <GetStarted />
                  <AnswerQuestion />
                  <Anchor id={"IBM-section"} anchorOffetY={30} />
                  <IBM />
                  <ReadyToGetStarted />
                  <Footer />
                </div>
              </div>
            )}
            {matches.mobile && (
              <div className={styles.appWrapperOwner}>
                <div 
                  className={styles.bgStarWrapper} 
                >
                </div>
                <Annoncement />
                <div className={styles.appWrapper}>
                  <Header isAnnoncementShow={isAnnoncementShow}  />
                  <SimpleOnChain />
                  <SimpleOnChainContent />
                  <BackedBy />
                  <Understanding />
                  {/* <MSizeSlider /> */}
                  <Slider
                    slidesPerView="auto"
                    spaceBetween={10}
                    freeMode={false}
                    centerInsufficientSlides={true}
                    pagination={{
                      clickable: true,
                    }} 
                  />
                  <GetStartedMobileSlider />
                  <AnswerQuestion />
                  <Anchor id={"IBM-section"} anchorOffetY={40} />
                  <IBM />
                  <ReadyToGetStarted />
                  <Footer />
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Media>
  );
};

export default MainPage;
