import { useRef } from 'react';
import { GetStartedTitle } from '../../../components/GetStarted/GetStartedTitle/GetStartedTitle.jsx';
import { InfoBlock } from '../../../components/GetStarted/InfoBlock/InfoBlock';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Autoplay  } from "swiper";
import 'swiper/css';

import styles from './GetStartedMobileSlider.module.scss';

import secondStyles from '../../../components/GetStarted/GetStarted.module.scss';

export const GetStartedMobileSlider = () => {

  return (
    <div className={styles.SliderWrapper}>
      <GetStartedTitle />
      <br></br>
      <Swiper
        slidesPerView="auto"
        
        height={500}
        spaceBetween={0}
        
        centerInsufficientSlides={true}
        pagination={{
          clickable: true,
        }}
        
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: true,
        // }}
        modules={[Autoplay]}
      >
        <SwiperSlide className={styles.SwiperSlide}>
          <InfoBlock
            img='node-search.svg'
            title='No-code Setup'
            subtitle='Index on-chain data, create relevant schemas, and measure what matters without writing a single line of code.'
            jsonName={'lottieJsonNodeSearch'}
            widthLottie={220}
            heightLottie={43}
            speed={2}
            isShowOnlyAnimation={true}
            classNameImg={secondStyles.nodeSearch}
          />
        </SwiperSlide>
        <SwiperSlide className={styles.SwiperSlide}>
        <InfoBlock
          img='link-session-data.svg'
          title='Link Session Data'
          subtitle='Use our secure SDK to link wallets with encrypted session data to capture end-to-end user lifecycle.'
          jsonName={'lottieJsonLinkSessionData'}
          widthLottie={320}
          heightLottie={80}
          speed={3}
          isShowOnlyAnimation={true}
          classNameImg={secondStyles.linkSessionВeta}
        />
        </SwiperSlide>
        <SwiperSlide className={styles.SwiperSlide}>
          <InfoBlock
            img='infinite-integrations.svg'
            title='Infinite Integrations'
            subtitle={`User behaviour can't be measured in silos. Bring together on-chain and off-chain data points to create one holistic view.`}
            jsonName={'lottieJsonInfiniteIntegrations'}
            widthLottie={247}
            heightLottie={209}
            speed={1}
            isShowOnlyAnimation={true}
            classNameImg={secondStyles.infiniteIntegrations}
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

